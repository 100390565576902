<template>
  <div class="panel-menu-wrapper">
    <div class="panel-title" v-if="title" :class="styleTitle">
      <h2>
        <slot name="title">Only this Store</slot>
      </h2>
    </div>
    <div class="panel-menu-body">
      <slot name="row"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Panel",
  props: {
    title: {
      type: Boolean,
      default: true
    },
    styleTitle: {
      type: String,
      default: "yellow"
    }
  }
};
</script>

<style lang="css" scoped>
.panel-menu-wrapper {
	 overflow: hidden;
	 margin-bottom: 2.5rem;
	 border-bottom-right-radius: 0.5rem;
	 border-bottom-left-radius: 0.5rem;
}
 .panel-menu-wrapper:last-child {
	 margin-bottom: 0;
}
 .panel-title {
	 padding: 1rem;
	 border-top-left-radius: 0.5rem;
	 border-top-right-radius: 0.5rem;
}
 .panel-title.yellow {
	 background-color: #fbbf24;
}
 .panel-title.red {
	 background-color: #fa2d60;
}
 h2 {
	 color: #fff;
	 font-size: 1.125rem;
	 line-height: 1.75rem;
	 text-transform: uppercase;
   margin: 0;
}
 .panel-menu-body {
	 background-color: #f9fafb;
}
 
</style>