const getCustomerScreenDefaultState = () => {
  return {
    status: false,
    CustomerScreen: [],
    a_name: 'test name',
    a_desc: 'test desc',
    pictureControl: -1,
    myCroppa: {},
    // Validation
    formValidation: {
      a_name: { isValid: false, errorMessage: '' },
      a_desc: { isValid: false, errorMessage: '' },
    },
  };
};

const CustomerScreens = {
  state: getCustomerScreenDefaultState(),
  mutations: {
    RESET_CustomerScreen_STATE(state) {
      Object.assign(state, getCustomerScreenDefaultState());
    },
  },
  actions: {
    closeCustomerScreenModalBox({ commit }) {
      commit('RESET_CustomerScreen_STATE');
      commit('RESET_MODALBOX');
    },
    getCustomerScreen(state, CustomerScreenHash) {
      console.log(CustomerScreenHash);
    },
  },
};

export default CustomerScreens;
