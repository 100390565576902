<template>
  <div
    class="product-group"
    :class="!gitem.type ? ['yellow'] : ['green']"
  >
    <div class="group-header">
      <div class="group-text">
        <div class="mr-2 input-group">
          <input
            id="txtProductName"
            name="txtProductName"
            type="text"
            placeholder="Group Name"
            v-model="gitem.name"
          />
        </div>
        <div class="input-group">
          <input
            id="txtDescription"
            name="txtDescription"
            type="text"
            placeholder="Description..."
            v-model="gitem.description"
          />
        </div>
      </div>
      <div class="actions">
        <button class="moveclass" @click="moveItem(gitem.ghash)">
          <i class="flaticon-move"></i>
        </button>
        <button @click.prevent="deleteItem()">
          <i class="flaticon-close"></i>
        </button>
        <div class="confirm" v-if="isDeletePanelActive">
          <p>Are you sure you want to delete this group?</p>
          <div>
            <button @click="deleteYes(gitem.ghash)">Yes</button>
            <button @click="isDeletePanelActive = false">No</button>
          </div>
        </div>
      </div>
    </div>
    <div class="checkbox-content">
      <div class="checkbox-group-wrapper">
        <div class="checkbox-group">
          <label class="mr-2" :for="`chkHalfHalf-${gitem.ghash}${gitem.name}`">Half & Half
            <ToggleCheckbox
            @clickToggle="setHhStatus()" 
            :onOff="gitem.hhstatus == 1"></ToggleCheckbox>
          </label>
        </div>
      </div>
      <div class="checkbox-group-wrapper">
        <div class="checkbox-group" >
          <label class="mr-2" :for="`chkExtra-${gitem.ghash}${gitem.name}`">Extra
            <ToggleCheckbox
            @clickToggle="setExtraStatus()" 
            :onOff="gitem.estatus == 1"></ToggleCheckbox>
          </label>
        </div>
      </div>
      <div class="flex-row items-center mr-2 input-group" v-if="gitem.type===1">
        <label for="txtFree" class="mr-2 text-base">Free Item</label>
        <input id="txtFree" name="txtFree" type="number" placeholder="Free" min="0" v-model="gitem.free" />
      </div>
      <div class="flex-row items-center input-group mr-2">
        <label for="txtMinimum" class="mr-2">Minimum</label>
        <input
          id="txtMinimum"
          name="txtMinimum"
          type="number"
          min="0"
          placeholder="Min."
          v-model="gitem.min"
        />
      </div>
      <div class="flex-row items-center mb-2">
        <label for="txtFree" class="mr-2 text-base">Size View</label>
        <ToggleCheckbox
          @clickToggle="changeStatus($event)" 
          :onOff="isSizeOpen" 
        ></ToggleCheckbox>
      </div>
    </div>
    <div class="flex-row items-center">
      <div class="w-20 mr-2">Printing</div>
      <div class="radio-group-wrapper">
        <div class="radio-group">
          <input
            :id="`printingType-${gitem.ghash}${gitem.name}`"
            :name="`printing-${gitem.name}`"
            type="radio"
            v-model="gitem.type_receipt"
            :value="0"
          />
          <label :for="`printingType-${gitem.ghash}${gitem.name}`">Dive</label>
        </div>
        <div class="radio-group">
          <input
            :id="`standart-${gitem.ghash}${gitem.name}`"
            :name="`printing-${gitem.name}`"
            type="radio"
            v-model="gitem.type_receipt"
            :value="1"
          />
          <label :for="`standart-${gitem.ghash}${gitem.name}`">Standart</label>
        </div>
        <div class="radio-group">
          <input
            :id="`toppings-${gitem.ghash}${gitem.name}`"
            :name="`printing-${gitem.name}`"
            type="radio"
            v-model="gitem.type_receipt"
            :value="2"
          />
          <label :for="`toppings-${gitem.ghash}${gitem.name}`">Toppings</label>
        </div>
        <div class="radio-group">
          <input
            :id="`extra-${gitem.ghash}${gitem.name}`"
            :name="`printing-${gitem.name}`"
            type="radio"
            v-model="gitem.type_receipt"
            :value="3"
          />
          <label :for="`extra-${gitem.ghash}${gitem.name}`">Extra</label>
        </div>
      </div>
    </div>
    <div class="flex-row items-center">
      <div class="w-20 mr-2">POS View</div>
      <div class="radio-group-wrapper">
        <div class="radio-group">
          <input
            :id="`dive-${gitem.ghash}${gitem.name}`"
            :name="`posView-${gitem.name}`"
            type="radio"
            v-model="gitem.type_pos"
            :value="0"
          />
          <label :for="`dive-${gitem.ghash}${gitem.name}`">Dive</label>
        </div>
        <div class="radio-group">
          <input
            :id="`standartPOS-${gitem.ghash}${gitem.name}`"
            :name="`posView-${gitem.name}`"
            type="radio"
            v-model="gitem.type_pos"
            :value="1"
          />
          <label :for="`standartPOS-${gitem.ghash}${gitem.name}`">Standart</label>
        </div>
        <div class="radio-group">
          <input
            :id="`toppingsPOS-${gitem.ghash}${gitem.name}`"
            :name="`posView-${gitem.name}`"
            type="radio"
            v-model="gitem.type_pos"
            :value="2"
          />
          <label :for="`toppingsPOS-${gitem.ghash}${gitem.name}`">Toppings</label>
        </div>
        <div class="radio-group">
          <input
            :id="`extraPOS-${gitem.ghash}${gitem.name}`"
            :name="`posView-${gitem.name}`"
            type="radio"
            v-model="gitem.type_pos"
            :value="3"
          />
          <label :for="`extraPOS-${gitem.ghash}${gitem.name}`">Extra</label>
        </div>
      </div>
    </div>
    <draggable
      class="content"
      v-model="gitem.icindekiler"
      :list="gitem.icindekiler"
      :options="{ group: { name:'optgroup', pull: false, put: ['optitems'] } }"
      @add="checkMove($event)"
    >
      <ProductGroupElement
        class="icitem"
        :class="{ chk: item.selected === 1 }"
        v-for="(item, index) in gitem.icindekiler"
        :key="`${item.ohash}${index}`"
        :gelement="item"
        :grecipe="item.recipe"
        :gtype="gitem.type"
        :isSizeOpen="isSizeOpen"
        :checkboxID="generateID(item.ohash)"
        @click-child="deleteGroupItem"
      ></ProductGroupElement>
    </draggable>
  </div>
</template>

<script>
import { isEqual, differenceWith } from "lodash";
import { mapState } from "vuex";
import draggable from "vuedraggable";
import ProductGroupElement from "./ProductGroupElement.vue";
import ToggleCheckbox from "@/widgets/ToggleCheckbox";

export default {
  name: "ProductGroup",
  props: {
    gitem: {
      type: Object,
    }
  },
  data() {
    return {
      isSizeOpen: false,
      isDeletePanelActive: false,
    };
  },
  components: {
    draggable,
    ProductGroupElement,
    ToggleCheckbox
  },
  computed: {
    ...mapState({
      stateOptions: "options",
      stateProducts: "products"
    })
  },
  methods: {
    changeStatus(value) {
      this.isSizeOpen = value;
    },
    generateID(hash) {
      return `${hash}${Math.floor(Math.random() * 100)}`;
    },
    checkMove(event) {
      let $self = this;
      let gItemIdx = this.stateProducts.product.groups.findIndex(
        (item) => item.ghash === $self.gitem.ghash
      );
      if (event.item.attributes.tur.value === "0") {
        this.stateProducts.product.groups[gItemIdx].icindekiler.map((item, index) => {
          if (item.ohash === this.stateProducts.product.draggingHash && index !== event.newIndex) {
            this.stateProducts.product.groups[gItemIdx].icindekiler.splice(
              event.newIndex,
              1
            );
          }
        });
      } else {
        console.log('all options')
        // all options
        this.stateOptions.options.map((option) => {
          if(option.ghash === event.item.attributes.ghash.value) {
            let groupAllItems = option.oitems;
            let addingGroupItems = this.stateProducts.product.groups[gItemIdx].icindekiler;
            let diff = differenceWith(groupAllItems, addingGroupItems, isEqual);
            if (diff.length > 0) {
              diff.map((item) => {
                // if item.ohash is already in the list, don't add it
                let isExist = this.stateProducts.product.groups[gItemIdx].icindekiler.some((el) => el.ohash === item.ohash);
                if(!isExist) {
                  this.stateProducts.product.groups[gItemIdx].icindekiler.push(item);
                }
              });
            }
          }
        })
        gItemIdx = -1;
      }
      this.stateProducts.product.draggingHash = "";
      this.stateProducts.product.draggingGroupHash = "";
    },
    moveItem(hash) {
      console.log(hash, 'move group');
    },
    deleteItem() {
      this.isDeletePanelActive = true;
    },
    deleteYes(hash) {
      this.stateProducts.product.groups = this.stateProducts.product.groups.filter((item) => item.ghash !== hash);
    },
    deleteGroupItem(hash) {
      this.gitem.icindekiler = this.gitem.icindekiler.filter(
        (item) => item.ohash !== hash
      );
    },
    setHhStatus() {
      this.gitem.hhstatus = this.gitem.hhstatus === 1 ? 0 : 1;
    },
    setExtraStatus() {
      this.gitem.estatus = this.gitem.estatus === 1 ? 0 : 1;
    }
  }
};
</script>

<style lang="css" scoped>
.product-group {
  position: relative; 
  padding: 0.5rem; 
  margin-bottom: 1rem; 
  font-weight: 600; 
  border-radius: 0.5rem; 
  border-width: 1px; 
  border-style: solid; 
  cursor: pointer; 
}
.group-header {
  display: flex; 
  margin-bottom: 0.5rem; 
  flex-direction: row; 
  justify-content: space-between; 
}
.group-text {
  display: flex; 
  margin-right: 0.5rem; 
  flex-direction: row; 
}
.checkbox-content {
  display: flex; 
  flex-direction: row; 
  align-items: baseline;
}
.check-option {
  line-height: 2rem; 
}
.checkbox-group-wrapper .checkbox-group label::before {
  display: none;
}
.checkbox-group-wrapper .checkbox-group label {
  padding-left: 0;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-direction: row;
}
.radio-group-wrapper {
  display: flex; 
  flex-direction: row; 
  align-items: center; 
}
.content {
  min-height: 120px;
  padding: 0.5rem; 
  background-color: #ffffff; 
  border-radius: 0.375rem; 
}
.actions button {
  cursor: pointer;
  font-size: 1.6em;
  line-height: 1.5rem;
  padding: 0 10px;
  background: none;
  border: none;
  outline: none;
  font-weight: 600;
  border-radius: var(--rounded-lg);
}
.confirm button {
  background: #17b3e6;
  font-size: 16px;
  line-height: 50px;
  display: inline-block;
  padding: 0 40px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  margin: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
button {
  font-size: 1.875rem;
  line-height: 2.25rem; 
}
input[type="number"] {
  width: 4rem; 
}
/* custom */
.yellow {
  background-color: #FFFBEB; 
  border-color: #FEF3C7; 
}
.green {
  background-color: #ECFDF5; 
  border-color: #D1FAE5; 
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.items-center {
  align-items: center;
}
.mt-2 {
  margin-top: 0.5rem; 
}
.mr-2 {
  margin-right: 0.5rem; 
}
.mb-2 {
  margin-bottom: 0.5rem; 
}
.w-20 {
  width: 5rem; 
}
.radio-group {
  margin-bottom: 0!important;
}
</style>