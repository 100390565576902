<template>
  <div 
    class="sp-wrapper" 
    :class="isActiveStatus ? ['active']: ''"
  >
    <div class="mr-2">{{ vItem.name }}</div>
    <div class="flex-col">
      <div class="flex-row items-center mr-2">
        <div class="mr-2" v-if="isActiveStatus">
          <label
            class="default-label"
            :class="vItem.default_select == 1 ? ['default-label--active']: ''"
            @click="changeDefault(vItem.code)"
          >Default</label>
        </div>
        <ToggleCheckbox 
          @clickToggle="changeStatus($event)" 
          :onOff="isActiveStatus"
        ></ToggleCheckbox>
      </div>
      <!-- Price, Rewards, Deposit -->
      <div class="flex-col" v-if="isActiveStatus">
        <div class="mr-2">
          <div class="flex-col">
            <label for="txtPrice">Price</label>
            <input id="txtPrice" name="txtPrice"  type="number" min="0" v-model="vItem.price" @change="updatePrice($event.target.value)" />
          </div>
        </div>
        <div class="mr-2">
          <div class="flex-col">
            <label for="txtRewards">Rewards</label>
            <input id="txtRewards" name="txtRewards" type="number" min="0" v-model="vItem.rw" @change="updateRewards($event.target.value)" />
          </div>
        </div>
        <div class="mr-2 flex-col"> 
          <label for="txtDeposite">Deposit</label>
          <input id="txtPrice" name="txtPrice" type="number" min="0" v-model="vItem.dep" @change="updateDeposite($event.target.value)" />
        </div>
      </div>
    </div>
  </div> 
</template>

<script>
import ToggleCheckbox from "@/widgets/ToggleCheckbox";
import { mapState } from "vuex";

export default {
  name: "SizesPrices",
  data() {
    return {
      isActiveStatus: this.vItem.status,
    };
  },
  props: [ "vItem" ],
  components: {
    ToggleCheckbox
  },
  computed: {
    ...mapState({
      stateProducts: "products",
      stateSizes: state => state.products.product.sizes,
    })
  },
  methods: {
    updatePrice(newValue) {
      this.stateSizes.map((size) => {
        if (this.vItem.code == size.code) {
          size.price = newValue;
          return;
        }
      });
    },
    updateRewards(newValue) {
      this.stateSizes.map((size) => {
        if (this.vItem.code == size.code) {
          size.rw = newValue;
          return;
        }
      });
    },
    updateDeposite(newValue) {
      this.stateSizes.map((size) => {
        if (this.vItem.code == size.code) {
          size.dep = newValue;
          return;
        }
      }); 
    },
    changeStatus(value) {
      this.isActiveStatus = value;
      this.vItem.status = value;
      const index = this.stateSizes.findIndex(el => el.code === this.vItem.code);
      if (value) {
        this.$parent.dismissError('productPrice');
        if (index === -1) {
          this.stateSizes.push(this.vItem);
        }
      } else { 
        if (index !== -1) {
          this.stateSizes.splice(index, 1);
        }
        this.vItem.default_select = 0;
      }
      this.checkDefaultValue();
      this.stateProducts.currentSize = this.vItem;
    },
    // check the default select
    checkDefaultValue() {
      let isFound = this.stateProducts.sizes.some(el => el.default_select === 1);
      if(!isFound) {
        if(this.stateSizes.length > 0){
          this.stateProducts.sizes.find(el => el.status).default_select = 1;
        };
      } 
    },
    changeDefault(itemCode) {
      // find the size in stateProducts.sizes
      this.stateProducts.sizes.map((size) => {
        if (itemCode == size.code) {
          size.default_select = 1;
          // find the size in stateSizes
          this.stateSizes.map((size) => {
            // set other sizes to default_select = 0
            if (itemCode != size.code) {
              size.default_select = 0;
            } else {
              size.default_select = 1;
            }
          });
        } else {
          size.default_select = 0;
        }
        return;
      });
    }
  }
};
</script>

<style lang="css" scoped>
.sp-wrapper.active {
  padding: 1rem;
  margin: 0.25em 0;
  background-color: #F9FAFB;
  border-radius: 0.5rem;
  width: 150px;
}
.default-label {
  font-size: 0.75rem;
  line-height: 1rem; 
  line-height: 2rem;  
}
.default-label--active {
  padding: 0.5rem;
  background-color: #BFDBFE; 
  color: #2563EB; 
  border-radius: 0.5rem; 
}
input[type="text"], 
input[type="number"] {
  width: 100%;
  font-size: 1rem;
}
/* cusotom */
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.items-center {
  justify-content: space-between;
  align-items: center;
}
.w-full {
  width: 100%; 
}
.w-33 {
  width: 33.333333%; 
}
.mt-4 {
  margin-top: 1rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
</style>
