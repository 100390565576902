<template>
  <div
    class="coupon-group"
  >
    <div class="group-header">
      <div class="group-text">
        <div class="mr-2 input-group">
          <input
            id="txtProductName"
            name="txtProductName"
            type="text"
            placeholder="Group Name"
            v-model="citem.name"
          />
        </div>
        <div class="input-group">
          <input
            id="txtDescription"
            name="txtDescription"
            type="text"
            placeholder="Description..."
            v-model="citem.description"
          />
        </div>
      </div>
      <div class="actions">
        <button class="moveclass">
          <i class="flaticon-move"></i>
        </button>
        <button @click.prevent="deleteItem()">
          <i class="flaticon-close"></i>
        </button>
        <div class="confirm" v-if="isDeletePanelActive">
          <p>Are you sure you want to delete this group?</p>
          <div class="delete">
            <button @click="deleteYes(citem.chash)">Yes</button>
            <button @click="isDeletePanelActive = false">No</button>
          </div>
        </div>
      </div>
    </div>
    <draggable
      class="content"
      v-model="citem.icindekiler"
      :list="citem.icindekiler"
      :options="{ group: { name: 'cupgroup', pull: false, put: ['cupitems'] } }"
      @add="checkMove($event)"
    >
      <CouponGroupElement
        class="icitem"
        :class="{ chk: item.selected === 1 }"
        v-for="(item, index) in citem.icindekiler"
        :key="`${item.phash}${index}`"
        :pelement="item"
        @click-child="deleteGroupItem"
        @click-setDefault="setDefault"
      ></CouponGroupElement>
    </draggable>
  </div>
</template>

<script>
import { mapState } from "vuex";
import draggable from "vuedraggable";
import CouponGroupElement from "./CouponGroupElement.vue";

export default {
  name: "CouponGroup",
  props: {
    citem: {
      type: Object
    }
  },
  data() {
    return {
      remove_stat: false,
      isDeletePanelActive: false
    };
  },
  components: {
    draggable,
    CouponGroupElement
  },
  computed: {
    ...mapState({
      stateOptions: "options",
      stateProducts: "products",
      stateMenus: "menus"
    })
  },
  methods: {
    checkMove(event) {
      let $self = this;
      let couponGroupIndex = this.stateProducts.product.coupons.findIndex(
        (item) => item.chash === $self.citem.chash
      );
      if (event.item.attributes.tur.value === "0") {
        this.stateProducts.product.coupons[couponGroupIndex].icindekiler.map((item, index) => {
          if (item.phash === this.stateProducts.product.draggingHash && index !== event.newIndex) {
            this.stateProducts.product.coupons[couponGroupIndex].icindekiler.splice(event.newIndex, 1);
          }
        });
      } else {
        // all products
        if (event.item.attributes.alantip.value === "0") {
          // Brand
          this.stateMenus.menus.b.map((item) => {
            if (item.mhash === $self.stateProducts.product.draggingGroupHash) {
              item.products.map((productItem) => {
                // if productItem is already in the list, do not add it again
                let isAlreadyInList = $self.stateProducts.product.coupons[couponGroupIndex].icindekiler.some((item) => item.phash === productItem.phash);
                if (!isAlreadyInList) {
                  $self.stateProducts.product.coupons[couponGroupIndex].icindekiler.push(productItem);
                }
              });
            }
          });
        } else {
          // Store
          this.stateMenus.menus.s.map((item) => {
            if (item.mhash === $self.stateProducts.product.draggingGroupHash) {
              item.products.map((productItem) => {
                // if productItem is already in the list, do not add it again
                let isAlreadyInList = $self.stateProducts.product.coupons[couponGroupIndex].icindekiler.some((item) => item.phash === productItem.phash);
                if (!isAlreadyInList) {
                  $self.stateProducts.product.coupons[couponGroupIndex].icindekiler.push(productItem);
                }
              });
            }
          });
        }
      }
      this.stateProducts.product.draggingHash = "";
      this.stateProducts.product.draggingGroupHash = "";
    },
    deleteItem() {
      this.isDeletePanelActive = true;
    },
    deleteYes(hash) {
      this.stateProducts.product.coupons =
        this.stateProducts.product.coupons.filter(
          (item) => item.chash !== hash
        );
    },
    deleteGroupItem(hash) {
      this.citem.icindekiler = this.citem.icindekiler.filter(
        (item) => item.phash !== hash
      );
    },
    setDefault(hash) {
      // console.log(hash);
      this.citem.icindekiler.map((item) => {
        if(item.phash === hash) {
          item.selected = 1;
        }
      });
    }
  }
};
</script>

<style lang="css" scoped>
.coupon-group {
  position: relative; 
  padding: 0.5rem; 
  margin-bottom: 1rem; 
  background-color: #FEF2F2; 
  font-weight: 600; 
  border-radius: 0.5rem; 
  border-width: 1px; 
  border-color: #FEE2E2; 
  border-style: solid; 
  cursor: pointer; 
}
.group-header {
  display: flex; 
  margin-bottom: 0.5rem; 
  flex-direction: row; 
  justify-content: space-between; 
}
.group-text {
  display: flex; 
  margin-right: 0.5rem; 
  flex-direction: row; 
}
.content {
  min-height: 120px;
  padding: 0.5rem; 
  background-color: #ffffff; 
  border-radius: 0.375rem; 
}
button {
  font-size: 1.875rem;
  line-height: 2.25rem; 
}
.confirm {
  padding: 0 1em;
}
.confirm .delete {
  display: flex; 
  justify-content: center; 
}
.confirm .delete button {
  background: #17b3e6!;
  font-size: 16px;
  line-height: 50px;
  display: inline-block;
  padding: 0 40px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  margin: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
input[type="number"] {
  width: 4rem; 
}
/* custom */
.mr-2 {
  margin-right: 0.5rem;
}
button {
  cursor: pointer;
  font-size: 1.6em;
  line-height: 1.5rem;
  padding: 0 10px;
  background: none;
  border: none;
  outline: none;
  font-weight: 600;
  border-radius: var(--rounded-lg);
}
</style>
