const getReportsDefaultState = () => {
  return {
    reductedReportList: [],
    addedReportList: [],
    reductedStartDate: new Date().toISOString().substr(0, 10),
    reductedEndDate: new Date().toISOString().substr(0, 10),
    addedStartDate: new Date().toISOString().substr(0, 10),
    addedEndDate: new Date().toISOString().substr(0, 10),
  }
};

const reports = {
  state: getReportsDefaultState(),
  actions: {
    async getReportList({ state, commit }, {
      reductedStartDate, 
      reductedEndDate, 
      addedStartDate, 
      addedEndDate,
      brandHash,
      storeHash,
    }) {
      state.vReportList = [];
      commit("LOADING", { isActiveStatus: true});
      try {
        let { data } = await window.$http.axios.get(
          `inventory/api/stocks/report?stock_reduction_start=${reductedStartDate}&stock_reduction_end=${reductedEndDate}&stock_addition_start=${addedStartDate}&stock_addition_end=${addedEndDate}&brand_hash=${brandHash}&store_hash=${storeHash}`
        );
        if(data.status) {
          state.reductedReportList = data.stock_reduced;
          state.addedReportList = data.stock_added;
        }
      } catch (error) {
        state.reductedReportList = [];
        state.addedReportList = [];
        commit('NOTIFICATION_MESSAGE', { status: -1, title: "Error", message: "Stock Report list not loaded" })
      }
      commit("LOADING", { isActiveStatus: false});
    }
  },
  getters: {
    getReductedStartDate: state => state.reductedStartDate,
    getReductedEndDate: state => state.reductedEndDate,
    getAddedStartDate: state => state.addedStartDate,
    getAddedEndDate: state => state.addedEndDate,
  }
}

export default reports;