<template>
  <div class="form-button-actions">
    <button class="btn-secondary" @click="cancel()">Cancel</button>
    <button class="btn-primary" @click="updateUser()" v-if="getFormType == 1">Update</button>
    <button class="btn-primary" @click="saveUser()" v-else>Save</button>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import store from "@/store";

export default {
  name: "UsersFormButtons",
  computed: {
    ...mapState({
      stateStores: "stores",
      stateCommon: "common",
      stateUsers: "users"
    }),
    ...mapGetters(["getFormType"])
  },
  methods: {
    cancel() {
      store.dispatch("closeUserModalBox");
    },
    updateUser() {
      if(this.$parent.$parent.$refs.userForm.checkForm()) {
        store.dispatch("updateUser");
      }
    },
    saveUser() {
      if(this.$parent.$parent.$refs.userForm.checkForm()) {
        store.dispatch("saveUser"); 
      }
    }
  }
};
</script> 