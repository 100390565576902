<template>
  <div>
    <div class="size-toggle">
      <label for="txtName">
        <strong>{{ selement.name }}</strong>
      </label>
      <ToggleCheckbox @clickToggle="changeToggle($event)" :onOff="isActiveStatus == true"></ToggleCheckbox>
    </div>
    <div v-if="isActiveStatus">
      <div class="size-table">
        <div class="prices-row" v-for="(price, idx) in prices" :key="idx">
          <span>{{ price.name }}:</span>
          <input 
            type="number" 
            min="0" 
            v-model="recipe[price.key]"
            @change="updateNewPrices({ value: $event.target.value, key: price.key })" 
          />
        </div>
        <div class="default-unit">
          <label for="txtDefaultUnit">Default Unit</label>
          <span>
            <select name="unit" id="unit" @change="updateUnit($event.target.value)">
              <option 
                v-for="(unit, idx) in stateCommon.vUnitList" 
                :key="idx" 
                :value="unit.unit_hash"
                :v-model="recipe.default_unit" 
                :selected="unit.unit_hash == recipe.default_unit">
                {{ unit.unit_name }}
              </option>
            </select>
          </span>
        </div>
      </div>
      <div class="table-list editable-table-wrapper" v-if="isRecipeAccess">
        <table>
          <thead>
            <tr>
              <th>
                <select v-if="!isInputGroupName" @change="changeGroup($event.target.value)">
                  <option 
                    v-for="(group, idx) in recipe.ingredient_groups"
                    :key="idx"
                    :value="group.group_name"
                    :selected="idx == currentGroupIndex"
                    >{{ group.group_name }}</option>
                </select>
                <div v-else>
                  <input 
                    type="text" 
                    @keyup.enter="addGroup($event.target.value)"
                    v-model="groupName"
                    placeholder="Enter a group name"/>
                </div>
              </th>
              <th>Product</th>
              <th width="160">Regular</th>
              <th width="160">Extra</th>
              <th width="160">Light</th>
              <th width="160">Comment</th>
              <th width="40">Item ACTIONS</th>
            </tr>
          </thead>
          <div class="validation-error" v-if="errorMessage">
            {{ errorMessage }}
          </div>
          <tbody 
            v-for="(field, idx) in ingredients" 
            :key="idx" 
            @click="selectedRowIndex(idx, sIdx)">
            <tr v-if="!isInputGroupName">
              <td></td>
              <td>
                <div class="write">
                  <div class="form-group">
                    <StockAutoComplete
                      :minChar="3" 
                      :tParams="'stock'" 
                      :text="field.ingredient_name"
                      v-model="field.ingredient_name" 
                      :value="field.ingredient_name" 
                      @clickedSelectItem="getStock"
                    ></StockAutoComplete>
                  </div>
                </div>
              </td>
              <td>
                <div class="write">
                  <div class="recipe-unit-wrapper">
                    <div class="input-group-text">
                      <money id="recipe_amount" type="text" min="0" autocomplete="off" v-model="field.regular_amount"
                        @change="calculatorRegularCost()" @blur="calculatorRegularCost()" @keyup="calculatorRegularCost()" :precision="3">
                      </money>
                      <span>{{ field.unit_name }}</span>
                    </div>
                    <div class="input-group-text-col">
                      <span>${{ (
                        parseFloat(field.ingredient_price) * parseFloat(field.regular_amount) || 0
                      ).toFixed(4) }}</span>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div class="write">
                  <div class="recipe-unit-wrapper">
                    <div class="input-group-text">
                      <money id="recipe_amount" type="text" min="0" autocomplete="off" v-model="field.extra_amount"
                        @change="calculatorExtraCost()" @blur="calculatorExtraCost()" @keyup="calculatorExtraCost()" :precision="3">
                      </money>
                      <span>{{ field.unit_name }}</span>
                    </div>
                    <div class="input-group-text-col">
                      <span>${{ (
                        parseFloat(field.ingredient_price) * parseFloat(field.extra_amount) || 0
                      ).toFixed(4) }}</span>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div class="write">
                  <div class="recipe-unit-wrapper">
                    <div class="input-group-text">
                      <money id="recipe_amount" type="text" min="0" autocomplete="off" v-model="field.light_amount"
                        @change="calculatorLightCost()" @blur="calculatorLightCost()" @keyup="calculatorLightCost()" :precision="3">
                      </money>
                      <span>{{ field.unit_name }}</span>
                    </div>
                    <div class="input-group-text-col">
                      <span>${{ (
                        parseFloat(field.ingredient_price) * parseFloat(field.light_amount) || 0
                      ).toFixed(4) }}</span>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div class="write">
                  <div class="recipe-unit-wrapper">
                    <div class="input-group-text">
                      <input type="text" v-model="field.comment" class="comment">
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div class="item-actions">
                  <button @click="deleteRow(field)">
                    <i class="flaticon-delete"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>
                <div class="invoice-total-wrapper" v-if="!isInputGroupName">
                  <button class="btn-primary" @click="toggleGroupInput(true)">
                    <span>Add Group</span>
                  </button>
                  <button class="btn-secondary" @click="deleteGroup()">
                    <span>Delete Group</span>
                  </button>
                </div>
                <div class="invoice-total-wrapper" v-else>
                  <button class="btn-primary" @click="addGroup(groupName)">
                    <span>Save Group</span>
                  </button>
                  <button v-if="this.recipe.ingredient_groups.length != 0" class="btn-secondary" @click="toggleGroupInput(false)">
                    <span>Cancel</span>
                  </button>
                </div>
              </td>
              <td>
                <strong>Total Costs:</strong>
              </td>
              <td>${{ vRegularTotalCost }}</td>
              <td>${{ vExtraTotalCost }}</td>
              <td>${{ vLightTotalCost }}</td>
              <td></td>
              <td>
                <button class="btn-primary"  @click="addRow()" v-if="!isInputGroupName">
                  <span>Add Stock</span>
                </button>
              </td>
            </tr>
            <div class="confirm" v-if="isDeletePanelActive">
              <p>Are you sure you want to delete this group?</p>
              <div>
                <button @click="deleteYes()">Yes</button>
                <button @click="isDeletePanelActive = false;">No</button>
              </div>
            </div>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapState, mapGetters} from "vuex";
import StockAutoComplete from "@/widgets/AutoComplete/StockAutoComplete.vue";
import ToggleCheckbox from "@/widgets/ToggleCheckbox/index.vue";
import { mask } from "vue-the-mask";
import { Money } from 'v-money';

export default {
  name: "SizeGroup",
  props: ["selement", "sIdx"],
  data() {
    return {
      isActiveStatus: false,
      recipe: {
        size_code: "",
        default_unit: "",
        price: 0,
        e_price: 0,
        l_price: 0,
        f_price: 0,
        phash: "",
        ingredient_groups: []
      },
      ingredients: [],
      isDeletePanelActive: false,
      isInputGroupName: false,
      groupName: "",
      currentGroupIndex: 0,
      vRegularTotalCost: "",
      vExtraTotalCost: "",
      vLightTotalCost: "",
      defaultUnitHash: "",
      prices: [
        { id: 0, name: "Normal", key: "price", price: 0},
        { id: 1, name: "Extra", key: "e_price", price: 0},
        { id: 2, name: "Light", key: "l_price", price: 0},
        { id: 3, name: "Free Dif", key: "f_price", price: 0},
      ],
      // default values
      defaultUnit: "",
      // store data for reset
      groupList: [],
      amountList: [],
      sizeInfo: {},
      brandHash: "",
      storeHash: "",
      errorMessage: "",
    };
  },
  directives: { mask },
  components: {
    ToggleCheckbox,
    StockAutoComplete,
    Money
  },
  computed: {
    ...mapState({
      stateOptions: "options",
      stateProducts: "products",
      stateCommon: "common",
      stateStore: "stores",
      stateAuth: "auth",
    }),
    ...mapGetters(["getFormType"]),
    stateOptionPrices() {
      return store.state.options.option.o_prices;
    },
    formValidation() {
      return store.state.options.formValidation;
    },
    isRecipeAccess() {
      if(this.stateAuth.current.user.recipes_access == 1) {
        return true;
      } else {
        return false;
      }
    }
  },
  beforeMount() {
    // if the form is edit and recipe is not empty, store data
    if(this.getFormType == 1 && this.recipe.phash != "") {
      this.storeSizeData();
    }
  },
  created() {
    this.firstConfig();
  },
  updated() {
    this.calculator();
  },
  methods: {
    firstConfig() {
      this.brandHash = this.stateStore.current.brandHash;
      this.storeHash = this.stateStore.current.storeHash;
      this.recipe.default_unit = this.stateCommon.vUnitList[0].unit_hash;
      this.setSizeInfo();
      this.calculator();
    },
    setSizeInfo() {
      this.stateOptionPrices.map((item) => {
        if (item.size_code == this.selement.code) { 
          this.isActiveStatus = true;
          this.recipe = item;
          this.prices[0].price = item.price;
          this.prices[1].price = item.e_price;
          this.prices[2].price = item.l_price;
          this.prices[3].price = item.f_price;
          this.ingredients = item.ingredient_groups.length > 0 
            ? item.ingredient_groups[this.currentGroupIndex].group_ingredients : [];
          this.isInputGroupName = item.ingredient_groups.length > 0 ? false : true;
        }
      });
    },
    changeToggle(value) {
      let toggleNewValue = value ? 1 : 0;
      if (toggleNewValue == 1) {
        this.$parent.$parent.$refs.optionItemForm.dismissError('vOptionSize');
        this.isActiveStatus = true;
        // set recipe object
        this.recipe = {
          size_code: this.selement.code,
          default_unit: this.defaultUnitHash,
          price: 0,
          e_price: 0,
          l_price: 0,
          f_price: 0,
          phash: "",
          ingredient_groups: []
        }
        this.ingredients = this.recipe.ingredient_groups.map((group) => {
          return group.group_ingredients;
        })
        this.isInputGroupName = true;
        this.stateOptionPrices.push(this.recipe);
      } else {
        this.isActiveStatus = false;
        // remove object from o_prices
        const index = this.stateOptionPrices.findIndex(item => item.size_code === this.selement.code);
        if (index !== -1) {
          this.stateOptionPrices.splice(index, 1);
        }
      }
    },
    updateUnit(value) {
      this.recipe.default_unit = value;
    },
    updateNewPrices({ value, key }) {
      this.recipe[key] = parseFloat(value);
    },
    selectedRowIndex(idx, sIdx) {
      this.stateOptions.selectedRow = idx;
      this.stateOptions.selectedSize = this.stateOptionPrices.findIndex(item => item.size_code === this.stateOptions.vSize[sIdx].code);
    },
    calculator() {
      this.calculatorRegularCost();
      this.calculatorExtraCost();
      this.calculatorLightCost();
    },
    calculatorRegularCost() {
      let total = 0;
      this.ingredients.map(item => {
        total +=
          parseFloat(item.ingredient_price) * parseFloat(item.regular_amount) || 0;
      });
      this.vRegularTotalCost = total.toFixed(4);
    },
    calculatorExtraCost() {
      let total = 0;
      this.ingredients.map(item => {
        total +=
          parseFloat(item.ingredient_price) * parseFloat(item.extra_amount) || 0;
      });
      this.vExtraTotalCost = total.toFixed(4);
    },
    calculatorLightCost() {
      let total = 0;
      this.ingredients.map(item => {
        total +=
          parseFloat(item.ingredient_price) * parseFloat(item.light_amount) || 0;
      });
      this.vLightTotalCost = total.toFixed(4);
    },
    addRow() {
      this.ingredients.push({
        ingredient_name: "",
        stock_hash: "",
        ingredient_price: "",
        unit_hash: "",
        unit_name: "",
        regular_amount: "0",
        extra_amount: "0",
        light_amount: "0",
      });
    },
    deleteRow(field) {
      let idx = this.ingredients.indexOf(field);
      // let idx = this.ingredients.findIndex(item => item.ingredient_hash == field.ingredient_hash);
      if (idx > -1) {
        this.ingredients.splice(idx, 1);
      }
      // if(this.ingredients.length == 0) {
      //   this.errorMessage = "Ingredient is required";
      //   this.formValidation.vOptionIngredient.isValid = false;
      // } else {
      //   this.errorMessage = "";
      //   this.formValidation.vOptionIngredient.isValid = true;
      // }
      this.calculator();
    },
    removeIngredientError() {
      this.errorMessage = "";
      this.formValidation.vOptionIngredient.isValid = true;
    },
    // after auto complete get stock
    async getStock(value) {
      try {
        let { data } = await window.$http.axios.get(`inventory/api/stocks/${value.hash}?brand_hash=${this.brandHash}&store_hash=${this.storeHash}`);
        const newItem = {
          ingredient_name: data.stock.stock_name,
          stock_hash: data.stock.stock_hash,
          ingredient_hash: "",
          ingredient_price: data.stock.stock_unit_price,
          unit_hash: data.stock.unit_hash,
          unit_name: data.stock.unit_name,
          regular_amount: 0,
          extra_amount: 0,
          light_amount: 0,
          comment: "",
          newItem: true,
        };
        this.ingredients[this.stateOptions.selectedRow] = newItem;
        this.ingredients.push(newItem);
        if (this.stateOptions.selectedRow > -1) {
          this.ingredients.splice(this.stateOptions.selectedRow, 1);
        };
        // remove error message
        this.removeIngredientError();
        this.calculator();
      } catch (error) {
        console.error(error);
      }
    },
    // Store size data (group list & size info & ingredients)
    storeSizeData() {
      this.groupList = this.recipe.ingredient_groups.map(group => ({
        group_name: group.group_name,
        group_ingredients: group.group_ingredients,
        group_hash: group.group_hash,
      }));
      this.sizeInfo = {
        regular_amount: this.recipe.price,
        extra_amount: this.recipe.e_price,
        light_amount: this.recipe.l_price,
        free_dif: this.recipe.f_price,
        default_unit: this.recipe.default_unit,
      }
      this.amountList = this.recipe.ingredient_groups.flatMap(group => 
        group.group_ingredients.map(ingredient => ({
          regular_amount: ingredient.regular_amount,
          extra_amount: ingredient.extra_amount,
          light_amount: ingredient.light_amount,
          comment: ingredient.comment,
          ingredient_hash: ingredient.ingredient_hash
        }))
      );
    },
    // After cancel the form, reset original size data
    resetSizeData() {
      // groupList
      this.recipe.ingredient_groups = this.groupList;
      // sizeInfo
      this.recipe.price = this.sizeInfo.regular_amount;
      this.recipe.e_price = this.sizeInfo.extra_amount;
      this.recipe.l_price = this.sizeInfo.light_amount;
      this.recipe.f_price = this.sizeInfo.free_dif;
      this.recipe.default_unit = this.sizeInfo.default_unit;
      // ingredients
      this.recipe.ingredient_groups.map((item) => {
        item.group_ingredients.map((ingredient, idx) => {
          this.amountList.map((amount) => {
            if (ingredient.ingredient_hash == amount.ingredient_hash) {
              ingredient.regular_amount = amount.regular_amount;
              ingredient.extra_amount = amount.extra_amount;
              ingredient.light_amount = amount.light_amount;
              ingredient.comment = amount.comment;
            }
            // remove ingredient if ingredient name is empty, or new item
            if (ingredient.ingredient_name == "") {
              item.group_ingredients.splice(idx, 1);
            } else if(ingredient.newItem ) {
              item.group_ingredients.splice(idx, 1);
            }
          });
        });
      });
    },
    // Group functions
    addGroup(value) {
      if(value == "") {
        this.errorMessage = "Group name is required";
        return;
      } else {
        this.errorMessage = "";
      }
      const newGroup = {
        group_hash: "",
        group_ingredients: [],
        group_name: value,
      };
      this.recipe.ingredient_groups.push(newGroup);
      this.currentGroupIndex = this.recipe.ingredient_groups.length - 1;
      this.changeGroup(value);
      this.addRow();
      this.removeIngredientError();
      this.isInputGroupName = false;
      this.groupName = "";
    },
    changeGroup(value) {
      let idx = this.recipe.ingredient_groups.findIndex(item => item.group_name == value);
      this.currentGroupIndex = idx;
      this.ingredients = this.recipe.ingredient_groups[idx].group_ingredients;
    },
    toggleGroupInput(value) {
      this.groupName = "";
      if(value) {
        this.isInputGroupName = true;
      } else {
        if(this.recipe.ingredient_groups.length != 0) {
          this.isInputGroupName = false;
          this.removeIngredientError();
        }
      }
    },
    deleteGroup() {
      this.isDeletePanelActive = true;
    },
    deleteYes() {
      this.isDeletePanelActive = false;
      this.recipe.ingredient_groups.splice(this.currentGroupIndex, 1);
      if(this.recipe.ingredient_groups.length > 0) {
        this.currentGroupIndex = 0;
        this.ingredients = this.recipe.ingredient_groups[0].group_ingredients;
      } else {
        this.ingredients = [];
        this.isInputGroupName = true;
        this.removeIngredientError();
      }
    },
  }
}
</script>

<style lang="css" scoped>
.form-row .input-group label {
  margin: 0;
}
.size-toggle {
  display: flex;
  margin: 1rem auto;
  align-items: center;
  gap: 20px;
}
.size-table {
  display: flex;
}
.prices-row span {
  margin: auto 1rem;
}
.prices-row input {
  width: 50px !important;
}
.default-unit {
  display: flex;
  /* margin: 1rem auto; */
  align-items: center;
}
.default-unit label {
  margin: auto 1rem;
}
.default-unit input {
  margin-right: 0.25rem;
  width: 50px !important;
}
h2 {
  font-size: 1rem;
  line-height: 1.5rem;
}
.size-item label {
  margin-bottom: 0;
  margin-right: 0.25rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
}
.input-group {
  position: relative;
}
input[type="text"],
input[type="tel"],
input[type="number"],
textarea {
  width: 100%;
}
textarea {
  min-height: 5rem;
  max-height: 10rem;
  resize: none;
}
.editable-table-wrapper {
  margin-bottom: 1rem;
}
.editable-table-wrapper.modeWrite .write {
  display: block;
}
.editable-table-wrapper input {
  width: 100%;
}
.invoice-total-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: right;
  font-size: 2rem;
  gap: 1em;
}
.total-wrapper {
  width: 50%;
  margin-top: 1rem;
}
.total-wrapper .subtotal,
.total-wrapper .total,
.total-wrapper .balance-due {
  margin-bottom: 1.5rem;
}
.total-wrapper em {
  margin-right: 2rem;
  font-style: normal;
  font-weight: 600;
}
.total-wrapper span {
  font-weight: 400;
}
table tbody td {
  vertical-align: top;
}
tfoot {
  position: relative;
}
tfoot .confirm {
  z-index: 100;
}
tfoot tr td {
  text-align: right;
}
.recipe-unit-wrapper input[type="text"] {
  border-bottom-left-radius: 0;
  text-align: center;
}
.recipe-unit-wrapper .input-group-text span {
  border-bottom-right-radius: 0;
  background: var(--gray-50);
  color: var(--gray-500);
}
.item-actions button {
  font-size: 1.6em;
  line-height: 1.5rem;
  padding: 0 10px;
  background: none;
  border: none;
  outline: none;
  font-weight: 600;
  border-radius: var(--rounded-lg);
  margin: 0 auto;
}
.comment {
  border-bottom-left-radius: 5% !important;
}
</style>