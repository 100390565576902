<template>
  <div v-if="stateMenus.status" id="branch-settings">
    <div class="flex items-center justify-between pb-4 mb-4 border-b border-gray-100">
      <!-- <h2 class="flex-grow">Branch Settings</h2> -->
    </div>
    <div class="stores">
      <div 
        class="store"
        :class="store.shash == stateStores.current.storeHash ? 'active' : ''"
        @click="getBranch(store.shash)"
        v-for="store, index in stateStores.stores" 
        :key="index"
      >
        <span>{{ store.b_name }} -</span>
        <span>{{ store.s_name }}</span>
      </div>
      <TabsNav 
        @goTo="setMenu"
        :list="subMenu"
        :selected="stateStores.vSelectedTab"
      >
        <Tab 
          :isSelected="stateStores.vSelectedTab === tab.id"
          v-for="(tab, index) in subMenu"
          :key="index" 
        >
          <WorkingHours v-if="stateStores.vSelectedTab == 0"></WorkingHours>
          <DeliveryMap v-if="stateStores.vSelectedTab == 1"></DeliveryMap>
          <!-- <CustomerScreen v-if="stateStores.vSelectedTab == 2"></CustomerScreen> -->
          <!-- <Menuboard v-if="stateStores.vSelectedTab == 3"></Menuboard> -->
          <PosUsers v-if="stateStores.vSelectedTab == 4"></PosUsers>
        </Tab>
      </TabsNav>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapState } from "vuex";
import TabsNav from "@/widgets/Tabs/TabsNav";
import Tab from "@/widgets/Tabs/Tab";
import WorkingHours from "@/views/BranchSettings/WorkingHours";
import DeliveryMap from "@/views/BranchSettings/DeliveryMap";
// import Menuboard from "@/views/BranchSettings/Menuboard";
// import CustomerScreen from "@/views/BranchSettings/CustomerScreen";
import PosUsers from "@/views/BranchSettings/PosUsers";

export default {
  name: "BranchSettings",
  data() {
    return {
      isShowingWorkingHours: true,
      isShowingDeliveryMap: false,
      subMenu: [
        { id: 0, name: "WORKING HOURS" },
        { id: 1, name: "DELIVERY MAP" },
        // { id: 2, name: "CUSTOMER SCREEN" },
        // { id: 3, name: "MENUBOARD" },
        { id: 4, name: "POS USERS" },
      ],
      vSelectedTab: "working-hours",
    };
  },
  components: {
    TabsNav,
    Tab,
    WorkingHours,
    DeliveryMap,
    // Menuboard,
    // CustomerScreen,
    PosUsers,
  },
  computed: {
    ...mapState({
      stateCommon: "common",
      stateMenus: "menus",
      stateOptions: "options",
      stateStores: "stores"
    }),
    storeHash() {
      return this.stateStores.current.storeHash;
    }
  },
  created() {
    this.stateStores.vSelectedTab = 0;
  },
  watch: {
    storeHash() {
      this.getBranch(this.storeHash);
      this.stateStores.vSelectedTab = 0;
    }
  },
  methods: {
    setMenu(tab) {
      this.stateStores.vSelectedTab = tab;
    },
    getBranch(storeHash) {
      store.dispatch("setStoreDetails", storeHash);
      store.dispatch("getMenus", storeHash).then(() => {
        store.dispatch("getTabsMenu");
        this.stateMenus.vSelectedTab = this.stateMenus.vTabMenu[0].id;
      });
      store.dispatch("getOptions", storeHash).then(() => {
        store.dispatch("getTabsOptionItems");
        if(this.stateOptions.vTabMenu.length > 0) {
          this.stateOptions.vSelectedTab = this.stateOptions.vTabMenu[0].id;
        } else {
          this.stateOptions.vSelectedTab = "";
        }
      });
      store.dispatch(
        "showModalBox",
        Object.assign(store.state.common.modalBox, {
          isActiveStatus: false,
          alias: ""
        })
      );
    }
  }
};
</script>

<style scoped>
#branch-settings {
  padding: 1rem;
  width: 100%;
}
.links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.stores .store {
  font-size: 20px;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 2px;
  cursor: pointer;
  margin: 0 10px 10px 0;
  padding: 30px;
  color: #555;
  transition: 0.1s;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
.stores .store:hover {
  background-color: #2c3e50;
  color: #fff;
  transform: scale(1);
  transition: 0.1s;
}
.stores .store.active {
  background-color: #2c3e50;
  color: #fff;
}
</style>