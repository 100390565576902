import menus from './menus';
const getProductsDefaultState = () => {
  return {
    pictureControl: -1,
    myCroppa: {},
    sizes: [],
    currentSize: {},
    vProductInfo: '',
    product: {
      draggingHash: '',
      draggingGroupHash: '',
      phash: '',
      myCroppa: {},
      ptype: 0,
      name: '',
      desc: '',
      total: 0.0,
      s_size_item: null,
      sizes: [],
      bags: [],
      groups: [],
      coupons: [],
      min_qty: 1,
      calorie: 0,
      os_delivery: 1,
      os_pickup: 1,
      os_pos: 1,
      os_online: 1,
      os_mobile: 1,
      os_kiosk: 1,
      os_customize: 1,
      print_stat: 1,
      product_cook_type: 1,
      product_prepare_time: 0,
      product_cook_time: 0,
      product_send_oven: 0,
      product_kitchen_stat: 0,
      product_boxing_stat: 0,
      product_kitchen_hash: '',
      tax1: 5,
      tax2: 0,
    },
    // Validation
    formValidation: {
      productName: { isValid: false, errorMessage: '' },
      productImage: { isValid: false, errorMessage: '' },
      productPrice: { isValid: false, errorMessage: '' },
    },
  };
};

const products = {
  state: getProductsDefaultState(),
  mutations: {
    RESET_FORM_PRODUCTS(state) {
      Object.assign(state, getProductsDefaultState());
      // reset sizes in menus
      menus.state.sizes.map((size) => {
        size.default_select = 0;
        size.status = false;
        size.priority = 0;
        size.price = 0;
        size.rw = 0;
        size.dep = 0;
      });
    },
    GET_PRODUCT(state, payload) {
      Object.assign(state, payload);
    },
    PRODUCT_CALCULATION(state, payload) {
      state.vProductInfo = payload;
    },
  },
  actions: {
    closeProductModalBox({ commit }) {
      commit('RESET_FORM_PRODUCTS');
      commit('RESET_MODALBOX');
    },
    async getProduct({ commit }, { storeHash, menuHash, productHash }) {
      commit('LOADING', { isActiveStatus: true });
      try {
        let { data } = await window.$http.axios.get(
          `product/getitem?s=${storeHash}&mh=${menuHash}&ph=${productHash}`
        );
        commit('GET_PRODUCT', data);
      } catch (error) {
        console.error(error);
      }
      commit('LOADING', { isActiveStatus: false });
    },
    async saveProduct({ state, commit, dispatch }, { storeHash, menuHash, menuType }) {
      const FormData = require('form-data');
      const formData = new FormData();
      formData.append('ptype', state.product.ptype);
      formData.append('name', state.product.name);
      formData.append('min_qty', state.product.min_qty);
      formData.append('calorie', state.product.calorie);
      formData.append('os_delivery', state.product.os_delivery);
      formData.append('os_pickup', state.product.os_pickup);
      formData.append('os_pos', state.product.os_pos);
      formData.append('os_online', state.product.os_online);
      formData.append('os_mobile', state.product.os_mobile); 
      formData.append('os_kiosk', state.product.os_kiosk);
      formData.append('os_customize', state.product.os_customize);
      formData.append('print_stat', state.product.print_stat);
      formData.append('product_cook_type', state.product.product_cook_type);
      formData.append('product_prepare_time', state.product.product_prepare_time);
      formData.append('product_cook_time', state.product.product_cook_time);
      formData.append('product_send_oven', state.product.product_send_oven);
      formData.append('product_kitchen_stat', state.product.product_kitchen_stat);
      formData.append('product_kitchen_hash', state.product.product_kitchen_hash);
      formData.append('product_boxing_stat', state.product.product_boxing_stat);
      formData.append('desc', state.product.desc);
      formData.append('tax1', state.product.tax1);
      formData.append('tax2', state.product.tax2);
      formData.append('sizes', JSON.stringify(state.product.sizes));
      formData.append(
        'p_picture',
        state.product.myCroppa.generateDataUrl('image/jpeg', 0.8)
      );
      formData.append('groups', JSON.stringify(state.product.groups));
      formData.append('coupons', JSON.stringify(state.product.coupons));
      formData.append('bags', JSON.stringify(state.product.bags));

      const config = {
        method: 'post',
        url: `product/add?s=${storeHash}&mh=${menuHash}`,
        data: formData,
      };
      commit('LOADING', { isActiveStatus: true });
      try {
        let { data } = await window.$http.axios(config);
        console.log(data)
        if (data.status) {
          commit('ADD_PRODUCT', {
            selectedMenu: menuHash,
            mType: menuType,
            productData: data.product,
          });
          commit('NOTIFICATION_MESSAGE',{ status: 1, title: 'Saved', message: 'Product has been saved successfully.' });
          dispatch('closeProductModalBox');
        } else {
          commit('NOTIFICATION_MESSAGE', { status: -1, title: 'Error', message: data.message });
        }
      } catch (error) {
        commit('NOTIFICATION_MESSAGE', { status: -1, title: 'Error', message: error.message });
      }
      commit('LOADING', { isActiveStatus: false });
    },
    async updateProduct(
      { state, commit, dispatch },
      { storeHash, menuHash, productHash }
    ) {
      const FormData = require('form-data');
      const formData = new FormData();

      formData.append('ptype', state.product.ptype);
      formData.append('name', state.product.name);
      formData.append('min_qty', state.product.min_qty);
      formData.append('calorie', state.product.calorie);
      formData.append('os_delivery', state.product.os_delivery);
      formData.append('os_pickup', state.product.os_pickup);
      formData.append('os_pos', state.product.os_pos);
      formData.append('os_online', state.product.os_online);
      formData.append('os_mobile', state.product.os_mobile);
      formData.append('os_kiosk', state.product.os_kiosk);
      formData.append('os_customize', state.product.os_customize);
      formData.append('print_stat', state.product.print_stat);
      formData.append('product_cook_type', state.product.product_cook_type);
      formData.append(
        'product_prepare_time',
        state.product.product_prepare_time
      );
      formData.append('product_cook_time', state.product.product_cook_time);
      formData.append('product_send_oven', state.product.product_send_oven);
      formData.append('product_kitchen_stat', state.product.product_kitchen_stat);
      formData.append('product_kitchen_hash', state.product.product_kitchen_hash);
      formData.append('product_boxing_stat', state.product.product_boxing_stat);
      formData.append('desc', state.product.desc);
      formData.append('tax1', state.product.tax1);
      formData.append('tax2', state.product.tax2);
      formData.append('sizes', JSON.stringify(state.product.sizes));
      // formData.append('p_picture', state.product.p_picture);
      formData.append(
        'p_picture',
        state.product.myCroppa.generateDataUrl('image/jpeg', 0.8)
      );
      formData.append('groups', JSON.stringify(state.product.groups));
      formData.append('coupons', JSON.stringify(state.product.coupons));
      formData.append('bags', JSON.stringify(state.product.bags));

      const config = {
        method: 'post',
        url: `product/update?s=${storeHash}&mh=${menuHash}&ph=${productHash}`,
        data: formData,
      };
      commit('LOADING', { isActiveStatus: true });
      try {
        let { data } = await window.$http.axios(config);
        if (data.status) {
          commit('UPDATE_PRODUCT', {
            selectedMenu: menuHash,
            mType: data.mtype,
            selectedProduct: productHash,
            productData: data.product,
          });
          commit('NOTIFICATION_MESSAGE',{ status: 1, title: 'Updated', message: 'Product has been saved successfully.' });
          dispatch('closeProductModalBox');
        } else {
          commit('NOTIFICATION_MESSAGE',{ status: -1, title: 'Error', message: data.message });
        }
      } catch (error) {
        commit('NOTIFICATION_MESSAGE',{ status: -1, title: 'Error', message: error.message });
      }
      commit('LOADING', { isActiveStatus: false });
    },
    async deleteProduct({ commit }, { storeHash, menuHash, menuType, productHash }) {
      commit('LOADING', { isActiveStatus: true });
      try {
        await window.$http.axios.get(
          `product/deleteitem?s=${storeHash}&mh=${menuHash}&ph=${productHash}`
        );
        commit('DELETE_PRODUCT', {
          selectedMenu: menuHash,
          menuType,
          selectedProduct: productHash,
        });
        commit('NOTIFICATION_MESSAGE', { status: 1, title: 'Deleted', message: 'Product has been deleted successfully.' });
      } catch (error) {
        console.error(error);
        commit('NOTIFICATION_MESSAGE', { status: -1, title: 'Error', message: error.message });
      }
      commit('LOADING', { isActiveStatus: false });
    },
    async productCalculation({ commit }, productHash ) {
      commit('LOADING', { isActiveStatus: true });
      try {
        let { data } = await window.$http.axios.get(`/v2/product/${productHash}`);
        if(data) {
          commit('PRODUCT_CALCULATION', data);
        } else {
          commit('NOTIFICATION_MESSAGE', { status: -1, title: 'Error', message: data.message });
        }
      } catch (error) {
        console.error(error);
      }
      commit('LOADING', { isActiveStatus: false });
    },
    async updateProductOrder({ commit }, { storeHash, menuHash, menuType, products }) {
      const FormData = require('form-data');
      const formData = new FormData();
      products.map((product) => {
        formData.append('phashlist[]', product.phash);
      });
      const config = {
        method: 'post',
        url: `product/changeorder?s=${storeHash}&mh=${menuHash}`,
        data: formData,
      };
      commit('LOADING', { isActiveStatus: true });
      try {
        let { data } = await window.$http.axios(config);;
        if (data.status) {
          commit('PRODUCT_CHANGE_SYNC_STATUS', {
            menuHash,
            menuType,
          });
          commit('NOTIFICATION_MESSAGE', { status: 1, title: 'Updated', message: 'Product order has been updated successfully.',
          });
        } else {
          commit('NOTIFICATION_MESSAGE', { status: -1, title: 'Error', message: data.message });
        }
      } catch (error) {
        commit('NOTIFICATION_MESSAGE', { status: -1, title: 'Error', message: error.message });
      }
      commit('LOADING', { isActiveStatus: false });
    }
  },
};

export default products;
