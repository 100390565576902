<template>
  <div class="prductInfo">
    <p class="bold">{{ stateProducts.vProductInfo.product_sizes[0].product_name }}</p>
    <Tabs>
      <Tab 
        v-for="(info, index) in stateProducts.vProductInfo.product_sizes" 
        :key="index" 
        :title="info.size"
        >
        <p class="title">Size: {{ info.size }}</p>
        <p class="title">Cost: {{ info.product_cost }}</p>
        <div class="optionInfo">
          <div v-for="(option, index) in info.selected_toppings" :key="index">
            <OptionInfo :option="option" />
            <hr />
          </div>
        </div>
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import Tabs from './Tabs.vue';
import Tab from './Tab.vue';
import { mapState } from 'vuex';
import OptionInfo from './OptionInfo.vue';
export default {
  name: 'ProductCalculation',
  computed: {
    ...mapState({
      stateProducts: "products",
    }),
  },
  components: {
    // SizeInfo,
    OptionInfo,
    Tabs,
    Tab,
  },
}
</script>

<style css scoped>
.prductInfo p.bold {
  font-size: 2em;
  line-height: 1em;
  font-weight: bold;
}
.prductInfo p.title {
  font-size: 1.5em;
  font-weight: 600;
}
</style>