<template>
  <form class="product-form" action="#">
    <div class="product-form-content">
      <div class="form-row">
        <div class="validation-error" v-if="formValidation.productName.errorMessage">
          {{ formValidation.productName.errorMessage }}
        </div>
        <div class="product-name">
          <label for="txtProductName">Product Name</label>
          <input id="txtProductName" name="txtProductName" type="text" v-model="stateProducts.product.name"
            autocomplete="off" @focus="dismissError('productName')" />
        </div>
        <div>
          <label for="txtMenuDescription">Description</label>
          <textarea id="txtMenuDescription" name="txtMenuDescription" v-model="stateProducts.product.desc" cols="10"
            rows="2"></textarea>
        </div>
      </div>
      <div class="flex-row product-type" v-if="productType !== 3">
        <div class="selectbox-wrapper">
          <label class="product-label" for="txtProductType">Product Type</label>
          <select id="txtProductType" v-model="stateProducts.product.product_cook_type">
            <option value="0">Pizza</option>
            <option value="1">Drinks</option>
            <option value="2">Seperated</option>
            <option value="3">Slice</option>
            <option value="4">Dip Sauce</option>
            <option value="5">Deals</option>
            <option value="6">Pasta</option>
            <option value="7">Chicken</option>
            <option value="8">Salat</option>
            <option value="9">Other</option>
            <option value="10">Beer</option>
          </select>
        </div>
        <div class="flex-item">
          <label for="txtGST">GST</label>
          <input id="txtGST" name="txtGST" type="number" v-model="stateProducts.product.tax1" />
        </div>
        <div class="flex-item">
          <label for="txtPST">PST</label>
          <input id="txtPST" name="txtPST" type="number" v-model="stateProducts.product.tax2" />
        </div>
        <div class="flex-item">
          <label for="txtMinimumQuantity">Quantity</label>
          <input id="txtMinimumQuantity" name="txtMinimumQuantity" type="number" min="1"
            v-model="stateProducts.product.min_qty" />
        </div>
        <div class="flex-item">
          <label for="txtCalories">Calories</label>
          <input id="txtCalories" name="txtCalories" type="text" min="0" v-model="stateProducts.product.calorie" />
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-item">
          <label>Delivery</label>
          <ToggleCheckbox @clickToggle="changeStatus($event, 'DELIVERY')"
            :onOff="stateProducts.product.os_delivery == 1">
          </ToggleCheckbox>
        </div>
        <div class="flex-item">
          <label>Pickup</label>
          <ToggleCheckbox @clickToggle="changeStatus($event, 'PICKUP')"
            :onOff="stateProducts.product.os_pickup == 1">
          </ToggleCheckbox>
        </div>
        <div class="flex-item">
          <label>Web</label>
          <ToggleCheckbox @clickToggle="changeStatus($event, 'WEB')"
            :onOff="stateProducts.product.os_online == 1">
          </ToggleCheckbox>
        </div>
        <div class="flex-item">
          <label>POS</label>
          <ToggleCheckbox @clickToggle="changeStatus($event, 'POS')"
            :onOff="stateProducts.product.os_pos == 1"></ToggleCheckbox>
        </div>
        <div class="flex-item">
          <label>Kiosk</label>
          <ToggleCheckbox @clickToggle="changeStatus($event, 'KIOSK')"
            :onOff="stateProducts.product.os_kiosk == 1"></ToggleCheckbox>
        </div>
        <div class="flex-item">
          <label>Mobile</label>
          <ToggleCheckbox @clickToggle="changeStatus($event, 'MOBILE')"
            :onOff="stateProducts.product.os_mobile == 1">
          </ToggleCheckbox>
        </div>
        <div class="flex-item" v-if="productType !== 3">
          <label>POS Print Stat</label>
          <ToggleCheckbox @clickToggle="changeStatus($event, 'PRINT')"
            :onOff="stateProducts.product.print_stat == 1">
          </ToggleCheckbox>
        </div>
        <div class="flex-item" v-if="productType !== 3">
          <label>No Details</label>
          <ToggleCheckbox @clickToggle="changeStatus($event, 'DETAIL')"
            :onOff="stateProducts.product.os_customize == 1">
          </ToggleCheckbox>
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-item">
          <label>Oven Status</label>
          <ToggleCheckbox @clickToggle="changeStatus($event, 'OVEN')"
            :onOff="stateProducts.product.product_send_oven == 1">
          </ToggleCheckbox>
        </div>
        <div class="flex-item" v-if="stateProducts.product.product_send_oven == 1">
          <label>Kitchen Status</label>
          <ToggleCheckbox @clickToggle="changeStatus($event, 'KITCHEN')"
            :onOff="stateProducts.product.product_kitchen_stat == 1">
          </ToggleCheckbox>
        </div>
        <div class="flex-item" v-if="stateProducts.product.product_send_oven == 1">
            <label for="txtKitchenHash">Kitchen Hash</label>
            <input id="txtKitchenHash" name="txtKitchenHash" type="text"
              v-model="stateProducts.product.product_kitchen_hash" />
        </div>
        <div class="flex-item" v-if="stateProducts.product.product_send_oven == 1">
          <label>Boxing Status</label>
          <ToggleCheckbox @clickToggle="changeStatus($event, 'BOXING')"
            :onOff="stateProducts.product.product_boxing_stat == 1">
          </ToggleCheckbox>
        </div>
        <div class="flex-item">
          <label for="txtPreparingTime">Preparing Time</label>
          <input id="txtPreparingTime" name="txtPreparingTime" type="text"
              v-model="stateProducts.product.product_prepare_time" />
        </div>
        <div class="flex-item">
          <label for="txtCookingTime">Cooking Time</label>
          <input id="txtCookingTime" name="txtCookingTime" type="text"
              v-model="stateProducts.product.product_cook_time" />
        </div>
      </div>
      <div class="flex-column">
        <div class="validation-error" v-if="formValidation.productPrice.errorMessage && productType !== 3">
        {{ formValidation.productPrice.errorMessage }}
      </div>
      <div class="size-price">
        <SizesPrices v-for="item in stateProducts.sizes" :vItem="item" :key="item.code"></SizesPrices>
      </div>
      </div>
      <draggable handle=".moveclass" animation="150" sort="true" group="groupcu" v-model="stateProducts.product.coupons">
        <CouponGroup :citem="citem" :key="citem.chash" v-for="citem in stateProducts.product.coupons"></CouponGroup>
      </draggable>
      <draggable handle=".moveclass" animation="150" sort="true" group="groupgr">
        <ProductGroup :gitem="gitem" :key="gitem.ghash" v-for="gitem in stateProducts.product.groups"></ProductGroup>
      </draggable>
      <div v-if="productType === 3">
        <BagGroup></BagGroup>
      </div>
    </div>

    <div class="scroll-content">
      <!-- START IMAGE -->
      <div class="imagearea">
        <croppa 
          @image-remove="onRemoveImage" 
          @new-image-drawn="onLoadImage" 
          v-model="stateProducts.product.myCroppa" 
          @focus="dismissError('productImage')"
          :height="200"
          :input-attrs="{ class: 'croppainput' }" 
          :placeholder-font-size="16" 
          :prevent-white-space="true" 
          :width="200" 
          :show-loading="true"
          canvas-color="#edf4f4"
          remove-button-color="black"
          :placeholder="'Choose an image'" 
          :placeholder-color="'default'" 
        >
        </croppa>
      </div>
      <div class="validation-error" v-if="formValidation.productImage.errorMessage">
        {{ formValidation.productImage.errorMessage }}
      </div>
      <!-- END IMAGE -->
      <!-- START COUPON -->
      <div class="actions" v-if="productType === 1">
        <button class="btn btn-primary" @click.prevent="addCoupon()">Coupon Products</button>
      </div>
      <div class="product-coupon" v-if="productType === 1 || productType === 3">
        <!-- brand menu starts -->
        <div class="group-wrapper" :key="item.mhash" v-for="item in this.stateMenus.menus.b">
          <Accordion :title="`${item.m_name} / BRAND`">
            <template #body>
              <!-- <div v-if="item.m_sh_stat===0 || item.m_sh_stat==='0' "> -->
              <draggable 
                class="flex-wrap" 
                :clone="cloneOpt"
                :options="{ animation: 150, group: { name: 'cupitems', pull: 'clone', put: false }, sort: false }"
                v-model="item.products" 
                @start="startDragDropCoupon($event, item)"
              >
                <div class="draggable-item" v-for="(productItem, idx) in item.products" :key="idx" :tur="0">
                  <div
                    v-if="productItem.ptype == 0 || productItem.ptype == 3">
                    {{ productItem.p_name }}
                    <span v-if="productItem.ptype == 2">HIDDEN</span>
                    <span class="product-label" v-if="productItem.p_desc">{{ productItem.p_desc }}</span>
                  </div>
                </div>
                <div class="draggable-item" :tur="1" :alantip="0" :mhash="item.mhash">ALL PRODUCTS</div>
              </draggable>
              <!-- </div> -->
            </template>
          </Accordion>
        </div>
        <!-- brand menu ends -->
        <!-- store menu starts -->
        <div class="group-wrapper" :key="item.mhash" v-for="item in this.stateMenus.menus.s">
          <Accordion :title="`${item.m_name} / STORE`">
            <template #body>
              <!-- <div v-show="item.m_sh_stat===0 || item.m_sh_stat==='0' "> -->
              <draggable 
                class="flex-wrap" 
                :clone="cloneOpt"
                :options="{ animation: 150, group: { name: 'cupitems', pull: 'clone', put: false }, sort: false }"
                v-model="item.products" 
                @start="startDragDropCoupon($event, item)"
              >
                <!-- TODO: You cannot use v-for and v-if statement for same element. We have to find another solution -->
                <div 
                  class="draggable-item" 
                  v-for="(productItem, idx) in item.products" 
                  :key="idx"
                  :tur="0"
                  :v-if="productItem.ptype == 0 || productItem.ptype == 2"
                  :phash="productItem.phash"
                >
                  {{ productItem.p_name }}
                  <span v-if="productItem.ptype == 2">HIDDEN</span>
                  <span class="product-label" v-if="productItem.p_desc">{{ productItem.p_desc }}</span>
                </div>
                <div class="draggable-item" :tur="1" :alantip="1" :mhash="item.mhash">ALL PRODUCTS</div>
              </draggable>
              <!-- </div> -->
            </template>
          </Accordion>
        </div>
        <!-- store menu ends -->
      </div>
      <!-- END COUPON -->
      <!-- START PRODUCT TOPPINGS -->
      <div class="actions" v-if="productType === 0 || productType === 1 || productType === 2">
        <div class="product-topping">
          <button class="btn btn-primary" @click.prevent="addGroup(0)">Single</button>
          <button class="btn btn-primary" @click.prevent="addGroup(1)">Multiple</button>
        </div>
      </div>
      <div class="topping-wrapper" v-if="productType !== 3">
        <div class="group-wrapper" :key="option.ghash" v-for="option in stateOptions.options">
          <Accordion :title="option.gname">
            <template #body>
              <draggable
                class="flex-wrap" 
                :clone="cloneOpt"
                :options="{ animation: 150, group: { name: 'optitems', pull: 'clone', put: false }, sort: false }"
                v-model="option.oitems"
                @start="startDragDrop($event, option)"
              >
                <div 
                  class="draggable-item" 
                  v-for="(item, idx) in option.oitems" 
                  :key="idx" 
                  :tur="0"
                  :ohash="item.ohash"
                >
                  {{ item.name }}
                  <span class="product-label" v-if="item.desc">{{ item.desc }}</span>
                </div>
                <div 
                  class="draggable-item" 
                  :tur="1" 
                  :alantip="2"
                  :ghash="option.ghash"
                >ALL OPTIONS</div>
              </draggable>
            </template>
          </Accordion>
        </div>
      </div>
      <!-- END PRODUCT TOPPINGS  -->
    </div>
  </form>
</template>

<script>
import { mapState } from "vuex";
import draggable from "vuedraggable";

import ToggleCheckbox from "@/widgets/ToggleCheckbox";
import Accordion from "@/widgets/Accordion";
import SizesPrices from "./widgets/SizesPrices";
import ProductGroup from "./widgets/ProductGroup.vue";
import CouponGroup from "./widgets/CouponGroup.vue";
import BagGroup from "./widgets/BagGroup.vue";

export default {
  name: "ProductsForm",
  props: {
    productType: {
      type: Number,
      default: 0
    }
  },
  components: {
    ToggleCheckbox,
    Accordion,
    SizesPrices,
    draggable,
    ProductGroup,
    CouponGroup,
    BagGroup
  },
  data() {
    return {
      groupOptions: {},
    };
  },
  computed: {
    ...mapState({
      stateUsers: "users",
      stateCommon: "common",
      stateMenus: "menus",
      stateOptions: "options",
      stateProducts: "products",
    }),
    formValidation() {
      return this.$store.state.products.formValidation;
    },
  },
  created() {
    // if oven status = 0, then hide kitchen and boxing status
    if (this.stateProducts.product.product_send_oven == 0) {
      this.stateProducts.product.product_kitchen_stat = 0;
      this.stateProducts.product.product_boxing_stat = 0;
    }
    this.syncSizesWithMenu();
  },
  mounted() {
    this.stateProducts.product.ptype = this.productType;
  },
  methods: {
    syncSizesWithMenu() {
      this.stateProducts.sizes = this.stateMenus.sizes.map(menuSize => {
        const productSize = this.stateProducts.product.sizes.find(ps => ps.code === menuSize.code);
        return productSize ? { ...menuSize, ...productSize } : menuSize;
      });

      // this.stateProducts.sizes = this.stateMenus.sizes;
      // if (this.stateProducts.product.sizes.length > 0) {
      //   let activeItemsList = this.stateProducts.product.sizes.filter(
      //     (item) => item.status === true
      //   );
      //   activeItemsList.map((activeItem) => {
      //     this.stateProducts.sizes.map((item) => {
      //       if (item.code === activeItem.code) {
      //         Object.assign(item, activeItem);
      //       }
      //     });
      //   });
      // }
    },
    onLoadImage() {
      this.stateProducts.pictureControl = 1;
      this.dismissError('productImage')
    },
    onRemoveImage() {
      this.stateProducts.pictureControl = 0;
      this.formValidation.productImage.isValid = false;
      this.formValidation.productImage.errorMessage = "Product image is required!";
    },
    changeStatus(value, stateToggle) {
      let toggleNewValue = value ? 1 : 0;
      switch (stateToggle) {
        case "OVEN":
          this.stateProducts.product.product_send_oven = toggleNewValue;
          this.stateProducts.product.product_kitchen_stat = toggleNewValue;
          this.stateProducts.product.product_boxing_stat = toggleNewValue;
          this.stateProducts.product.product_kitchen_hash = "PM";
          this.stateProducts.product.product_prepare_time = "0";
          this.stateProducts.product.product_cook_time = "0";
          break;
        case "KITCHEN":
          this.stateProducts.product.product_kitchen_stat = toggleNewValue;
          break;
        case "BOXING":
          this.stateProducts.product.product_boxing_stat = toggleNewValue;
          break;
        case "DELIVERY":
          this.stateProducts.product.os_delivery = toggleNewValue;
          break;
        case "PICKUP":
          this.stateProducts.product.os_pickup = toggleNewValue;
          break;
        case "PRINT":
          this.stateProducts.product.print_stat = toggleNewValue;
          break;
        case "DETAIL":
          this.stateProducts.product.os_customize = toggleNewValue;
          break;
        case "WEB":
          this.stateProducts.product.os_online = toggleNewValue;
          break;
        case "POS":
          this.stateProducts.product.os_pos = toggleNewValue;
          break;
        case "KIOSK":
          this.stateProducts.product.os_kiosk = toggleNewValue;
          break;
        case "MOBILE":
          this.stateProducts.product.os_mobile = toggleNewValue;
          break;
        default:
          break;
      }
    },
    addGroup(type) {
      const date = new Date();
      const timestamp = date.getTime();
      this.stateProducts.product.groups.push({
        ghash: "ga" + timestamp,
        type: type,
        name: "GroupName_" + this.stateProducts.product.groups.length,
        icindekiler: [],
        free: 0,
        min: 0,
        hhstatus: 0,
        estatus: 0,
        type_receipt: 0,
        type_pos: 0
      });
    },
    addCoupon() {
      const date = new Date();
      const timestamp = date.getTime();
      this.stateProducts.product.coupons.push({
        chash: "cp" + timestamp,
        name: "Coupon Name #" + this.stateProducts.product.coupons.length,
        icindekiler: []
      });
    },
    startDragDrop(event, opt) {
      if (opt.oitems[event.oldIndex] !== undefined) {
        this.stateProducts.product.draggingHash = opt.oitems[event.oldIndex].ohash;
      } 
      this.stateProducts.product.draggingGroupHash = opt.ghash;
      // all options
      if (event.oldIndex == opt.oitems.length) {
        this.stateProducts.product.draggingHash = 'all';
        opt.oitems.map((item) => {
          if (item.ohash !== undefined) {
            this.stateProducts.product.draggingHash = item.ohash;
          }
        });
      }
    },
    startDragDropCoupon(event, item) {
      if (item.products[event.oldIndex] !== undefined) {
        this.stateProducts.product.draggingHash = item.products[event.oldIndex].phash;
      }
      this.stateProducts.product.draggingGroupHash = item.mhash;
    },
    cloneOpt(event) {
      if (event) {
        return JSON.parse(JSON.stringify(event)); // If you don't do this, it takes the old values when copying!!!! very important!!!!
      }
    },
    // Validations
    dismissError(field) {
      this.formValidation[field].isValid = false;
      this.formValidation[field].errorMessage = "";
    },
    isEmpty(val) {
      return val === "" || val === " " || val === null ? true : false;
    },
    checkForm() {
      let checkingProductName = this.isValidProductName();
      let checkingProductImage = this.isValidProductImage();
      let checkingProductPrice = this.isValidProductPrice();
      return checkingProductName
        && checkingProductImage
        && checkingProductPrice
        ? true : false;
    },
    isValidProductName() {
      if (this.isEmpty(this.stateProducts.product.name)) {
        this.formValidation.productName.isValid = false;
        this.formValidation.productName.errorMessage = "Product name is required!";
        return false;
      } else {
        this.formValidation.productName.isValid = true;
        this.formValidation.productName.errorMessage = "";
        return true;
      }
    },
    isValidProductImage() {
      switch (this.stateProducts.pictureControl) {
        case 0:
          this.formValidation.productImage.isValid = false;
          this.formValidation.productImage.errorMessage = "Product image is required!";
          return false;
        case 1:
          this.formValidation.productImage.isValid = true;
          this.formValidation.productImage.errorMessage = "";
          return true;
        default:
          this.formValidation.productImage.isValid = true;
          this.formValidation.productImage.errorMessage = "";
          return true;
      }
    },
    isValidProductPrice() {
      if (this.stateProducts.product.sizes.length <= 0 && this.productType !== 3) {
        this.formValidation.productPrice.isValid = false;
        this.formValidation.productPrice.errorMessage = "Product price is required!";
        return false;
      } else {
        this.formValidation.productPrice.isValid = true;
        this.formValidation.productPrice.errorMessage = "";
        return true;
      }
    },
  }
};
</script>

<style lang="css" scoped>
.product-form {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 1.25rem;
  grid-auto-flow: column;
  position: relative;
  min-width: 1000px !important;
}
.product-form-content {
  overflow-y: auto;
  padding-right: 0.75rem;
  grid-column: span 3 / span 3;
  max-width: 90%;
  max-height: calc(100vh - 180px);
  vertical-align: top;
}
.product-title {
  margin-right: 1rem;
  width: 100%;
}
.product-type {
  margin-right: 1rem;
  width: 100%;
}
.product-type select {
  width: 150px;
  margin-right: 1rem;
}
.product-name {
  display: flex;
  flex-direction: column;
}
.product-label {
  display: block;
}
.product-coupon {
  overflow-y: auto;
  margin-bottom: 0.5rem;
  margin-bottom: 100px;
}
.product-topping {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.5rem;
}
.topping-wrapper {
  overflow-y: auto;
  margin-bottom: 100px;
}
h2 {
  font-size: 1rem;
  line-height: 1.5rem;
}
label {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
}
input {
  padding: 0.5rem;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  width: 100%;
  border: 1px solid #D1D5DB;
  border-radius: 0.5rem;
}
.sp-wrapper .form-row {
  margin-bottom: 0;
}
.sp-wrapper .checkbox-group {
  margin-right: 0;
}
.draggable-item {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
  background-color: #F3F4F6;
  color: #111827;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-radius: 0.5rem;
  cursor: pointer;
}
.draggable-item:last-child {
  background-color: #D1D5DB;
  color: #4B5563;
  font-weight: 600;
}
.selectbox-wrapper {
  margin-right: 1rem;
}
.flex-col {
  display: flex;
  flex-direction: column;
  /* width: 100px; */
}
.flex-row {
  display: flex;
  flex-direction: row;
  /* width: 100px; */
  margin: 1em 0;
}
.flex-item {
  width: auto;
  margin-right: 2em;
}
.flex-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.size-price {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 1em 0;
}
.scroll-content {
  overflow: auto;
  position: absolute;
  max-height: calc(100vh - 180px);
  right: 0;
  top: 0;
  width: 32%;
}
.imagearea {
  text-align: center;
}
.actions {
  margin: 1em auto;
  text-align: center;
  display: grid;
  gap: 0.5rem;
}
</style>
