<template>
  <div>
    <footer>
      <div class="footer-content">
        <div class="version">
          <span>{{ ver }}</span>
        </div>
        <div class="username">
          <i class="fontello-icon">&#xf2be;</i>
          {{ $store.getters.getUser.name }}
        </div>
      </div>
      <div class="branch-info"  @click="openBranchSetting()">
        <div class="brand-name">
          <i class="flaticon-favorites"></i>
          <span v-if="stateStores.stores !== ''">
            <strong>BRAND</strong>
            {{ stateStores.current.brandName }}
          </span>
        </div>
        <div class="store-name">
          <i class="flaticon-shop"></i>
          <span v-if="stateStores.stores !== ''">
            <strong>STORE</strong>
            {{ stateStores.current.storeName }}
          </span>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store";

export default {
  name: "FooterWrapper",
  data() {
    return {
      storeHash: "",
      ver: ""
    };
  },
  computed: {
    ...mapState({
      stateStores: "stores",
      stateMenus: "menus",
      stateOptions: "options",
      stateUser: "users",
      stateCommon: "common"
    })
  },
  created() {
    this.ver = require('../../../package.json').version
  },
  methods: {
    cancel() {
      store.commit("RESET_MODALBOX");
    },
    openBranchSetting() {
      // update sidebar menu
      this.stateCommon.location = "branch-settings";
      this.$parent.$refs.sidebar.$refs.menu.activeIndex = 10;
      this.$parent.$refs.sidebar.$refs.menu.leaveBgColor();
    },
  }
};
</script>

<style lang="css" scoped>
footer {
  position: fixed;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 20;
  display: flex;
  justify-content: space-between;
  /* border-top: #999 1px solid; */
  background: #e5e5e5;
}
.footer-content {
  display: flex;
  justify-content: space-between;
}
.version {
  background: #9ca3af;
  color: #fff;
  font-size: 1em;
  padding: 5px 10px;
  line-height: 1em;
  position: absolute;
  bottom: 0;
  left: 0;
}
.username {
  overflow: hidden;
  padding-left: 4rem;
  font-size: 1.5em;
  line-height: 4rem;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgba(0, 0, 0, .5);
}
.branch-info {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.branch-info div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1em;
}
.branch-info i {
  margin-right: 1rem;
  font-size: 1.875rem;
  line-height: 2.25rem;
  line-height: 1rem;
}
.branch-info span {
  display: flex;
  overflow-y: hidden;
  font-size: 0.5em;
  line-height: 1.25rem;
  font-weight: 400;
  white-space: nowrap;
  flex-direction: column;
}
.branch-info span strong {
  display: block;
  font-size: 0.5em;
  line-height: 1.5rem;
  font-weight: 400;
}
.branch-info .store-name {
  position: relative;
  padding: 0 1em;
  background-color: #9ca3af;
  color: #fff;
}
/* .branch-info .store-name::before {
  position: absolute;
  bottom: 0px;
  left: -3rem;
  margin-left: 1.5rem;
  display: inline-block;
  height: 0px;
  width: 0px;
  vertical-align: middle;
  border-bottom: 60px solid #9ba3af;
  border-left: 24px solid transparent;
  content: "";
} */
.branch-info .store-name i {
  color: #fff;
}
.branch-info .store-name span {
  color: #fff;
}
.branch-info .store-name span strong {
  color: #fff;
}
ul {
  margin: 0;
}
ul li {
  display: block;
  padding: 1rem;
  margin: 0;
  margin-bottom: 0.5rem;
  background-color: #f9fafb;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  text-align: left;
  border-width: 1px;
  border-color: #f3f4f6;
  border-style: solid;
  cursor: pointer;
}
ul li:last-child {
  margin-bottom: 0;
}
ul li:hover {
  background-color: #2563eb;
  color: #fff;
}
</style>
