<template>
  <div id="audit">
    <div class="audit-header">
      <h2 class="flex-grow">Audits</h2>
      <div>
          <button class="btn-primary" @click="showAuditModal(0, 'newAudit')">
            <span>Create Audit</span>
          </button>
        </div>
    </div>
    <div class="table-list-data">
      <dataset v-slot="{ ds }" :ds-data="stateAudit.stockAuditList" :ds-filter-fields="{}" :ds-sortby="sortBy"
        :ds-sort-as="{}" :ds-search-in="[]" :ds-search-as="{}"
        class="table-list"
        >
        <div :data-page-count="ds.dsPagecount">
          <div class="actions-menu">
            <div class="table-search-wrapper">
              <div class="search-box">
                <label>Start Date </label>
                <input 
                  type="date" 
                  v-model="stateAudit.startDate"
                  :max="stateAudit.endDate"
                />
              </div>
              <div class="search-box">
                <label>End Date </label>
                <input 
                  type="date" 
                  v-model="stateAudit.endDate"
                  :min="stateAudit.startDate"
                />
              </div>
            </div>
            <div class="table-show-wrapper">
              <dataset-show />
            </div>
          </div>
          <div class="table-list">
            <table>
              <thead>
                <tr>
                  <th v-for="(th, index) in cols" :key="th.field" :class="['sort', th.sort]"
                    @click="clickSort($event, index)">
                    {{ th.name }}
                    <i class="gg-select"></i>
                  </th>
                  <th class="table-actions-col"></th>
                </tr>
              </thead>
              <dataset-item tag="tbody">
                <template v-slot="{ row, rowIndex }">
                  <tr  :key="rowIndex">
                    <td width="20%">{{ row.creation_time }}</td>
                    <td width="20%">{{ row.modification_time }}</td>
                    <td width="20%">{{ row.comment }}</td>
                    <td width="15%">
                      <div class="item-actions">
                        <button @click="showAuditModal(1, 'editAudit', row.audit_hash)">
                          <i class="flaticon-edit"></i>
                        </button>
                        <button @click="deleteAudit(row.audit_hash)">
                          <i class="flaticon-delete"></i>
                        </button>
                      </div>
                    </td>
                    <div class="confirm" v-if="isDeletePanelActive && deleteStockAuditHash==row.audit_hash">
                      <p>Are you sure you want to delete this stock audit?</p>
                      <div>
                        <button @click="deleteYes(row.audit_hash)">Yes</button>
                        <button @click="deleteNo()">No</button>
                      </div>
                    </div>
                  </tr>
                </template>
                <template v-slot:noDataFound>
                  <tr>
                    <td colspan="8">No results found</td>
                  </tr>
                </template>
              </dataset-item>
            </table>
          </div>
          <div class="table-pagination">
            <div class="table-pagination-info-wrapper">
              <dataset-info />
            </div>
            <div class="table-pagination-pager-wrapper">
              <dataset-pager />
            </div>
          </div>
        </div>
      </dataset>
    </div>
    <SecondModalBox v-if="stateAudit.stockModalBox" :alias="'newStock'">
        <template #modalTitle>New Stock</template>
        <template #modalBody>
          <StockForm ref="stockForm" />
        </template>
        <template #modalFooter>
          <StockFormButton />
        </template>
      </SecondModalBox>
    <ModalBox :alias="'newAudit'">
      <template #modalTitle>New Audit</template>
      <template #modalBody>
        <AuditForm ref="auditForm" />
      </template>
      <template #modalFooter>
        <AuditFormButton />
      </template>
    </ModalBox>
    <ModalBox :alias="'editAudit'">
      <template #modalTitle>Edit Audit</template>
      <template #modalBody>
        <AuditForm ref="auditForm" />
      </template>
      <template #modalFooter>
        <AuditFormButton />
      </template>
    </ModalBox>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import store from '@/store';
import ModalBox from '@/widgets/ModalBox';
import SecondModalBox from '@/widgets/ModalBox/SecondModalBox.vue';
import StockForm from '../../Stocks/StockForm';
import StockFormButton from '../../Stocks/StockFormButtons';
import AuditForm from './StockAuditsForm';
import AuditFormButton from './StockAuditsButtons';

import {
    Dataset,
    DatasetItem,
    DatasetInfo,
    DatasetPager,
    DatasetShow,
} from "vue-dataset";

export default {
  name: "StockAudits",
  data() {
    return {
      vInvoiceList: [],
      cols: [
        {
          name: "Created Date",
          field: "creation_time",
          sort: "creation_time",
        },
        {
          name: "Modified Date",
          field: "modification_time",
          sort: "modification_time",
        },
        {
          name: "Comment",
          field: "comment",
          sort: "comment",
        },
      ],
      startDate: '',
      endDate: '',
      storeHash: '',
      brandHash: '',
      isDeletePanelActive: false,
      deleteStockAuditHash: '',
    };
  },
  components: {
    ModalBox,
    Dataset,
    DatasetItem,
    DatasetInfo,
    DatasetPager,
    // DatasetSearch,
    DatasetShow,
    AuditForm,
    AuditFormButton,
    SecondModalBox,
    StockForm,
    StockFormButton,
  },
  created() {
    this.getAuditList();
    this.storeHash = this.stateStores.current.storeHash;
    this.brandHash = this.stateStores.current.brandHash;
  },
  watch: {
    getAuditStartDate() {
      this.getAuditList();
    },
    getAuditEndDate() {
      this.getAuditList();
    },
  },
  computed: {
    ...mapState({ stateAudit: "audits", stateStores: "stores" }),
    ...mapGetters(["getAuditStartDate", "getAuditEndDate"]),
    sortBy() {
      return this.cols.reduce((acc, o) => {
        if (o.sort) {
          o.sort === "asc" ? acc.push(o.field) : acc.push("-" + o.field);
        }
        return acc;
      }, []);
    },
  },
  methods: {
    numberParse(val) {
      return parseFloat(val);
    },
    dateParse(val) {
      return new Date(val).getTime();
    },
    clickSort(event, i) {
      let toset;
      const sortEl = this.cols[i];
      if (!event.shiftKey) {
        this.cols.map(o => {
          if (o.field !== sortEl.field) {
            o.sort = "";
          }
        });
      }
      if (!sortEl.sort) {
        toset = "asc";
      }
      if (sortEl.sort === "desc") {
        toset = event.shiftKey ? "" : "asc";
      }
      if (sortEl.sort === "asc") {
        toset = "desc";
      }
      sortEl.sort = toset;
    },
    getAuditList() {
      store.dispatch("getStockAuditList", {
        startDate: this.stateAudit.startDate,
        endDate: this.stateAudit.endDate,
        brandHash: this.brandHash,
        storeHash: this.storeHash,
      })
    },
    showAuditModal(type, alias, auditHash) {
      let modalOpts = {
        isActiveStatus: true,
        type: type,
        alias: alias,
      };
      if (auditHash) {
        store.commit("GET_STOCK_AUDIT", auditHash)
      }
      store.dispatch(
        "showModalBox",
        Object.assign(store.state.common.modalBox, modalOpts)
      );
    },
    deleteAudit(auditHash) {
      this.deleteStockAuditHash = auditHash;
      this.isDeletePanelActive = true;
    },
    deleteYes(auditHash) {
      store.dispatch("deleteStockAudit", {
        auditHash,
        brandHash: this.brandHash,
        storeHash: this.storeHash,
      });
      this.deleteNo();
    },
    deleteNo() {
      this.deleteStockAuditHash = "";
      this.isDeletePanelActive = false;
    },
  }
};
</script>

<style scoped>
#audit .table-list-data {
  display: flex;
}
#audit .audit-header {
  display: flex; 
  padding-bottom: 1rem; 
  margin-bottom: 1rem; 
  justify-content: space-between; 
  align-items: center; 
  border-bottom-width: 1px; 
  border-color: #F3F4F6; 
}
#audit .flex-grow {
  flex-grow: 1; 
}

#audit .actions-menu {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: baseline;
}
#audit .item-actions {
  justify-content: center;
}
#audit .table-list {
  overflow-x: auto;
  width: 100%;
}
</style>