<template>
  <div id="user">
    <Loader :isActiveStatus="!stateUsers.status"></Loader>
    <div class="user-wrapper" v-if="stateUsers.status">
      <h2 class="user-title">Users</h2>
      <div>
        <button class="btn-primary" @click="showUserModal(0, 'newUser')">
          <span>Create User</span>
        </button>
      </div>
    </div>
    <Panel :title="false">
      <template #row>
        <div class="message" v-if="stateMenus.posusers.length == 0">No user found.</div>
        <div class="item" v-for="(item, idx) in stateMenus.posusers" :key="idx">
          <div class="menu-item">
              <div class="menu-name">
                {{ item.name }}
                <span class="label" v-if="item.status === '0'">Hidden</span>
                <div class="text-xs uppercase title">
                  {{ getTitle(item.u_type) }}
                </div>
              </div>
            <div
              class="sync"
              :class="{ 'not-sync': item.sync_pos_status == '0' }"
            ></div>
          </div>
          <div class="item-actions">
            <button @click="changeStatus(item, 'status', 0)">
              <i
                class="flaticon-show"
                :class="{ close: item.status == '0' }"
              ></i>
            </button>
            <button @click="editUser(item.uhash, item)">
              <i class="flaticon-edit"></i>
            </button>
            <button @click="deleteUser(item.uhash, item)">
              <i class="flaticon-delete"></i>
            </button>
            <button @click="syncUser(item)" class="sync-time">
              <i class="flaticon-sync"></i>
              <span v-if="item.sync_pos_time">Last Update:<br>{{ item.sync_pos_time }}</span>
              <span v-else>Not Synced Yet</span>
            </button>
          </div>
          <!-- confirm panel to delete the item -->
          <div class="confirm" v-if="isDeletePanelActive && deleteUserHash==item.uhash">
            <p>Are you sure you want to delete this user?</p>
            <div>
              <button @click="deleteYes(item.uhash)">Yes</button>
              <button @click="deleteNo()">No</button>
            </div>
          </div>
        </div>
      </template>
    </Panel>

    <ModalBox :alias="'newUser'">
      <template #modalTitle>New User</template>
      <template #modalBody>
        <UsersForm ref="userForm" />
      </template>
      <template #modalFooter>
        <UsersFormButtons></UsersFormButtons>
      </template>
    </ModalBox>

    <ModalBox :alias="'editUser'">
      <template #modalTitle>Edit User</template>
      <template #modalBody>
        <UsersForm ref="userForm" />
      </template>
      <template #modalFooter>
        <UsersFormButtons></UsersFormButtons>
      </template>
    </ModalBox>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Panel from "@/widgets/Panel";
import UsersForm from "./UsersForm";
import UsersFormButtons from "./UsersFormButtons";
import store from "@/store";

export default {
  name: "PosUsers",
  data() {
    return {
      isDeletePanelActive: false,
      deleteUserHash: "",
      storeHash: "",
    };
  },
  components: {
    Panel,
    UsersForm,
    UsersFormButtons
  },
  created() {
    this.storeHash = this.stateStores.current.storeHash;
  },
  computed: {
    ...mapState({
      stateMenus: "menus",
      stateStores: "stores",
      stateCommon: "common",
      stateUsers: "users"
    }),
  },
  methods: {
    showUserModal(type, alias, userHash) {
      let modalOpts = {
        isActiveStatus: true,
        type,
        alias
      };
      if (userHash) {
        modalOpts["hash"] = userHash;
      }
      store.dispatch(
        "showModalBox",
        Object.assign(store.state.common.modalBox, modalOpts)
      );
    },
    getTitle(userType) {
      if (userType == 3) {
        return "Cashier";
      } else if (userType == 4) {
        return "Cashier/Manager";
      } else if (userType == 5) {
        return "Driver";
      } else if (userType == 6) {
        return "Pizzamaker";
      }
      return -1;
    },
    async changeStatus(user, props, y) {
      // y = 0: status, 1: pos_status, 2: web_status, 3: mobile_status
      let newstat = user[props] == 1 ? '0' : '1';
      const params = new URLSearchParams();
      params.append("uhash", user.uhash);
      params.append("newstat", newstat);
      params.append("y", y);
      const config = {
          method: "post",
          url: `posuser/changestatus?s=${this.storeHash}`,
          data: params
        };
      try {
        let { data } = await window.$http.axios(config);
        if (data.status) {
          user.sync_pos_status = "0";
          user[props] = newstat;
          store.commit("NOTIFICATION_MESSAGE", { status: 1, title: "POS Sync Completed", message: "User status updated successfully." });
        } else {
          store.commit("NOTIFICATION_MESSAGE", { status: 1, title: "Success", message: data.message });
        }
      } catch(error) {
        console.error(error);
        store.commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: error });
      }
    },
    editUser(userItemHash, userItem) {
      store.commit("SET_CURRENT_USER", userItem);
      this.showUserModal(1, "editUser", userItemHash);
    },
    deleteUser(userHash) {
      this.isDeletePanelActive = true;
      this.deleteUserHash = userHash;
    },
    deleteYes(userHash) {
      store.dispatch("deleteUser", userHash);
      this.deleteNo();
    },
    deleteNo() {
      this.deleteUserHash = "";
      this.isDeletePanelActive = false;
    },
    currentTime() {
      const pad = (num) => (num < 10 ? `0${num}` : num);
      let now = new Date();
      let date = `${now.getFullYear()}-${pad(now.getMonth() + 1)}-${pad(now.getDate())}`;
      let time = `${pad(now.getHours())}:${pad(now.getMinutes())}:${pad(now.getSeconds())}`;
      return `${date} ${time}`;
    },
    async syncUser(item) {
      store.commit('LOADING', { isActiveStatus: true });
      try {
        let { data } = await window.$http.axios.get(`sync/pos/posuser?u=${item.uhash}&s=${this.storeHash}`);
        if (data.status) {
          store.commit("NOTIFICATION_MESSAGE", { status: 1, title: "POS Sync Completed", message: data.message });
          item.sync_pos_time = this.currentTime();
          item.sync_pos_status = '1';
        } else {
          store.commit('NOTIFICATION_MESSAGE', { status: -1, title: 'Error', message: data.message });
          item.sync_pos_status = '0';
        }
      } catch (error) {
        store.commit('NOTIFICATION_MESSAGE', { status: -1, title: 'Error', message: error.message });
        item.sync_pos_status = '0';
      }
      store.commit('LOADING', { isActiveStatus: false });
    }
  }
};
</script>

<style lang="css" scoped>
.user-wrapper {
  display: flex; 
  margin-right: 1rem;
  margin-bottom: 1rem; 
  justify-content: space-between; 
  align-items: center; 
  border-bottom-width: 1px; 
  border-color: #F3F4F6; 
}
.user-title {
  flex-grow: 1;
}
.message {
  color: #000;
  font-size: 1.5rem;
  text-align: center;
  padding: 1rem;
}
</style>