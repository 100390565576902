<template>
  <div id="vendor">
    <div class="vendor-wrapper">
      <h2 class="vendor-title">Vendors</h2>
      <div>
        <button class="btn-primary" @click="showVendorModal(0, 'newVendor')">
          <span>Create Vendor</span>
        </button>
      </div>
    </div>
    <div class="table-list-data">
      <dataset
        v-slot="{ ds }"
        :ds-data="vendors.vVendorList"
        :ds-filter-fields="{}"
        :ds-sortby="sortBy"
        :ds-search-in="[
          'name',
          'email',
          'address',
        ]"
        :ds-search-as="{}"
      >
        <div :data-page-count="ds.dsPagecount" class="table-filter-wrapper">
          <div class="actions-menu">
            <div class="table-search-wrapper">
              <dataset-search ds-search-placeholder="Search..." />
            </div>
          </div>
          <div class="table-show-wrapper">
              <dataset-show />
          </div>
        </div>
        <div class="table-list">
          <table>
            <thead>
              <tr>
                <th
                v-for="(th, index) in cols"
                :key="th.field"
                :class="['sort', th.sort]"
                @click="clickSort($event, index)"
                >
                  {{ th.name }}
                <i class="gg-select"></i>
                </th>
                <th class="table-actions-col"></th>
              </tr>
            </thead>
            <dataset-item tag="tbody">
              <template v-slot="{ row }">
                <tr>
                  <td width="15%">{{ row.name }}</td>
                  <!-- <td width="20%">{{ row.manager }}</td> -->
                  <td width="20%">{{ row.phone }}</td>
                  <td width="25%">{{ row.email }}</td>
                  <td width="25%">{{ row.address }}</td>
                  <td width="5%">
                    <div class="item-actions">
                      <button @click="showVendorModal(1, 'editVendor', row.hash)">
                        <i class="flaticon-edit"></i>
                      </button>
                      <button @click="deleteVendor(row.hash)">
                        <i class="flaticon-delete"></i>
                      </button>
                    </div>
                  </td>
                  <div class="confirm" v-if="isDeletePanelActive && deleteVendorHash==row.hash">
                    <p>Are you sure you want to delete this this vendor?</p>
                    <div>
                      <button @click="deleteYes(row.hash)">Yes</button>
                      <button @click="deleteNo()">No</button>
                    </div>
                  </div>
                </tr>
              </template>
              <template v-slot:noDataFound>
                <tr>
                  <td colspan="6">No results found</td>
                </tr>
              </template>
            </dataset-item>
          </table>
        </div>
        <div class="table-pagination">
          <div class="table-pagination-pager-wrapper">
            <dataset-pager />
          </div>
          <div class="table-pagination-info-wrapper">
            <dataset-info />
          </div>
        </div>
      </dataset>
    </div>
    <ModalBox :alias="'newVendor'">
      <template #modalTitle>New Vendor</template>
      <template #modalBody>
        <VendorForm ref="vendorForm" />
      </template>
      <template #modalFooter>
        <VendorFormButtons />
      </template>
    </ModalBox>
    <ModalBox :alias="'editVendor'">
      <template #modalTitle>Edit Vendor</template>
      <template #modalBody>
        <VendorForm ref="vendorForm" />
      </template>
      <template #modalFooter>
        <VendorFormButtons />
      </template>
    </ModalBox>
  </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store";
import ModalBox from '@/widgets/ModalBox';
import VendorForm from "./VendorForm.vue";
import VendorFormButtons from "./VendorFormButtons.vue";

import {
  Dataset,
  DatasetItem,
  DatasetInfo,
  DatasetPager,
  DatasetSearch,
  DatasetShow
} from "vue-dataset";

export default {
  name: "Vendors",
  data() {
    return {
      cols: [
        {
          name: "Vendor",
          field: "name",
          sort: ""
        },
        {
          name: "Phone",
          field: "phone",
          sort: ""
        },
        {
          name: "E-mail",
          field: "email",
          sort: ""
        },
        {
          name: "Address",
          field: "address",
          sort: ""
        }
      ],
      brandHash: "",
      isDeletePanelActive: false,
      deleteVendorHash: ""
    };
  },
  mounted() {
    store.dispatch("getVendorList", this.brandHash);
  },
  components: {
    ModalBox,
    VendorForm,
    VendorFormButtons,
    Dataset,
    DatasetItem,
    DatasetInfo,
    DatasetPager,
    DatasetSearch,
    DatasetShow,
  },
  created() {
    this.brandHash = this.stateStore.current.brandHash;
  },
  computed: {
    ...mapState({
        vendors: state => state.vendors,
        stateInvoice: "invoices",
        stateStore: "stores",
    }),
    sortBy() {
      return this.cols.reduce((acc, o) => {
        if (o.sort) {
          o.sort === "asc" ? acc.push(o.field) : acc.push("-" + o.field);
        }
        return acc;
      }, []);
    }
  },
  methods: {
    clickSort(event, i) {
      let toset;
      const sortEl = this.cols[i];
      if (!event.shiftKey) {
        this.cols.map(o => {
          if (o.field !== sortEl.field) {
            o.sort = "";
          }
        });
      }
      if (!sortEl.sort) {
        toset = "asc";
      }
      if (sortEl.sort === "desc") {
        toset = event.shiftKey ? "" : "asc";
      }
      if (sortEl.sort === "asc") {
        toset = "desc";
      }
      sortEl.sort = toset;
    },
    showVendorModal(type, alias, vendorHash) {
        let modalOpts = {
            isActiveStatus: true,
            type,
            alias,
            hash: vendorHash
        };
        if(vendorHash) {
            const vendroDetails = this.vendors.vVendorList.find(vendor => vendor.hash === vendorHash);
            if(vendroDetails) {
                store.commit("GET_VENDOR", vendroDetails);
            }
            modalOpts["hash"] = vendorHash;
        }
        store.dispatch(
          "showModalBox",
          Object.assign(store.state.common.modalBox, modalOpts)
        );
    },
    deleteVendor(vendorHash) {
      this.deleteVendorHash = vendorHash;
      this.isDeletePanelActive = true;
    },
    deleteYes(vendorHash) {
      store.dispatch("deleteVendor", {
          vendorHash,
          brandHash: this.brandHash,
        });
      this.deleteNo();
    },
    deleteNo() {
      this.deleteVendorHash = "";
      this.isDeletePanelActive = false;
    },
  }
}
</script>

<style lang="css" scoped>
.vendor-wrapper {
  display: flex; 
  padding-bottom: 1rem; 
  margin-bottom: 1rem; 
  justify-content: space-between; 
  align-items: center; 
  border-bottom-width: 1px; 
  border-color: #F3F4F6; 
}
.vendor-title {
  flex-grow: 1;
}
.item-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.item-actions button {
  font-size: 1.6em;
  line-height: 1.5rem;
  padding: 0 10px;
  background: none;
  border: none;
  outline: none;
  font-weight: 600;
  border-radius: var(--rounded-lg);
  margin: 0 auto!important;
}
</style>