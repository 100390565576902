const getOptionsDefaultState = () => {
  return {
    options: '',
    vTabMenu: [],
    vSelectedTab: '',
    vSize: [],
    selectedSize: 0,
    selectedRow: 0,
    stockModalBox: false,
    option: {
      o_name: '',
      o_hash: '',
      o_desc: '',
      o_note: '',
      o_prices: [],
      o_removable: 0,
      o_selected: 0,
      o_status: 0,
      o_pos_status: 0,
      o_sync_pos_time: '',
    },
    formValidation: {
      vOptionName: { isValid: false, errorMessage: '' },
      vOptionSize: { isValid: false, errorMessage: '' },
      vOptionIngredient: { isValid: true },
    },
  };
};

const options = {
  state: getOptionsDefaultState(),
  mutations: {
    RESET_FORM_OPTIONS(state) {
      state.selectedRow = 0;
      state.option = {
        o_name: '',
        o_hash: '',
        o_desc: '',
        o_note: '',
        o_prices: [],
        // o_recipe: [],
        o_removable: 0,
        o_selected: 0,
        o_status: 0,
        o_pos_status: 0,
        o_sync_pos_time: '',
      };
      state.formValidation = {
        vOptionName: { isValid: false, errorMessage: '' },
        vOptionSize: { isValid: false, errorMessage: '' },
        vOptionIngredient: { isValid: true },
      };
    },
    GET_OPTIONS(state, payload) {
      state.options = payload.options; // TODO: Why this one is array in object
    },
    SET_TABS_OPTION_ITEMS(state, payload) {
      state.vTabMenu = payload;
    },
    SET_TAB_OPTION_ITEMS(state) {
      if (state.vSelectedTab === '' || state.vSelectedTab === false) {
        state.vSelectedTab = state.vTabMenu[0].id;
      }
    },
    GET_OPTIONS_ITEM(state, optionItemHash) {
      let tabMenuIdx = state.vTabMenu.findIndex(
        (item) => item.id === state.vSelectedTab
      );
      state.options[tabMenuIdx].oitems.map((item) => {
        if (item.ohash == optionItemHash) {
          state.option = {
            o_name: item.name,
            o_hash: item.ohash,
            o_desc: item.desc,
            o_note: item.note,
            o_prices: item.prices,
            o_removable: item.removable,
            o_selected: item.selected,
            o_status: item.status,
            o_pos_status: item.sync_pos_status,
            o_sync_pos_time: item.sync_pos_time,
          };
        }
      });
    },
    ADD_OPTION_ITEM(state, value) {
      let tabMenuIdx = state.vTabMenu.findIndex(
        (item) => item.id === state.vSelectedTab
      );
      if(state.options[tabMenuIdx].oitems.length > 0) {
        state.options[tabMenuIdx].oitems.unshift(value);
      } else {
        state.options[tabMenuIdx].oitems = [value];
      }
      
    },
    UPDATE_OPTION_ITEM(state, value) {
      let tabMenuIdx = state.vTabMenu.findIndex(
        (item) => item.id === state.vSelectedTab
      );
      state.options[tabMenuIdx].oitems.map((item) => {
        if (item.ohash === value.ohash) {
          item.name = value.name;
          item.desc = value.desc;
          item.note = value.note;
          item.prices = value.prices;
          item.recipe = value.recipe;
          item.removable = value.removable;
          item.selected = value.selected;
          item.status = value.status;
          item.sync_pos_status = value.sync_pos_status;
          item.sync_pos_time = value.sync_pos_time;
          return;
        }
      });
    },
    DELETE_OPTION_ITEM(state, optionItemHash) {
      let tabMenuIdx = state.vTabMenu.findIndex(
        (item) => item.id === state.vSelectedTab
      );
      state.options[tabMenuIdx].oitems.map((item) => {
        if (item.ohash === optionItemHash) {
          // remove item object from array
          let itemIdx = state.options[tabMenuIdx].oitems.indexOf(item);
          state.options[tabMenuIdx].oitems.splice(itemIdx, 1);
          return;
        }
      });
    },
    UPDATE_STOCK_IN_OPTION(state, value) {
      // update ingredient in o_prices
      let groups = state.option.o_prices[state.selectedSize].ingredient_groups
      groups.map((group) => {
        group.group_ingredients.map((ingredient) => {
          // find selected group ingredient
          if (ingredient.ingredient_name == "") {
            ingredient.comment = value.comment;
            ingredient.ingredient_name = value.stock_name;
            ingredient.ingredient_hash = value.stock_hash;
            ingredient.ingredient_price = value.stock_unit_price;
            ingredient.light_amount = value.light_amount;
            ingredient.regular_amount = value.regular_amount;
            ingredient.extra_amount = value.extra_amount;
            ingredient.stock_hash = value.stock_hash;
            ingredient.unit_hash = value.unit_hash;
            ingredient.unit_name = value.unit_name;
          }
        });
      });
      // add new stock to o_prices
      // state.option.o_prices[state.selectedSize].ingredients[state.selectedRow] = newStock;
    },
    OPTION_CHANGE_SYNC_STATUS(state, groupHash) {
      state.options.map((item) => {
        if (item.ghash === groupHash) {
          item.oitems.map((optionItem) => {
            optionItem.sync_pos_status = '0';
          })
        }
      });
    },
  },
  actions: {
    closeOptionModalBox({commit}) {
      commit('RESET_FORM_OPTIONS');
      commit('RESET_MODALBOX');
    },
    async getOptions({ commit }, storeHash) {
      commit('LOADING', { isActiveStatus: true });
      try {
        let { data } = await window.$http.axios.get(
          `v2/options?store=${storeHash}`
        );
        if(data.status) {
          commit('GET_OPTIONS', data);
        } else {
          console.log(data.message);
        }
      } catch (error) {
        console.error(error);
      }
      commit('LOADING', { isActiveStatus: false });
    },
    getTabsOptionItems({ state, commit }) {
      let tabs = [];
      if(state.options.length === 0) {
        commit('NOTIFICATION_MESSAGE', { status: -1, title: 'Error', message: 'There is no option items.' })
        return;
      };
      state.options.map((item) => {
        tabs.push({ name: item.gname, id: item.ghash });
      });
      commit('SET_TABS_OPTION_ITEMS', tabs);
      commit('SET_TAB_OPTION_ITEMS');
    },
    async saveOptionsItem({ state, commit, dispatch }, storeHash) {
      const FormData = require('form-data');
      const formData = new FormData();
      let priceItems = [];
      state.option.o_prices.map((items) => {
        let ingredientItems = [];
        items.ingredient_groups.map((recipe) => {
          recipe.group_ingredients.map((ingredient) => {
            let ingredientItem = {
              ingredient_name: ingredient.ingredient_name,
              ingredient_price: ingredient.ingredient_price,
              ingredient_hash: ingredient.ingredient_hash,
              unit_hash: ingredient.unit_hash,
              unit_name: ingredient.unit_name,
              stock_hash: ingredient.stock_hash,
              light_amount: ingredient.light_amount,
              regular_amount: ingredient.regular_amount,
              extra_amount: ingredient.extra_amount,
              comment: ingredient.comment,
            }
            ingredientItems.push({
              group_name: recipe.group_name,
              group_ingredients: [ingredientItem],
            });
          })
        });
        let priceItem = {
          size_code: items.size_code,
          default_unit: items.default_unit,
          price: items.price,
          e_price: items.e_price,
          l_price: items.l_price,
          f_price: items.f_price,
          ingredient_groups: ingredientItems,
        };
        priceItems.push(priceItem);
      });

      formData.append('o_name', state.option.o_name);
      formData.append('o_desc', state.option.o_desc);
      formData.append('o_note', state.option.o_note);
      formData.append('g_hash', state.vSelectedTab);
      formData.append('prices', JSON.stringify(priceItems));

      const config = {
        method: 'post',
        url: `https://api.laruota.ca/v2/options?store=${storeHash}`,
        data: formData,
      };
      commit('LOADING', { isActiveStatus: true });
      try {
        let { data } = await window.$http.axios(config);
        if (data.status) {
          commit('ADD_OPTION_ITEM', data.option_item);
          commit('NOTIFICATION_MESSAGE', { status: 1, title: 'Success', message: 'Option item has been added successfully.' });
          // dispatch("getOptions", store.state.current.storeHash);
          dispatch('closeOptionModalBox');
        } else {
          commit('NOTIFICATION_MESSAGE',{ status: -1, title: 'Error', message: data.message });
        }
      } catch (error) {
        commit('NOTIFICATION_MESSAGE', { status: -1, title: 'Error', message: error.message });
      }
      commit('LOADING', { isActiveStatus: false });
    },
    async updateOptionsItem({ state, commit, dispatch }, {
      storeHash,
      optionItemHash,
    }) {
      const FormData = require('form-data');
      const formData = new FormData();
      let priceItems = [];
      state.option.o_prices.map((items) => {
        let ingredientItems = [];
        items.ingredient_groups.map((recipe) => {
          recipe.group_ingredients.map((ingredient) => {
            // if recipe does not have ingredient_name, then skip
            let ingredientItem = {
              ingredient_name: ingredient.ingredient_name,
              ingredient_price: ingredient.ingredient_price,
              ingredient_hash: ingredient.ingredient_hash,
              unit_hash: ingredient.unit_hash,
              unit_name: ingredient.unit_name,
              stock_hash: ingredient.stock_hash,
              light_amount: ingredient.light_amount,
              regular_amount: ingredient.regular_amount,
              extra_amount: ingredient.extra_amount,
              comment: ingredient.comment,
            }
            ingredientItems.push({
              group_name: recipe.group_name,
              group_ingredients: [ingredientItem],
            });
          })
        });
        let priceItem = {
          size_code: items.size_code,
          default_unit: items.default_unit,
          phash: items.phash,
          price: items.price,
          e_price: items.e_price,
          l_price: items.l_price,
          f_price: items.f_price,
          ingredient_groups: ingredientItems,
        };
        priceItems.push(priceItem);
      });

      formData.append('o_name', state.option.o_name);
      formData.append('o_desc', state.option.o_desc);
      formData.append('o_note', state.option.o_note);
      formData.append('o_hash', state.option.o_hash);
      formData.append('g_hash', state.vSelectedTab);
      formData.append('prices', JSON.stringify(priceItems));

      const config = {
        method: 'put',
        url: `https://api.laruota.ca/v2/options/${optionItemHash}?store=${storeHash}`,
        data: formData,
      };
      commit('LOADING', { isActiveStatus: true });
      try {
        let { data } = await window.$http.axios(config);
        if (data.status) {
          commit('UPDATE_OPTION_ITEM', data.option_item);
          commit('NOTIFICATION_MESSAGE', { status: 1, title: 'Updated', message: 'Options item updated!' });
          dispatch('closeOptionModalBox');
        } else {
          commit('NOTIFICATION_MESSAGE', { status: -1, title: 'Error', message: data.message });
        }
      } catch (error) {
        commit('NOTIFICATION_MESSAGE', { status: -1, title: 'Error', message: error.message });
      }
      commit('LOADING', { isActiveStatus: false });
    },
    async deleteOptionItems({ commit }, optionItemHash) {
      commit('LOADING', { isActiveStatus: true });
      try {
        await window.$http.axios.delete(`v2/options/${optionItemHash}`);
        commit('DELETE_OPTION_ITEM', optionItemHash);
        commit('NOTIFICATION_MESSAGE', { status: 1, title: 'Deleted', message: 'Options item deleted!' });
      } catch (error) {
        commit('NOTIFICATION_MESSAGE',{ status: -1, title: 'Error', message: error.message });
      }
      commit('LOADING', { isActiveStatus: false });
    },
    async updateOptionOrder({ commit }, { storeHash, groupHash, optionList }) {
      // console.log(storeHash, groupHash, optionList);
      const FormData = require('form-data');
      const formData = new FormData();
      formData.append('ghash', groupHash);
      optionList.map((product) => {
        formData.append('ohashlist[]', product.ohash);
      });
      const config = {
        method: 'post',
        url: `option/changeorder?s=${storeHash}`,
        data: formData,
      };
      commit('LOADING', { isActiveStatus: true });
      try {
        let { data } = await window.$http.axios(config);;
        if (data.status) {
          commit('OPTION_CHANGE_SYNC_STATUS', groupHash);
        } else {
          commit('NOTIFICATION_MESSAGE', { status: -1, title: 'Error', message: data.message });
        }
      } catch (error) {
        console.error(error);
        commit('NOTIFICATION_MESSAGE', { status: -1, title: 'Error', message: error.message });
      }
      commit('LOADING', { isActiveStatus: false });
    }
  },
};

export default options;
