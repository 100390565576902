<template>
  <div class="form-button-actions">
    <button class="btn-secondary" @click="cancel()">Cancel</button>
    <button class="btn-primary" @click="updateMenuboard()" v-if="getFormType == 1">Update</button>
    <button class="btn-primary" @click="saveMenuboard()" v-else>Save</button>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import store from '@/store';

export default {
  name: 'MenuboardFormButton',
  computed: {
    ...mapState({
      stateMenuboard: "menuboards"
    }),
    ...mapGetters(["getFormType"])
  },
  methods: {
    cancel() {
      store.dispatch("closeMenuboardModalBox");
    },
    updateMenuboard() {
      const menuboardsHash = this.stateMenuboard.vMenuboardHash;
      if (this.$parent.$parent.$refs.menuboardsForm.checkForm()) {
        store.dispatch("updateMenuboard", menuboardsHash);
      }
    },
    saveMenuboard() {
      if (this.$parent.$parent.$refs.menuboardsForm.checkForm()) {
        store.dispatch("saveMenuboard");
      }
    }
  }
}
</script>