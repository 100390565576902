const getHoursDefaultState = () => ({
  store_hours: '',
  deliveryHours: [],
  carryoutHours: [],
  deliverySchedule: [],
  carryoutSchedule: [],
  deliverySelectedDay: "0",
  carryoutSelectedDay: "0",
});

function formatTime (time) {
  const [hour, minute] = time.split(':');
  return `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}`;
}

const hours = {
  state: getHoursDefaultState(),
  mutations: {
    SETHOURS(state, hours) {
      state.store_hours = hours;
    },
    SETDELIVERYHOURS(state, day) {
      state.deliverySelectedDay = day;
      state.deliveryHours = [];
      state.store_hours.d.map((item) => {
        if (item.day == day) {
          state.deliveryHours.push(item);
        }
      });
      // delivery hours schedule
      state.deliverySchedule = [];
      let openTime = null;
      let closeTime = null;
      state.deliveryHours.forEach((item, index) => {
        if (item.status === 1 && openTime === null) {
          openTime = formatTime(`${item.hour}:${item.minute}`);
        } else if (item.status === 0 && openTime !== null) {
          closeTime = formatTime(`${item.hour}:${state.deliveryHours[index - 1].minute}`);
          state.deliverySchedule.push({ openTime, closeTime });
          openTime = null;
          closeTime = null;
        }
        // If the store is still open at the end of the day
        if (index === state.deliveryHours.length - 1 && openTime !== null) {
          closeTime = '23:45';
          state.deliverySchedule.push({ openTime, closeTime });
        }
      });
    },
    SETCARRYOUTHOURS(state, day) {
      state.carryoutSelectedDay = day;
      state.carryoutHours = [];
      state.store_hours.c.map((item) => {
        if (item.day == day) {
          state.carryoutHours.push(item);
        }
      });
      // carryout hours schedule 
      state.carryoutSchedule = [];
      let openTime = null;
      let closeTime = null;
      state.carryoutHours.forEach((item, index) => {
        if (item.status === 1 && openTime === null) {
          openTime = formatTime(`${item.hour}:${item.minute}`);
        } else if (item.status === 0 && openTime !== null) {
          closeTime = formatTime(`${item.hour}:${state.carryoutHours[index - 1].minute}`);
          state.carryoutSchedule.push({ openTime, closeTime });
          openTime = null;
          closeTime = null;
        }
        // If the store is still open at the end of the day
        if (index === state.carryoutHours.length - 1 && openTime !== null) {
          closeTime = '23:45';
          state.carryoutSchedule.push({ openTime, closeTime });
        }
      });
    },
  },
  actions: {
    configHours({ commit }, hours) {
      commit("SETHOURS", hours);
      commit("SETDELIVERYHOURS", "0");
      commit("SETCARRYOUTHOURS", "0");
    },
    async updateHours({ commit }, {
      storeHash,
      hoursData,
    }) {
      commit("LOADING", { isActiveStatus: true});
      try {
        let { data } = await window.$http.axios.post(
          `/store/updatehours?s=${storeHash}`, 
          `hours=${hoursData}`
        );
        if(data.status) {
          commit("NOTIFICATION_MESSAGE", { status: 1, title: "Updated", message: "Hours has been updated successfully" });
        } else {
          commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: data.message });
        }
      } catch (error) {
        commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: "Something went wrong, Please try again later" });
      }
      commit("LOADING", { isActiveStatus: false});
    }
  },
  getters: {},
}

export default hours;