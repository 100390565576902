<template>
  <div>
    <div>
      <p>Option Name: {{ option.option_name }}</p>
      <p>Option Hash: {{ option.option_hash }}</p>
      <p>Option Unit: {{ option.unit }}</p>
      <p>Option Amount: {{ option.amount }}</p>
      <div v-for="(ingredient, index) in option.option_ingredients" :key="(index)">
        <div v-if="option.option_ingredients" class="p-left">
          <IngredientInfo :ingredient="ingredient" :index="index" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IngredientInfo from './IngredientInfo.vue';
export default {
  name: 'OptionInfo',
  data() {
    return {
      stateOption: false,
    }
  },
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
  components: {
    IngredientInfo,
  },
  methods: {
    toggleOption() {
      this.stateOption = !this.stateOption;
    }
  },
}
</script>

<style css scoped>
.form-button-actions {
  padding: 10px 0;
}
.form-button-actions button.btn-primary {
  margin-left: 0.5rem;
  background-color: #17a2b8;
  color: #fff;
}
.ingredient p {
  font-size: 1em;
  line-height: 1em;
  padding-left: 30px;
  padding-bottom: 10px;
}
.p-left {
  font-size: 1em;
  padding-left: 50px;
}
p {
  font-size: 1.2em;
  line-height: 1em;
}
</style>