<template>
  <div id="stock">
    <div class="stock-wrapper">
      <h2 class="stock-title">Stocks</h2>
      <div>
        <button class="btn-primary" @click="showStockModal(0, 'newStock')">
          <span>Create Stock</span>
        </button>
      </div>
    </div>
    <div class="table-list-data">
      <dataset
        v-slot="{ ds }"
        :ds-data="stocks.vStockList"
        :ds-filter-fields="{}"
        :ds-sortby="sortBy"
        :ds-search-in="['stock_sku', 'stock_barcode', 'stock_name']"
        :ds-search-as="{}"
      >
        <div :data-page-count="ds.dsPagecount" class="table-filter-wrapper">
          <div class="actions-menu">
            <div class="table-search-wrapper">
              <dataset-search ds-search-placeholder="Search..." />
            </div>
          </div>
          <div class="table-show-wrapper">
            <dataset-show />
          </div>
        </div>
        <div class="table-list">
          <table>
            <thead>
              <tr>
                <th
                  v-for="(th, index) in cols"
                  :key="th.field"
                  :class="['sort', th.sort]"
                  @click="clickSort($event, index)"
                >
                  {{ th.name }}
                  <i class="gg-select"></i>
                </th>
                <th class="table-actions-col"></th>
              </tr>
            </thead>
            <dataset-item tag="tbody">
              <template v-slot="{ row, rowIndex }">
                <tr :key="rowIndex">
                  <td width="15%">{{ row.stock_sku }}</td>
                  <td width="15%">{{ row.stock_barcode }}</td>
                  <td width="15%">{{ row.stock_name }}</td>
                  <td width="15%">{{ row.stock_amount }}</td>
                  <td width="10%">{{ row.unit_name }}</td>
                  <td width="10%">{{ row.stock_unit_price }}</td>
                  <td width="10%">{{ row.stock_tax1 }}</td>
                  <td width="10%">{{ row.stock_tax2 }}</td>
                  <td width="15%">
                    <div class="item-actions">
                      <button @click="showStockModal(1, 'editStock', row.stock_hash)">
                        <i class="flaticon-edit"></i>
                      </button>
                      <button @click="deleteStock(row.stock_hash)">
                        <i class="flaticon-delete"></i>
                      </button>
                    </div>
                  </td>
                  <div class="confirm" v-if="isDeletePanelActive && deleteStockHash==row.stock_hash">
                    <p>Are you sure you want to delete this stock?</p>
                    <div>
                      <button @click="deleteYes(row.stock_hash)">Yes</button>
                      <button @click="deleteNo()">No</button>
                    </div>
                  </div>
                </tr>
              </template>
              <template v-slot:noDataFound>
                <tr>
                  <td colspan="7">No results found</td>
                </tr>
              </template>
            </dataset-item>
          </table>
        </div>
        <div class="table-pagination">
          <div class="table-pagination-info-wrapper">
            <dataset-info />
          </div>
          <div class="table-pagination-pager-wrapper">
            <dataset-pager />
          </div>
        </div>
      </dataset>
    </div>
    <SecondModalBox v-if="stocks.vendorModalBox" :alias="'newVendor'">
      <template #modalTitle>New Vendor</template>
      <template #modalBody>
        <VendorForm ref="vendorForm" />
      </template>
      <template #modalFooter>
        <VendorFormButton />
      </template>
    </SecondModalBox>
    <ModalBox :alias="'newStock'">
      <template #modalTitle>New Stock</template>
      <template #modalBody>
        <StockForm ref="stockForm" />
      </template>
      <template #modalFooter>
        <StockFormButtons />
      </template>
    </ModalBox>
    <ModalBox :alias="'editStock'">
      <template #modalTitle>Edit Stock</template>
      <template #modalBody>
        <StockForm ref="stockForm" />
      </template>
      <template #modalFooter>
        <StockFormButtons />
      </template>
    </ModalBox>
  </div>
</template>
  
<script>
import { mapState } from 'vuex';
import store from '@/store';
import ModalBox from '@/widgets/ModalBox';
import SecondModalBox from '@/widgets/ModalBox/SecondModalBox';
import VendorForm from '@/views/Vendors/VendorForm';
import VendorFormButton from '@/views/Vendors/VendorFormButtons';
import StockForm from './StockForm.vue';
import StockFormButtons from './StockFormButtons.vue';

import {
  Dataset,
  DatasetItem,
  DatasetInfo,
  DatasetPager,
  DatasetSearch,
  DatasetShow
} from "vue-dataset";

export default {
  name: "Stocks",
  data() {
    return {
      cols: [
        {
          name: "SKU",
          field: "stock_sku",
          sort: ""
        },
        {
          name: "Barcode",
          field: "stock_barcode",
          sort: ""
        },
        {
          name: "Name",
          field: "stock_name",
          sort: ""
        },
        {
          name: "Stock Amount",
          field: "stock_amount",
          sort: ""
        },
        {
          name: "Unit Name",
          field: "unit_name",
          sort: ""
        },
        {
          name: "Unit Price",
          field: "stock_unit_price",
          sort: ""
        },
        {
          name: "GST",
          field: "stock_tax1",
          sort: ""
        },
        {
          name: "PST",
          field: "stock_tax2",
          sort: ""
        }
      ],
      // type: 0
      brandHash: "",
      storeHash: "",
      isDeletePanelActive: false,
      deleteStockHash: "",
    };
  },
  created() {
    this.brandHash = this.stores.current.brandHash;
    this.storeHash = this.stores.current.storeHash;
    store.dispatch("getStockList", {
      brandHash: this.brandHash,
      storeHash: this.storeHash
    });
  },
  components: {
    ModalBox,
    StockForm,
    StockFormButtons,
    SecondModalBox,
    Dataset,
    DatasetItem,
    DatasetInfo,
    DatasetPager,
    DatasetSearch,
    DatasetShow,
    VendorForm,
    VendorFormButton,
  },
  computed: {
    ...mapState(["stocks", "stores"]),
    sortBy() {
      return this.cols.reduce((acc, o) => {
        if (o.sort) {
          o.sort === "asc" ? acc.push(o.field) : acc.push("-" + o.field);
        }
        return acc;
      }, []);
    }
  },
  methods: {
    numberParse(val) {
      return parseFloat(val);
    }, 
    clickSort(event, i) {
      let toset;
      const sortEl = this.cols[i];
      if (!event.shiftKey) {
        this.cols.map(o => {
          if (o.field !== sortEl.field) {
            o.sort = "";
          }
        });
      }
      if (!sortEl.sort) {
        toset = "asc";
      }
      if (sortEl.sort === "desc") {
        toset = event.shiftKey ? "" : "asc";
      }
      if (sortEl.sort === "asc") {
        toset = "desc";
      }
      sortEl.sort = toset;
    },
    showStockModal(type, alias, stockHash) {
      let modalOpts = {
        isActiveStatus: true,
        type,
        alias,
        hash: stockHash,
      };
      if(stockHash) {
        const stockDetails = this.stocks.vStockList.find(stock => stock.stock_hash === stockHash);
        if(stockDetails) {
          store.commit("GET_STOCK", stockDetails);
        }
        modalOpts["hash"] = stockHash;
        // transaction
        store.dispatch("getTransactionList", {
          stockHash,
          brandHash: this.brandHash,
          storeHash: this.storeHash,
          page: 1,
        });
      }
      store.dispatch(
        "showModalBox",
        Object.assign(store.state.common.modalBox, modalOpts)
      )
    },
    deleteStock(stockHash) {
      this.deleteStockHash = stockHash;
      this.isDeletePanelActive = true;
    },
    deleteYes(stockHash) {
      store.dispatch("deleteStock", {
        stockHash,
        brandHash: this.brandHash,
        storeHash: this.storeHash
      });
      this.deleteNo();
    },
    deleteNo() {
      this.deleteStockHash = "";
      this.isDeletePanelActive = false;
    },
  }
};
</script>

<style lang="css" scoped>
.stock-wrapper {
  display: flex; 
  padding-bottom: 1rem; 
  margin-bottom: 1rem; 
  justify-content: space-between; 
  align-items: center; 
  border-bottom-width: 1px; 
  border-color: #F3F4F6; 
}
.stock-title {
  flex-grow: 1;
}
.item-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.item-actions button {
  font-size: 1.6em;
  line-height: 1.5rem;
  padding: 0 10px;
  background: none;
  border: none;
  outline: none;
  font-weight: 600;
  border-radius: var(--rounded-lg);
  margin: 0 auto;
}
</style>