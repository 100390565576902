<template>
  <div class="option-form">
    <div class="row">
      <div class="column">
        <div class="form-wrapper">
          <div class="form-row" :class="{ error: formValidation.vOptionName.errorMessage }">
            <div class="input-group">
              <label for="txtName">Name</label>
              <input type="text" v-model="stateOptions.option.o_name" autocomplete="nope"
                @focus="dismissError('vOptionName')" />
              <div class="validation-error" v-if="formValidation.vOptionName.errorMessage">
                {{ formValidation.vOptionName.errorMessage }}
              </div>
            </div>
            <div class="input-group">
              <label for="txtName">Description</label>
              <input type="text" v-model="stateOptions.option.o_desc" />
            </div>
            <div class="input-group">
              <label for="txtName">Note</label>
              <textarea type="text" v-model="stateOptions.option.o_note"></textarea>
            </div>
          </div>
        </div>
        <div class="validation-error" v-if="formValidation.vOptionSize.errorMessage">
          {{ formValidation.vOptionSize.errorMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapState } from "vuex";

export default {
  name: "OptionItemsForm",
  created() {
    this.stateOptions.vSize = JSON.parse(
      JSON.stringify(this.stateMenus.sizes)
    );
  },
  computed: {
    ...mapState({
      stateOptions: "options",
      stateMenus: "menus"
    }),
    formValidation() {
      return store.state.options.formValidation;
    },
  },
  methods: {
    dismissError(field) {
      this.formValidation[field].isValid = false;
      this.formValidation[field].errorMessage = "";
    },
    checkForm() {
      this.isValidOptionIngredient();
      let checkingOptionName = this.isValidOptionName();
      let checkingOptionSize = this.isValidOptionSize();
      let checkingOptionIngredient =  this.formValidation.vOptionIngredient.isValid;
      return checkingOptionName
        && checkingOptionSize
        && checkingOptionIngredient
        ? true : false;

    },
    isValidOptionName() {
      if (this.stateOptions.option.o_name === "") {
        this.formValidation.vOptionName.isValid = false;
        this.formValidation.vOptionName.errorMessage = "Name is required";
        return false;
      }
      this.formValidation.vOptionName.isValid = true;
      this.formValidation.vOptionName.errorMessage = "";
      return true;
    },
    isValidOptionSize() {
      if (this.stateOptions.option.o_prices.length == 0) {
        this.formValidation.vOptionSize.isValid = false;
        this.formValidation.vOptionSize.errorMessage = "Size is required";
        return false;
      } else {
        this.formValidation.vOptionSize.isValid = true;
        this.formValidation.vOptionSize.errorMessage = "";
        return true;
      }
    },
    isValidOptionIngredient() {
      this.$parent.$parent.$refs.sizeGroup.map((sizeGroup) => {
        if(sizeGroup.isActiveStatus) {
          sizeGroup.recipe.ingredient_groups.map((group) => {
            if(group.group_ingredients==[]) { 
              this.formValidation.vOptionIngredient.isValid = false;
              sizeGroup.errorMessage = `Ingredient is required in Group Name ${group.group_name}`;
              return false;
            } else {
              group.group_ingredients.map((ingredient, idx) => {
                if (ingredient.ingredient_name == "") {
                  this.formValidation.vOptionIngredient.isValid = false;
                  sizeGroup.errorMessage = `Ingredient ${idx + 1} is not registered correctly in Group Name ${group.group_name}`;
                  return false;
                }
              });
            }
          });
        }
      });
    },
  }
};
</script>

<style lang="css" scoped>
.form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 10px;
}
.input-group {
  margin-bottom: 10px;
}
</style>