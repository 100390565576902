<template>
  <div class="form-button-actions">
    <button class="btn-secondary" @click="cancel()">Cancel</button>
    <button class="btn-primary" @click="updateMenu()" v-if="getFormType == 1">Update</button>
    <button class="btn-primary" @click="saveMenu()" v-else>Save</button>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import store from "@/store";

export default {
  name: "MenusFormButtons",
  data() {
    return {
      storeHash: "",
    };
  },
  created() {
    this.storeHash = this.stateStores.current.storeHash;
  },
  computed: {
    ...mapState({
      stateStores: "stores",
      stateMenus: "menus",
      stateCommon: "common"
    }),
    ...mapGetters(["getFormType"]),
    stateItem() {
      return this.stateMenus.current;
    }
  },
  methods: {
    cancel() {
      store.dispatch("closeMenuModalBox");
    },
    updateMenu() {
      if (this.$parent.$parent.$refs.menuForm.checkForm()) {
        store.dispatch("updateMenu", {
          storeHash: this.storeHash,
          menuItemHash: this.stateItem.mhash
        });
      }
    },
    saveMenu() {
      if (this.$parent.$parent.$refs.menuForm.checkForm()) {
        store.dispatch("saveMenu", this.storeHash);
      }
    }
  }
};
</script>