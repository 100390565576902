<template>
  <div class="form-button-actions">
    <button class="btn-secondary" @click="cancel()">Cancel</button>
    <button class="btn-primary" @click="updateOptionItem()" v-if="getFormType == 1">Update</button>
    <button class="btn-primary" @click="saveOptionItem()" v-else>Save</button>
  </div>
</template>

<script>
import store from "@/store";
import { mapState, mapGetters } from "vuex";

export default {
  name: "OptionItemsFormButtons",
  data() {
    return {
      storeHash: "",
    }
  },
  created() {
    this.storeHash = this.stateStores.current.storeHash;
  },
  computed: {
    ...mapState({
      stateCommon: "common",
      stateOptions: "options",
      stateStores: "stores"
    }),
    ...mapGetters(["getFormType"]),
    formValidation() {
      return store.state.options.formValidation;
    }
  },
  methods: {
    cancel() {
      // Reset original size data
      this.$parent.$parent.$refs.sizeGroup.map((sizeGroup) => {
        if(sizeGroup.isActiveStatus) {
          sizeGroup.resetSizeData();
        }
      });
      store.dispatch("closeOptionModalBox");
    },
    updateOptionItem() {
      let optionItemHash = this.stateOptions.option.o_hash;
      if (this.$parent.$parent.$refs.optionItemForm.checkForm()) {
        store.dispatch("updateOptionsItem", {
          optionItemHash,
          storeHash: this.storeHash,
        });
      } else {
        return false;
      }
    },
    saveOptionItem() {
      if (this.$parent.$parent.$refs.optionItemForm.checkForm()) {
        store.dispatch("saveOptionsItem", this.storeHash);
      } else {
        return false;
      }
    }
  }
};
</script>