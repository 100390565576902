const initialState = () => {
  return {
    isAuthenticated: false,
    current: {
      user: {
        name: "",
        status: false,
        token: "",
        uhash: "",
        utype: "",
        recipes_access: 0,
        invoices_access: 0,
        vendors_access: 0,
        stocks_access: 0,
      },
    },
  };
};

const storeUser = {
  namespace: true,
  state: initialState(),
  mutations: {
    LOGIN(state, payload) {
      state = Object.assign(state.current.user, payload);
    },
    LOGOUT(state) {
      let reset = initialState();
      let currentUser = state.current.user;
      state = Object.assign(currentUser, reset.current.user);
    },
  },
  actions: {
    logoutService({ commit }) {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('uhash');
      localStorage.removeItem('utype');
      localStorage.removeItem('expDate');
      localStorage.removeItem('recipes_access');
      localStorage.removeItem('invoices_access');
      localStorage.removeItem('vendors_access');
      localStorage.removeItem('stocks_access');
      commit("LOGOUT");
    },
  },
  getters: {
    getUser: (state) => {
      return state.current.user;
    },
  },
};

export default storeUser;
