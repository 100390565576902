<template>
  <div class="opacity-wrapper modalbox-wrapper" v-if="alias === stateCommon.modalBox.alias && getModalState">
    <div class="modalbox">
      <div class="modalbox-content">
        <div class="modalbox-title">
            <slot name="modalTitle"></slot>
        </div>
        <div class="modalbox-body">
          <slot name="modalBody"></slot>
        </div>
        <div class="modalbox-footer">
          <slot name="modalFooter"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "ModalBox",
  props: {
    alias: {
      type: String,
      required: true
    },
    head: {
      type: Boolean,
      default: true
    },
    body: {
      type: Boolean,
      default: true
    },
    foot: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState({ stateCommon: "common" }),
    ...mapGetters(["getModalState", "getSecondModalState"])
  },
};
</script>

<style lang="css" scoped>
.modalbox-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* z-index: 50; */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.modalbox {
  overflow: hidden;
  margin: 2rem;
  border-radius: 0.5rem;
  border-width: 1px;
  border-color: #e5e7eb;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  min-width: 640px;
  max-width: 100%;
}
.modalbox-content {
  min-width: 30rem;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  position: relative;
  max-height: 90vh;
}
.modalbox-title {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0;
  background-color: #f9fafb;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
  border-bottom-width: 1px;
  border-color: #e5e7eb;
  border-style: solid;
  line-height: 3.5rem;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  height: 50px;
  z-index: 20;
  height: 100%;
}
.modalbox-body {
  overflow-y: scroll;
  padding: 2em;
  height: calc(80vh - 200px);
}
.modalbox-body .input-group:last-child {
  margin-bottom: 0;
}
.modalbox-body .form-col:last-child {
  margin-right: 0;
}
.modalbox-footer {
	padding: 1rem;
  background-color: #f9fafb;
  border-top-width: 1px;
  border-color: #e5e7eb;
  border-style: solid;
  /* position: absolute; */
  position: sticky;
  right: 0;
  bottom: 0;
  height: 50px;
  left: 0;
  z-index: 20;
  height: 100%;
}
#menu .modalbox-body,
#stock .modalbox-body,
#vendor .modalbox-body {
  height: 100%!important;
}
</style>