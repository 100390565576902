<template>
  <div id="report">
    <div class="report-header">
      <h2 class="flex-grow">Added Reports</h2>
    </div>
    <div class="table-list-data">
      <dataset v-slot="{ ds }" :ds-data="stateReport.addedReportList" :ds-filter-fields="{}" :ds-sortby="sortBy"
        :ds-sort-as="{ stock_name: numberParse, stock_cost: numberParse, stock_amount: numberParse, stock_unit: numberParse }"
        >
        <div :data-page-count="ds.dsPagecount">
          <div class="actions-menu">
            <div class="table-search-wrapper">
              <div class="search-box">
                <label>Added Start Date </label>
                <input 
                  type="date" 
                  v-model="stateReport.addedStartDate"
                  :max="stateReport.addedEndDate"
                />
              </div>
              <div class="search-box">
                <label>Added End Date </label>
                <input 
                  type="date" 
                  v-model="stateReport.addedEndDate"
                  :min="stateReport.addedStartDate"
                />
              </div>
            </div>
            <div class="table-show-wrapper">
              <dataset-show />
            </div>
          </div>
          <div class="table-list">
            <table>
              <thead>
                <tr>
                  <th v-for="(th, index) in cols" :key="th.field" :class="['sort', th.sort]"
                    @click="clickSort($event, index)">
                    {{ th.name }}
                    <i class="gg-select"></i>
                  </th>
                </tr>
              </thead>
              <dataset-item tag="tbody">
                <template v-slot="{ row, rowIndex }">
                  <tr :key="rowIndex">
                    <td width="20%">{{ row.name }}</td>
                    <td width="20%">${{ row.cost }}</td>
                    <td width="20%">{{ row.amount }}</td>
                    <td width="20%">{{ row.unit_name }}</td>
                  </tr>
                </template>
                <template v-slot:noDataFound>
                  <tr>
                    <td colspan="8">No results found</td>
                  </tr>
                </template>
              </dataset-item>
            </table>
          </div>
          <div class="table-pagination">
            <div class="table-pagination-info-wrapper">
              <dataset-info />
            </div>
            <div class="sum">
              <span>Sum: {{ totalAddedCost }}</span>
            </div>
            <div class="table-pagination-pager-wrapper">
              <dataset-pager />
            </div>
          </div>
        </div>
      </dataset>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import store from '@/store';

import {
    Dataset,
    DatasetItem,
    DatasetInfo,
    DatasetPager,
    DatasetShow,
} from "vue-dataset";

export default {
  name: "StockAddedReports",
  data() {
    return {
      vInvoiceList: [],
      cols: [
        {
          name: "Stock Name",
          field: "name",
          sort: "name"
        },
        {
          name: "Cost",
          field: "cost",
          sort: "cost",
        },
        {
          name: "Amount",
          field: "amount",
          sort: "amount",
        },
        {
          name: "Unit",
          field: "unit_name",
          sort: "unit_name",
        },
      ],
      addedStartDate: '',
      addedEndDate: '',
      totalAddedCost: 0,
      brandHash: "",
      storeHash: "",
    };
  },
  components: {
    Dataset,
    DatasetItem,
    DatasetInfo,
    DatasetPager,
    // DatasetSearch,
    DatasetShow,
  },
  watch: {
    getAddedStartDate() {
      this.getReportList();
      this.calculateTotalCost();
    },
    getAddedEndDate() {
      this.getReportList();
      this.calculateTotalCost();
    },
  },
  computed: {
    ...mapState({stateReport: "reports", stateStores: "stores" }),
    ...mapGetters(["getAddedStartDate", "getAddedEndDate"]),
    sortBy() {
      return this.cols.reduce((acc, o) => {
        if (o.sort) {
          o.sort === "asc" ? acc.push(o.field) : acc.push("-" + o.field);
        }
        return acc;
      }, []);
    },
  },
  created() {
    this.brandHash = this.stateStores.current.brandHash;
    this.storeHash = this.stateStores.current.storeHash;
    this.getReportList();
  },
  updated() {
    this.calculateTotalCost();
  },
  methods: {
    numberParse(val) {
      return parseFloat(val);
    },
    dateParse(val) {
      return new Date(val).getTime();
    },
    clickSort(event, i) {
      let toset;
      const sortEl = this.cols[i];
      if (!event.shiftKey) {
        this.cols.map(o => {
          if (o.field !== sortEl.field) {
            o.sort = "";
          }
        });
      }
      if (!sortEl.sort) {
        toset = "asc";
      }
      if (sortEl.sort === "desc") {
        toset = event.shiftKey ? "" : "asc";
      }
      if (sortEl.sort === "asc") {
        toset = "desc";
      }
      sortEl.sort = toset;
    },
    calculateTotalCost() {
      let totalAdded = 0;
      this.stateReport.addedReportList.map((item) => {
        totalAdded += parseFloat(item.cost) || 0;
      });
      this.totalAddedCost = totalAdded.toFixed(2);
    },
    getReportList() {
      store.dispatch("getReportList", {
        addedStartDate: this.stateReport.addedStartDate,
        addedEndDate: this.stateReport.addedEndDate,
        brandHash: this.brandHash,
        storeHash: this.storeHash,
      })
    },
  }
};
</script>

<style scoped>
#report .table-list-data {
  /* display: flex; */
  padding-bottom: 0;
}
#report .report-header {
  display: flex;  
  padding-bottom: 1rem;
  justify-content: space-between; 
  align-items: center; 
  border-bottom-width: 1px; 
  border-color: #F3F4F6; 
}
#report .flex-grow {
  flex-grow: 1; 
}

#report .actions-menu {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: baseline;
  padding-top: 1em;
}
</style>