const getCouponsDefaultState = () => {
  return {
    status: true,
    coupons: "",
    dataCagir: false,
    newScreenStatus: true,
    current: {
      c_type: 0,
      p_type: 0,
      s_type: 0,
      c_name: "",
      c_desc: "",
      c_phash: [],
      c_discount: 0,
      c_adminnotes: "",
      c_date_start: "",
      c_date_end: "",
      c_dates: [],
      c_stores: [],
      c_codes: [],
      c_code: "",
      c_use_type: 0,
      c_min_price: 0.0,
      l_type_online: 0,
      l_type_pos: 0,
      l_type_kiosk: 0,
      l_type_mobil: 0,
    },
    formValidation: {
      vCouponName: { isValid: false, errorMessage: "" },
      vCouponDiscount: { isValid: false, errorMessage: "" },
      vCouponPlatform: { isValid: false, errorMessage: "" },
      vCouponDate: { isValid: false, errorMessage: "" },
      vCouponStore: { isValid: false, errorMessage: "" },
      vCouponCode: { isValid: false, errorMessage: "" },
    }
  };
};

const coupons = {
  state: getCouponsDefaultState(),
  mutations: {
    RESET_FORM_COUPONS(state) {
      Object.assign(state.current, getCouponsDefaultState().current);
      Object.assign(state.formValidation, getCouponsDefaultState().formValidation);
    },
    GET_COUPON(state, payload) {
      state.current = {
        c_type: payload.detail.c_type,
        p_type: payload.detail.p_type,
        s_type: payload.detail.s_type,
        c_name: payload.detail.name,
        c_desc: payload.detail.message,
        c_phash: payload.detail.product_name,
        c_discount: payload.detail.discount,
        c_adminnotes: payload.detail.admin_notes,
        c_date_start: payload.dates[0].date_start,
        c_date_end: payload.dates[0].date_end,
        c_dates: payload.dates,
        c_stores: payload.stores,
        c_codes: [],
        c_code: '',
        c_use_type: payload.detail.c_type,
        c_min_price: payload.detail.min_price,
        l_type_online: payload.detail.l_type_online,
        l_type_pos: payload.detail.l_type_pos,
        l_type_kiosk: payload.detail.l_type_kiosk,
        l_type_mobil: payload.detail.l_type_mobil,
      }
    },
    GET_COUPONS(state, payload) {
      Object.assign(state, payload);
    },
    DELETE_COUPON(state, hash) {
      state.coupons.map((item, idx) => {
        if (item.detail.chash == hash) {
          // delete this coupons
          state.coupons.splice(idx, 1)
        }
      });
    },
  },
  actions: {
    closeCouponModalBox({ commit }) {
      commit("RESET_FORM_COUPONS");
      commit("RESET_MODALBOX");
    },
    async getCoupons({ commit }) {
      commit("LOADING", { isActiveStatus: true });
      try {
        let { data } = await window.$http.axios.get("coupons/getallitems");
        commit("GET_COUPONS", data);
      } catch (error) {
        console.error(error);
        return;
      }
      commit("LOADING", { isActiveStatus: false });
    },
    async saveCoupon({ state, commit, dispatch }) {
      commit("LOADING", { isActiveStatus: true });
      const params = new URLSearchParams();
      params.append("c_type", state.current.c_type);
      params.append("p_type", state.current.p_type);
      params.append("s_type", state.current.s_type);
      params.append("c_name", state.current.c_name);
      params.append("c_desc", state.current.c_desc);
      params.append("c_phash", JSON.stringify(state.current.c_phash));
      params.append("c_discount", state.current.c_discount);
      params.append("c_adminnotes", state.current.c_adminnotes);
      params.append("c_min_price", state.current.c_min_price);
      params.append("c_dates", JSON.stringify(state.current.c_dates));
      params.append("c_stores", JSON.stringify(state.current.c_stores));
      params.append("c_codes", JSON.stringify(state.current.c_codes));
      params.append("l_type_online", JSON.stringify(state.current.l_type_online));
      params.append("l_type_pos", JSON.stringify(state.current.l_type_pos));
      params.append("l_type_kiosk", JSON.stringify(state.current.l_type_kiosk));
      params.append("l_type_mobil", JSON.stringify(state.current.l_type_mobil));
      const config = {
        method: 'post',
        url: 'coupons/addcoupon',
        data: params
      }
      try {
        let { data } = await window.$http.axios(config);
        if (data.status) {
          state.coupons.unshift(data.coupon);
          commit("NOTIFICATION_MESSAGE", { status: 1, title: "Saved", message: "Coupon has been saved successfully." });
        dispatch("closeCouponModalBox");
        } else {
          commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: data.message });
        }
      } catch (error) {
        commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: error.message });
      }
      commit("LOADING", { isActiveStatus: false });
    },
    async updateCoupon({ state, commit, dispatch }) {
      commit("LOADING", { isActiveStatus: true });
      const params = new URLSearchParams();
      params.append("c_type", state.current.c_type);
      params.append("p_type", state.current.p_type);
      params.append("s_type", state.current.s_type);
      params.append("c_name", state.current.c_name);
      params.append("c_desc", state.current.c_desc);
      params.append("c_phash", JSON.stringify(state.current.c_phash));
      params.append("c_discount", state.current.c_discount);
      params.append("c_adminnotes", state.current.c_adminnotes);
      params.append("c_min_price", state.current.c_min_price);
      params.append("c_dates", JSON.stringify(state.current.c_dates));
      params.append("c_stores", JSON.stringify(state.current.c_stores));
      params.append("c_codes", JSON.stringify(state.current.c_codes));
      params.append("l_type_online", JSON.stringify(state.current.l_type_online));
      params.append("l_type_pos", JSON.stringify(state.current.l_type_pos));
      params.append("l_type_kiosk", JSON.stringify(state.current.l_type_kiosk));
      params.append("l_type_mobil", JSON.stringify(state.current.l_type_mobil));
      const config = {
        method: 'post',
        url: 'coupons/updatecoupon',
        data: params
      }
      try {
        let { data } = await window.$http.axios(config);
        if (data.status) {
          state.coupons.unshift(data.coupon);
          commit("NOTIFICATION_MESSAGE", { status: 1, title: "Updated", message: "Coupon has been updated successfully." });
        dispatch("closeCouponModalBox");
        } else {
          commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: data.message });
        }
      } catch (error) {
        console.error(error);
        commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: error.message }); 
      };
    },
    async deleteCoupon({ commit }, chash) {
      commit("LOADING", { isActiveStatus: true });
      try {
        await window.$http.axios.get(`coupons/deleteitem?c=${chash}`);
        commit("DELETE_COUPON", chash);
        commit("NOTIFICATION_MESSAGE", { status: 1, title: "Deleted", message: "Coupon has been deleted successfully!" });
      } catch (error) {
        commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: error.message });
      }
      commit("LOADING", { isActiveStatus: false });
    }
  },
  getters: {
    getCouponState: (state) => {
      return state.status;
    },
  },
};

export default coupons;