<template>
  <form class="menuboard-form" action="#">
    <div class="left">
      <div class="form-row">
        <div class="form-col">
          <div class="input-group">
            <label for="txtMenuboardTitle">Menuboard Title</label>
            <input id="txtMenuboardTitle" name="txtMenuboardTitle" type="text" v-model="stateMenuboard.m_name"
              @focus="dismissError('m_name')">
            <div class="validation-error" v-if="formValidation.m_name.errorMessage">
              {{ formValidation.m_name.errorMessage }}
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="input-group">
          <label for="txtMenuboardDescription">Description</label>
          <textarea id="txtMenuboardDescription" name="txtMenuboardDescription" cols="30" rows="10"
            v-model="stateMenuboard.m_desc" @focus="dismissError('m_desc')"></textarea>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="imagearea" v-bind:class="{ picture_error: stateMenuboard.pictureControl == 0 }">
        <croppa @image-remove="onRemoveImage" @new-image-drawn="onLoadImage" v-model="stateMenuboard.myCroppa"
          :height="200" :input-attrs="{ class: 'croppainput' }" :placeholder-font-size="16" :prevent-white-space="true"
          :width="200" :show-loading="true" canvas-color="#edf4f4" remove-button-color="black"></croppa>
        <span class="pe-message" v-if="stateMenuboard.pictureControl == 0">Image is required.</span>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'MenuboardForm',
  computed: {
    ...mapState({
      stateMenuboard: "menuboards",
      formValidation: state => state.menuboards.formValidation
    })
  },
  methods: {
    onLoadImage() {
      this.stateItem.pictureControl = 1;
    },
    onRemoveImage() {
      this.stateItem.pictureControl = 0;
    },
    dismissError(field) {
      this.stateMenuboard.formValidation[field].isValid = false;
      this.stateMenuboard.formValidation[field].errorMessage = "";
    },
    checkForm() {
      if (this.isEmpty(this.stateMenuboard.m_name)) {
        this.formValidation.m_name.isValid = false;
        this.formValidation.m_name.errorMessage = "Menuboard name cannnot be empty!.";
        return false;
      }
      this.formValidation.m_name.isValid = true;
      this.formValidation._name.errorMessage = "";
      return true;
    }
  }
}
</script>

<style>
.menuboard-form {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  align-items: center;
}
</style>