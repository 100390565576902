import { render, staticRenderFns } from "./ProductCalculation.vue?vue&type=template&id=2a16bafd&scoped=true"
import script from "./ProductCalculation.vue?vue&type=script&lang=js"
export * from "./ProductCalculation.vue?vue&type=script&lang=js"
import style0 from "./ProductCalculation.vue?vue&type=style&index=0&id=2a16bafd&prod&css=true&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a16bafd",
  null
  
)

export default component.exports