<template>
  <div class="sidebar-height">
    <ul class="sidebar-content">
      <li
        v-for="menuItem, index in filteredMenu"
        class="nav-link"
        :key=index
        :class="{ 'active': index == selectedIndex ||  index == activeIndex}"
        @mouseover="changeBgColor(index, menuItem.name)"
        @mouseout="leaveBgColor()"
        @mouseleave="leaveBgColor()"
        @click="changeMenu(index, menuItem.name)"
        
      >
        <i class="fontello-icon"><span v-html="menuItem.icon"></span></i>
        <div class="nav" :class="menuItem.name">
          {{ menuItem.title }}
        </div>
        <div class="sub-menu" v-if="menuItem.subMenu">
          <ul>
            <li 
              class="nav-link"
              v-for="subMenuItem, idx in menuItem.subMenu"
              :key="idx"
              @click.stop="changeSubMenu(index, subMenuItem.name, menuItem.subMenuType)"
            >
              <i class="fontello-icon"><span v-html="subMenuItem.icon"></span></i>
              <span>{{ subMenuItem.title }}</span>
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <div class="flex">
      <div class="nav-link logout" @click="logout()">
        <i class="flaticon-logout"></i>
        <span>Logout</span>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapState } from "vuex";

export default {
  name: "DashboardMenu",
  data() {
    return {
      selectedIndex: 0,
      activeIndex: 0,
      mainMenu: [
        {
          name: "menus",
          title: 'MENUS',
          icon: "&#xe800;",
          status: true,
        },
        {
          name: "products",
          title: "PRODUCTS",
          icon: "&#xf0f5;",
          status: true,
        },
        {
          name: "option-items",
          title: "OPTION ITEMS",
          icon: "&#xf217;",
          status: true,
        },
        {
          name: "stocks",
          title: "STOCKS",
          icon: "&#xf0ca;",
          status: false,
          subMenuType: "stock",
          subMenu: [
            {
              name: "stocks",
              title: "STOCKS",
              icon: "&#xf0ca;",
            },
            {
              name: "stock-audits",
              title: "AUDITS",
              icon: "&#xf50d;",
            },
            {
              name: "stock-reducted-reports",
              title: "REDUCTED REPORTS",
              icon: "&#xf15b;",
            },
            {
              name: "stock-added-reports",
              title: "ADDED REPORTS",
              icon: "&#xf15b;",
            },
          ],
        },
        {
          name: "vendors",
          title: "VENDORS",
          icon: "&#xe804;",
          status: false,
        },
        {
          name: "invoices",
          title: "INVOICES",
          icon: "&#xe805;",
          status: false,
        },
        {
          name: "coupons",
          title: "COUPONS",
          icon: "&#xe801;",
          status: true,
        },
        {
          name: "branch-settings",
          title: "BRANCH SETTINGS",
          icon: "&#xe806;",
          status: true,
          subMenuType: "branch-settings",
          subMenu: [
            {
              name: "working-hours",
              title: "WORKING HOURS",
              icon: "&#xe810;",
            },
            {
              name: "delivery-map",
              title: "DELIVERY MAP",
              icon: "&#xe811;",
            },
            // {
            //   name: "customer-screen",
            //   title: "CUSTOMER SCREEN",
            //   icon: "&#xf108;",
            // },
            // {
            //   name: "menuboard",
            //   title: "MENUBOARD",
            //   icon: "&#xf108;",
            // },
            {
              name: "pos-users",
              title: "POS USERS",
              icon: "&#xe802;",
            },
          ],
        },
      ],
    };
  },
  created() {
    // check if the user has access to stock
    const userAccess = this.stateAuth.current.user;
    ["stocks_access", "vendors_access", "invoices_access"].forEach(accessType => {
      const menuName = accessType.split('_')[0];
      // console.log(menuName);
      if (userAccess[accessType] == 1) {
        this.mainMenu.find(menu => menu.name === menuName).status = true;
      }
    });
  },
  computed: {
    ...mapState({
      stateCommon: "common",
      stateAuth: "auth",
    }),
    filteredMenu() {
      return this.mainMenu.filter(menuItem => menuItem.status);
    }
  },
  methods: {
    changeBgColor(i) {
      this.selectedIndex = i;
    },
    leaveBgColor() {
      if (this.activeIndex == this.selectedIndex) {
        return;
      } 
      this.selectedIndex = this.activeIndex;
    },
    changeMenu(i, menulink){
      if(menulink == "branch-settings") {
        store.commit("LOADING", { isActiveStatus: true });
        setTimeout(() => {
          store.commit("LOADING", { isActiveStatus: false });
        }, 500);
      }
      this.activeIndex = i;
      // this.stateCommon.location = menulink;
      store.commit("CHAANGE_LOCATION", menulink);
    },
    changeSubMenu(i, submenulink, menuType) {
      if(menuType == "branch-settings") {
        store.commit("LOADING", { isActiveStatus: true });
        setTimeout(() => {
          store.commit("LOADING", { isActiveStatus: false });
        }, 500);
      }
      this.activeIndex = this.selectedIndex;
      this.activeIndex = i;
      // this.stateCommon.location = submenulink;
      store.commit("CHAANGE_LOCATION", submenulink)
    },
    logout() {
      this.$store.dispatch("logoutService");
      this.stateCommon.location = "menus";
      this.stateAuth.isAuthenticated = false;
    },
  }
};
</script>

<style lang="css" scoped>
.nav-link {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  color: #222;
  text-decoration: none;
  font-size: 1.2em;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  position: relative;
}
.nav-link i {
  font-size: 1.5em;
}
.nav-link div,
.nav-link span {
  font-size: 0.8em;
}
.active {
  color: #fff;
  background-color: #2c3e50;
}
.sub-menu {
  display: none;
}
.sub-menu ul {
  margin-left: -25px;
}
.sub-menu ul li {
  padding: 0.5rem 1rem;
  color: #222;
  font-size: 1.2em;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.nav-link:hover .sub-menu {
  display: block;
  position: absolute;
  background-color: #2c3e50;
  color: #fff;
  width: 100%;
  z-index: 1;
  right: -100%;
    top: 0;
}
.sub-menu .nav-link {
  color: #fff;
}
.sidebar-height {
  display: flex; 
  flex-direction: column; 
  justify-content: space-between;
  height: 85%;
}
.sidebar-content {
  padding: 0;
  margin: 0;
  list-style: none;
}
.branch-settings {
  display: flex; 
  justify-content: space-between; 
  align-items: center;
}
.stock-settings {
  display: flex; 
  justify-content: space-between; 
  align-items: center;
}
.flaticon-arrow-point-to-right {
  margin-top: 0.25rem; 
  font-size: 1.25rem;
  line-height: 1.75rem; 
  vertical-align: middle; 
}
.rotate-90 {
  transform: rotate(90deg);
}
.flex {
  display: flex; 
  justify-content: space-between;
  padding: 1rem;
  /* margin: 0 auto; */
}
.logout {
  /* margin-bottom: 1.25rem; */
  font-size: 1.25rem;
  line-height: 1.75rem; 
  cursor: pointer; 
}
.reload {
  font-size: 1.25rem;
  line-height: 1.75rem; 
  cursor: pointer; 
}
.flaticon-logout {
  vertical-align: middle;
}
</style>
