<template>
  <div id="app">
    <Loader :isActiveStatus="stateCommon.loader.isActiveStatus"></Loader>
    <Login v-if="!stateAuth.isAuthenticated"></Login>
    <Dashboard v-else></Dashboard>
  </div>
</template>

<script>
import Login from "@/components/Login/Login.vue";
import Dashboard from "@/views/Dashboard.vue";
import Loader from "@/widgets/Loader/index.vue";
import store from "@/store";
import { mapState } from "vuex";

export default {
  name: "App",
  components: {
    Login,
    Dashboard,
    Loader
  },
  computed: {
    ...mapState({
      stateAuth: "auth",
      stateCommon: "common"
    })
  },
  created() {
    this.appConfig(); 
  },
  methods: {
    appConfig() {
      const token = localStorage.getItem("token");
      this.stateAuth.isAuthenticated = !!token;
      if (token) {
        const user = localStorage.getItem("user");
        store.commit("LOGIN", { name: user, status: true, token });
      } else {
        store.dispatch("logoutService");
      }
    },
  }
};
</script>