<template>
  <div
    class="coupon-group-element"
  >
    <div class="mb-2">
      <div class="cel-title">
        <span class="cel-name">{{pelement.p_name}}</span>
        <button @click.prevent="$emit('click-child', pelement.phash)">
          <i class="flaticon-close"></i>
        </button>
      </div>
      <div v-if="pelement.p_desc" class="cel-desc">({{pelement.p_desc}})</div>
    </div>
    <div class="mini-checkbox-wrapper">
      <label>Default
        <ToggleCheckbox
          @clickToggle="setDefault()" 
          :onOff="pelement.selected == 1"></ToggleCheckbox>
      </label>
    </div>
  </div>
</template>

<script>
import ToggleCheckbox from "@/widgets/ToggleCheckbox/index.vue";
export default {
  name: "CouponGroupElement",
  components: {
    ToggleCheckbox
  },
  props: ["pelement"],
  methods: {
    setDefault() {
      this.pelement.selected = this.pelement.selected === 1 ? 0 : 1;
    }
  }
};
</script>

<style lang="css" scoped>
.coupon-group-element {
  display: inline-block; 
  padding: 0.5rem; 
  margin-right: 0.5rem; 
  margin-bottom: 0.5rem; 
  background-color: #FEF2F2; 
  font-size: 0.875rem;
  line-height: 1.25rem; 
  font-weight: 300; 
  border-radius: 0.25rem; 
  border-width: 1px; 
  border-color: #FEE2E2; 
  border-style: solid; 
}
.cel-title {
  display: flex; 
  flex-direction: row; 
  justify-content: space-between; 
}
.cel-name {
  color: #EF4444; 
  font-weight: 600; 
}
.cel-title button i {
  color: #EF4444; 
}
.cel-desc {
  font-size: 0.75rem;
  line-height: 1rem; 
}
.mini-checkbox-wrapper {
  margin-right: 0.5rem;
}
.mini-checkbox-wrapper label {
  font-size: 0.75rem;
  line-height: 1rem; 
  line-height: 1; 
  display: flex;
  align-items: center;
  gap: 1rem; 
}
label {
  font-size: 0.75rem;
  line-height: 1rem; 
  line-height: 1; 
}
/* custom */
.mb-2 {
  margin-bottom: 0.5rem;
}
button {
  cursor: pointer;
  font-size: 1.6em;
  line-height: 1.5rem;
  padding: 0 10px;
  background: none;
  border: none;
  outline: none;
  font-weight: 600;
  border-radius: var(--rounded-lg);
}
</style>