<template>
    <div class="invoice-form">
      <div class="layout">
        <div class="layout-column">
          <div 
            class="form-row"
            :class="{ error: formValidation.vInvoiceDate.errorMessage }"
          >
            <div class="input-group">
              <label for="invoiceDate">Invoice Date</label>
              <div class="text-group">
                <input
                  id="invoiceDate"
                  name="invoiceDate"
                  type="tel"
                  size="23"
                  placeholder="YYYY-MM-DD"
                  v-mask="'####-##-##'"
                  v-model="stateInvoice.vInvoiceDate"
                  autocomplete="nope"
                  @focus="dismissError('vInvoiceDate')"
                />
                <div
                  class="validation-error"
                  v-if="formValidation.vInvoiceDate.errorMessage"
                >
                  {{ formValidation.vInvoiceDate.errorMessage }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="layout-column">
          <div 
            class="form-row"
            :class="{ error: formValidation.vInvoiceNumber.errorMessage }"
          >
            <div class="input-group">
              <label for="invoiceNumber">Invoice Number</label>
              <div class="text-group">
                <input
                  id="invoiceNumber"
                  name="invoiceNumber"
                  v-model="stateInvoice.vInvoiceNumber"
                  type="text"
                  autocomplete="nope"
                  @focus="dismissError('vInvoiceNumber')"
                />
                <div
                  class="validation-error"
                  v-if="formValidation.vInvoiceNumber.errorMessage"
                >
                  {{ formValidation.vInvoiceNumber.errorMessage }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="layout">
        <div class="layout-column">
          <div 
            class="form-row"
            :class="{ error: formValidation.vCompany.errorMessage }"
          >
            <div class="input-group">
              <label for="txtcompany">Company</label>
              <div class="text-group">
                <VendorAutoComplete
                  :minChar="3"
                  :tParams="'company'"
                  @clickedSelectItem="getVendor"
                  :text="stateInvoice.vCompany"
                  :hash="stateInvoice.vCompanyId"
                ></VendorAutoComplete>
                <div
                  class="validation-error"
                  v-if="formValidation.vCompany.errorMessage"
                >
                  {{ formValidation.vCompany.errorMessage }}
                </div>
              </div>
            </div>
          </div>
          <div 
            class="form-row"
            :class="{ error: formValidation.vBillingAddress.errorMessage }"
          >
            <div class="input-group">
              <label for="billingAddress">Company Address</label>
              <div class="text-group">
                <textarea
                  id="billingAddress"
                  name="billingAddress"
                  v-model="stateInvoice.vBillingAddress"
                  cols="30"
                  rows="3"
                  autocomplete="nope"
                  @focus="dismissError('vBillingAddress')"
                  disabled="true"
                ></textarea>
                <div
                  class="validation-error"
                  v-if="formValidation.vBillingAddress.errorMessage"
                >
                  {{ formValidation.vBillingAddress.errorMessage }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="layout-column">
          <div 
            class="form-row"
            :class="{ error: formValidation.vEmail.errorMessage }"
          >
            <div class="input-group">
              <label for="email">Company E-mail</label>
              <div class="text-group">
                <input
                  id="text"
                  name="text"
                  type="text"
                  v-model="stateInvoice.vEmail"
                  autocomplete="nope"
                  @focus="dismissError('vEmail')"
                  disabled="true"
                />
                <div
                  class="validation-error"
                  v-if="formValidation.vEmail.errorMessage"
                >
                  {{ formValidation.vEmail.errorMessage }}
                </div>
              </div>
            </div>
          </div>
          <div 
            class="form-row"
            :class="{ error: formValidation.vMessageInvoice.errorMessage }"
          >
            <div class="input-group">
              <label for="messageInvoice">Message on Invoice</label>
              <div class="text-group">
                <textarea
                  id="messageInvoice"
                  name="messageInvoice"
                  v-model="stateInvoice.vMessageInvoice"
                  cols="30"
                  rows="3"
                  autocomplete="nope"
                ></textarea>
                <div
                  class="validation-error"
                  v-if="formValidation.vMessageInvoice.errorMessage"
                >
                  {{ formValidation.vMessageInvoice.errorMessage }}
                </div>
              </div>
            </div>
          </div>
        </div>
          <div class="layout-column">
            <div class="imagearea">
              <croppa
                @image-remove="onRemoveImage"
                @new-image-drawn="onLoadImage"
                v-model="stateInvoice.myCroppa"
                @focus="dismissError('vPicture')"
                :height="200"
                :input-attrs="{class:'croppainput'}"
                :placeholder-font-size="16"
                :prevent-white-space="true"
                :width="200"
                :show-loading="true"
                canvas-color="#edf4f4"
                remove-button-color="black"
              ></croppa>
              <div class="validation-error" v-if="formValidation.vPicture.errorMessage">
                {{ formValidation.vPicture.errorMessage }}
              </div>
            </div>
          </div>
      <div>
      </div>
      </div>
      <div
        class="table-list editable-table-wrapper"
        :class="{
          modeReadOnly: stateInvoice.isReadOnly,
          modeWrite: stateInvoice.isWrite,
        }"
        v-if="isVendorActiveStatus"
      >
        <table>
          <thead>
            <tr>
              <th width="40">#</th>
              <th width="40">Product</th>
              <th width="40">Item Price</th>
              <th width="40">Unit</th>
              <th width="40">Quantity</th>
              <th width="70">GST</th>
              <th width="70">PST</th>
              <th width="100">Total</th>
              <th width="40" class="table-actions-col"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(field, index) in stateTableFields"
              :key="index"
              @click="selectedRowIndex(index)"
            >
              <td>{{ index + 1 }}</td>
              <td>
                <div class="read-only">product content</div>
                <div class="write">
                  <div class="form-group">
                    <StockAutoComplete
                      :minChar="3"
                      :tParams="'stock'"
                      :text="field.vProduct"
                      :hash="field.vStockHash"
                      @clickedSelectItem="getStock"
                      autocomplete="nope"
                      v-model="field.vProduct"
                    ></StockAutoComplete>
                  </div>
                </div>
              </td>
              <td>
                <div class="read-only">item price content</div>
                <div class="write">
                  <div class="input-group-text">
                    <money
                      id="itemPrice"
                      name="itemPrice"
                      type="text"
                      maxlength="5"
                      min="1"
                      default="1"
                      v-model="field.vProductPrice"
                      v-bind="money"
                      disabled="true"
                      autocomplete="nope"
                      unchangeable
                      @blur="calculatorLineTotal(index)"
                      @change="calculatorLineTotal(index)"
                      @keyup="calculatorLineTotal(index)"
                    ></money>
                  </div>
                </div>
              </td>
              <td>
                <div class="read-only">unit name content</div>
                <div class="write">
                  <input
                    id="unit"
                    name="unit"
                    type="text"
                    v-model="stateTableFields[index].vUnitName"
                    disabled="true"
                    autocomplete="nope"
                    unchangeable
                  />
                </div>
              </td>
              <td>
                <div class="read-only">amount content</div>
                <div class="write">
                  <div class="input-group-text">
                    <input
                      id="amount"
                      name="amount"
                      type="number"
                      maxlength="5"
                      min="0"
                      default="1"
                      v-model="field.vAmount"
                      autocomplete="nope"
                      @blur="calculatorLineTotal(index)"
                      @change="calculatorLineTotal(index)"
                      @keyup="calculatorLineTotal(index)"
                    />
                  </div>
                </div>
              </td>
              <td>
                <div class="read-only">gst content</div>
                <div class="write">
                  <input
                    id="gst"
                    name="gst"
                    type="number"
                    min="1"
                    v-model="stateTableFields[index].vGST"
                    autocomplete="nope"
                    @blur="calculatorLineTotal(index)"
                    @change="calculatorLineTotal(index)"
                    @keyup="calculatorLineTotal(index)"
                  />
                </div>
              </td>
              <td>
                <div class="read-only">pst content</div>
                <div class="write">
                  <input
                    id="pst"
                    name="pst"
                    type="number"
                    min="1"
                    v-model="stateTableFields[index].vPST"
                    disabled="true"
                    autocomplete="nope"
                    unchangeable
                  />
                </div>
              </td>
              <td>
                <div class="read-only">prodcut total content</div>
                <div class="write">
                  <money
                    id="productTotal"
                    name="productTotal"
                    type="text"
                    v-model="field.vProductTotal"
                    v-bind="money"
                    disabled="true"
                    autocomplete="nope"
                  ></money>
                </div>
              </td>
              <td>
                <div class="item-actions">
                    <button @click="deleteRow(field)">
                        <i class="flaticon-delete"></i>
                    </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="validation-error"
          v-if="formValidation.vProduct.errorMessage"
        >
          {{ formValidation.vProduct.errorMessage }}
        </div>
      </div>
      <div class="invoice-total-wrapper" v-if="isVendorActiveStatus">
        <div>
          <button class="btn-secondary" @click="addRow">
            <i class="flaticon-plus"></i>
            <span>Add Line</span>
          </button>
        </div>
        <div class="total-wrapper">
          <div class="subtotal">
            <div>
              <em>Subtotal :</em>
              <span>${{ stateInvoice.vSubtotal }}</span>
            </div>
          </div>
          <!-- <div class="taxtotal">
            <div>
              <em>Tax :</em>
              <span>${{ stateInvoice.vTaxtotal }}</span>
            </div>
          </div> -->
          <div class="total">
            <div>
              <em>Total :</em>
              <span>${{ stateInvoice.vTotal }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
import store from "@/store";
import { mapState, mapGetters } from "vuex";
import { mask } from "vue-the-mask";
import VendorAutoComplete from "@/widgets/AutoComplete/VendorAutoComplete";
import StockAutoComplete from "@/widgets/AutoComplete/StockAutoComplete.vue";
import { Money } from 'v-money';

export default {
    name: "InvoiceForm",
    data() {
      return {
        money: {
          decimal: ",",
          thousands: ".",
          prefix: "$",
          precision: 2,
          masked: false,
        },
        brandHash: "",
        storeHash: "",
        isVendorActiveStatus: false,
      }
    },
    directives: { mask },
    components: {
      VendorAutoComplete,
      StockAutoComplete,
      Money,
    },
    created() {
      this.invoiceFormConfig();
      this.brandHash = this.stateStore.current.brandHash;
      this.storeHash = this.stateStore.current.storeHash;
    },
    computed: {
      ...mapState({
        stateInvoice: "invoices",
        stateStore: "stores"
      }),
      stateTableFields: {
        get() {
          return this.stateInvoice.tableFields;
        },
        set(value) {
          this.$set(this.stateInvoice, 'tableFields', value);
        }
      },
      formValidation() {
        return store.state.invoices.formValidation;
      },
      ...mapGetters(["getFormType"])
    },
    methods: {
      invoiceFormConfig() {
        if (this.getFormType === 0) {
          this.stateInvoice.vSubtotal = 0;
          this.stateInvoice.vTotal = 0;
          this.stateInvoice.vTaxtotal = 0;
        } else {
          this.isVendorActiveStatus = true;
          this.calculator();
        };
      },
      async getVendor(value) {
        this.dismissError('vCompany');
        this.dismissError('vEmail');
        this.dismissError('vBillingAddress');
        try {
          let { data } = await this.axios.get(`inventory/api/vendors/${value.hash}?brand_hash=${this.brandHash}`);
          this.stateInvoice.vCompanyHash = value.hash;``
          const newItem = {
            vCompany: data.vendor.name,
            vEmail: data.vendor.email,
            vCompanyId: data.vendor.hash,
            vBillingAddress: data.vendor.address,
          };
          Object.assign(
            this.stateInvoice,
            newItem
          );
          this.isVendorActiveStatus = true;
        } catch (error) {
          console.error(error);
        }
      },
      async getStock(value) {
        this.dismissError('vProduct');
        try {
          let { data } = await window.$http.axios.get(`inventory/api/stocks/${value.hash}?brand_hash=${this.brandHash}&store_hash=${this.storeHash}`);
          const newItem = {
            vRowId: "-1",
            vProduct: data.stock.stock_name,
            vStockHash: data.stock.stock_hash,
            vProductPrice: parseFloat(data.stock.stock_unit_price).toFixed(2),
            vDescription: data.stock.stock_sku,
            vAmount: 1,
            vGST: parseFloat(data.stock.stock_tax1).toFixed(2),
            vPST: parseFloat(data.stock.stock_tax2).toFixed(2),
            vProductTotal: parseFloat(data.stock.stock_unit_price).toFixed(2) * this.vAmount,
            vUnitHash: data.stock.unit_hash,
            vUnitName: data.stock.unit_name,
          };
          Object.assign(
            this.stateTableFields[this.stateInvoice.selectedRow],
            newItem
          );
          this.calculatorLineTotal(this.stateInvoice.selectedRow);
        } catch (error) {
          console.error(error);
        }
      },
      addRow() {
        this.stateTableFields.push({
          vRowId: "-1",
          vProduct: "",
          vStockHash: "",
          // vUnitPrice: "",
          vAmount: "1",
          vGST: "",
          vPST: "",
          vOtherTax: "",
          vProductTotal: "",
          vSKU: "",
          vBarcode: "",
          vUnitName: "",
          vUnitHash: "",
        });
      },
      deleteRow(field) {
        // delete selected row
        let idx = this.stateTableFields.indexOf(field);
        if (idx > -1) {
            this.stateTableFields.splice(idx, 1);
        }
        // delete other empty rows
        // let newTableFields = [];
        this.stateTableFields = this.stateTableFields.filter(
            item => item.vProduct !== ""
        );
        //  = newTableFields;
        this.calculator();
      },
      selectedRowIndex(idx) {
          this.stateInvoice.selectedRow = idx;
      },
      calculator() {
        // setTimeout(() => {
          this.calculatorTaxTotal();
          this.calculatorSubTotal();
          this.calculatorTotal();
        // }, 1000); 
      },
      calculatorLineTotal(idx) {
          const beforeTaxPrice =
              parseFloat(this.stateTableFields[idx].vProductPrice) *
              parseFloat(this.stateTableFields[idx].vAmount);
          this.stateTableFields[idx].vProductTotal =
              beforeTaxPrice +
              this.calculatorTaxGST(idx, beforeTaxPrice) +
              this.calculatorTaxPST(idx, beforeTaxPrice);
          this.stateTableFields[idx].vProductTax = this.calculatorTaxGST(idx, beforeTaxPrice) + this.calculatorTaxPST(idx, beforeTaxPrice);
          this.calculator();
      },
      calculatorTaxGST(idx, beforeTaxPrice) {
          return parseFloat(
              beforeTaxPrice * (this.stateTableFields[idx].vGST / 100)
          );
      },
      calculatorTaxPST(idx, beforeTaxPrice) {
          return parseFloat(
              beforeTaxPrice * (this.stateTableFields[idx].vPST / 100)
          );
      },
      calculatorTaxTotal(){        
          let _tempTax = 0;
          this.stateTableFields.map(item => {
            if(item.vProduct == "") {
              return;
            }
            _tempTax = parseFloat(item.vProductTax) + _tempTax;
          });
          if(_tempTax > 0) {
            this.stateInvoice.vTaxtotal = _tempTax.toFixed(2);
          } else {
            this.stateInvoice.vTaxtotal = 0;
          }
      },
      calculatorSubTotal() {
          const _tempArr = [];
          this.stateTableFields.map(item => {
              let _tempSubTotalItem = item.vProductPrice * item.vAmount;
              _tempArr.push(parseFloat(_tempSubTotalItem));
          });
          let _tempSubTotal = 0;
          _tempArr.map(item => {
              _tempSubTotal = item + _tempSubTotal;
          });
          if(_tempSubTotal > 0) {
            this.stateInvoice.vSubtotal = _tempSubTotal.toFixed(2);
          } else {
            this.stateInvoice.vSubtotal = 0;
          }
      },
      calculatorTotal() {
          let _tempTotal = 0;
          this.stateTableFields.map(item => {
              _tempTotal = parseFloat(item.vProductTotal) + _tempTotal;
          });
          if(_tempTotal > 0) {
            this.stateInvoice.vTotal = _tempTotal.toFixed(2);
          } else {
            this.stateInvoice.vTotal = 0;
          }
      },
      /***   Validation Methods Start */
      dismissError (field) {
        this.formValidation[field].isValid = false;
        this.formValidation[field].errorMessage = "";
      },
      isEmpty(val) {
        return val === "" || val === " " || val === null ? true : false;
      },
      checkForm() {
        let checkingInvoiceDate = this.isValidInvoiceDate();
        let checkingInvoiceNumber = this.isValidInvoiceNumber();
        let checkingCompany = this.isValidCompany();
        let checkingBillingAddress = this.isValidBillingAddress();
        let checkingEmail = this.isValidEmail();
        let checkingProduct = this.isValidProduct();
        let checkingInvoicePicture = this.isValidInvoicePicture();
        return checkingInvoiceDate &&
        checkingInvoiceNumber &&
        checkingCompany &&
        checkingBillingAddress &&
        checkingEmail &&
        checkingProduct && 
        checkingInvoicePicture
        ? true
        : false;
      },
      isValidInvoiceDate() {
        if(this.isEmpty(this.stateInvoice.vInvoiceDate)) {
          this.formValidation.vInvoiceDate.isValid = false;
          this.formValidation.vInvoiceDate.errorMessage = "Invoice Date cannot be empty";
          return false;
        }
        this.formValidation.vInvoiceDate.isValid = true;
        this.formValidation.vInvoiceDate.errorMessage = "";
        return true
      },
      isValidInvoiceNumber() {
        if(this.isEmpty(this.stateInvoice.vInvoiceNumber)) {
          this.formValidation.vInvoiceNumber.isValid = false;
          this.formValidation.vInvoiceNumber.errorMessage = "Invoice Number cannot be empty";
          return false;
        }
        this.formValidation.vInvoiceNumber.isValid = true;
        this.formValidation.vInvoiceNumber.errorMessage = "";
        return true
      },
      isValidCompany() {
        if(this.isEmpty(this.stateInvoice.vCompany)) {
          this.formValidation.vCompany.isValid = false;
          this.formValidation.vCompany.errorMessage = "Company cannot be empty";
          return false;
        }
        this.formValidation.vCompany.isValid = true;
        this.formValidation.vCompany.errorMessage = "";
        return true
      },
      isValidBillingAddress() {
        if(this.isEmpty(this.stateInvoice.vBillingAddress)) {
          this.formValidation.vBillingAddress.isValid = false;
          this.formValidation.vBillingAddress.errorMessage = "Billing Address cannot be empty";
          return false;
        }
        this.formValidation.vBillingAddress.isValid = true;
        this.formValidation.vBillingAddress.errorMessage = "";
        return true
      },
      isValidEmail() {
        if(this.isEmpty(this.stateInvoice.vEmail)) {
          this.formValidation.vEmail.isValid = false;
          this.formValidation.vEmail.errorMessage = "Email cannot be empty";
          return false;
        }
        this.formValidation.vEmail.isValid = true;
        this.formValidation.vEmail.errorMessage = "";
        return true
      },
      isValidProduct() {
        if(this.stateInvoice.tableFields.length == 0) {
          this.formValidation.vProduct.isValid = false;
          this.formValidation.vProduct.errorMessage = "Product cannot be empty";
          return false;
        } else {
          let isProductNameEmpty = this.stateInvoice.tableFields.some(item => item.vProduct == "");
          let isProductHashEmpty = this.stateInvoice.tableFields.some(item => item.vStockHash == "");
          if(isProductHashEmpty) {
            this.formValidation.vProduct.isValid = false;
            this.formValidation.vProduct.errorMessage = "Product is not registered in the system!";
            return false;
          } else if(isProductNameEmpty) {
            this.formValidation.vProduct.isValid = false;
            this.formValidation.vProduct.errorMessage = "Product cannot be empty!";
            return false;
          }
        }
        this.formValidation.vProduct.isValid = true;
        this.formValidation.vProduct.errorMessage = "";
        return true
      },
      isValidInvoicePicture() {
        if(this.stateInvoice.myCroppa == "") {
          this.formValidation.vPicture.isValid = false;
          this.formValidation.vPicture.errorMessage = "Invoice Picture cannot be empty";
          return false;
        }
        this.formValidation.vPicture.isValid = true;
        this.formValidation.vPicture.errorMessage = "";
        return true
      },
      // image
      onLoadImage() {
        this.stateInvoice.pictureControl = 1;
      },
      onRemoveImage() {
        this.stateInvoice.pictureControl = 0;
      },
    }
}
</script>

<style scoped>
.layout-header {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}
.layout-header.form-row {
  width: 49%;
}
.input-group {
  position: relative;
}
textarea {
  min-height: 5rem;
  max-height: 10rem;
  resize: none;
}
.editable-table-wrapper {
  margin-bottom: 1rem;
}
.write,
.read-only {
  display: none;
}
.editable-table-wrapper.modeReadOnly .read-only {
  display: block;
}
.editable-table-wrapper.modeWrite .write {
  display: block;
}
.editable-table-wrapper input {
  width: 100%;
}
.invoice-total-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: right;
  font-size: 2rem;
}
.total-wrapper {
  width: 50%;
  margin-top: 1rem;
}
.total-wrapper .taxtotal,
.total-wrapper .subtotal,
.total-wrapper .total,
.total-wrapper .balance-due {
  margin-bottom: 1.5rem;
}
.total-wrapper em {
  margin-right: 2rem;
  font-style: normal;
  font-weight: 600;
}
.total-wrapper span {
  font-weight: 400;
}
table tbody td {
  vertical-align: top;
}
/* added for error message */
.text-group {
  display: block;
}
.imagearea {
  text-align: center;
}
.item-actions button {
  width: 100%;
}
</style>