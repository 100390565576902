<template>
  <div>
    <div class="form-button-actions">
      <button class="btn-secondary" @click="cancel()">Cancel</button>
      <button class="btn-primary" @click="updateStockAudit()" v-if="getFormType == 1">Update</button>
      <button class="btn-primary" @click="saveStockAudit()" v-else>Save</button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import store from "@/store";

export default {
  name: "StockAmountsButtons",
  data() {
    return {
      brandHash: "",
      storeHash: ""
    }
  },
  computed: {
    ...mapState(["audits", "stores"]),
    ...mapGetters(["getFormType"])
  },
  created() {
    this.brandHash = this.stores.current.brandHash;
    this.storeHash = this.stores.current.storeHash;
  },
  methods: {
    cancel() {
      store.dispatch("closeAuditModalBox");
    },
    updateStockAudit() {
      let auditHash = this.audits.audit.audit_hash;
      if(this.$parent.$parent.$refs.auditForm.checkForm()) {
        store.dispatch("updateStockAudit", {
          auditHash,
          brandHash: this.brandHash,
          storeHash: this.storeHash
        });
      }
    },
    saveStockAudit() {
      if(this.$parent.$parent.$refs.auditForm.checkForm()) {
        store.dispatch("saveStockAudit", {
          brandHash: this.brandHash,
          storeHash: this.storeHash
        });
      }
    }
  }
}
</script>