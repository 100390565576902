<template>
  <div v-if="stateMenus.status" id="menu">
    <!-- <div v-if="stateMenus.menus !== ''"> -->
    <div class="menu-header">
      <h2 class="menu-title">Menus</h2>
      <div>
        <button class="btn-primary" @click="showMenuModal(0, 'newMenu')">
          <!-- <i class="flaticon-plus"></i> -->
          <span>Create Menu</span>
        </button>
      </div>
    </div>

    <Panel v-if="stateMenus.menus.s.length > 0" :styleTitle="'red'">
      <template #title>STORE'S MENU</template>
      <template #row>
        <draggable
          v-model="stateMenus.menus.s"
          handle=".moveclass" 
          animation="150" 
          sort="true" 
          :group="{ put: false }"
          @end="updateMenuOrder('1')"
        >
          <div class="item" v-for="item in stateMenus.menus.s" :key="item.mhash">
            <div class="menu-item">
              <div 
                class="image" 
                :style="{backgroundImage: getImgPathStyle(item.m_image)}"
              ></div>
              <div class>
                <div class="menu-name">
                  {{ item.m_name }}
                  <span class="label" v-if="item.status === '0'">Hidden</span>
                </div>
                <div class="menu-status">
                  <div @click="changeStatus(item, 'pos_status', 1)">
                    <span v-if="item.pos_status == 1" title="Showing in Branch">
                      <i class="flaticon-cashier-machine"></i>
                    </span>
                    <span v-else title="Not Showing in Branch">
                      <i class="flaticon-cashier-machine close"></i>
                    </span>
                  </div>
                  <div @click="changeStatus(item, 'web_status', 2)">
                    <span v-if="item.web_status == 1" title="Showing on Website">
                      <i class="flaticon-world-wide-web"></i>
                    </span>
                    <span v-else title="Not Showing on Website">
                      <i class="flaticon-world-wide-web close"></i>
                    </span>
                  </div>
                  <div @click="changeStatus(item, 'mobile_status', 3)">
                    <span v-if="item.mobile_status == 1" title="Showing on Mobile App">
                      <i class="flaticon-smartphone"></i>
                    </span>
                    <span v-else title="Not Showing on Mobile App">
                      <i class="flaticon-smartphone close"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="sync" :class="{ 'not-sync': item.sync_pos_status == '0' }"></div>
            </div>
            <div class="item-actions">
              <button @click="changeStatus(item, 'status', 0)">
                <i class="flaticon-show" :class="{ 'close': item.status === '0' }"></i>
              </button>
              <button @click="editMenuItem(item.mhash, '1')">
                <i class="flaticon-edit"></i>
              </button>
              <button class="moveclass">
                <img src="../../assets/images/vertical-arrow.png" alt="" class="arrow">
              </button>
              <button @click="deleteMenuItem(item.mhash)">
                <i class="flaticon-delete"></i>
              </button>
              <button @click="syncMenu(item, '1')" class="sync-time">
                <i class="flaticon-sync"></i>
                <span v-if="item.sync_pos_time">Last Update:<br>{{ item.sync_pos_time }}</span>
                <span v-else>Not Synced Yet</span>
              </button>
            </div>
            <div class="confirm" v-if="isDeletePanelActive && deleteMenuHash==item.mhash">
              <p>Are you sure you want to delete this menu?</p>
              <div>
                <button @click="deleteYes(item.mhash, item)">Yes</button>
                <button @click="deleteNo()">No</button>
              </div>
            </div>
          </div>
        </draggable>
      </template>
    </Panel>

    <Panel v-if="stateMenus.menus.b.length > 0">
      <template #title>BRAND'S MENU</template>
      <template #row>
        <draggable
          v-model="stateMenus.menus.b"
          handle=".moveclass" 
          animation="150" 
          sort="true"
          :group="{ put: false }"
          @end="updateMenuOrder('0')"
        >
          <div class="item" v-for="item in stateMenus.menus.b" :key="item.mhash">
            <div class="menu-item">
              <div 
                class="image" 
                :style="{backgroundImage: getImgPathStyle(item.m_image)}"
              ></div>
              <div class>
                <div class="menu-name">
                  {{ item.m_name }}
                  <span class="label" v-if="item.status === '0'">Hidden</span>
                </div>
                <div class="menu-status">
                  <div @click="changeStatus(item, 'pos_status', 1)">
                    <span v-if="item.pos_status == 1" title="Showing in Branch">
                      <i class="flaticon-cashier-machine"></i>
                    </span>
                    <span v-else title="Not Showing in Branch">
                      <i class="flaticon-cashier-machine close"></i>
                    </span>
                  </div>
                  <div @click="changeStatus(item, 'web_status', 2)">
                    <span v-if="item.web_status == 1" title="Showing on Website">
                      <i class="flaticon-world-wide-web"></i>
                    </span>
                    <span v-else title="Not Showing on Website">
                      <i class="flaticon-world-wide-web close"></i>
                    </span>
                  </div>
                  <div @click="changeStatus(item, 'mobile_status', 3)">
                    <span v-if="item.mobile_status == 1" title="Showing on Mobile App">
                      <i class="flaticon-smartphone"></i>
                    </span>
                    <span v-else title="Not Showing on Mobile App">
                      <i class="flaticon-smartphone close"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div :class="['sync', { 'not-sync': item.sync_pos_status == '0' }]"></div>
            </div>
            <div class="item-actions">
              <button @click="changeStatus(item, 'status', 0)">
                <i class="flaticon-show" :class="{ 'close': item.status === '0' }"></i>
              </button>
              <button @click="editMenuItem(item.mhash, '0')">
                <i class="flaticon-edit"></i>
              </button>
              <button class="moveclass">
                <img src="../../assets/images/vertical-arrow.png" alt="" class="arrow">
              </button>
              <button @click="deleteMenuItem(item.mhash)">
                <i class="flaticon-delete"></i>
              </button>
              <button @click="syncMenu(item, '0')" class="sync-time">
                <i class="flaticon-sync"></i>
                <span v-if="item.sync_pos_time">Last Update:<br>{{ item.sync_pos_time }}</span>
                <span v-else>Not Synced Yet</span>
              </button>
            </div>
            <div class="confirm" v-if="isDeletePanelActive && deleteMenuHash==item.mhash">
              <p>Are you sure you want to delete this menu?</p>
              <div>
                <button @click="deleteYes(item.mhash, item)">Yes</button>
                <button @click="deleteNo()">No</button>
              </div>
            </div>
          </div>
        </draggable>
      </template>
    </Panel>
    <ModalBox :alias="'newMenu'">
      <template #modalTitle>New Menu</template>
      <template #modalBody>
        <MenusForm ref="menuForm" />
      </template>
      <template #modalFooter>
        <MenusFormButtons></MenusFormButtons>
      </template>
    </ModalBox>
    <ModalBox :alias="'editMenu'">
      <template #modalTitle>Edit Menu</template>
      <template #modalBody>
        <MenusForm ref="menuForm" />
      </template>
      <template #modalFooter>
        <MenusFormButtons></MenusFormButtons>
      </template>
    </ModalBox>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Panel from "@/widgets/Panel";
import MenusForm from "./MenusForm";
import MenusFormButtons from "./MenusFormButtons";
import store from "@/store";
import draggable from "vuedraggable";

export default {
  name: "Menus",
  data() {
    return {
      isDeletePanelActive : false,
      deleteMenuHash: "",
    };
  },
  components: {
    Panel,
    MenusForm,
    MenusFormButtons,
    draggable,
  },
  computed: {
    ...mapState({
      stateStores: "stores",
      stateMenus: "menus",
      stateCommon: "common",
    }),
    stateItem() {
      return this.stateMenus.current;
    }
  },
  methods: {
    showMenuModal(type, alias, menuHash) {
      let modalOpts = {
        isActiveStatus: true,
        type,
        alias,
      };
      if (menuHash) {
        modalOpts["hash"] = menuHash;
      }
      store.dispatch(
        "showModalBox",
        Object.assign(store.state.common.modalBox, modalOpts)
      );
    },
    getImgPathStyle(imgName) {
      return 'url(https://laruota.ca/images/menus/' + imgName + ')';
    },
    async changeStatus(menu, props, y) {
      // y = 0: status, 1: pos_status, 2: web_status, 3: mobile_status
      let newstat = menu[props] == 1 ? "0" : "1";
      const params = new URLSearchParams();
      params.append("mhash", menu.mhash);
      params.append("newstat", newstat);
      params.append("y", y);
      const config = {
          method: "post",
          url: `menu/changestatus?s=${this.stateStores.current.storeHash}`,
          data: params
        };
      try {
        let { data } = await window.$http.axios(config);
        if (data.status) {
          menu.sync_pos_status = "0";
          menu[props] = newstat;
        } else {
          store.commit("NOTIFICATION_MESSAGE", { status: 1, title: "Success", message: data.message });
        }
      } catch(error) {
        console.error(error);
        store.commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: error });
      }
    },
    updateMenuOrder (menu) {
      // get new orders with mhash
      const menuList = [];
      const menuType = menu == '0' ? 'b' : 's';
      this.stateMenus.menus[menuType].map((item) => {
        menuList.push({
          mhash: item.mhash,
        });
      });
      store.dispatch('updateMenuOrder', {
        storeHash: this.stateStores.current.storeHash,
        menuType: menuType,
        menuList: menuList
      });
    },
    currentTime() {
      const pad = (num) => (num < 10 ? `0${num}` : num);
      let now = new Date();
      let date = `${now.getFullYear()}-${pad(now.getMonth() + 1)}-${pad(now.getDate())}`;
      let time = `${pad(now.getHours())}:${pad(now.getMinutes())}:${pad(now.getSeconds())}`;
      return `${date} ${time}`;
    },
    async syncMenu(menu) {
      store.commit('LOADING', { isActiveStatus: true });
      try {
        let { data } = await window.$http.axios.get(`sync/pos/menu?m=${menu.mhash}`);
        if (data.status) {
          store.commit("NOTIFICATION_MESSAGE", { status: 1, title: "POS Sync Completed", message: data.message });
          menu.sync_pos_time = this.currentTime();
          menu.sync_pos_status = "1";
        } else {
          store.commit('NOTIFICATION_MESSAGE', { status: -1, title: 'Error', message: data.message });
          menu.sync_pos_status = "0";
        }
      } catch (error) {
        store.commit('NOTIFICATION_MESSAGE', { status: -1, title: 'Error', message: error.message });
        menu.sync_pos_status = "0";
      }
      store.commit('LOADING', { isActiveStatus: false });
    },
    editMenuItem(menuItemHash, menuType) {
      let selectedItem = store.getters.getMenuItem(
        menuItemHash,
        menuType,
      );
      this.stateItem.m_name = selectedItem.m_name;
      this.stateItem.m_desc = selectedItem.m_desc;
      this.stateItem.m_type = selectedItem.m_type;
      this.stateItem.mhash = selectedItem.mhash;
      this.showMenuModal(1, "editMenu", menuItemHash);
    },
    deleteMenuItem(menuItemHash) {
      this.deleteMenuHash = menuItemHash;
      this.isDeletePanelActive = true;
    },
    deleteYes(menuItemHash, menuItem) {
      store.dispatch("deleteMenu", {
        itemHash: menuItemHash,
        item: menuItem,
        storeHash: this.stateStores.current.storeHash,
      });
      this.deleteNo();
    },
    deleteNo() {
      this.deleteMenuHash = "";
      this.isDeletePanelActive = false;
    },
  }
};
</script>

<style scoped>
.menu-header {
  display: flex;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 1px;
  border-color: #F3F4F6;
}
.menu-title {
  flex-grow: 1;
}
.pos-time {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  margin-left: 1rem;
}
.pos-time p {
  margin: 0;
  font-size: 0.8em;
}
</style>
