<template>
<div class="vendor-form">
    <div class="row">
    <div class="column">
        <div class="form-wrapper">
        <div class="form-row" :class="{ error: formValidation.vVendorName.errorMessage }">
            <div class="input-group">
            <label for="vendorName">Vendor</label>
            <div class="input-group-item">
                <input
                id="vendorName"
                name="vendorName"
                type="text"
                v-model="vendors.vVendorName"
                autocomplete="off"
                @focus="dismissError('vVendorName')"
                
                />
                <div class="validation-error" v-if="formValidation.vVendorName.errorMessage">
                  {{ formValidation.vVendorName.errorMessage }}
                </div>
              </div>
            </div>
        </div>
        <div class="form-row" :class="{ error: formValidation.vVendorPhone.errorMessage }">
            <div class="input-group">
            <label for="phone">Phone</label>
            <div class="input-group-item">
                <input
                  type="text"
                  v-mask="'(###) ### ####'"
                  v-model="vendors.vVendorPhone"
                  autocomplete="nope"
                  @focus="dismissError('vVendorPhone')"
                  
                />
                <div
                class="validation-error"
                v-if="formValidation.vVendorPhone.errorMessage"
                >{{ formValidation.vVendorPhone.errorMessage }}</div>
            </div>
            </div>
        </div>
        <div class="form-row" :class="{ error: formValidation.vVendorEmail.errorMessage }">
            <div class="input-group">
            <label for="eMail">E-mail</label>
            <div class="input-group-item">
                <input
                id="eMail"
                name="eMail"
                type="text"
                v-model="vendors.vVendorEmail"
                autocomplete="off"
                @focus="dismissError('vVendorEmail')"
                
                />
                <div
                class="validation-error"
                v-if="formValidation.vVendorEmail.errorMessage"
                >{{ formValidation.vVendorEmail.errorMessage }}</div>
            </div>
            </div>
        </div>
        <div class="form-row" :class="{ error: formValidation.vVendorAddress.errorMessage }">
            <div class="input-group">
            <label for="address">Address</label>
            <div class="input-group-item">
                <textarea
                id="address"
                name="address"
                v-model="vendors.vVendorAddress"
                cols="30"
                rows="10"
                autocomplete="nope"
                @focus="dismissError('vVendorAddress')"
                
                ></textarea>
                <div
                class="validation-error"
                v-if="formValidation.vVendorAddress.errorMessage"
                >{{ formValidation.vVendorAddress.errorMessage }}</div>
            </div>
            </div>
        </div>
        </div>
    </div>
    </div>
</div>
</template>

<script>
import { mapState } from "vuex";
import store from "../../store";
import { mask } from "vue-the-mask";

export default {
  name: "VendorForm",
  computed: {
    ...mapState(["vendors"]),
    formValidation() {
      return store.state.vendors.formValidation;
    }
  },
  directives: { mask },
  beforeDestroy() {
    this.clearForm();
  },
  methods: {
    clearForm() {
      store.commit("RESET_VENDOR_FORM");
    },
    /***   Validation Methods Start */
    dismissError (field) {
      this.formValidation[field].isValid = false;
      this.formValidation[field].errorMessage = "";
    },
    checkForm() {
      let checkingVendorName = this.isValidVendorName();
      let checkingPhone = this.isValidPhone();
      let checkingEmail = this.isValidEmail();
      let checkingAddress = this.isValidAddress();
      return checkingVendorName &&
        checkingPhone &&
        checkingEmail &&
        checkingAddress
        ? true
        : false;
    },
    isValidVendorName() {
      if (this.isEmpty(this.vendors.vVendorName)) {
        this.formValidation.vVendorName.isValid = false;
        this.formValidation.vVendorName.errorMessage =
          "Vendor name cannot be empty!";
        return false;
      }
      this.formValidation.vVendorName.isValid = true;
      this.formValidation.vVendorName.errorMessage = "";
      return true;
    },
    isValidPhone() {
      if (this.isEmpty(this.vendors.vVendorPhone)) {
        this.formValidation.vVendorPhone.isValid = false;
        this.formValidation.vVendorPhone.errorMessage =
          "Phone number cannot be empty!";
        return false;
      }
      if (this.vendors.vVendorPhone.length > 16) {
        this.formValidation.vVendorPhone.isValid = false;
        this.formValidation.vVendorPhone.errorMessage = "Invalid phone number!";
        return false;
      }
      this.formValidation.vVendorPhone.isValid = true;
      this.formValidation.vVendorPhone.errorMessage = "";
      return true;
    },
    isValidEmail() {
      if (this.isEmpty(this.vendors.vVendorEmail)) {
        this.formValidation.vVendorEmail.isValid = false;
        this.formValidation.vVendorEmail.errorMessage =
          "E-mail address cannot be empty!";
        return false;
      }
      if (!this.validateEmail(this.vendors.vVendorEmail)) {
        this.formValidation.vVendorEmail.isValid = false;
        this.formValidation.vVendorEmail.errorMessage = "Invalid e-mail address!";
        return false;
      }
      this.formValidation.vVendorEmail.isValid = true;
      this.formValidation.vVendorEmail.errorMessage = "";
      return true;
    },
    isValidAddress() {
      if (this.isEmpty(this.vendors.vVendorAddress)) {
        this.formValidation.vVendorAddress.isValid = false;
        this.formValidation.vVendorAddress.errorMessage = "Address cannot be empty!";
        return false;
      }
      this.formValidation.vVendorAddress.isValid = true;
      this.formValidation.vVendorAddress.errorMessage = "";
      return true;
    },
    isEmpty(val) {
      return val === "" || val === " " || val === null ? true : false;
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
  }
};
</script>

<style scoped>
/* input[type="text"],
input[type="tel"],
input[type="number"],
textarea {
  width: 100%;
} */
textarea {
  min-height: 5rem;
  max-height: 10rem;
  resize: none;
}
</style>