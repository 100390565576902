<template>
  <div class="bag-group">
    <draggable
      class="content"
      v-model="stateProducts.product.bags"
      :options="{ group: { name: 'cupgroup', pull: false, put: ['cupitems'] } }"
      @add="checkMove($event)"
    >
      <BagGroupElement
        class="icitem"
        v-for="(item, index) in stateProducts.product.bags"
        :key="`${item.phash}${index}`"
        :pelement="item"
        @click-child="deleteGroupItem"
      ></BagGroupElement>
    </draggable>
  </div>
</template>

<script>
import { isEqual, unionWith } from "lodash";

import { mapState } from "vuex";
import draggable from "vuedraggable";
import BagGroupElement from "./BagGroupElement.vue";

export default {
  name: "BagGroup",
  data() {
    return {
      remove_stat: false
    };
  },
  components: {
    draggable,
    BagGroupElement
  },
  computed: {
    ...mapState({
      stateOptions: "options",
      stateProducts: "products",
      stateMenus: "menus"
    })
  },
  methods: {
    generateID(hash) {
      return `${hash}${Math.floor(Math.random() * 100)}`;
    },
    checkMove(event) {
      let $self = this;
      // let couponGroupIndex = this.stateProducts.product.coupons.findIndex(
      //   (item) => item.chash === $self.citem.chash
      // );

      if (event.item.attributes.tur.value === "1") {
        // TODO: This part haven't finished yet

        if (event.item.attributes.alantip.value === "0") {
          // Brand
          this.stateMenus.menus.b.map((item) => {
            if (item.mhash === $self.stateProducts.product.draggingGroupHash) {
              item.products.map((productItem) => {
                $self.stateProducts.product.bags.push(productItem);
              });
            }
          });
        } else {
          // Store
          this.stateMenus.menus.s.map((item) => {
            if (item.mhash === $self.stateProducts.product.draggingGroupHash) {
              item.products.map((productItem) => {
                $self.stateProducts.product.bags.push(productItem);
              });
            }
          });
        }
      } else {
        let groupAllItems = this.stateProducts.product.bags;

        let addingItem = [];
        let addingItemHash = this.stateProducts.product.draggingHash;

        groupAllItems.map((item) => {
          if (item.phash === addingItemHash) {
            addingItem.push(item);
          }
        });
        this.stateProducts.product.bags = unionWith(
          groupAllItems,
          addingItem,
          isEqual
        );
      }
      this.stateProducts.product.draggingHash = "";
      this.stateProducts.product.draggingGroupHash = "";
    },
    deleteGroupItem(hash) {
      this.stateProducts.product.bags = this.stateProducts.product.bags.filter(
        (item) => item.phash !== hash
      );
    }
  }
};
</script>

<style lang="css" scoped>
.bag-group {
  position: relative; 
  padding: 0.5rem; 
  margin-bottom: 1rem; 
  background-color: #FEF2F2; 
  font-weight: 600; 
  border-radius: 0.5rem; 
  border-width: 1px; 
  border-color: #FEE2E2; 
  border-style: solid; 
  cursor: pointer; 
}
.content {
  min-height: 120px;
  padding: 0.5rem; 
  background-color: #ffffff; 
  border-radius: 0.375rem; 
}
button {
  font-size: 1.875rem;
line-height: 2.25rem; 
}
input[type="number"] {
  width: 4rem; 
}
.actions button {
  cursor: pointer;
  font-size: 1.6em;
  line-height: 1.5rem;
  padding: 0 10px;
  background: none;
  border: none;
  outline: none;
  font-weight: 600;
  border-radius: var(--rounded-lg);
}
</style>
