<template>
  <div class="accordion">
    <div
      class="accordion-click"
      @click="changeStatus()"
    >
      <div>{{ title }}</div>
      <i class="flaticon-plus" v-if="!isActiveStatus"></i>
      <i class="flaticon-minus" v-else></i>
    </div>
    <div class="accordion-body" v-if="isActiveStatus">
      <slot name="body">Accordion Body</slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Accordion",
  data() {
    return {
      isActiveStatus: false,
    };
  },
  created() {
    this.isActiveStatus = this.isOpen;
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    changeStatus() {
      this.isActiveStatus = !this.isActiveStatus;
    }
  }
};
</script>

<style lang="css" scoped>
.accordion {
  overflow: hidden; 
  margin-bottom: 0.25rem; 
  border-radius: 0.5rem; 
  border-width: 1px; 
  border-color: #F3F4F6; 
}
.accordion-click {
  position: relative; 
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; 
  padding-left: 1rem; 
  padding-right: 2.5rem; 
  background-color: #F9FAFB; 
  font-weight: 600; 
  cursor: pointer; 
}
.accordion-body {
  padding: 1rem; 
  border-top-width: 1px; 
  border-color: #F3F4F6; 
}
i {
  position: absolute; 
  top: 0.5rem; 
  right: 1rem; 
  line-height: 1; 
  transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
}
</style>