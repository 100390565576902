<template>
  <div class="login-panel">
    <!-- <Loader :isActiveStatus="stateCommon.loader.isActiveStatus"></Loader> -->
    <div class="logo-container">
      <div class="logo-img">
        <svg
          data-name="logo"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1920 384"
        >
          <path
            d="m1178.71 234.43-59.07-136.12h-39.63l78.89 181.88h39.55l78.9-181.88h-39.49l-59.15 136.12zM778.42 207.37q60.63 0 60.63-54.57t-60.63-54.56H657.17v182h36.37v-72.9h17.68l85.32 72.75h55.89l-85.25-72.75ZM693.54 171v-36.39h78.82q30.24 0 30.31 18.19T772.36 171ZM79.8 98.31.91 280.19h39.63L99.61 144l59.15 136.19h39.56L119.36 98.31H79.8zM1299.82 243.82h181.88v36.38h-181.88zM366.23 225.04 257.84 98.31h-37.12v181.88h36.38V153.47l108.54 126.72h36.97V98.31h-36.38v126.73zM560.24 98.24H439v182h121.24q60.69 0 60.62-60.63V159q0-60.75-60.62-60.76Zm24.17 116.52q0 28.77-29.13 28.76h-80V134.61h79.93q28.9 0 29 28.62ZM1801.55 98.31l-78.96 181.88h39.7L1821.3 144l59.22 136.19H1920l-78.89-181.88h-39.56zM1518.08 158.94v60.63q0 60.71 60.63 60.63H1700v-97h-36.09v60.63h-80.07q-29.57 0-29.57-29.21v-50.8c0-19.37 9.76-29.13 29.2-29.13h116.3V98.31h-121.28c-40.3 0-60.41 18.19-60.41 60.63ZM1299.82 171.07h181.88v36.38h-181.88zM1299.82 98.31h181.88v36.38h-181.88zM908.92 276.05l-46.65 107.51h39.63l44.8-103.51h50q60.71 0 60.63-60.63v-60.63q0-44.88-33.42-56.49L1068.25-.47h-39.63l-42.81 98.71h-50q-60.69 0-60.62 60.63v60.62c.24 30.02 11.48 48.8 33.73 56.56Zm2.88-110.9q0-30.32 24.26-30.31h54.56q30.32 0 30.31 30.31v48.5q0 30.32-30.31 30.32h-48.5q-30.32 0-30.32-30.32Z"
          />
        </svg>
      </div>
      <form @submit.prevent="login()">
        <div class="form-row">
          <!-- <label for="txtEmail">E-mail</label> -->
          <i class="fontello-icon" v-show="!email">&#xf2be;</i>
          <input
            @focus="dismissError()"
            ref="email"
            type="text"
            name="txtEmail"
            id="txtEmail"
            autocomplete="off"
            v-model="email"
          />
        </div>
        <div class="form-row">
          <!-- <label for="txtPassword">Password</label> -->
          <i class="fontello-icon" v-show="!password">&#xe80e;</i>
          <input
            @focus="dismissError()"
            type="password"
            name="txtPassword"
            id="txtPassword"
            autocomplete="nope"
            v-model="password"
          />
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.999 511.999" xml:space="preserve" @click="passwordShow()" id="showEye" v-if="isPasswordActive">
            <g>
              <path d="M508.745,246.041c-4.574-6.257-113.557-153.206-252.748-153.206S7.818,239.784,3.249,246.035    c-4.332,5.936-4.332,13.987,0,19.923c4.569,6.257,113.557,153.206,252.748,153.206s248.174-146.95,252.748-153.201    C513.083,260.028,513.083,251.971,508.745,246.041z M255.997,385.406c-102.529,0-191.33-97.533-217.617-129.418    c26.253-31.913,114.868-129.395,217.617-129.395c102.524,0,191.319,97.516,217.617,129.418    C447.361,287.923,358.746,385.406,255.997,385.406z"/>
            </g>
            <g>
              <path d="M255.997,154.725c-55.842,0-101.275,45.433-101.275,101.275s45.433,101.275,101.275,101.275    s101.275-45.433,101.275-101.275S311.839,154.725,255.997,154.725z M255.997,323.516c-37.23,0-67.516-30.287-67.516-67.516    s30.287-67.516,67.516-67.516s67.516,30.287,67.516,67.516S293.227,323.516,255.997,323.516z"/>
            </g>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 59.049 59.049" xml:space="preserve" @click="passwordHide()" id="hideEye" v-else>
            <g>
              <path d="M11.285,41.39c0.184,0.146,0.404,0.218,0.623,0.218c0.294,0,0.585-0.129,0.783-0.377c0.344-0.432,0.273-1.061-0.159-1.405   c-0.801-0.638-1.577-1.331-2.305-2.06l-7.398-7.398l7.629-7.629c7.334-7.333,18.003-9.836,27.839-6.534   c0.523,0.173,1.09-0.107,1.267-0.63c0.175-0.523-0.106-1.091-0.63-1.267c-10.562-3.545-22.016-0.857-29.89,7.016L0,30.368   l8.812,8.812C9.593,39.962,10.426,40.705,11.285,41.39z"/>
              <path d="M50.237,21.325c-1.348-1.348-2.826-2.564-4.394-3.616c-0.458-0.307-1.081-0.185-1.388,0.273   c-0.308,0.458-0.185,1.08,0.273,1.388c1.46,0.979,2.838,2.113,4.094,3.369l7.398,7.398l-7.629,7.629   c-7.385,7.385-18.513,9.882-28.352,6.356c-0.52-0.187-1.093,0.084-1.279,0.604c-0.186,0.52,0.084,1.092,0.604,1.279   c3.182,1.14,6.49,1.693,9.776,1.693c7.621,0,15.124-2.977,20.665-8.518l9.043-9.043L50.237,21.325z"/>
              <path d="M30.539,41.774c-2.153,0-4.251-0.598-6.07-1.73c-0.467-0.29-1.084-0.148-1.377,0.321c-0.292,0.469-0.148,1.085,0.321,1.377   c2.135,1.33,4.6,2.032,7.126,2.032c7.444,0,13.5-6.056,13.5-13.5c0-2.685-0.787-5.279-2.275-7.502   c-0.308-0.459-0.93-0.582-1.387-0.275c-0.459,0.308-0.582,0.929-0.275,1.387c1.267,1.893,1.937,4.102,1.937,6.39   C42.039,36.616,36.88,41.774,30.539,41.774z"/>
              <path d="M30.539,18.774c2.065,0,4.089,0.553,5.855,1.6c0.474,0.281,1.088,0.125,1.37-0.351c0.281-0.475,0.125-1.088-0.351-1.37   c-2.074-1.229-4.451-1.879-6.875-1.879c-7.444,0-13.5,6.056-13.5,13.5c0,2.084,0.462,4.083,1.374,5.941   c0.174,0.354,0.529,0.56,0.899,0.56c0.147,0,0.298-0.033,0.439-0.102c0.496-0.244,0.701-0.843,0.458-1.338   c-0.776-1.582-1.17-3.284-1.17-5.06C19.039,23.933,24.198,18.774,30.539,18.774z"/>
              <path d="M54.621,5.567c-0.391-0.391-1.023-0.391-1.414,0l-46.5,46.5c-0.391,0.391-0.391,1.023,0,1.414   c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293l46.5-46.5C55.012,6.591,55.012,5.958,54.621,5.567z"/>
            </g>
          </svg>
        </div>
        <div class="form-actions">
          <button type="submit" class="w-full btn btn-primary login">Login</button>
        </div>
      </form>
    </div>
    <div class="error-container" v-if="errorStatus">
      <p>{{ errorMessage }}</p>
    </div>
    <div class="panel-name">
      <span>{{ brandname }}&nbsp;</span>
      <span>CONNECT PANEL</span>
    </div>
    <div class="bar"></div>
  </div>
</template>

<script>
import store from "@/store";
// import Loader from "@/widgets/Loader/index.vue";
import Authorization from "@/store/modules/auth/authorization.js";
import { mapState } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      errorStatus: false,
      errorMessage: "",
      brandname: "",
      isPasswordActive: false
    };
  },
  computed: {
    ...mapState({
      stateCommon: "common",
      stateAuth: "auth"
    })
  },
  created() {
    this.brandname = "BRAND";
    let urlParams = window.location.search;
    console.log('url', urlParams);
  },
  mounted() {
    this.focusInput();
  },
  methods: {
    passwordShow() {
      this.isPasswordActive = false;
      document.getElementById("txtPassword").type = "password";
    },
    passwordHide() {
      this.isPasswordActive = true;
      document.getElementById("txtPassword").type = "text";
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    async login() {
      if(this.email == "" || this.password == "") {
        this.errorStatus = true;
        this.errorMessage = 'Please fill all fields!';
      } else {
        // validate email
        if(this.validateEmail(this.email)) {
          this.errorStatus = false;
        } else {
          this.errorStatus = true;
          this.errorMessage = 'Invalid email format!';
          return;
        }
        store.commit("LOADING", { isActiveStatus: true});
        let user = await Authorization({
          email: this.email,
          password: this.password
        });
        if (user.status !== false) {
          store.commit("LOADING", { isActiveStatus: false });
          // console.log('user', user);
          localStorage.setItem("user", user.name);
          localStorage.setItem("token", user.token);
          localStorage.setItem("uhash", user.uhash);
          localStorage.setItem("utype", user.utype);
          localStorage.setItem("token", user.token);
          localStorage.setItem("recipes_access", user.recipe_access);
          localStorage.setItem("invoices_access", user.invoice_access);
          localStorage.setItem("vendors_access", user.vendor_access);
          localStorage.setItem("stocks_access", user.stock_access);
          store.commit("LOGIN", {
            name: localStorage.getItem("user"),
            status: true,
            token: localStorage.getItem("token"),
            uhash: localStorage.getItem("uhash"),
            utype: localStorage.getItem("utype"),
            recipes_access: localStorage.getItem("recipes_access"),
            invoices_access: localStorage.getItem("invoices_access"),
            vendors_access: localStorage.getItem("vendors_access"),
            stocks_access: localStorage.getItem("stocks_access")
          });
          this.stateAuth.isAuthenticated = true;
        } else {
          store.commit("LOADING", { isActiveStatus: false });
          this.errorStatus = true;
          this.errorMessage = user.message;
        }
      } 
    },
    dismissError() {
      this.errorStatus = false;
    },
    focusInput() {
      this.$refs.email.focus();
    }
  }
};
</script>

<style scoped>
.login-panel {
  display: flex; 
  margin-left: auto;
  margin-right: auto; 
  flex-direction: column; 
  justify-content: center; 
  max-width: 24rem; 
  height: 100vh; 
}
.logo-container {
  padding: 1.25rem; 
  background-color: #ffffff; 
  border-radius: 0.375rem; 
  border-width: 1px; 
  border-color: #F3F4F6; 
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); 
}
.logo-img {
  margin-left: auto;
  margin-right: auto; 
  margin-bottom: 1em; 
  width: 10em; 
}
.form-row {
  display: flex; 
  margin-bottom: 1.5rem; 
  position: relative;
}
.form-row i {
  font-size: 1.5em; 
  color: #9CA3AF;
  position: absolute;
  left: 2%;
  top: 50%;
  transform: translateY(-50%);
}
.form-row svg {
  width: 1.5em;
  position: absolute;
  right: 2%;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
button.login {
  width: 100%;
}
.error-container {
  width: 100%;
  background-color: red;
  font-weight: 500;
  color: #fff;
  border-radius: 0 0 5px 5px;
}
.error-container p {
  font-size: 1em;
  padding: 5px 5px;
  text-align: center;
}
.panel-name {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  font-size: 1.5rem;
  font-weight: 500;
  color: #000;
  position: absolute;
  bottom: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
}
.bar {
  width: 250px;
  height: 0.5rem;
  background-color: #fbbf24;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
</style>