const getMenuboardDefaultState = () => {
  return {
    status: false,
    menuboard: [],
    m_name: 'test name',
    m_desc: 'test desc',
    pictureControl: -1,
    myCroppa: {},
    vMenuboardHash: '',
    // Validation
    formValidation: {
      m_name: { isValid: false, errorMessage: '' },
      m_desc: { isValid: false, errorMessage: '' },
    },
  };
};

const menuboards = {
  state: getMenuboardDefaultState(),
  mutations: {
    RESET_MENUBOARD_STATE(state) {
      Object.assign(state, getMenuboardDefaultState());
    },
  },
  actions: {
    closeMenuboardModalBox({ commit }) {
      commit('RESET_MENUBOARD_STATE');
      commit('RESET_MODALBOX');
    },
    getMenuboard(menuboardHash) {
      console.log(menuboardHash);
    },
  },
};

export default menuboards;
