<template>
  <div class="opacity-wrapper" v-if="isActiveStatus">
    <div class="spinner"></div>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    isActiveStatus: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="css" scoped>
.opacity-wrapper {
  z-index: 100;
  opacity: 0.5;
}
.spinner {
  display: block; 
  z-index: 20; 
  width: 6rem; 
  height: 6rem; 
  border-radius: 9999px; 
  border-width: 4px; 
  /* border-color: #4B5563;  */
  border-style: solid; 
  opacity: 0.75;
  /* border-top-color: rgba(255, 255, 255, 0.25); */
  animation: spinner 0.6s infinite linear;
  /* transition: top 0.3s 0.3s ease, opacity 0.3s 0.3s ease, */
    /* border-radius 0.3s ease; */
}

@keyframes spinner {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(359deg);
  }
}
</style>