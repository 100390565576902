<template>
  <div class="autocomplete-wrapper" autocomplete>
    <input
      type="text"
      autocomplete="off"
      @keyup="getQuery()"
      v-model="vSelectedText"
      :id="`txt${tParams}`"
      :disabled="isDisabled ? true : false"
      @focus="dismissError('vCompany')"
    />
    <div 
      class="autocomplete-list-wrapper"
      v-if="vendorListStatus"
    >
      <div class="no-result" v-if="vQueryList == []">
        <p>The result didn't find.</p>
      </div>
      <ul class="autocomplete-list" v-else>
        <li
          v-for="(item, index) in vQueryList"
          :key="index"
          @click="selectItem(item.vendor_name, item.vendor_hash)"
        >{{ item.vendor_name }}</li>
      </ul>
      <div v-if="getSecondModalState && vQueryList.length == 0">
        No result found.
      </div>
      <div v-else>
        <button 
          class="autocomplete-add"
          @click="addVendor('newVendor')"
          v-if="!getSecondModalState"
        >
          <i class="icon-plus"></i>
          <span>Add New Vendor</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import store from '@/store';
import { mapState, mapGetters } from 'vuex';

export default {
  name: "VendorAutoComplete",
  data() {
    return {
      vQuery: "",
      vQueryList: [],
      vSelectedText: this.text || "",
      vSelectedHash: this.hash || "",
      vSelectedAmount: "",
      vSelectedUnitName: "",
      vSelectedUnitHash: "",
      vendorListStatus: false,
      brandHash: "",
      storeHash: "",
      timer: 1000,
    };
  },
  props: {
    minChar: {
      type: Number,
      default: 3
    },
    tParams: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    },
    hash: {
      type: String,
      default: ""
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
  },
  created() {
    this.brandHash = this.stateStore.current.brandHash;
    this.storeHash = this.stateStore.current.storeHash;
  },
  computed: {
    ...mapState({ stateInvoice: "invoices", stateStore: "stores", stateStock: "stocks", stateOptions: "options"}),
    ...mapGetters(["getSecondModalState"])
  },
  watch: {
    text(newValue) {
      this.vSelectedText = newValue;
    },
    hash(newValue) {
      this.vSelectedHash = newValue;
    },
    vSelectedText(newValue) {
      this.vQuery = newValue;
      this.vendorListStatus = false;
    }
  },
  methods: {
    dismissError (field) {
      this.stateInvoice.formValidation[field].isValid = false;
      this.stateInvoice.formValidation[field].errorMessage = "";
    },
    addVendor(alias) {
      this.vendorListStatus = false;
      // set Vendor Name in Vendor Form
      store.state.vendors.vVendorName = this.vQuery;
      this.stateStock.vendorModalBox = true;
      store.state.invoices.vendorModalBox = true;
      // open modal
      let modalOpts = {
        isActiveStatus: true,
        type: 0,
        alias: alias,
      };
      store.dispatch(
        "showSecondModalBox",
        modalOpts
      );
    },
    getQuery() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        if(this.vQuery.length >= this.minChar){
          this.vendorAutoComplete();
        } else {
          this.vendorListStatus = false,
          this.clearQuery()
        }
      }, 1000);
    },
    async vendorAutoComplete() {
      store.commit("LOADING", { isActiveStatus: true});
      let { data } = await window.$http.axios.get(
        `inventory/api/vendors/autocomplete?brand_hash=${this.brandHash}&vendor=${this.vQuery}`
      );
      this.vQueryList = data;
      this.vendorListStatus = true;
      store.commit("LOADING", { isActiveStatus: false});
    },
    clearQuery() {
      this.vQuery = "";
      this.vQuery = [];
      this.vendorListStatus = false;
    },
    selectItem(name, hash, amount, unitName, unitHash) {
      this.vSelectedText = name;
      this.vSelectedHash = hash;
      this.vSelectedAmount = amount;
      this.vSelectedUnitName = unitName;
      this.vSelectedUnitHash = unitHash;
      this.$emit("clickedSelectItem", { name, hash, amount, unitName, unitHash });
      this.clearQuery();
    }
  }
};
</script>

<style scoped>
.autocomplete-wrapper {
  position: relative;
  width: 100%;
}
.autocomplete-list-wrapper {
  z-index: 30;
  position: absolute;
  right: 0;
  left: 0;
  top: 36px;
  min-width: 240px;
  background: #fff;
  border-radius: var(--rounded-lg);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid var(--indigo-700);
  border-bottom-width: 4px;
  box-shadow: var(--shadow-lg);
  box-sizing: border-box;
}
.autocomplete-add {
  display: block;
  width: 100%;
  padding: 1rem;
  /* font-size: 1.6rem; */
  font-weight: 600;
  text-align: left;
  background: #fff;
  border: none;
  outline: none;
  cursor: pointer;
}
.autocomplete-list {
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid var(--gray-300);
}
.autocomplete-list li {
  padding: 1rem;
  /* font-size: 1.4rem; */
  font-weight: 600;
  cursor: pointer;
  text-align: left;
}
.autocomplete-add:hover,
.autocomplete-list li:hover {
  background: var(--indigo-700);
  color: #fff;
}
.no-result p {
  padding: 1rem;
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--gray-600);
}
</style>