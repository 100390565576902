<template>
  <div class="form-button-actions">
    <button class="btn-secondary" @click="cancel()">Cancel</button>
    <button class="btn-primary" @click="updateProduct()" v-if="getFormType == 1">Update</button>
    <button class="btn-primary" @click="saveProduct()" v-else-if="getFormType == 0">Save</button>
  </div>
</template>

<script>
import store from "@/store";
import { mapState, mapGetters } from "vuex";

export default {
  name: "ProductsFormButtons",
  data() {
    return {
      menuHash: "",
      storeHash: "",
      menuType: "",
    };
  },
  created() {
    this.menuHash = this.stateMenus.vSelectedTab;
    this.storeHash = this.stateStores.current.storeHash;
    this.menuType = this.getMenuType(this.menuHash);
  },
  computed: {
    ...mapState({
      stateStores: "stores",
      stateProducts: "products",
      stateMenus: "menus"
    }),
    ...mapGetters(["getFormType", "getMenuType"])
  },
  methods: { 
    cancel() {
      store.dispatch("closeProductModalBox");
    },
    updateProduct() {
      let productHash = this.stateProducts.product.phash;
      if(this.$parent.$parent.$refs.productsForm.checkForm()){
        store.dispatch("updateProduct", {
          storeHash: this.storeHash,
          menuHash: this.menuHash,
          productHash
        })
      }
    },
    saveProduct() {
      if(this.$parent.$parent.$refs.productsForm.checkForm()){
        store.dispatch("saveProduct", {
          storeHash: this.storeHash,
          menuHash: this.menuHash,
          menuType: this.menuType,
        })
      }
    }
  }
};
</script>
