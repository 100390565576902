<template>
    <div>
        <div class="form-button-actions">
            <button class="btn-secondary" @click="cancel()">Cancel</button>
            <button class="btn-primary" @click="updateVendor()" v-if="getFormType == 1">Update</button>
            <button class="btn-primary" @click="saveVendor()" v-else>Save</button>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import store from "@/store";

export default {
    name: "VendorFormButton",
    data() {
        return {
            brandHash: ""
        }
    },
    created() {
        this.brandHash = this.stores.current.brandHash;
    },
    computed: {
        ...mapState(["vendors", "stores"]),
        ...mapGetters(["getFormType"])
    },
    methods: {
        cancel() {
            store.dispatch("closeVendorModalBox");
        },
        updateVendor() {
            const vendorHash = store.state.vendors.vVendorHash;
            if(this.$parent.$parent.$refs.vendorForm.checkForm()) {
                store.dispatch("updateVendor", {
                    vendorHash,
                    brandHash: this.brandHash,
                });
            }
        },
        saveVendor() {
            if(this.$parent.$parent.$refs.vendorForm.checkForm()) {
                store.dispatch("saveVendor", this.brandHash);
            }
        }
    }
}
</script>