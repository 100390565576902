import invoices from '../invoices';
import optionItems from '../options';
import common from '../index.js';

const getStocksDefaultState = () => {
  return {
    status: true,
    vStockList: [],
    // Stock
    vStockHash: '',
    vStockSKU: '',
    vStockBarcode: '',
    vStockName: '',
    vStockGST: 5,
    vStockPST: 0,
    vStockUnitName: '',
    vStockUnitHash: '',
    vStockUnitPrice: '',
    selectedRow: 0,
    vendorModalBox: false,
    // Transaction
    transactionList: [],
    page: 1,
    // Vendor Assignment
    vVendorList: [],
    // Unit Multiplier
    vUnitList: [],
    // Validation
    formValidation: {
      vStockSKU: { isValid: false, errorMessage: '' },
      vStockBarcode: { isValid: false, errorMessage: '' },
      vStockName: { isValid: false, errorMessage: '' },
      vStockGST: { isValid: false, errorMessage: '' },
      vStockPST: { isValid: false, errorMessage: '' },
      // vStockUnitName: { isValid: false, errorMessage: '' },
      vStockUnitHash: { isValid: false, errorMessage: '' },
      vStockUnitPrice: { isValid: false, errorMessage: '' },
      vVendorList: { isValid: false, errorMessage: '' },
      vUnitList: { isValid: false, errorMessage: '' },
    }
  };
}

const stocks = {
  state: getStocksDefaultState(),
  mutations: {
    RESET_STOCK_FORM(state) {
      state.vStockHash = '';
      state.vStockSKU = '';
      state.vStockBarcode = '';
      state.vStockName = '';
      state.vStockGST = 0;
      state.vStockPST = 0;
      state.vStockUnitHash = '';
      state.vStockUnitName = '';
      state.vStockUnitPrice = '';
      state.transactionList = [];
      state.page = 1;
      state.vVendorList = [];
      state.vUnitList = [];
      state.selectedRow = 0;
      state.formValidation = {
        vStockSKU: { isValid: false, errorMessage: '' },
        vStockBarcode: { isValid: false, errorMessage: '' },
        vStockName: { isValid: false, errorMessage: '' },
        vStockGST: { isValid: false, errorMessage: '' },
        vStockPST: { isValid: false, errorMessage: '' },
        // vStockUnitName: { isValid: false, errorMessage: '' },
        vStockUnitHash: { isValid: false, errorMessage: '' },
        vStockUnitPrice: { isValid: false, errorMessage: '' },
        vVendorList: { isValid: false, errorMessage: '' },
        vUnitList: { isValid: false, errorMessage: '' },
      }
    },
    GET_STOCK(state, value) {
      Object.assign(state, {
        vStockHash: value.stock_hash,
        vStockSKU: value.stock_sku,
        vStockBarcode: value.stock_barcode,
        vStockName: value.stock_name,
        vStockGST: value.stock_tax1,
        vStockPST: value.stock_tax2,
        vStockUnitHash: value.unit_hash,
        vStockUnitName: value.unit_name,
        vStockUnitPrice: value.stock_unit_price,
        vVendorList: value.stock_vendors,
        vUnitList: value.stock_unit_multipliers,
      })
    },
    ADD_STOCK(state, value) {
      if(state.vStockList.length > 0) {
        state.vStockList.unshift(value);
      } else {
        state.vStockList = [value];
      }
    },
    UPDATE_STOCK(state, value) {
      let sIndex = state.vStockList.findIndex(item => item.stock_hash === value.stock_hash);
      state.vStockList[sIndex] = value;
      state.vStockList = [...state.vStockList];
    },
    DELETE_STOCK(state, stockHash) {
      state.vStockList = state.vStockList.filter(item => item.stock_hash !== stockHash);
    },
    // UNIT MULTIPLIER
    SET_UNIT_LIST(state, value) {
      state.vUnitList = value;
      state.vUnitList.map(item => {
        item.unit_multiplier = 0;
      })
    },
    SET_UNIT_LIST_UPDATE(state) {
      // if unit list does not have the selected unit, add it
      let isExist = state.vUnitList.find(item => item.unit_hash === state.vStockUnitHash);
      if(!isExist) {
        state.vUnitList.unshift({
          unit_hash: state.vStockUnitHash,
          unit_name: state.vStockUnitName,
          unit_multiplier: state.vStockUnitPrice,
        });
      } else {
        // if unit list has the default unit, update the unit multiplier
        let index = state.vUnitList.findIndex(item => item.unit_hash === state.vStockUnitHash);
        state.vUnitList[index].unit_multiplier = state.vStockUnitPrice;
      }
    },
    UPDATE_VENDOR_IN_STOCK(state, value) {
      console.log(value);
      let idx = state.vVendorList.length;
        state.vVendorList[idx-1] = {
          vendor_hash: value.hash,
          vendor_name: value.name,
        };
      // console.log(idx);
      // if(idx > -1) {
      //   state.vVendorList[idx] = {
      //     vendor_hash: value.hash,
      //     vendor_name: value.name,
      //   };
      // } else {
      //   state.vVendorList.push({
      //     vendor_hash: value.hash,
      //     vendor_name: value.name,
      //   });
      // }
      console.log(state.vVendorList);
    }
  },
  actions: {
      closeStockModalBox({ commit }) {
        commit("RESET_STOCK_FORM");
        if(invoices.state.stockModalBox || optionItems.state.stockModalBox) {
          invoices.state.stockModalBox = false;
          commit("CLOSE_SECOND_MODALBOX");
        } else {
          commit("RESET_MODALBOX");
        }
      },
      async getStockList({ state, commit }, {
        brandHash,
        storeHash,
      }) {
        commit("LOADING", { isActiveStatus: true});
        try {
          let { data } = await window.$http.axios.get('inventory/api/stocks?brand_hash=' + brandHash + '&store_hash=' + storeHash);
          if(data.stocks) {
            state.vStockList = data.stocks;
          } else {
            state.vStockList = [];
            commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: data.message });
          }
        } catch(error) {
          state.vStockList = [];
          commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: error.message });
        }
        commit("LOADING", { isActiveStatus: false});
      },
      async getTransactionList({ state, commit }, { stockHash, storeHash, brandHash, page }) {
        state.transactionList = [];
        commit("LOADING", { isActiveStatus: true});
        try {
          let { data } = await window.$http.axios.get(
            `inventory/api/stocks/transactions/${stockHash}?brand_hash=${brandHash}&store_hash=${storeHash}&page=${page}`
          );
          if(data.status) {
            state.transactionList = data.transactions;
          }
        } catch (error) {
          state.transactionList = [];
        }
        commit("LOADING", { isActiveStatus: false});
      },
      async saveStock({ state, commit, dispatch }, { brandHash, storeHash }) {
        const FormData = require('form-data');
        const formData = new FormData();
        let stock = {
          stock_hash: state.vStockHash,
          stock_name: state.vStockName,
          stock_sku: state.vStockSKU,
          stock_barcode: state.vStockBarcode,
          stock_tax1: Number(state.vStockGST),
          stock_tax2: Number(state.vStockPST),
          stock_unit_price: Number(state.vStockUnitPrice),
          unit_hash: state.vStockUnitHash,
          stock_vendors: state.vVendorList,
          stock_unit_multipliers: state.vUnitList,
        }
        formData.append('stock', JSON.stringify(stock));
        const config = {
          method: 'post',
          url: `inventory/api/stocks?brand_hash=${brandHash}&store_hash=${storeHash}`,
          data: formData
        }
        commit("LOADING", { isActiveStatus: true});
        try {
          // check if the vendor is the same as the invoice vendor
          // if(common.state.location == "invoices") {
          //   const vendorMatch = state.vVendorList.some(vendor => vendor.vendor_hash === invoices.state.vCompanyHash);
          //   if (!vendorMatch) {
          //     commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: "Vendor is not as same as invoice vendor!" });
          //     commit("LOADING", { isActiveStatus: false});
          //     return;
          //   }
          // }
          let { data } = await window.$http.axios(config);
          if(data.status) {
            // need to detect where the page is (invoices/ option-items/ stocks)
            if(common.state.location == "invoices") {
              commit("UPDATE_STOCK_IN_INVOICE", data.stock)
            } else if(common.state.location == "option-items") {
              commit("UPDATE_STOCK_IN_OPTION", data.stock)
            } else if(common.state.location == 'stock-audits') {
              commit("UPDATE_STOCK_IN_AUDIT", data.stock)
            }  else {
              commit("ADD_STOCK", data.stock);
              dispatch("closeStockModalBox");
            }
            commit("CLOSE_SECOND_MODALBOX");
            commit("NOTIFICATION_MESSAGE", { status: 1, title: "Saved", message: "Stock has been saved successfully!" });
          } else {
            commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: data.message });
          }
        } catch (error) {
          commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: error.message });
        }
        commit("LOADING", { isActiveStatus: false});
      },
      async updateStock({ state, commit, dispatch }, { stockHash, brandHash, storeHash }) {
        const FormData = require('form-data');
        const formData = new FormData();
        let stock = {
          stock_hash: state.vStockHash,
          stock_name: state.vStockName,
          stock_sku: state.vStockSKU,
          stock_barcode: state.vStockBarcode,
          stock_tax1: Number(state.vStockGST),
          stock_tax2: Number(state.vStockPST),
          stock_unit_price: Number(state.vStockUnitPrice),
          unit_hash: state.vStockUnitHash,
          stock_vendors: state.vVendorList,
          stock_unit_multipliers: state.vUnitList,
        }
        formData.append('stock', JSON.stringify(stock));
        const config = {
          method: 'put',
          url: `inventory/api/stocks/${stockHash}?brand_hash=${brandHash}&store_hash=${storeHash}`,
          data: formData
        }
        commit("LOADING", { isActiveStatus: true});
        try {
          // check if the vendor is the same as the invoice vendor
          if(common.state.location == "invoices") {
            const vendorMatch = state.vVendorList.some(vendor => vendor.vendor_hash === invoices.state.vCompanyHash);
            if (!vendorMatch) {
              commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: "Vendor is not as same as invoice vendor!" });
              commit("LOADING", { isActiveStatus: false});
              return;
            }
          }
          let { data } = await window.$http.axios(config);
          if(data.status) {
            commit('UPDATE_STOCK', data.stock);
            commit("NOTIFICATION_MESSAGE", { status: 1, title: "Updated", message: "Stock has been updated successfully!" });
            // close modal box
            dispatch("closeStockModalBox");
          } else {
            commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: data.message });
          }
        } catch (error) {
          commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: error.message });
        }
        commit("LOADING", { isActiveStatus: false});
      },
      async deleteStock({ commit }, { stockHash, brandHash, storeHash }) {
        commit("LOADING", { isActiveStatus: true});
        try {
          let { data } = await window.$http.axios.delete(`inventory/api/stocks/${stockHash}?brand_hash=${brandHash}&store_hash=${storeHash}`);
          if(data.status) {
            commit("DELETE_STOCK", stockHash);
            commit("NOTIFICATION_MESSAGE", { status: 1, title: "Deleted", message: "Stock has been deleted successfully!" });
          } else {
            commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: data.message });
          }
        } catch (error) {
          commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: error.message });
        }
        commit("LOADING", { isActiveStatus: false});
      },
  },
  getters: {
    getPage: state => state.page,
  }
}

export default stocks;