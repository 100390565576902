import axios from "axios";

const getStorageToken = localStorage.getItem("token");
const token = getStorageToken !== null ? getStorageToken.token : "";

export default axios.create({
  // baseURL: "https://api.androvega.ca/",
  baseURL: "https://api.laruota.ca/",
  // baseURL: "https://vanapi.androvega.ca/",
  headers: {
    common: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}` || "",
    },
  },
});
