<template>
  <div id="product">
    <div class="product-header">
      <h2 class="flex-grow">Products</h2>
      <div>
        <button class="btn-primary" @click="showProductModal(0, 'newProduct')">
          <span>Create Product</span>
        </button>
        <button class="ml-2 btn-primary" @click="showProductModal(0, 'newCouponItem')">
          <span>Create Coupon Item</span>
        </button>
        <button class="ml-2 btn-primary" @click="showProductModal(0, 'newBag')">
          <span>Create Bag</span>
        </button>
      </div>
    </div>
    <div class="tabs-wrapper">
      <TabsNav @goTo="setSelectedTab" :list="stateMenus.vTabMenu" :selected="stateMenus.vSelectedTab" :tag="true">
        <Tab v-for="productList in stateMenus.vTabMenuProducts" :isSelected="stateMenus.vSelectedTab === productList.id"
          :key="productList.id">
          <Panel :title="false">
            <template #row> 
              <draggable
                v-model="productList.products"
                handle=".moveclass" 
                animation="150" 
                sort="true" 
                :group="{ put: false }"
                @end="updateProductOrder"
              >
                <div class="item" v-for="(item, idx) in productList.products" :key="idx">
                  <div class="menu-item">
                    <div 
                      class="image" 
                      :style="{backgroundImage: getImgPathStyle(item.p_picture)}"
                    ></div>
                    <div class>
                      <div class="menu-name">
                        <div>
                          {{ item.p_name }}
                          <span class="label" v-if="item.status === '0'">Hidden</span>
                        </div>
                        <div class="mt-2">
                          <span class="label coupon" v-if="item.ptype === '1'">Coupon Product</span>
                          <span class="label bag" v-if="item.ptype === '3'">Bag Product</span>
                        </div>
                      </div>
                    </div>
                    <div class="sync" :class="{ 'not-sync': item.sync_pos_status == '0' }"></div>
                  </div>
                  <div class="item-actions">
                    <button @click.prevent="openCalc(item.phash)" v-if='item.ptype == "0"'>
                      <!-- <i class="fontello-icon"><span>&#xf0ca;</span></i> -->
                      <i class="flaticon-info"></i>
                    </button>
                    <button @click.prevent="changeStatus(item, 'status')">
                      <i class="flaticon-show" :class="{ close: item.status === '0' }"></i>
                    </button>
                    <button @click.prevent="editProductItem(item.phash)">
                      <i class="flaticon-edit"></i>
                    </button>
                    <button class="moveclass">
                      <img src="../../assets/images/vertical-arrow.png" alt="" class="arrow">
                    </button>
                    <button @click="deleteProductItem(item.phash)">
                      <i class="flaticon-delete"></i>
                    </button>
                    <button @click.prevent="syncProduct(item)" class="sync-time">
                      <i class="flaticon-sync"></i>
                      <span v-if="item.sync_pos_time">Last Update:<br>{{ item.sync_pos_time }}</span>
                      <span v-else>Not Synced Yet</span>
                    </button>
                  </div>
                  <div class="confirm" v-if="isDeletePanelActive && deleteProductHash==item.phash">
                    <p>Are you sure you want to delete this product?</p>
                    <div>
                      <button @click="deleteYes(item.phash)">Yes</button>
                      <button @click="deleteNo()">No</button>
                    </div>
                  </div>
                </div>
              </draggable>
            </template>
          </Panel>
        </Tab>
      </TabsNav>
    </div>

    <ModalBox :alias="'newProduct'">
      <template #modalTitle>New Product</template>
      <template #modalBody>
        <ProductsForm ref="productsForm" />
      </template>
      <template #modalFooter>
        <ProductsFormButtons />
      </template>
    </ModalBox>

    <ModalBox :alias="'editProduct'">
      <template #modalTitle>Edit Product Item</template>
      <template #modalBody>
        <ProductsForm ref="productsForm" />
      </template>
      <template #modalFooter>
        <ProductsFormButtons />
      </template>
    </ModalBox>

    <ModalBox :alias="'newCouponItem'">
      <template #modalTitle>New Coupon Item</template>
      <template #modalBody>
        <ProductsForm :productType="1" ref="productsForm" />
      </template>
      <template #modalFooter>
        <ProductsFormButtons />
      </template>
    </ModalBox>

    <ModalBox :alias="'editCouponItem'">
      <template #modalTitle>Edit Coupon Item</template>
      <template #modalBody>
        <ProductsForm :productType="1" ref="productsForm" />
      </template>
      <template #modalFooter>
        <ProductsFormButtons />
      </template>
    </ModalBox>

    <ModalBox :alias="'newBag'">
      <template #modalTitle>New Bag</template>
      <template #modalBody>
        <ProductsForm :productType="3" ref="productsForm" />
      </template>
      <template #modalFooter>
        <ProductsFormButtons />
      </template>
    </ModalBox>

    <ModalBox :alias="'editBagItem'">
      <template #modalTitle>Edit Bag Item</template>
      <template #modalBody>
        <ProductsForm :productType="3" ref="productsForm" />
      </template>
      <template #modalFooter>
        <ProductsFormButtons />
      </template>
    </ModalBox>

    <ModalBox :alias="'openCalc'">
      <template #modalTitle>Product Calculation</template>
      <template #modalBody>
        <ProductCalculation />
      </template>
      <template #modalFooter>
        <ProductsFormButtons />
      </template>
    </ModalBox>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import TabsNav from "@/widgets/Tabs/TabsNav";
import Tab from "@/widgets/Tabs/Tab";
import Panel from "@/widgets/Panel";

import ProductsForm from "./ProductsForm";
import ProductsFormButtons from "./ProductsFormButtons";
import ProductCalculation from "./widgets/Calculation/ProductCalculation.vue";
import store from "@/store";
import draggable from "vuedraggable";

export default {
  name: "Products",
  data() {
    return {
      isDeletePanelActive: false,
      deleteProductHash: "",
    };
  },
  components: {
    TabsNav,
    Tab,
    Panel,
    ProductsForm,
    ProductsFormButtons,
    ProductCalculation,
    draggable,
  },
  computed: {
    ...mapState({
      stateMenus: "menus",
      stateCommon: "common",
      stateStores: "stores",
      stateProducts: "products",
    }),
    ...mapGetters(["getMenuProducts", "getMenuType" ]),
  },
  created() {
    store.dispatch("getTabsMenu");
  },
  methods: {
    showProductModal(type, alias, productHash) {
      let modalOpts = {
        isActiveStatus: true,
        type,
        alias
      };
      if (productHash) {
        modalOpts["hash"] = productHash;
      } else {
        this.stateProducts.pictureControl = 0;
      }
      store.dispatch(
        "showModalBox",
        Object.assign(store.state.common.modalBox, modalOpts)
      );
    },
    getImgPathStyle(imgName) {
      return `url("${imgName}")`;
    },
    setSelectedTab(tab) {
      this.stateMenus.vSelectedTab = tab;
    },
    changeStatus(product, props) {
      const $self = this;
      let newstat = product[props] == 1 ? "0" : "1";
      const params = new URLSearchParams();
      params.append("phash", product.phash);
      params.append("newstat", newstat);
      this.axios
        .post(
          `product/changestatus?s=${this.stateStores.current.storeHash}`,
          params
        )
        .then((response) => {
          if (response.data.status) {
            product.sync_pos_status = "0";
            product[props] = newstat;
          } else {
            $self.stateCommon.notification.notificationList.push({
              status: 1,
              title: "Success",
              message: response.data.message
            });
          }
        })
        .catch((error) => {
          console.error(error);
          $self.stateCommon.notification.notificationList.push({
            status: -1,
            title: "Error",
            message: error
          });
        });
    },
    editProductItem(productHash) {
      let menuHash = this.stateMenus.vSelectedTab;
      let storeHash = this.stateStores.current.storeHash;
      store.dispatch("getProduct", { storeHash, menuHash, productHash })
      .then(() => {
        switch (Number(this.stateProducts.product.ptype)) {
            case 0:
              this.showProductModal(1, "editProduct", productHash);
              return;
            case 1:
              this.showProductModal(1, "editCouponItem", productHash);
              return;
            case 2:
              this.showProductModal(1, "editProduct", productHash);
              return;
            case 3:
              this.showProductModal(1, "editBagItem", productHash);
              return;
          }
      });
    },
    updateProductOrder() {
      // get new orders with phash
      let menuIdx = this.stateMenus.vTabMenu.findIndex((item) => item.id === this.stateMenus.vSelectedTab);
      let menuHash = this.stateMenus.vSelectedTab;
      const productList = [];
      this.stateMenus.vTabMenuProducts[menuIdx].products.map((item) => {
        productList.push({
          phash: item.phash
        });
      });
      store.dispatch('updateProductOrder', { 
        storeHash: this.stateStores.current.storeHash,
        menuHash, 
        menuType: this.getMenuType(menuHash),
        products: productList 
      });
    },
    deleteProductItem(productHash) {
      this.deleteProductHash = productHash;
      this.isDeletePanelActive = true;
    },
    deleteYes(productHash) {
      let storeHash = this.stateStores.current.storeHash;
      let menuHash = this.stateMenus.vSelectedTab;
      let menuType = this.getMenuType(menuHash);
      store.dispatch("deleteProduct", { storeHash, menuHash, menuType, productHash });
      this.deleteNo();
    },
    deleteNo() {
      this.deleteProductHash = "";
      this.isDeletePanelActive = false;
    },
    currentTime() {
      const pad = (num) => (num < 10 ? `0${num}` : num);
      let now = new Date();
      let date = `${now.getFullYear()}-${pad(now.getMonth() + 1)}-${pad(now.getDate())}`;
      let time = `${pad(now.getHours())}:${pad(now.getMinutes())}:${pad(now.getSeconds())}`;
      return `${date} ${time}`;
    },
    async syncProduct(product) {
      store.commit('LOADING', { isActiveStatus: true });
      try {
        let { data } = await this.axios.get(`sync/pos/product?p=${product.phash}`);
        if(data.status) {
          store.commit('NOTIFICATION_MESSAGE', { status: 1, title: 'POS Sync Completed', message: data.message });
          product.sync_pos_time = this.currentTime();
          product.sync_pos_status = "1";
        } else {
          store.commit('NOTIFICATION_MESSAGE', { status: -1, title: 'Error POS Sync', message: data.message });
          product.sync_pos_status = "0";
        }
      } catch(error)  {
        store.commit('NOTIFICATION_MESSAGE', { status: -1, title: 'Error', message: error.message });
        product.sync_pos_status = "0";
      }
      store.commit('LOADING', { isActiveStatus: false });
    },
    async openCalc(productHash) {
      let menuHash = this.stateMenus.vSelectedTab;
      let storeHash = this.stateStores.current.storeHash;
      store.dispatch("getProduct", { storeHash, menuHash, productHash });
      this.showProductModal(2, "openCalc", productHash);
      store.dispatch("productCalculation", productHash);
    },
  }
};
</script>

<style lang="css" scoped>
.product-header {
  display: flex;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 1px;
  border-color: #F3F4F6;
}
.flex-grow {
  flex-grow: 1;
}
.product-title {
  flex-grow: 1;
}
button.ml-2 {
  margin-left: 0.5rem;
}
</style>
