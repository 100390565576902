import invoices from './invoices';
import common from './index';

const getVendorsDefaultState = () => {
    return {
        vVendorList: [],
        vVendorHash: '',
        vVendorName: '',
        vVendorPhone: '',
        vVendorEmail: '',
        vVendorAddress: '',
        // Validation
        formValidation: {
            vVendorName: { isValid: false, errorMessage: "" },
            vVendorPhone: { isValid: false, errorMessage: "" },
            vVendorEmail: { isValid: false, errorMessage: "" },
            vVendorAddress: { isValid: false, errorMessage: "" },
        },
    }
}

const vendors = {
    state: getVendorsDefaultState(),
    mutations: {
        RESET_VENDOR_FORM(state) {
            state.vVendorHash = '';
            state.vVendorName = '';
            state.vVendorPhone = '';
            state.vVendorEmail = '';
            state.vVendorAddress = '';
            state.formValidation.vVendorName = { isValid: false, errorMessage: "" };
            state.formValidation.vVendorPhone = { isValid: false, errorMessage: "" };
            state.formValidation.vVendorEmail = { isValid: false, errorMessage: "" };
            state.formValidation.vVendorAddress = { isValid: false, errorMessage: "" };
        },
        GET_VENDOR(state, value) {
            Object.assign(state, {
                vVendorHash: value.hash,
                vVendorName: value.name,
                vVendorPhone: value.phone,
                vVendorEmail: value.email,
                vVendorAddress: value.address,
            })
        },
        ADD_VENDOR(state, value) {
            if(state.vVendorList.length > 0 || state.vVendorList != null) {
                state.vVendorList.unshift(value);
            } else {
                state.vVendorList = [value];
            }
        },
        UPDATE_VENDOR(state, value) {
            let vIndex = state.vVendorList.findIndex(item => item.hash === value.hash);
            state.vVendorList[vIndex] = value;
            state.vVendorList = [...state.vVendorList];
        },
        DELETE_VENDOR(state, vendorHash) {
            state.vVendorList = state.vVendorList.filter(item => item.hash !== vendorHash);
        }
    },
    actions: {
        closeVendorModalBox({ commit }) {
            commit("RESET_VENDOR_FORM");
            if(invoices.state.vendorModalBox) {
                invoices.state.vendorModalBox = false;
                commit("CLOSE_SECOND_MODALBOX");
            } else {
                commit("RESET_MODALBOX");
            }
        },
        async getVendorList({ state, commit }, brandHash) {
            commit("LOADING", { isActiveStatus: true});
            try {
                let { data } = await window.$http.axios.get(`inventory/api/vendors?brand_hash=${brandHash}`);
                if(data.vendors) {
                    state.vVendorList = data.vendors;
                } else {
                    state.vVendorList = [];
                    commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: data.message });
                }
            } catch(error) {
                state.vVendorList = [];
                commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: error.message });
            }
            commit("LOADING", { isActiveStatus: false});
        },
        async saveVendor({ state, commit, dispatch }, brandHash) {
            const FormData = require('form-data');
            const formData = new FormData();
            let vendor = {
                name: state.vVendorName,
                hash: state.vVendorHash,
                phone: state.vVendorPhone,
                email: state.vVendorEmail,
                address: state.vVendorAddress,
            }
            formData.append('vendor', JSON.stringify(vendor));
            const config = {
                method: 'post',
                url: `inventory/api/vendors?brand_hash=${brandHash}`,
                data: formData
            }
            commit("LOADING", { isActiveStatus: true});
            try {
                let { data } = await window.$http.axios(config);
                if(data.status) {
                    if(common.state.location == "invoices") {
                        commit("UPDATE_VENDOR_IN_INVOICE", data.vendor)
                        commit("CLOSE_SECOND_MODALBOX");
                    } else if(common.state.location == "stocks") {
                        commit("UPDATE_VENDOR_IN_STOCK", data.vendor)
                        commit("CLOSE_SECOND_MODALBOX");
                    } else {
                        commit("ADD_VENDOR", data.vendor);
                        dispatch("closeVendorModalBox");
                    }
                    commit("NOTIFICATION_MESSAGE", { status: 1, title: "Saved", message: "Vendor has been saved successfully." });
                } else {
                    commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: data.message });
                }
            } catch (error) {
                console.error(error);
                commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: error.message });
            }
            commit("LOADING", { isActiveStatus: false});
        },
        async updateVendor({ state, commit, dispatch }, {
            vendorHash,
            brandHash,
        }) {
            const FormData = require('form-data');
            const formData = new FormData();
            let vendor = {
                name: state.vVendorName,
                hash: state.vVendorHash,
                phone: state.vVendorPhone,
                email: state.vVendorEmail,
                address: state.vVendorAddress,
            }
            formData.append('vendor', JSON.stringify(vendor));
            const config = {
                method: 'put',
                url: `inventory/api/vendors/${ vendorHash }?brand_hash=${brandHash}`,
                data: formData
            }
            commit("LOADING", { isActiveStatus: true});
            try {
                let { data } = await window.$http.axios(config);
                if(data.status) {
                    // if(common.state.location == "invoices") {
                    //     commit("UPDATE_VENDOR_IN_MODAL", data.vendor)
                    // }
                    commit('UPDATE_VENDOR', data.vendor);
                    commit("NOTIFICATION_MESSAGE", { status: 1, title: "Updated", message: "Vendor has been updated successfully." });
                    // close modal box
                    dispatch("closeVendorModalBox");
                } else {
                    commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: data.message });
                }
            } catch (error) {
                commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: error.message });
            }
            commit("LOADING", { isActiveStatus: false});
        },
        async deleteVendor({ commit }, {
            vendorHash,
            brandHash,
        }) {
            commit("LOADING", { isActiveStatus: true});
            try {
                let { data } = await window.$http.axios.delete(`inventory/api/vendors/${vendorHash}?brand_hash=${brandHash}`);
                if(data.status) {
                    commit("DELETE_VENDOR", vendorHash);
                    commit("NOTIFICATION_MESSAGE", { status: 1, title: "Deleted", message: "Vendor has been deleted successfully." });
                } else {
                    commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: data.message });
                }
            } catch(error) {
                commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: error.message });
            }
            commit("LOADING", { isActiveStatus: false});
        }
    },
}

export default vendors;