import menus from "./menus";
import stores from "./stores";

const getUsersDefaultState = () => {
  return {
    status: true,
    name: "",
    passcode: "",
    uhash: "",
    email: "",
    password: "",
    u_type: 3,
    shift_open_status: 0,
    shift_close_status: 0,
    report_shift_summary: 0,
    report_shift_driver: 0,
    report_general: 0,
    report_map: 0,
    report_menu: 0,
    stat_edit: 0,
    stat_delete: 0,
    formValidation: {
      vUserName: { isValid: false, errorMessage: "" },
      vUserPasscode: { isValid: false, errorMessage: "" },
    }
  };
};

const users = {
  state: getUsersDefaultState(),
  mutations: {
    RESET_FORM_USERS(state) {
      Object.assign(state, getUsersDefaultState());
    },
    SET_CURRENT_USER(state, userItem) {
      Object.assign(state, {
        name: userItem.name,
        passcode: userItem.passcode,
        uhash: userItem.uhash,
        email: userItem.email,
        password: userItem.password,
        u_type: parseInt(userItem.u_type),
        shift_open_status: parseInt(userItem.shift_open_status),
        shift_close_status: parseInt(userItem.shift_close_status),
        report_shift_summary: parseInt(userItem.report_shift_summary),
        report_shift_driver: parseInt(userItem.report_shift_driver),
        report_general: parseInt(userItem.report_general),
        report_map: parseInt(userItem.report_map),
        report_menu: parseInt(userItem.report_menu),
        stat_edit: parseInt(userItem.stat_edit),
        stat_delete: parseInt(userItem.stat_delete),
      });
    },
    ADD_USER(payload) {
      menus.state.posusers.push(payload);
    },
    UPDATE_USER(payload) {
      let index = menus.state.posusers.findIndex((user) => user.uhash === payload.uhash);
      Object.assign(menus.state.posusers[index], payload);
    },
    DELETE_USER(uhash) {
      let index = menus.state.posusers.findIndex((user) => user.uhash === uhash);
      menus.state.posusers.splice(index, 1);
    }
  },
  actions: {
    closeUserModalBox({ commit }) {
      commit("RESET_FORM_USERS");
      commit("RESET_MODALBOX");
    },
    async saveUser({state, commit, dispatch}) {
      commit("LOADING", { isActiveStatus: true });
      const params = new URLSearchParams();
      params.append("name", state.name);
      params.append("passcode", state.passcode);
      params.append("email", state.email);
      params.append("password", state.password);
      params.append("u_type", state.u_type);
      params.append("shift_open_status", state.shift_open_status);
      params.append("shift_close_status", state.shift_close_status);
      params.append(
        "report_shift_summary",
        state.report_shift_summary
      );
      params.append("report_shift_driver", state.report_shift_driver);
      params.append("report_general", state.report_general);
      params.append("report_map", state.report_map);
      params.append("report_menu", state.report_menu);
      params.append("stat_edit", state.stat_edit);
      params.append("stat_delete", state.stat_delete);
      const config = {
        method: "post",
        url: `posuser/addposuser?s=${stores.state.current.storeHash}`,
        data: params,
      }
      try {
        let { data } = await window.$http.axios(config);
        if (data.status) {
          menus.state.posusers.unshift(data.user);
          commit("ADD_USER", data.user);
          commit("NOTIFICATION_MESSAGE", { status: 1, title: "Saved", message: "User has been saved successfully." });
          dispatch("closeUserModalBox");
        } else {
          commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: data.message });
        }
      } catch(error) {
        commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: error.message });
      }
      commit("LOADING", { isActiveStatus: false });
    },
    async updateUser ({state, commit, dispatch}) {
      commit("LOADING", { isActiveStatus: true });
      const params = new URLSearchParams();
      params.append("name", state.name);
      params.append("passcode", state.passcode);
      params.append("email", state.email);
      params.append("password", state.password);
      params.append("u_type", state.u_type);
      params.append("shift_open_status", state.shift_open_status);
      params.append("shift_close_status", state.shift_close_status);
      params.append(
        "report_shift_summary",
        state.report_shift_summary
      );
      params.append("report_shift_driver", state.report_shift_driver);
      params.append("report_general", state.report_general);
      params.append("report_map", state.report_map);
      params.append("report_menu", state.report_menu);
      params.append("stat_edit", state.stat_edit);
      params.append("stat_delete", state.stat_delete);

      // https://api.androvega.ca/posuser/updateitem?s=STDEA4359821232&uh=be9c74cb888e7c8b56738aad78f8b842df2db66e42544e7c2e115907
      const config = {
        method: "post",
        url: `posuser/updateitem?s=${stores.state.current.storeHash}&uh=${state.uhash}`,
        data: params,
      }
      try {
        let { data } = await window.$http.axios(config);
        if(data.status) {
          commit("UPDATE_USER", data.user);
          commit("NOTIFICATION_MESSAGE", { status: 1, title: "Saved", message: "User has been saved successfully." });
          dispatch("closeUserModalBox");
        } else {
          commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: data.message });
        }
      } catch(error) {
        commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: error.message });
      }
      commit("LOADING", { isActiveStatus: false });
    },
    async deleteUser({ commit }, uhash) {
      commit("LOADING", { isActiveStatus: true });
      try {
        await window.$http.axios.get(`posuser/deleteitem?s=${stores.state.current.storeHash}&u=${uhash}`);
          commit("DELETE_USER", uhash);
          commit("NOTIFICATION_MESSAGE", { status: 1, title: "Deleted", message: "User has been deleted successfully!" });
      } catch(error) {
        console.error(error);
        commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: error.message });
      }
      commit("LOADING", { isActiveStatus: false });
    }
  },
};

export default users;
