<template>
  <div class="form-button-actions">
    <button class="btn-secondary" @click="cancel()">Cancel</button>
    <button class="btn-primary" @click="updateCoupon()" v-if="getFormType == 1">Update</button>
    <button class="btn-primary" @click="saveCoupon()" v-else>Save</button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";

export default {
  name: "CouponsFormButtons",
  computed: {
    ...mapGetters(["getFormType"])
  },
  methods: {
    cancel() {
      store.commit("RESET_MODALBOX");
      store.commit("RESET_FORM_COUPONS");
    },
    updateCoupon() {
      if(this.$parent.$parent.$refs.couponForm.checkForm()) {
        store.dispatch("updateCoupon");
      }
    },
    saveCoupon() {
      if(this.$parent.$parent.$refs.couponForm.checkForm()) {
        store.dispatch("saveCoupon");
      }
    }
  }
};
</script>
