<template>
  <div class="audit-form">
      <div class="row">
        <div class="column">
          <div class="form-wrapper">
            <div class="form-row">
              <div class="input-group">
                <label for="stockName">Comment</label>
                <div class="input-group-item">
                    <input
                    id="comment"
                    name="comment"
                    type="text"
                    v-model="currentAudit.comment"
                    autocomplete="off"
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table-list editable-table-wrapper">
          <table>
            <thead>
              <tr>
                <th width="40">#</th>
                <th width="40">Stock Name</th>
                <th width="40">Stock Amount</th>
                <th width="40">Stock Unit</th>
                <th width="40" class="table-actions-col"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(field, index) in stateTableFields"
                :key="index"
                @click="selectedRowIndex(index)"
              >
                <td>{{ index + 1 }}</td>
                <td>
                  <div class="write">
                    <div class="form-group">
                      <StockAutoComplete
                        :minChar="3"
                        :tParams="'audit'"
                        :text="field.vStockName"
                        :hash="field.vStockHash"
                        @clickedSelectItem="getStockAudit"
                        autocomplete="nope"
                        v-model="field.vStockName"
                      ></StockAutoComplete>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="write">
                    <input
                      id="stockAmount"
                      name="stockAmount"
                      type="number"
                      min="1"
                      v-model="field.vStockAmount"
                      autocomplete="nope"
                    />
                  </div>
                </td>
                <td>
                  <div class="write">
                    <input
                      id="stockUnitName"
                      name="stockUnitName"
                      type="text"
                      v-model="field.vStockUnit"
                      autocomplete="nope"
                      disabled="true"
                    />
                  </div>
                </td>
                <td>
                  <div class="item-actions">
                    <button @click="deleteRow(field)">
                      <i class="flaticon-delete"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="validation-error"
          v-if="formValidation.vStockName.errorMessage"
        >
          {{ formValidation.vStockName.errorMessage }}
        </div>
        <div class="invoice-total-wrapper">
            <button class="btn-secondary" @click="addRow">
              <i class="flaticon-plus"></i>
              <span>Add Line</span>
            </button>
        </div>
      </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapState } from "vuex";
import StockAutoComplete from "@/widgets/AutoComplete/StockAutoComplete";

export default {
  name: "AuditForm",
  data() {
    return {
      brandHash: "",
      storeHash: "",
    };
  },
  created() {
    this.brandHash = this.stateStore.current.brandHash;
    this.storeHash = this.stateStore.current.storeHash;
  },
  computed: {
    ...mapState({
      stateAudits: "audits",
      stateTableFields: state => state.audits.stockFields,
      currentAudit: state => state.audits.audit,
      stateStore: "stores",
    }),
    formValidation() {
      return store.state.audits.formValidation;
    }
  },
  watch : {
    stateTableFields: {
      handler: function () {
        console.log('stateTableFields changed')
      },
      deep: true
    }
  },
  components: {
    StockAutoComplete,
  },
  methods: {
    // after selecting stock from autocomplete
    getStockAudit(value) {
      this.dismissError("vStockName");
      try {
        const newItem = {
          vRowId: "-1",
          vStockName: value.name,
          vStockAmount: value.amount,
          vStockItemHash: "",
          vStockHash: value.hash,
          vStockUnit: value.unitName,
          vStockUnitHash: value.unitHash,
        };
        Object.assign(
          this.stateTableFields[this.stateAudits.selectedRow],
          newItem
        );
      } catch (error) {
        console.error(error);
      }
    },
    addRow() {
      this.stateTableFields.push({
        vRowId: '-1',
        vStockName: '',
        vStockAmount: parseFloat(0).toFixed(2),
        vStockUnit: '',
        vStockUnitHash: '',
        vStockItemHash: '',
        vStockHash: '',
      });
    },
    deleteRow(field) {
      // delete selected row
      let idx = this.stateTableFields.indexOf(field);
      if (idx > -1) {
        this.stateTableFields.splice(idx, 1);
      }
    },
    dismissError(field) {
      this.formValidation[field].isValid = true;
      this.formValidation[field].errorMessage = "";
    },
    checkForm() {
      console.log('checkForm')
      let checkingStockFields = this.isValidStockFields();
      if (checkingStockFields) {
        return true;
      } else {
        return false;
      }
    },
    isValidStockFields() {
      let isValid = true;
      this.stateTableFields.forEach((field) => {
        if (field.vStockName == "") {
          isValid = false;
          this.formValidation.vStockName.isValid = false;
          this.formValidation.vStockName.errorMessage = "Stock cannot be blank!";
          return;
        }
      });
      return isValid;
    },
    selectedRowIndex(idx) {
      this.stateAudits.selectedRow = idx;
    },
  }
};
</script>

<style scoped>
/* input[type="text"],
input[type="tel"],
input[type="number"],
textarea {
  width: 100%;
} */
textarea {
  min-height: 5rem;
  max-height: 10rem;
  resize: none;
}
</style>