import axios from "@/vue-axios/service.js";

const instance = axios.create({
  // baseURL: "https://api.androvega.ca/",
  baseURL: "https://api.laruota.ca/",
  // baseURL: "https://vanapi.androvega.ca/",
});

const Authorization = ({ email, password }) => {
  return instance
    .get(`login?email=${email}&password=${password}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export default Authorization;

// ertugrul@megabitepizza.com
// eh34908md835
