const state = {
  modalBox: {
    isActiveStatus: false,
    type: 0, // 0: Create, 1: Edit, 2: View (Others)
    alias: "",
    hash: "",
  },
  secondModalBox: {
    isActiveStatus: false,
    type: 0, // 0: Create, 1: Edit
    alias: "",
    hash: "",
  },
  loader: {
    isActiveStatus: false,
  },
  notification: {
    notificationList: [
      // { status: -1, title: "Error Title", message: "Error message" },
      // { status: 0, title: "Info Title", message: "Info message" },
      // { status: 1, title: "Success Title", message: "Success message" },
      // { status: 2, title: "Warning Title", message: "Warning message" },
    ],
  },
  // menu location
  location: "menus",
  vUnitList: []
};

const getResetModalBox = () => {
  return {
    isActiveStatus: false,
    type: 0, // 0: New, 1: Edit
    alias: "",
    hash: "",
  };
};

const mutations = {
  CHAANGE_LOCATION(state, value) {
    state.location = value;
  },
  RESET_MODALBOX(state) {
    Object.assign(state.modalBox, getResetModalBox());
    document.body.classList.remove("modal-open");
  },
  CLOSE_SECOND_MODALBOX(state) {
    Object.assign(state.secondModalBox, {
      isActiveStatus: false,
      type: 1,
      alias: "",
      hash: "",
    });
  },
  MODALBOX_ACTIVATION(state, value) {
    Object.assign(state.modalBox, value.isActiveStatus);
    document.body.classList.add("modal-open");
  },
  SECOND_MODALBOX_ACTIVATION(state, value) {
    Object.assign(state.secondModalBox, value);
  },
  LOADING(state, value) {
    state.loader = value;
  },
  NOTIFICATION_MESSAGE(state, value) {
    state.notification.notificationList.push(value);
  },
};

const actions = {
  async getUnitList({ state, commit }) {
    try {
        let { data } = await window.$http.axios.get('inventory/api/units');
        if(data.status) {
          state.vUnitList = data.units;
        } else {
          state.vUnitList = [];
          commit('NOTIFICATION_MESSAGE', { status: -1, title: "Warning", message: "Unit list not loaded" })
        }
    } catch (err) {
        state.vUnitList = [];
        commit('NOTIFICATION_MESSAGE', { status: -1, title: "Error", message: "Unit list not loaded" })
    }
  },
  showModalBox(context, value) {
    console.log(value);
    if (value.isActiveStatus) {
      context.commit("MODALBOX_ACTIVATION", value);
    } else {
      context.commit("RESET_MODALBOX");
    }
  },
  showSecondModalBox({ commit }, value) {
    if (value.isActiveStatus) {
      commit("SECOND_MODALBOX_ACTIVATION", value);
    } else {
      commit("RESET_MODALBOX");
    }
  }
};

const getters = {
  getModalState: (state) => {
    return state.modalBox.isActiveStatus;
  },
  getFormType: (state) => {
    if (state.secondModalBox.isActiveStatus) {
      return state.secondModalBox.type;
    } else {
      return state.modalBox.type;
    }
  },
  getSecondModalState: (state) => {
    return state.secondModalBox.isActiveStatus;
  },
  getLoadingStatus: (state) => {
    return state.loader.isActiveStatus;
  },
  isContentLoaded: (state) => {
    return !state.loader.isActiveStatus;
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
