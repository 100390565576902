const getStoresDefaultState = () => {
  return {
    status: false,
    stores: "",
    ut: "", // TODO: What is the meaning?
    current: {
      brandHash: "",
      brandName: "",
      storeHash: "",
      storeName: "",
    },
    vSelectedTab: 0,
  };
};

const stores = {
  state: getStoresDefaultState(),
  mutations: {
    SET_STORE_LIST(state, payload) {
      Object.assign(state, payload);
    },
    SET_CURRENT_STORE(state, payload) {
      Object.assign(state.current, payload);
    },
  },
  actions: {
    setStoreDetails({ state, commit }, storeHash) {
      state.stores.map((item) => {
        if (item.shash === storeHash) {
          commit("SET_CURRENT_STORE", {
            brandHash: item.bhash,
            brandName: item.b_name,
            storeHash: item.shash,
            storeName: item.s_name,
          });
          return false;
        }
      });
    },
  },
  getters: {
    getFirstStoreHash: (state) => {
      return state.stores[0].shash;
    },
  },
};

export default stores;
