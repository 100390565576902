import Vue from "vue";
import Router from "vue-router";
import Login from "@/components/Login/Login";
import Dashboard from "@/views/Dashboard";

import store from "@/store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/login",
      component: Login,
    },
    {
      path: "/",
      component: Dashboard,
      meta: { requiresAuth: true },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    let token = localStorage.getItem("token") || false;
    if (token) {
      store.commit("LOGIN", {
        name: localStorage.getItem("user"),
        status: true,
        token,
        uhash: localStorage.getItem("uhash"),
        utype: localStorage.getItem("utype"),
        recipes_access: localStorage.getItem("recipes_access"),
        invoices_access: localStorage.getItem("invoices_access"),
        vendors_access: localStorage.getItem("vendors_access"),
        stocks_access: localStorage.getItem("stocks_access"),
      });
    }
  }
});

export default router;
