<template>
  <div id="coupon">
    <div class="coupon-wrapper" v-if="coupons.status">
      <h2 class="coupon-title">Coupons</h2>
      <div>
        <button class="btn-primary" @click="showCouponModal(0, 'newCoupon')">
          <span>Create Coupon</span>
        </button>
      </div>
    </div>
    <div class="table-list-data">
      <dataset
        v-slot="{ ds }"
        :ds-data="coupons.coupons"
        :ds-filter-fields="{}"
        :ds-sortby="sortBy"
        :ds-search-in="[
          'coupon_name',
        ]"
        :ds-search-as="{}"
      >
        <div :data-page-count="ds.dsPagecount" class="table-filter-wrapper">
          <!-- <div class="actions-menu">
            <div class="table-search-wrapper">
              <dataset-search ds-search-placeholder="Search..." />
            </div>
          </div> -->
          <div class="table-show-wrapper">
              <dataset-show />
          </div>
        </div>
        <div class="table-list">
          <table>
            <thead>
              <tr>
                <th
                  v-for="(th, index) in cols"
                  :key="th.field"
                  :class="['sort', th.sort]"
                  @click="clickSort($event, index)"
                >
                  {{ th.name }}
                  <i class="gg-select"></i>
                </th>
                <th class="table-actions-col"></th>
              </tr>
            </thead>
            <dataset-item tag="tbody">
              <template v-slot="{ row, rowIndex }">
                <tr :key="rowIndex">
                  <td width="10%">{{ row.detail.name }}</td>
                  <td width="25%">
                    <ul class="info" v-for="(date, index) in row.dates" :key="index">
                      <li>{{ date.date_start }} ~ {{ date.date_end }}<br></li>
                    </ul>
                  </td>
                  <td width="10%">
                    <div v-if="row.detail.c_type == 0">{{ parseFloat(row.detail.discount).toFixed(2) }}%</div>
                    <div v-else-if="row.detail.c_type == 1">${{ parseFloat(row.detail.discount).toFixed(2) }}</div>
                    <div v-else>{{ parseFloat(row.detail.discount).toFixed(2) }}</div>
                  </td>
                  <td>
                    <div v-if="row.detail.s_type == 0">Delivery/Pickup</div>
                    <div v-else-if="row.detail.s_type == 1">Delivery</div>
                    <div v-else-if="row.detail.s_type == 2">Pickup</div>
                    <div v-else>-</div>
                  </td>
                  <td width="10%">
                    <div v-if="row.detail.p_type == 0">All Products</div>
                    <div v-else-if="row.detail.p_type == 1">Special Product</div>
                    <div v-else>-</div>
                  </td>
                  <td width="10%">
                    <ul class="stores">
                      <li v-for="(branch, idx) in row.stores" :key="idx">{{ branch.name }}<br></li>
                    </ul>
                  </td>
                  <td width="10%">
                    <div class="item-actions">
                      <button @click="changeStatus(row)">
                        <i class="flaticon-show" :class="{ 'close': row.detail.status === '0' }"></i>
                      </button>
                      <!-- <button @click="showCouponModal(1, 'editCoupon', row.detail.chash)">
                        <i class="flaticon-edit"></i>
                      </button> -->
                      <button @click="deleteCoupon(row.detail.chash)">
                        <i class="flaticon-delete"></i>
                      </button>
                    </div>
                  </td>
                  <div class="confirm" v-if="isDeletePanelActive && deleteCouponHash==row.detail.chash">
                    <p>Are you sure you want to delete this coupon?</p>
                    <div>
                      <button @click="deleteYes(row.detail.chash)">Yes</button>
                      <button @click="deleteNo()">No</button>
                    </div>
                  </div>
                </tr>
              </template>
              <template v-slot:noDataFound>
                <tr>
                  <td colspan="6">No results found</td>
                </tr>
              </template>
            </dataset-item>
          </table>
        </div>
        <div class="table-pagination">
          <div class="table-pagination-pager-wrapper">
            <dataset-pager />
          </div>
          <div class="table-pagination-info-wrapper">
            <dataset-info />
          </div>
        </div>
      </dataset>
    </div>
    <ModalBox :alias="'newCoupon'">
      <template #modalTitle>New Coupon</template>
      <template #modalBody>
        <CouponsForm ref="couponForm" />
      </template>
      <template #modalFooter>
        <CouponsFormButtons></CouponsFormButtons>
      </template>
    </ModalBox>
    <ModalBox :alias="'editCoupon'">
      <template #modalTitle>Edit Coupon</template>
      <template #modalBody>
        <CouponsForm ref="couponForm" />
      </template>
      <template #modalFooter>
        <CouponsFormButtons></CouponsFormButtons>
      </template>
    </ModalBox>
  </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store";
import ModalBox from '@/widgets/ModalBox';
import CouponsForm from "./CouponsForm";
import CouponsFormButtons from "./CouponsFormButtons";

import {
  Dataset,
  DatasetItem,
  DatasetInfo,
  DatasetPager,
  // DatasetSearch,
  DatasetShow
} from "vue-dataset";

export default {
  name: "Coupons",
  data() {
    return {
      cols: [
        {
          name: "Coupon Name",
          field: "coupon_name",
          sort: ""
        },
        {
          name: "Date",
          field: "date",
          sort: ""
        },
        {
          name: "Discount",
          field: "discount",
          sort: ""
        },
        {
          name: "Service",
          field: "service",
          sort: ""
        },
        {
          name: "Product",
          field: "product",
          sort: ""
        },
        {
          name: "Stores",
          field: "stores",
          sort: ""
        },
      ],
      isDeletePanelActive: false,
      deleteCouponHash: "",
    }
  },
  components: {
    CouponsForm,
    CouponsFormButtons,
    ModalBox,
    Dataset,
    DatasetItem,
    DatasetInfo,
    DatasetPager,
    // DatasetSearch,
    DatasetShow,
  },
  computed: {
    ...mapState(["common", "coupons"]),
    sortBy() {
      return this.cols.reduce((acc, o) => {
        if (o.sort) {
          o.sort === "asc" ? acc.push(o.field) : acc.push("-" + o.field);
        }
        return acc;
      }, []);
    }
  },
  created() {
    store.dispatch("getCoupons");
  },
  methods: {
    clickSort(event, i) {
      let toset;
      const sortEl = this.cols[i];
      if (!event.shiftKey) {
        this.cols.map(o => {
          if (o.field !== sortEl.field) {
            o.sort = "";
          }
        });
      }
      if (!sortEl.sort) {
        toset = "asc";
      }
      if (sortEl.sort === "desc") {
        toset = event.shiftKey ? "" : "asc";
      }
      if (sortEl.sort === "asc") {
        toset = "desc";
      }
      sortEl.sort = toset;
    },
    showCouponModal(type, alias, couponHash) {
      let modalOpts = {
        isActiveStatus: true,
        type,
        alias,
      };
      if (couponHash) {
        const couponDetails = this.coupons.coupons.find(coupon => coupon.detail.chash == couponHash);
        if (couponDetails) {
          store.commit("GET_COUPON", couponDetails);
        }
        modalOpts["hash"] = couponHash;
      }
      store.dispatch(
        "showModalBox",
        Object.assign(store.state.common.modalBox, modalOpts)
      );
    },
    changeStatus(couponItem) {
      const $self = this;
      // y = 0: status, 1: pos_status, 2: web_status, 3: mobile_status
      let newstat = couponItem.detail.status == "1" ? "0" : "1";
      const params = new URLSearchParams();
      params.append("chash", couponItem.detail.chash);
      params.append("newstat", newstat);
      this.axios
        .post("coupons/changestatus", params)
        .then((response) => {
          if (response.data.status) {
            couponItem.detail.status = newstat;
            $self.common.notification.notificationList.push({
              status: 1,
              title: "Updated",
              message: "Coupon status updated"
            });
          } else {
            $self.common.notification.notificationList.push({
              status: -1,
              title: "Error",
              message: response.data.message
            });
          }
        })
        .catch((error) => {
          console.error(error);
          $self.common.notification.notificationList.push({
            status: -1,
            title: "Error",
            message: error
          });
        });
    },
    deleteCoupon(couponHash) {
      this.deleteCouponHash = couponHash;
      this.isDeletePanelActive = true;
    },
    deleteYes(couponHash) {
      store.dispatch("deleteCoupon", couponHash );
      this.deleteNo();
    },
    deleteNo() {
      this.deleteCouponHash = "";
      this.isDeletePanelActive = false;
    },
  }
};
</script>

<style lang="css" scoped>
.coupon-wrapper {
  display: flex; 
  padding-bottom: 1rem; 
  margin-bottom: 1rem; 
  justify-content: space-between; 
  align-items: center; 
  border-bottom-width: 1px; 
  border-color: #F3F4F6; 
}
.coupon-title {
  flex-grow: 1;
}
.coupon {
  display: flex;
  position: relative;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  margin-bottom: 1.25rem;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 0.5rem;
  border-width: 1px;
  border-color: #e5e7eb;
  border-style: solid;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.coupon:hover {
  background-color: #FEF3C7;
}
.coupon ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.coupon ul li {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 400;
  line-height: 2rem;
}
.coupon h2, .coupon h3 {
  margin: 0;
  margin-bottom: 0.25rem;
}
.coupon h2 {
  text-transform: uppercase;
  max-width: 170px;
}
.info {
  margin-right: 0.5rem;
  /* width: 25%; */
  max-width: 320px;
}
.starting-date, .expired-date {
  margin-bottom: 0.25rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.starting-date strong, .expired-date strong {
  display: block;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
}
.label {
  display: block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  text-align: center;
  border-radius: 0.375rem;
}
.label.active {
  background-color: #6ee7b7;
  color: #064e3b;
}
.label.expired {
  background-color: #fca5a5;
  color: #7f1d1d;
}
.discount-wrapper {
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  color: #4b5563;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 600;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 14rem;
  border-right-width: 2px;
  border-left-width: 2px;
  border-color: #e5e7eb;
  border-style: dashed;
}
.discount-wrapper em {
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-style: normal;
  font-weight: 500;
}
.type-wrapper {
  display: flex;
  padding: 1rem;
  flex-direction: column;
  justify-content: center;
}
.type-wrapper div {
  margin-bottom: 1.25rem; 
}
.stores {
  padding-left: 0;
  list-style: none;
  text-align: center;
}
.stores ul {
  padding: 0;
  margin: 0;
}
.info {
  list-style: none;
}
</style>