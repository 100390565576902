<template>
  <form action="#">
    <div>
      <div class="form-row">
        <div class="label-title">User Type</div>
        <div class="flex flex-row radio-group-wrapper">
          <div class="radio-group">
            <input id="type3" name="userType" type="radio" v-model="stateUser.u_type" :value="3" />
            <label for="type3">Cashier</label>
          </div>
          <div class="radio-group">
            <input id="type4" name="userType" type="radio" v-model="stateUser.u_type" :value="4" />
            <label for="type4">Cashier/Manager</label>
          </div>
          <div class="radio-group">
            <input id="type5" name="userType" type="radio" v-model="stateUser.u_type" :value="5" />
            <label for="type5">Driver</label>
          </div>
          <div class="radio-group">
            <input id="type6" name="userType" type="radio" v-model="stateUser.u_type" :value="6" />
            <label for="type6">Pizzamaker</label>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="grid grid-cols-2 gap-4">
          <div class="flex flex-col input-group">
            <label for="txtUserName">Name</label>
            <input id="txtUserName" name="txtUserName" type="text" v-model="stateUser.name" @focus="dismissError('vUserName')" />
          </div>
          <div class="validation-error" v-if="formValidation.vUserName.errorMessage">
            {{ formValidation.vUserName.errorMessage }}
          </div>
          <div class="flex flex-col input-group">
            <label for="txtPassCode">Passcode</label>
            <input id="txtPassCode" name="txtPassCode" type="text" v-model="stateUser.passcode" @focus="dismissError('vUserPasscode')" />
          </div>
          <div class="validation-error" v-if="formValidation.vUserPasscode.errorMessage">
            {{ formValidation.vUserPasscode.errorMessage }}
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="grid grid-cols-2 gap-4">
          <div class="flex flex-col input-group">
            <label for="txtEmail">E-mail</label>
            <input id="txtEmail" name="txtEmail" type="text" v-model="stateUser.email" />
          </div>
          <div class="flex flex-col input-group">
            <label for="txtPassword">Password</label>
            <input id="txtPassword" name="txtPassword" type="text" v-model="stateUser.password" />
          </div>
        </div>
      </div>
    </div>

    <h2>Shift Permissions</h2>
    <div class="flex flex-row mb-4 form-row">
      <div class="mr-10">
        <div class="mb-2">Opening</div>
        <ToggleCheckbox
          @clickToggle="changeStatus($event, 'OpeningShift')"
          :onOff="stateUser.shift_open_status === 1"
        ></ToggleCheckbox>
      </div>
      <div class="mr-10">
        <div class="mb-2">Closing</div>
        <ToggleCheckbox
          @clickToggle="changeStatus($event, 'ClosingShift')"
          :onOff="stateUser.shift_close_status === 1"
        ></ToggleCheckbox>
      </div>
    </div>

    <h2>Report Permissions</h2>
    <div class="flex flex-row mb-4 form-row">
      <div class="mr-10">
        <div class="mb-2">General</div>
        <ToggleCheckbox
          @clickToggle="changeStatus($event, 'ReportingGeneral')"
          :onOff="stateUser.report_general === 1"
        ></ToggleCheckbox>
      </div>
      <div class="mr-10">
        <div class="mb-2">Summary</div>
        <ToggleCheckbox
          @clickToggle="changeStatus($event, 'ReportingSummary')"
          :onOff="stateUser.report_shift_summary === 1"
        ></ToggleCheckbox>
      </div>
      <div class="mr-10">
        <div class="mb-2">Driver</div>
        <ToggleCheckbox
          @clickToggle="changeStatus($event, 'ReportingDriver')"
          :onOff="stateUser.report_shift_driver === 1"
        ></ToggleCheckbox>
      </div>
      <div class="mr-10">
        <div class="mb-2">Map</div>
        <ToggleCheckbox
          @clickToggle="changeStatus($event, 'ReportingMAP')"
          :onOff="stateUser.report_map === 1"
        ></ToggleCheckbox>
      </div>
      <div class="mr-10">
        <div class="mb-2">POS</div>
        <ToggleCheckbox
          @clickToggle="changeStatus($event, 'ReportingPOS')"
          :onOff="stateUser.report_menu === 1"
        ></ToggleCheckbox>
      </div>
    </div>

    <h2>Accessing Permissions</h2>
    <div class="flex flex-row mb-4 form-row">
      <div class="mr-10">
        <div class="mb-2">Edit</div>
        <ToggleCheckbox
          @clickToggle="changeStatus($event, 'Editing')"
          :onOff="stateUser.stat_edit === 1"
        ></ToggleCheckbox>
      </div>
      <div class="mr-10">
        <div class="mb-2">Delete</div>
        <ToggleCheckbox
          @clickToggle="changeStatus($event, 'Deleting')"
          :onOff="stateUser.stat_delete === 1"
        ></ToggleCheckbox>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState } from "vuex";
import ToggleCheckbox from "@/widgets/ToggleCheckbox";

export default {
  name: "UsersForm",
  components: {
    ToggleCheckbox
  },
  computed: {
    ...mapState({
      // stateMenus: "menus",
      stateUser: "users"
    }),
    formValidation() {
      return this.stateUser.formValidation;
    }
  },
  mounted() {},
  methods: {
    changeStatus(value, stateToggle) {
      let toggleNewValue = value ? 1 : 0;
      switch (stateToggle) {
        case "OpeningShift":
          this.stateUser.shift_open_status = toggleNewValue;
          break;
        case "ClosingShift":
          this.stateUser.shift_close_status = toggleNewValue;
          break;
        case "ReportingGeneral":
          this.stateUser.report_general = toggleNewValue;
          break;
        case "ReportingSummary":
          this.stateUser.report_shift_summary = toggleNewValue;
          break;
        case "ReportingDriver":
          this.stateUser.report_shift_driver = toggleNewValue;
          break;
        case "ReportingMAP":
          this.stateUser.report_map = toggleNewValue;
          break;
        case "ReportingPOS":
          this.stateUser.report_menu = toggleNewValue;
          break;
        case "Editing":
          this.stateUser.stat_edit = toggleNewValue;
          break;
        case "Deleting":
          this.stateUser.stat_delete = toggleNewValue;
          break;

        default:
          break;
      }
    },
    // Validation Functions
    dismissError(field) {
      this.formValidation[field].isValid = false;
      this.formValidation[field].errorMessage = "";
    },
    checkForm() {
      let checkingUserName = this.isValidUserName();
      let checkingUserPasscode = this.isValidUserPasscode();
      return checkingUserName && checkingUserPasscode ? true : false;
    },
    isEmpty(val) {
      return val === "" || val === " " || val === null ? true : false;
    },
    isValidUserName() {
      if(this.isEmpty(this.stateUser.name)) {
        this.formValidation.vUserName.isValid = false;
        this.formValidation.vUserName.errorMessage = "Please enter a name.";
        return false;
      } else {
        this.formValidation.vUserName.isValid = true;
        this.formValidation.vUserName.errorMessage = "";
        return true;
      }
    },
    isValidUserPasscode() {
      if(this.isEmpty(this.stateUser.passcode)) {
        this.formValidation.vUserPasscode.isValid = false;
        this.formValidation.vUserPasscode.errorMessage = "Please enter a passcode.";
        return false;
      } else {
        this.formValidation.vUserPasscode.isValid = true;
        this.formValidation.vUserPasscode.errorMessage = "";
        return true;
      }
    }
  }
};
</script>

<style lang="css" scoped>
h2 {
  font-size: 1rem;
  line-height: 1.5rem; 
}
</style>