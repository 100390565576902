const getStockAuditsDefaultState = () => {
  return {
    stockAuditList: [],
    startDate: new Date().toISOString().substr(0, 10),
    endDate: new Date().toISOString().substr(0, 10),
    audit: {},
    selectedRow: 0,
    stockModalBox: false,
    stockFields: [
      {
        vRowId: '-1',
        vStockName: '',
        vStockAmount: 0,
        vStockUnit: '',
        vStockUnitHash: '',
        vStockItemHash: '',
        vStockHash: '',
      }
    ],
    formValidation: {
      vStockName: { isValid: false, errorMessage: '' },
    }
  }
};

const audits = {
  state: getStockAuditsDefaultState(),
  mutations: {
    RESET_AUDIT_FORM(state) {
      state.audit = {};
      state.stockFields = [
        {
          vRowId: '-1',
          vStockName: '',
          vStockAmount: 0,
          vStockItemHash: '',
          vStockHash: '',
        }
      ];
    },
    CLOSE_AUDIT_MODAL_BOX(state) {
      state.isAuditModalBoxActive = false;
    },
    SET_STOCK_AUDITS(state, payload) {
      state.stockAuditList = payload;
    },
    GET_STOCK_AUDIT(state, payload) {
      state.stockFields.pop(); // Deleting empty first row in table
      state.audit = state.stockAuditList.find(audit => audit.audit_hash === payload);
      let tableItems = state.audit.stock_items;
      tableItems.map((item, index) => {
        state.stockFields.push({
          vRowId: index,
          vStockName: item.stock_name,
          vStockAmount: parseFloat(item.stock_amount).toFixed(2),
          vStockItemHash: item.audit_item_hash,
          vStockHash: item.stock_hash,
          vStockUnit: item.stock_unit_name,
          vStockUnitHash: item.stock_unit_hash,
        });
      });
    },
    ADD_STOCK_AUDIT(state, payload) {
      state.stockAuditList.push(payload);
    },
    UPDATE_STOCK_AUDIT(state, payload) {
      let sIndex = state.stockAuditList.findIndex((audit) => audit.audit_hash === payload.audit_hash);
      Object.assign(state.stockAuditList[sIndex], payload);
    },
    DELETE_STOCK_AUDIT(state, audioHash) {
      let sIndex = state.stockAuditList.findIndex((audit) => audit.audit_hash === audioHash);
      state.stockAuditList.splice(sIndex, 1);
    },
    // second ModalBox
    UPDATE_STOCK_IN_AUDIT(state, value) {
      let newStock = {
        vRowId: '-1',
        vStockAmount: value.stock_amount,
        vStockHash: value.stock_hash,
        vStockItemHash: "",
        vStockName: value.stock_name,
        vStockUnit: value.unit_name,
        vStockUnitHash: value.unit_hash,
      };
      // add new stock to stockFields
      Object.assign(
        state.stockFields[state.selectedRow],
        newStock
      );
      state.stockModalBox = false;
    }
  },
  actions: {
    closeAuditModalBox({ commit }) {
      commit("RESET_AUDIT_FORM");
      commit("RESET_MODALBOX");
    },
    async getStockAuditList({ state, commit }, {
      startDate, 
      endDate,
      brandHash,
      storeHash,
    }) {
      state.stockAuditList = [];
      commit("LOADING", { isActiveStatus: true});
      try {
        let { data } = await window.$http.axios.get(`inventory/api/stocks/audit?start=${startDate}&end=${endDate}&brand_hash=${brandHash}&store_hash=${storeHash}`);
        if(data.status) {
          if(data.stock_audit == null) {
            data.stock_audit = [];
            commit('NOTIFICATION_MESSAGE', { status: -1, title: "Error", message: "Stock Audit list loaded successfully" })
          } else {
            state.stockAuditList = data.stock_audit;
            commit('NOTIFICATION_MESSAGE', { status: -1, title: "Error", message: "Stock Audit list loaded successfully" })
          }
        }
      } catch (error) {
        state.stockAuditList = [];
        commit('NOTIFICATION_MESSAGE', { status: -1, title: "Error", message: "Stock Audit list not loaded" })
      }
      commit("LOADING", { isActiveStatus: false});
    },
    async saveStockAudit({ state, commit, dispatch }, {
        brandHash,
        storeHash,
      }) {
      const FormData = require('form-data');
      const formData = new FormData();
      let audit = {
          comment: state.audit.comment,
          stock_items: [],
      }
      state.stockFields.map((item) => {
        let stock_item = {
          stock_name: item.vStockName,
          stock_amount: Number(item.vStockAmount),
          stock_hash: item.vStockHash,
        }
        audit.stock_items.push(stock_item);
      })
      formData.append('audit', JSON.stringify(audit));
      const config = {
          method: 'post',
          url: `inventory/api/stocks/audit?brand_hash=${brandHash}&store_hash=${storeHash}`,
          data: formData,
      }
      commit("LOADING", { isActiveStatus: true});
      try {
        let { data } = await window.$http.axios(config);
        if(data.status) {
          commit('ADD_STOCK_AUDIT', data.stock_audit);
          commit("NOTIFICATION_MESSAGE", { status: 1, title: "Saved", message: "Stock Audit has been saved successfully" });
          dispatch('closeAuditModalBox');
        } else {
          commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: data.message });
        }
      } catch (error) {
        commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: "Stock Audit has not been saved successfully" });
      }
      commit("LOADING", { isActiveStatus: false});
    },
    async updateStockAudit({ state, commit, dispatch }, {
      auditHash,
      brandHash,
      storeHash,
    }) {
    const FormData = require('form-data');
    const formData = new FormData();
    let audit = {
        audit_hash: auditHash,
        comment: state.audit.comment,
        stock_items: [],
    }
    state.stockFields.map((item) => {
      let stock_item = {
        stock_name: item.vStockName,
        stock_amount: Number(item.vStockAmount),
        stock_hash: item.vStockHash,
      }
      audit.stock_items.push(stock_item);
    })
    formData.append('audit', JSON.stringify(audit));
    const config = {
        method: 'put',
        url: `inventory/api/stocks/audit/${auditHash}?brand_hash=${brandHash}&store_hash=${storeHash}`,
        data: formData,
    }
    commit("LOADING", { isActiveStatus: true});
    try {
      let { data } = await window.$http.axios(config);
      if(data.status) {
        commit('UPDATE_STOCK_AUDIT', data.stock_audit);
        commit("NOTIFICATION_MESSAGE", { status: 1, title: "Updated", message: "Stock Audit has been updated successfully" });
        dispatch('closeAuditModalBox');
      } else {
        commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: data.message });
      }
    } catch (error) {
      commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: "Stock Audit has not been updated successfully" });
    }
    commit("LOADING", { isActiveStatus: false});
  },
    async deleteStockAudit({ commit }, {
      auditHash,
      brandHash,
      storeHash,
    }) {
      commit("LOADING", { isActiveStatus: true});
      try {
        let { data } = await window.$http.axios.delete(`inventory/api/stocks/audit/${auditHash}?brand_hash=${brandHash}&store_hash=${storeHash}`);
        if(data.status) {
          commit('DELETE_STOCK_AUDIT', auditHash);
          commit("NOTIFICATION_MESSAGE", { status: 1, title: "Deleted", message: "Stock has been deleted successfully" });
        }
      } catch (error) {
        commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: "Stock has not been deleted successfully" });
      }
      commit("LOADING", { isActiveStatus: false});
    }
  },
  getters: {
    getAuditStartDate: state => state.startDate,
    getAuditEndDate: state => state.endDate,
  }
}

export default audits;