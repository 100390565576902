<template>
    <div>
        <div class="form-button-actions">
            <button class="btn-secondary" @click="cancel()">Cancel</button>
            <button class="btn-primary" @click="updateInvoice()" v-if="getFormType == 1">Update</button>
            <button class="btn-primary" @click="saveInvoice()" v-else>Save</button>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import invoice from "@/store/modules/common/invoices.js";

export default {
    name: "InvoiceFormButtons",
    data() {
        return {
            brandHash: "",
            storeHash: "",
        }
    },
    created() {
        this.brandHash = this.stores.current.brandHash;
        this.storeHash = this.stores.current.storeHash;
    },
    computed: {
        ...mapState(["invoices", "stores"]),
        ...mapGetters(["getFormType"])
    }, 
    methods: {
        cancel() {
            store.dispatch("closeInvoiceModalBox");
        },
        updateInvoice() {
            const invoiceHash = invoice.state.vInvoiceHash;
            if (this.$parent.$parent.$refs.invoiceForm.checkForm()) {
                store.dispatch("updateInvoice", {
                    invoiceHash,
                    brandHash: this.brandHash,
                    storeHash: this.storeHash
                });
            }
        },
        saveInvoice() {
            if (this.$parent.$parent.$refs.invoiceForm.checkForm()) {
                store.dispatch("saveInvoice",{
                    brandHash: this.brandHash,
                    storeHash: this.storeHash
                });
            }
        }
    }
}
</script>