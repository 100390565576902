const getMenusDefaultState = () => {
  return {
    status: false,
    menus: '',
    store_hours: '',
    sizes: [],
    areas: '',
    otherstoreareas: '',
    posusers: '',
    vTabMenu: [],
    vTabMenuProducts: [],
    vSelectedTab: '',
    current: {
      myCroppa: {},
      m_name: '',
      m_desc: '',
      m_type: 0,
      m_hash: '',
      pictureControl: -1,
    },
    // Validation
    formValidation: {
      m_name: { isValid: false, errorMessage: '' },
      m_picture: { isValid: false, errorMessage: '' },
    },
  };
};

const menus = {
  state: getMenusDefaultState(),
  mutations: {
    GET_MENUS(state, payload) {
      Object.assign(state, payload);
    },
    SET_TABS_MENU(state, payload) {
      state.vTabMenu = payload;
    },
    SET_TAB_PRODUCTS(state, payload) {
      state.vTabMenuProducts = payload;
      if ((state.vSelectedTab === '' || state.vSelectedTab === false) && state.vTabMenu[0]) {
        state.vSelectedTab = state.vTabMenu[0].id;
      }
    },
    MENU_ADD(state, payload) {
      const menuType = payload.m_type == 0 ? 'b' : 's';
      state.menus[menuType].unshift(payload);
    },
    MENU_UPDATE(state, payload) {
      const menuType = payload.m_type == 0 ? 'b' : 's';
      let mindex = state.menus[menuType].findIndex(
        (item) => item.mhash === payload.mhash
      );
      Object.assign(state.menus[menuType][mindex], payload);
    },
    MENU_DELETE(state, payload) {
      const menuType = payload.m_type == 0 ? 'b' : 's';
      state.menus[menuType].splice(state.menus[menuType].indexOf(payload), 1);
    },
    MENU_CHANGE_SYNC_STATUS(state, payload) {
      const menuType = payload.menuType == 0 ? 'b' : 's';
      state.menus[menuType].map((item) => {
        item.sync_pos_status = '0';
      });
    },
    // Product Mutations
    ADD_PRODUCT(state, { selectedMenu, mType, productData }) {
      const menuType = mType == 0 ? 'b' : 's';
      const menuHashIdx = state.menus[menuType].findIndex(
        (item) => item.mhash === selectedMenu
      )
      state.menus[menuType][menuHashIdx].products.unshift(productData);
    },
    UPDATE_PRODUCT(state, { selectedMenu, mType, selectedProduct, productData }) {
      const menuType = mType == 0 ? 'b' : 's';
      const menuHashIdx = state.menus[menuType].findIndex(
        (item) => item.mhash === selectedMenu
      )
      const productHashIdx = state.menus[menuType][menuHashIdx].products.findIndex(
        (item) => item.phash === selectedProduct
      )
      Object.assign(
        state.menus[menuType][menuHashIdx].products[productHashIdx],
        productData
      )
    },
    DELETE_PRODUCT(state, { selectedMenu, mType, selectedProduct }) {
      const menuType = mType == 0 ? 'b' : 's';
      const menuHashIdx = state.menus[menuType].findIndex(
        (item) => item.mhash === selectedMenu
      )
      const productHashIdx = state.menus[menuType][menuHashIdx].products.findIndex(
        (item) => item.phash === selectedProduct
      )
      state.menus[menuType][menuHashIdx].products.splice(productHashIdx, 1);
    },
    PRODUCT_CHANGE_SYNC_STATUS(state, payload) {
      const menuType = payload.menuType == 0 ? 'b' : 's';
      state.menus[menuType].map ((menu) => {
        if(menu.mhash === payload.menuHash) {
          menu.products.map((product) => {
            product.sync_pos_status = '0';
          });
        }
      })
    }
  },
  actions: {
    closeMenuModalBox({ state, commit }) {
      Object.assign(state.current, {
        myCroppa: {},
        m_name: '',
        m_desc: '',
        m_type: 0,
        pictureControl: -1,
      });
      commit("RESET_MODALBOX");
    },
    async getMenus({ commit, dispatch }, storeHash) {
      try {
        let { data } = await window.$http.axios.get(`get/menus?s=${storeHash}`);
        commit('GET_MENUS', data);
        dispatch('configHours', data.store_hours)
      } catch (error) {
        console.error(error);
        return;
      }
    },
    getTabsMenu({ state, commit }) {
      let menuList = [];
      let menuProductList = [];
      state.menus.s.map((item) => {
        menuList.push({ name: item.m_name, id: item.mhash, menuType: 's' });
        menuProductList.push({ products: item.products, id: item.mhash });
      });
      state.menus.b.map((item) => {
        menuList.push({ name: item.m_name, id: item.mhash, menuType: 'b' });
        menuProductList.push({ products: item.products, id: item.mhash });
      });
      commit('SET_TABS_MENU', menuList);
      commit('SET_TAB_PRODUCTS', menuProductList);
    },
    async saveMenu({ state, commit,dispatch }, storeHash ) {
      const params = new URLSearchParams();
      params.append("m_name", state.current.m_name);
      params.append("m_desc", state.current.m_desc);
      params.append("m_type", state.current.m_type);
      params.append(
        "m_picture",
        state.current.myCroppa.generateDataUrl("image/jpeg", 0.8)
      );
      const config = {
        method: "post",
        url: `menu/addstoremenus?s=${storeHash}`,
        data: params,
      }
      commit("LOADING", { isActiveStatus: true });
      try {
        let { data } = await window.$http.axios(config);
        if(data.status) {
          commit('MENU_ADD', data.menu);
          commit("NOTIFICATION_MESSAGE", { status: 1, title: "Saved", message: "Menu has been saved successfully." });
          dispatch("closeMenuModalBox");
        } else {
          commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: data.message })
        }
      } catch (error) {
        commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: error.message })
      }
      commit("LOADING", { isActiveStatus: false });
    },
    async updateMenu({ state, commit, dispatch }, { storeHash, menuItemHash }) {
      const params = new URLSearchParams();
      params.append("m_name", state.current.m_name);
      params.append("m_desc", state.current.m_desc);
      params.append("m_type", state.current.m_type);
      params.append(
        "m_picture",
        state.current.myCroppa.generateDataUrl("image/jpeg", 0.8)
      );
      const config = {
        method: "post",
        url: `menu/updateitem?s=${storeHash}&mh=${menuItemHash}`,
        data: params,
      }
      commit("LOADING", { isActiveStatus: true });
      try {
        let { data } = await window.$http.axios(config);
        if(data.status) {
          commit('MENU_UPDATE', data.menuitem);
          commit("NOTIFICATION_MESSAGE", { status: 1, title: "Updated", message: "Menu has been updated successfully." });
          dispatch("closeMenuModalBox");
        } else {
          commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: data.message});
        } 
      } catch (error) {
        commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: error.message });
      }
      commit("LOADING", { isActiveStatus: false });
    },
    async deleteMenu({ commit }, { itemHash, item, storeHash }) {
      // https://api.laruota.ca/menu/deleteitem?s=LQ53408502&mh=9d4495ed5fc068f7a6cc31d91f81baef28c9337ac2145a5a38411d99
      try {
        let data = await window.$http.axios.get(
          `menu/deleteitem?s=${storeHash}&mh=${itemHash}`
        );
        if (data.data.status) {
          commit('MENU_DELETE', item);
          commit('NOTIFICATION_MESSAGE', { status: 1, title: 'Deleted', message: 'Menu has been deleted successfully.' });
        } else {
          commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: data.data.message });
        }
      } catch (error) {
        console.error(error);
        commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: error.message });
      }
    },
    async updateMenuOrder({ commit }, { storeHash, menuType, menuList }) {
      const FormData = require('form-data');
      const formData = new FormData();
      formData.append('m_type', menuType);
      menuList.map((menu) => {
        formData.append('mhashlist[]', menu.mhash);
      });
      const config = {
        method: 'post',
        url: `menu/changeorder?s=${storeHash}`,
        data: formData,
      };
      commit('LOADING', { isActiveStatus: true });
      try {
        let { data } = await window.$http.axios(config);;
        if (data.status) {
          commit('MENU_CHANGE_SYNC_STATUS', menuType);
        } else {
          commit('NOTIFICATION_MESSAGE', { status: -1, title: 'Error', message: data.message });
        }
      } catch (error) {
        console.error(error);
        commit('NOTIFICATION_MESSAGE', { status: -1, title: 'Error', message: error.message });
      }
      commit('LOADING', { isActiveStatus: false });
    },
  },
  getters: {
    getMenuProducts: (state) => (menuHash) => {
      let productList = '';
      state.menus.b.map((item) => {
        if (menuHash === item.mhash) {
          productList = item.products;
          return productList;
        }
      });
      state.menus.s.map((item) => {
        if (menuHash === item.mhash) {
          productList = item.products;
        }
        return productList;
      });
    },
    getMenuType: (state) => (menuItemHash) => {
      let idx = '';
      idx = state.menus.s.findIndex((item) => item.mhash === menuItemHash);
      if (idx !== -1) {
        return '1';
      }
      idx = state.menus.b.findIndex((item) => item.mhash === menuItemHash);
      if (idx !== -1) {
        return '0';
      }
      return -1;
    },
    getMenuItem: (state) => (menuItemHash, menuType) => {
      let selectedItem = '';
      if (menuType === '0' || menuType === 0) {
        state.menus.b.map((item) => {
          if (item.mhash === menuItemHash) {
            selectedItem = item;
          }
        });
      }
      if (menuType === '1' || menuType === 1) {
        state.menus.s.map((item) => {
          if (item.mhash === menuItemHash) {
            selectedItem = item;
          }
        });
      }
      return selectedItem || -1;
    },
  },
};

export default menus;

