import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import common from './modules/common';
import stores from './modules/common/stores';
import menus from './modules/common/menus';
import options from './modules/common/options';
import vendors from './modules/common/vendors';
import invoices from './modules/common/invoices';
import coupons from './modules/common/coupons';
import users from './modules/common/users';
import products from './modules/common/products';
import customerscreens from './modules/common/branchsetting/customerscreens';
import menuboards from './modules/common/branchsetting/menuboards';
import stocks from './modules/common/stock/stocks';
import reports from './modules/common/stock/rerports';
import audits from './modules/common/stock/audits';
import hours from './modules/common/branchsetting/hours';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    auth,
    common,
    stores,
    menus,
    options,
    stocks,
    vendors,
    invoices,
    coupons,
    users,
    products,
    customerscreens,
    menuboards,
    reports,
    audits,
    hours,
  },
});
