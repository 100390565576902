<template>
  <div id="workinghours">
    <div class="working-hours-header">
      <h2 class="flex-grow">Working Hours</h2>
      <div>
        <button class="btn btn-primary" @click.prevent="updateHours()">Update Hours</button>
      </div>
    </div>
    <div class="working-hours">
      <div class="delivery-wrapper">
        <h3 class="delivery-title">DELIVERY</h3>
        <TabsNav 
          @goTo="setDeliveryDay"
          :list="vDays" 
          :selected="vDeliverySelectedDay"
        >
          <Tab 
            :isSelected="vDeliverySelectedDay === day.id"
            v-for="(day, index) in vDays"
            :key="index" 
          >
            <div class="hours">
              <div
                class="hour"
                v-for="(time, idx) in stateHours.deliveryHours"
                :key="idx"
              >
                <div class="format-time">
                  <span v-if="time.hour.length === 1">0</span>
                  <span>{{ time.hour }}</span>
                  <span>:</span>
                  <span v-if="time.minute.length === 1">0</span>
                  <span>{{ time.minute }}</span>
                </div>
                <ToggleCheckbox
                  @clickToggle="
                    changeStatus($event, 'd', {
                      day: time.day,
                      hour: time.hour,
                      minute: time.minute,
                      status: time.status,
                    })
                  "
                  :onOff="time.status == 1"
                >
                </ToggleCheckbox>
              </div>
            </div>
          </Tab>
        </TabsNav>
      </div>
      <div class="carryout-wrapper">
        <h3>CARRYOUT</h3>
        <TabsNav @goTo="setCarryoutDay" :list="vDays" :selected="vCarryoutSelectedDay">
          <Tab 
            :isSelected="vCarryoutSelectedDay === day.id"
            v-for="(day, index) in vDays"
            :key="index" 
          >
            <div class="hours">
              <div
                class="hour"
                v-for="(time, idx) in stateHours.carryoutHours"
                :key="idx"
              >
                <div class="format-time">
                  <span v-if="time.hour.length === 1">0</span>
                  <span>{{ time.hour }}</span>
                  <span>:</span>
                  <span v-if="time.minute.length === 1">0</span>
                  <span>{{ time.minute }}</span>
                </div>
                <ToggleCheckbox
                  @clickToggle="
                    changeStatus($event, 'c', {
                      day: time.day,
                      hour: time.hour,
                      minute: time.minute,
                      status: time.status,
                    })
                  "
                  :onOff="time.status === 1 || time.status === '1'"
                >
                </ToggleCheckbox>
              </div>
            </div>
          </Tab>
        </TabsNav>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store";

import TabsNav from "@/widgets/Tabs/TabsNav";
import Tab from "@/widgets/Tabs/Tab";
import ToggleCheckbox from "@/widgets/ToggleCheckbox";

export default {
  name: "WorkingHours",
  data() {
    return {
      vDays: [
        { id: "0", name: "Sunday"},
        { id: "1", name: "Monday"},
        { id: "2", name: "Tuesday"},
        { id: "3", name: "Wednesday"},
        { id: "4", name: "Thursday"},
        { id: "5", name: "Friday"},
        { id: "6", name: "Saturday"},
      ],
      vDeliverySelectedDay: "0",
      vCarryoutSelectedDay: "0",
      vOpeningTime: "",
      vClosingTime: "",
      hourList: [],
      minuteList: ["00", "15", "30", "45"],
    };
  },
  components: {
    TabsNav,
    Tab,
    ToggleCheckbox,
  },
  computed: {
    ...mapState({
      stateCommon: "common",
      stateMenus: "menus",
      stateStores: "stores",
      stateHours: "hours",
    })
  },
  created() {
    store.commit('SETDELIVERYHOURS', this.vDays[0].id);
    store.commit('SETCARRYOUTHOURS', this.vDays[0].id);
  },
  watch: {
    vDeliverySelectedDay() {
      store.commit('SETDELIVERYHOURS', this.vDeliverySelectedDay);
    },
    vCarryoutSelectedDay() {
      store.commit('SETCARRYOUTHOURS', this.vCarryoutSelectedDay);
    },
  },
  methods: {
    setDeliveryDay(tab) {
      this.vDeliverySelectedDay = tab;
    },
    setCarryoutDay(tab) {
      this.vCarryoutSelectedDay = tab;
    },
    changeStatus(value, alias, timeItem) {
      let toggleNewValue = value ? 1 : 0;
      this.stateHours.store_hours[alias].map((item) => {
        let findIndex =
          item.day === timeItem.day &&
          item.hour === timeItem.hour &&
          item.minute === timeItem.minute &&
          item.status === timeItem.status;
        if (findIndex) {
          item.status = toggleNewValue;
        }
      });
    },
    updateHours() {
      const hoursData = encodeURI(JSON.stringify(this.stateHours.store_hours));
      store.dispatch('updateHours', {
        storeHash: this.stateStores.current.storeHash,
        hoursData,
      });
    },
  }
};
</script>

<style lang="css" scoped>
.working-hours {
  display: flex;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 1.25rem;
  flex-direction: column;
}
.working-hours-header {
  display: flex; 
  padding-bottom: 1rem; 
  margin-bottom: 1rem; 
  margin-right: 1rem;
  justify-content: space-between; 
  align-items: center; 
  border-bottom-width: 1px; 
  border-color: #F3F4F6; 
}
.flex-grow {
  flex-grow: 1;
}
.delivery-title {
  display: flex; 
  flex-direction: row; 
  justify-content: space-between; 
}
.action-wrapper {
  display: flex; 
  margin-top: 1rem; 
  justify-content: space-between; 
}
h3 {
  margin: 0;
  margin-bottom: 0.5rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 600;
}
.delivery-wrapper {
  margin-bottom: 2.5rem;
}
.format-time {
  margin: auto 5px;
}
.hours {
  display: flex;
  overflow-x: auto;
  position: relative;
  padding: 1.25rem;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 520px;
}
.hours .hour {
  display: flex;
  margin: 0;
  margin-bottom: 0.25rem;
  flex-direction: row;
      justify-content: flex-start;
  align-items: center;
}
/* custom */
.mr-2 {
  margin-right: 0.5rem;
}
.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem; 
}
.ml-2 {
  margin-left: 0.5rem; 
}
.schdule-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.opening-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 10px auto;
}
select {
  display: inline-flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #fff;
  color: #374151;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  justify-content: center;
  width: 100%;
  border-radius: 0.375rem;
  border-width: 1px;
  border-color: #d1d5db;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
select:hover {
  background-color: #f9fafb;
}
select:focus {
  border-width: 1px;
  border-color: #6366f1;
  outline: 0;
}
.actions-wrapper {
  display: flex;
  gap: 10px;
  margin-left: 30px;
}
</style>