<template>
  <div class="toggle-checkbox-wrapper" @click="changeToggle" :class="{'on' : onOff}">
    <div class="toggle">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ToggleCheckbox",
  props: {
    onOff: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    changeToggle() {
      this.$emit("clickToggle", !this.onOff);
    }
  }
};
</script>

<style lang="css" scoped>
.toggle-checkbox-wrapper {
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 100;
	/* margin-top: 0.5em; */
}
.toggle {
	display: flex;
	position: relative;
	padding-left: 2.25rem;
	background-color: #e5e7eb;
	color: #d1d5db;
	font-size: 0.75rem;
	line-height: 1rem;
	font-weight: 600;
	align-items: center;
	width: 3.5rem;
	border-radius: 9999px;
	border-width: 0px;
	border-color: #e5e7eb;
	border-style: solid;
	cursor: pointer;
	padding-left: 0.5rem;
	height: 35px;
}
.toggle::before {
	display: block;
	position: absolute;
	background-color: #fff;
	width: 2rem;
	height: 2rem;
	border-radius: 9999px;
	border-width: 1px;
	border-color: #e5e7eb;
	border-style: solid;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
	top: 0px;
	content: "";
}
.toggle::before {
	left: -2px;
	right: auto;
}
.toggle-checkbox-wrapper.on .toggle {
	padding-left: 0.5rem;
	background-color: #10b981;
	color: #fff;
	border-color: #34d399;
	height: 34px;
}
.toggle-checkbox-wrapper.on .toggle::before {
	left: auto;
	right: -2px;
}
</style>