<template>
  <form class="menu-form" action="#">
    <div>
      <div class="form-row" v-if="getFormType == 0">
        <div class="label-title">Menu Type</div>
        <div class="menu-type">
          <div class="radio-group">
            <input id="brandMenu" name="menuType" type="radio" v-model="stateItem.m_type" value="0" />
            <label for="brandMenu" class="menu-label">Brand Menu</label>
          </div>
          <div class="radio-group">
            <input id="storeMenu" name="menuType" type="radio" v-model="stateItem.m_type" value="1" />
            <label for="storeMenu" class="menu-label">Store Menu</label>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div
          class="form-col"
          :class="{ error: formValidation.m_name.errorMessage }"
        >
          <div class="input-group">
            <label for="txtMenuTitle">Menu Title</label>
            <input  
              id="txtMenuTitle" 
              name="txtMenuTitle" 
              type="text" 
              v-model="stateItem.m_name" 
              @focus="dismissError('m_name')"
            />
            <div class="validation-error" v-if="formValidation.m_name.errorMessage">
              {{ formValidation.m_name.errorMessage }}
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="input-group">
          <label for="txtMenuDescription">Description</label>
          <textarea
            id="txtMenuDescription"
            name="txtMenuDescription"
            cols="30"
            rows="10"
            v-model="stateItem.m_desc"
          ></textarea>
        </div>
      </div>
    </div>
    <div>
      <div class="input-group" v-bind:class="{picture_error:stateItem.pictureControl==0}">
        <croppa
          @image-remove="onRemoveImage"
          @new-image-drawn="onLoadImage"
          v-model="stateItem.myCroppa"
          @focus="dismissError('m_picture')"
          :height="200"
          :input-attrs="{class:'croppainput'}"
          :placeholder-font-size="16"
          :prevent-white-space="true"
          :width="200"
          :show-loading="true"
          canvas-color="#edf4f4"
          remove-button-color="black"
        ></croppa>
        <div class="validation-error" v-if="formValidation.m_picture.errorMessage">
          {{ formValidation.m_picture.errorMessage }}
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "MenusForm",
  computed: {
    ...mapState({ stateMenus: "menus" }),
    ...mapGetters(["getFormType"]),
    formValidation() {
      return this.stateMenus.formValidation;
    },
    stateItem() {
      return this.stateMenus.current;
    }
  },
  methods: {
    onLoadImage() {
      this.stateItem.pictureControl = 1;
    },
    onRemoveImage() {
      this.stateItem.pictureControl = 0;
    },
    /***   Validation Methods Start */
    dismissError (field) {
      this.stateMenus.formValidation[field].isValid = false;
      this.stateMenus.formValidation[field].errorMessage = "";
    },
    isEmpty(val) {
      return val === "" || val === " " || val === null ? true : false;
    },
    checkForm() {
      let checkingMenuName = this.checkingMenuName();
      let checkingMenuImage = this.checkingMenuImage();
      return checkingMenuName && checkingMenuImage ? true : false;
    },
    checkingMenuName() {
      if(this.isEmpty(this.stateItem.m_name)) {
        this.formValidation.m_name.isValid = false;
        this.formValidation.m_name.errorMessage = "Menu name cannnot be empty!.";
        return false;
      } 
      this.formValidation.m_name.isValid = true;
      this.formValidation.m_name.errorMessage = "";
      return true;
    },
    checkingMenuImage() {
      if(this.stateItem.pictureControl == 0) {
        this.formValidation.m_picture.isValid = false;
        this.formValidation.m_picture.errorMessage = "Menu image cannnot be empty!.";
        return false;
      } 
      this.formValidation.m_picture.isValid = true;
      this.formValidation.m_picture.errorMessage = "";
      return true;
    }
  }
};
</script>

<style lang="css" scoped>
.menu-form {
  display: grid; 
  grid-template-columns: repeat(2, minmax(0, 1fr)); 
  gap: 2.5rem; 
}
.menu-type {
  display: flex; 
  flex-direction: row; 
}
.menu-label {
  margin-bottom: 0;
}
.input-group {
  display: flex; 
  flex-direction: column; 
}
.imagearea {
  display: flex; 
  flex-direction: row; 
  justify-content: center; 
}
</style>