<template>
  <div class="tabs">
    <ul class="tabs-nav">
      <li
        @click="setTab(listItem.id)"
        v-for="listItem in list"
        :class="{active : listItem.id === selected}"
        :key="listItem.id"
      >
        <div v-if="listItem.menuType == 's'" class="store">
          {{ listItem.name }}
          <span v-if="tag">(STORE)</span>
        </div>
        <div v-else class="brand">
          {{ listItem.name }}
          <span v-if="tag">(BRAND)</span>
        </div>
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "TabsNav",
  props: {
    list: {
      type: Array
    },
    selected: {
      default: 0
    },
    tag: {
      type: Boolean
    }
  },
  methods: {
    setTab(tab) {
      this.$emit("goTo", tab);
    }
  }
};
</script>

<style lang="css" scoped>
.tabs-nav {
  overflow-x: auto;
  padding: 0;
  margin: 0;
  list-style-type: none;
  /* white-space: nowrap; */
  flex-direction: column;
  border-bottom-width: 1px;
  border-color: #f3f4f6;
  border-style: solid;
  margin-bottom: 1rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.tabs-nav li {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #6b7280;
  font-size: 1rem;
  line-height: 1.5rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  cursor: pointer;
}
.tabs-nav li.active {
  color: #4338ca;
  font-weight: 600;
  border-bottom-width: 4px;
  border-color: #4338ca;
  border-style: solid;
  background-color: aliceblue;
}
.tabs-nav li:hover {
  color: #000;
}
.store span {
  color: #fa2d60;
  font-size: 0.8rem;
}
.brand span {
  color: #fbbf24;
  font-size: 0.8rem;
}
</style>