<template lang="html">
  <div class="tabs">
    <ul class='tabs__header'>
      <li v-for='(tab, index) in tabs'
        :key='tab.title'
        @click='selectTab(index)'
        :class='{"tab__selected": (index == selectedIndex)}'>
        {{ tab.title }}
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      default: 'dark'
    }
  },
  data () {
    return {
      selectedIndex: 0, // the index of the selected tab,
      tabs: [], // all of the tabs
    }
  },
  created () {
    this.tabs = this.$children
  },
  mounted () {
    this.selectTab(0)
  },
  methods: {
    selectTab (i) {
      this.selectedIndex = i

      // loop over all the tabs
      this.tabs.map((tab, index) => {
        tab.isActive = (index === i)
      })
    }
  }
}
</script>

<style lang="css">

  ul.tabs__header {
    display: block;
    list-style: none;
    margin: 0 0 0 20px;
    padding: 0;
  }

  ul.tabs__header > li {
    padding: 15px 30px;
    border-radius: 10px;
    margin: 0;
    display: inline-block;
    margin-right: 5px;
    cursor: pointer;
    
  }

  ul.tabs__header > li.tab__selected {
    font-weight: bold;
    border-radius: 10px 10px 0 0;
    border-bottom: 8px solid transparent;
    color: #4338ca;
    background: #fff;
    font-weight: 600;
    border-bottom-width: 4px;
    border-color: #4338ca;
    border-style: solid;
  }

  .tab {
    display: inline-block;
    color: black;
    padding: 20px;
    min-width: 800px;
    border-radius: 10px;
    min-height: 400px;
    overflow-x: auto;
    padding: 0;
    margin: 0;
    list-style-type: none;
    /* white-space: nowrap; */
    flex-direction: column;
    /* border-bottom-width: 1px; */
    /* border-color: #f3f4f6; */
    /* border-style: solid; */
  }

  .tabs .tab{
    background-color: #fff;
    color: #000;
    padding: 2em;
  }

  .tabs li {
    /* background-color: #ddd; */
    /* color: #aaa; */
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: #6b7280;
    font-size: 1rem;
    line-height: 1.5rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    cursor: pointer;
  }

  .tabs li.tab__selected {
    background-color: #888;
    color: white;

  }

</style>