<template>
  <div id="option">
    <div class="option-items-wrapper">
      <h2 class="flex-grow">Option Items</h2>
      <div>
        <button class="btn-primary" @click="addOptionItem()">
          <span>Create Option Item</span>
        </button>
      </div>
    </div>
    <div class="tabs-wrapper">
      <div class="item" v-if="stateOptions.options.length == 0">No results found</div>  
      <TabsNav
        @goTo="setSelectedTab"
        :list="stateOptions.vTabMenu"
        :selected="stateOptions.vSelectedTab"
      >
        <Tab
          v-for="optionItemsList in stateOptions.options"
          :isSelected="stateOptions.vSelectedTab === optionItemsList.ghash"
          :key="optionItemsList.ghash"
        >
        <Panel :title="false">
          <template #row>
            <draggable
              v-model="optionItemsList.oitems"
              handle=".moveclass" 
              animation="150" 
              sort="true" 
              group="ohash" 
              @end="updateOptionOrder(optionItemsList.ghash)"
            >
              <div class="item" v-for="(item, idx) in optionItemsList.oitems" :key="idx">
                <div class="menu-item">
                  <div class>
                    <div class="menu-name">
                      <div>
                        {{ item.name }}
                        <span class="label" v-if="item.status === '0' || !item.status">Hidden</span>
                      </div>
                    </div>
                  </div>
                  <div class="sync" :class="{ 'not-sync': item.sync_pos_status == '0' }"></div>
                </div>
                <div class="item-actions">
                  <button @click.prevent="changeStatus(item, 'status')">
                    <i class="flaticon-show" :class="{ 'close': item.status == '0' }"></i>
                  </button>
                  <button @click.prevent="editOptionItem(item.ohash)">
                    <i class="flaticon-edit"></i>
                  </button>
                  <button class=moveclass>
                    <img src="../../assets/images/vertical-arrow.png" alt="" class="arrow">
                  </button>
                  <button @click="deleteOptionItem(item.ohash)">
                    <i class="flaticon-delete"></i>
                  </button>
                  <button @click.prevent="syncOption(item, '0')" class="sync-time">
                    <i class="flaticon-sync"></i>
                    <span v-if="item.sync_pos_time">Last Update:<br>{{ item.sync_pos_time }}</span>
                    <span v-else>Not Synced Yet</span>
                  </button>
                </div>
                <!-- confirm panel to delete the item -->
                <div class="confirm" v-if="isDeletePanelActive && deleteOptionHash==item.ohash">
                  <p>Are you sure you want to delete this option item?</p>
                  <div>
                    <button @click="deleteYes(item.ohash)">Yes</button>
                    <button @click="deleteNo()">No</button>
                  </div>
                </div>
              </div>
            </draggable>
          </template>
        </Panel>
        </Tab>
      </TabsNav>
    </div>
    <SecondModalBox v-if="stateOptions.stockModalBox" :alias="'newStock'">
      <template #modalTitle>New Stock</template>
      <template #modalBody>
        <StockForm ref="stockForm" />
      </template>
      <template #modalFooter>
        <StockFormButton />
      </template>
    </SecondModalBox>
    <ModalBox :alias="'newOptionItem'">
      <template #modalTitle>New Option Item</template>
      <template #modalBody>
          <OptionItemsForm ref="optionItemForm"></OptionItemsForm>
          <div v-for="(size, sIdx) in stateOptions.vSize" :key="sIdx">
            <SizeGroup :selement="size" :sIdx="sIdx" ref="sizeGroup"></SizeGroup>
          </div>
      </template>
      <template #modalFooter>
        <OptionItemsFormButtons></OptionItemsFormButtons>
      </template>
    </ModalBox>

    <ModalBox :alias="'editOptionItem'">
      <template #modalTitle>Edit Option Item</template>
      <template #modalBody>
          <OptionItemsForm ref="optionItemForm"></OptionItemsForm>
          <div v-for="(size, sIdx) in stateOptions.vSize" :key="sIdx">
            <SizeGroup :selement="size" :sIdx="sIdx" ref="sizeGroup"></SizeGroup>
          </div>
      </template>
      <template #modalFooter>
        <OptionItemsFormButtons></OptionItemsFormButtons>
      </template>
    </ModalBox>
  </div>
</template>

<script>
import { mapState } from "vuex";

import TabsNav from "@/widgets/Tabs/TabsNav";
import Tab from "@/widgets/Tabs/Tab";
import Panel from "@/widgets/Panel";
import store from "@/store";
import ModalBox from "@/widgets/ModalBox";
import SecondModalBox from '@/widgets/ModalBox/SecondModalBox.vue';
import StockForm from '../Stocks/StockForm';
import StockFormButton from '../Stocks/StockFormButtons';
import draggable from "vuedraggable";
import OptionItemsForm from "./OptionItemsForm";
import OptionItemsFormButtons from "./OptionItemsFormButtons";
import SizeGroup from "./widgets/SizeGroup.vue";

export default {
  name: "OptionItems",
  data() {
    return { 
      vNameAsc: true,
      storeHash: this.$store.state.stores.current.storeHash,
      isDeletePanelActive: false,
      deleteOptionHash: "",
    }
  },
  components: {
    ModalBox,
    SecondModalBox,
    TabsNav,
    Tab,
    Panel,
    OptionItemsForm,
    OptionItemsFormButtons,
    draggable,
    StockForm,
    StockFormButton,
    SizeGroup,
  },
  computed: {
    ...mapState({
      stateOptions: "options",
      stateStores: "stores",
      stateCommon: "common",
    }),
    sortOptionName() {
      return this.vNameAsc ? "name" : "-name";
    },
    formValidation() {
      return store.state.options.formValidation;
    },
  },
  methods: {
    showOptionModal(type, alias, optionItemHash) {
      let modalOpts = {
        isActiveStatus: true,
        type,
        alias,
      };
      if (optionItemHash) {
        modalOpts["hash"] = optionItemHash;
      }
      store.dispatch(
        "showModalBox",
        Object.assign(store.state.common.modalBox, modalOpts)
      );
    },
    setSelectedTab(tab) {
      this.stateOptions.vSelectedTab = tab;
    },
    changeStatus(item, props) {
      const $self = this;
      // y = 0: status, 1: pos_status, 2: web_status, 3: mobile_status
      let newstat = item[props] == 1 ? "0" : "1";

      const params = new URLSearchParams();
      params.append("ohash", item.ohash);
      params.append("newstat", newstat);      
      this.axios
        .post(
          `option/changestatus?s=${this.stateStores.current.storeHash}`,
          params
        )
        .then((response) => {
          if (response.data.status) {
            item.sync_pos_status = "0";
            item[props] = newstat;
          } else {
            $self.stateCommon.notification.notificationList.push({
              status: 1,
              title: "Success",
              message: response.data.message
            });
          }
        })
        .catch((error) => {
          console.error(error);
          $self.stateCommon.notification.notificationList.push({
            status: -1,
            title: "Error",
            message: error
          });
        });
    },
    updateOptionOrder (ghash) {
      // get new orders with ohash
      const optionList = [];
      this.stateOptions.options.map((option) => {
        if (option.ghash === ghash) {
          option.oitems.map((item) => {
            optionList.push({
              ohash: item.ohash,
            });
          });
        }
      });
      store.dispatch("updateOptionOrder", {
        storeHash: this.storeHash,
        groupHash: ghash,
        optionList,
      })
    },
    currentTime() {
      const pad = (num) => (num < 10 ? `0${num}` : num);
      let now = new Date();
      let date = `${now.getFullYear()}-${pad(now.getMonth() + 1)}-${pad(now.getDate())}`;
      let time = `${pad(now.getHours())}:${pad(now.getMinutes())}:${pad(now.getSeconds())}`;
      return `${date} ${time}`;
    },
    async syncOption(item) {
      store.commit('LOADING', { isActiveStatus: true });
      try {
        let { data } = await this.axios.get(`sync/pos/option?o=${item.ohash}`);
        if(data.status) {
          item.sync_pos_time = this.currentTime();
          item.sync_pos_status = "1";
          store.commit('NOTIFICATION_MESSAGE', { status: 1, title: 'Success', message: 'Synced successfully.'});
        } else {
          store.commit('NOTIFICATION_MESSAGE', { status: -1,title: 'Error',message: data.message});
          item.sync_pos_status = "0";
        }
      } catch (error) {
        store.commit('NOTIFICATION_MESSAGE', { status: -1, title: 'Error', message: error.message,
        });
        item.sync_pos_status = "0";
      }
      store.commit('LOADING', { isActiveStatus: false });
    }, 
    addOptionItem() {
      this.showOptionModal(0, "newOptionItem");
    },
    editOptionItem(optionItemHash) {
      store.commit("LOADING", { isActiveStatus: true });
      setTimeout(() => {
        store.commit("GET_OPTIONS_ITEM", optionItemHash)
        store.commit("LOADING", { isActiveStatus: false });
        this.showOptionModal(1, "editOptionItem", optionItemHash);
      }, 500);      
    },
    deleteOptionItem(optionItemHash) {
      this.isDeletePanelActive = true;
      this.deleteOptionHash = optionItemHash;
    },
    deleteYes(optionItemHash) {
      store.dispatch("deleteOptionItems", optionItemHash);
      this.deleteNo();
    },
    deleteNo() {
      this.deleteOptionHash = "";
      this.isDeletePanelActive = false;
    },
  }
};
</script>

<style scoped>

.option-items-wrapper {
  display: flex; 
  padding-bottom: 1rem; 
  margin-bottom: 1rem; 
  justify-content: space-between; 
  align-items: center; 
  border-bottom-width: 1px;
  border-color: #F3F4F6; 
}
.flex-grow {
  flex-grow: 1;
}
.table-filter-wrapper {
  margin-bottom: 1.5rem;
  padding: 1rem 0;
}
.table-show-wrapper {
  display: flex;
  flex-direction: row;
}
.sorting-wrapper {
  margin-right: 2rem;
}
.option-box-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
}
.option-box {
  display: block;
  width: 340px;
  margin: 0 10px 10px 0;
  padding: 2rem;
  /* font-size: 1.6rem; */
  font-weight: 400;
  text-align: left;
  background: var(--gray-50);
  border: 1px solid var(--gray-100);
  border-radius: var(--rounded-lg);
}
.no-result {
  font-size: 1.6rem;
}
button.ml-2 {
  margin-left: 0.5rem; 
}
</style>
