<template>
  <div>
    <div class="stock-form">
      <div class="row">
        <div class="column">
          <div class="form-wrapper">
            <div
              class="form-row"
              :class="{ error: formValidation.vStockSKU.errorMessage }"
            >
              <div class="input-group">
                <label for="stockSKU">SKU</label>
                <div class="input-group-item">
                  <input
                      id="stockSKU"
                      name="stockSKU"
                      type="text"
                      v-model="stocks.vStockSKU"
                      autocomplete="off"
                      @focus="dismissError('vStockSKU')"
                  />
                  <div
                  class="validation-error"
                  v-if="formValidation.vStockSKU.errorMessage"
                  >
                    {{ formValidation.vStockSKU.errorMessage }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="form-row"
              :class="{ error: formValidation.vStockBarcode.errorMessage }"
            >
              <div class="input-group">
                <label for="stockBarcode">Barcode</label>
                <div class="input-group-item">
                  <input
                    id="stockBarcode"
                    name="stockBarcode"
                    type="text"
                    v-model="stocks.vStockBarcode"
                    autocomplete="off"
                    @focus="dismissError('vStockBarcode')"
                  />
                  <div
                    class="validation-error"
                    v-if="formValidation.vStockBarcode.errorMessage"
                  >
                    {{ formValidation.vStockBarcode.errorMessage }} 
                  </div>
                </div>
              </div>
            </div>
            <div
              class="form-row"
              :class="{ error: formValidation.vStockName.errorMessage }"
            >
              <div class="input-group">
                <label for="stockDefinition">Stock Name</label>
                <div class="input-group-item">
                  <input
                    id="stockDefinition"
                    name="stockDefinition"
                    type="text"
                    v-model="stocks.vStockName"
                    autocomplete="off"
                    @focus="dismissError('vStockName')"
                  />
                  <div
                    class="validation-error"
                    v-if="formValidation.vStockName.errorMessage"
                  >
                    {{ formValidation.vStockName.errorMessage }}
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="flex">
                <div class="form-col" :class="{error: formValidation.vStockUnitHash.errorMessage}">
                  <div class="input-group">
                    <label for="stockUnitDefinition">Stock Unit</label>
                      <div class="input-group-item">
                        <select
                          id="stockUnitDefinition"
                          name="stockUnitDefinition"
                          v-model="stocks.vStockUnitHash"
                          @focus="dismissError('vStockUnitHash')"
                          @change="updateUnitMultiplier(stocks.vStockUnitHash, stocks.vStockUnitPrice)"
                        >
                          <option disabled>Select Unit</option>
                          <option
                          v-for="(unit, index) in common.vUnitList"
                          :value="unit.unit_hash"
                          :key="index"
                          >
                          {{ stocks.vStockUnitHash === unit.unit_hash ? unit.unit_name + ' (Current)' : unit.unit_name }}
                          </option>
                        </select>
                        <div
                          class="validation-error"
                          v-if="formValidation.vStockUnitHash.errorMessage"
                        >
                          {{ formValidation.vStockUnitHash.errorMessage }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-col" :class="{ error: formValidation.vStockUnitPrice.errorMessage }">
                    <div class="input-group">
                      <label for="stockUnitPrice">Unit Price</label>
                      <div class="input-group-item">
                        <input
                          id="stockUnitPrice"
                          name="stockUnitPrice"
                          type="number"
                          min="0"
                          step=".01"
                          v-model="stocks.vStockUnitPrice"
                          autocomplete="nope"
                          @focus="dismissError('vStockUnitPrice')"
                          @input="updateUnitMultiplier(stocks.vStockUnitHash, $event.target.value)"
                        />
                        <div
                          class="validation-error"
                          v-if="formValidation.vStockUnitPrice.errorMessage"
                        >
                          {{ formValidation.vStockUnitPrice.errorMessage }}
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="flex">
                  <div class="form-col" :class="{ error: formValidation.vStockGST.errorMessage }">
                      <div class="input-group">
                          <label for="stockGST">GST</label>
                          <div class="input-group-item">
                              <input
                                  id="stockGST"
                                  name="stockGST"
                                  type="number"
                                  min="0"
                                  v-model="stocks.vStockGST"
                                  autocomplete="nope"
                                  @focus="dismissError('vStockGST')"
                                  
                              />
                              <div class="validation-error" v-if="formValidation.vStockGST.errorMessage">
                                  {{ formValidation.vStockGST.errorMessage }}
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="form-col" :class="{ error: formValidation.vStockPST.errorMessage }">
                      <div class="input-group">
                          <label for="stockPST">PST</label>
                          <div class="input-group-item">
                              <input
                                  id="stockPST"
                                  name="stockPST"
                                  type="number"
                                  min="0"
                                  v-model="stocks.vStockPST"
                                  autocomplete="nope"
                                  @focus="dismissError('vStockPST')"
                                  
                              />
                              <div class="validation-error" v-if="formValidation.vStockPST.errorMessage">
                                  {{ formValidation.vStockPST.errorMessage }}
                              </div>
                          </div>
                      </div>
                  </div>
                  
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="form-col table-list editable-table-wrapper">
            <div class="input-group">
              <label for="txtcompany">Vendor</label>
              <div class="text-group">
                <div class="table-list editable-table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th width="100">Vendor Name</th>
                        <th width="10" class="table-actions-col">Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(field, index) in stateTableFields"
                        :key="index"
                        @click="selectedRowIndex(index)"
                      >
                        <td>
                          <div class="write">
                            <div class="form-group">
                              <VendorAutoComplete
                                :minChar="3"
                                :tParams="'company'"
                                :text="field.vendor_name"
                                :hash="field.vendor_hash"
                                @clickedSelectItem="assignVendor"
                                v-model="field.vendor_name"
                              ></VendorAutoComplete>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="item-actions">
                            <button @click="deleteRow(field)">
                              <i class="flaticon-delete"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="validation-error" v-if="formValidation.vVendorList.errorMessage">
                      {{ formValidation.vVendorList.errorMessage }}
                  </div>
                </div>
                <div class="invoice-total-wrapper">
                    <button class="btn-secondary" @click="addRow">
                    <i class="flaticon-plus"></i>
                    <span>Add Line</span>
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column" v-if="stocks.transactionList.length > 0"> 
          <div class="form-wrapper">
            <div
              class="form-row"
              :class="{ error: formValidation.vStockSKU.errorMessage }"
            >
              <div class="input-group stock-transaction">
                <label for="stockTransaction" class="transaction-header">Stock Transactions</label>
                <dataset v-slot="{ ds }" :ds-data="stocks.transactionList" :ds-filter-fields="{}" :ds-sortby="sortBy"
                  :ds-sort-as="{ transaction_type: numberParse, transaction_source: numberParse, transaction_total_price: numberParse, stock_quantity: numberParse, creation_time: dateParse, creation_user: numberParse }"
                  :ds-search-in="[
                  'stock_name',
                  'stock_cost',
                  'stock_amount',
                  'stock_unit',
                  ]" :ds-search-as="{}"
                  >
                  <div :data-page-count="ds.dsPagecount">
                    <div class="table-list" v-if="stocks.transactionList.length > 0">
                      <table>
                        <thead>
                            <tr>
                            <th v-for="(th, index) in cols" :key="th.field" :class="['sort', th.sort]"
                                @click="clickSort($event, index)">
                                {{ th.name }}
                                <i class="gg-select"></i>
                            </th>
                            </tr>
                        </thead>
                        <dataset-item tag="tbody">
                          <template v-slot="{ row, rowIndex }">
                            <tr :key="rowIndex">
                              <td width="10%">{{ row.transactions_type }}</td>
                              <td width="10%">{{ row.transactions_source }}</td>
                              <td width="5%">${{ row.transaction_total_price }}</td>
                              <td width="5%">{{ row.stock_quantity }}</td>
                              <td width="10%">{{ row.creation_time }}</td>
                              <td width="10%">{{ row.creation_user }}</td>
                              <td width="10%">{{ row.update_time }}</td>
                              <td width="10%">{{ row.update_user }}</td>
                            </tr>
                          </template>
                          <template v-slot:noDataFound> 
                            <tr>
                              <td colspan="8">No results found</td>
                            </tr>
                          </template>
                        </dataset-item>
                      </table>
                    </div>
                    <div class="table-pagination" v-if="stocks.transactionList.length > 0">
                      <div class="table-pagination-info-wrapper">
                        <dataset-info />
                      </div>
                      <div class="table-pagination-pager-wrapper">
                        <dataset-pager />
                      </div>
                    </div>
                  </div>
                </dataset>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="column">
          <div class="form-col table-list editable-table-wrapper">
            <div class="input-group">
              <label for="txtcompany">Unit Multiplier</label>
              <div class="text-group">
                <div class="table-list editable-table-wrapper">
                  <table>
                    <thead>
                      <tr>
                          <th width="40">Unit Name</th>
                          <th width="40">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(unit, index) in stateUnitFields"
                        :key="index"
                        @click="selectedRowIndex(index)"
                      >
                        <td>{{ unit.unit_name }}</td>
                        <td>
                          <div class="write">
                            <div class="form-group">
                              <input
                                type="number"
                                min="0"
                                v-model="unit.unit_multiplier"
                                v-if="unit.unit_hash === stocks.vStockUnitHash"
                                disabled
                                @input="updateUnitMultiplier(unit.unit_hash, $event.target.value)"
                              />
                              <input
                                type="number"
                                min="0"
                                v-model="unit.unit_multiplier"
                                v-else
                                @input="updateUnitMultiplier(unit.unit_hash, $event.target.value)"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapState, mapGetters } from "vuex";
import { mask } from "vue-the-mask";
import VendorAutoComplete from "@/widgets/AutoComplete/VendorAutoComplete";
import {
  Dataset,
  DatasetItem,
  DatasetInfo,
  DatasetPager,
} from "vue-dataset";

export default {
  name: "StockForm",
  data() {
    return {
      cols: [
        {
          name: "Type",
          field: "transaction_type",
          sort: "",
        },
        {
          name: "Source",
          field: "transaction_source",
          sort: "",
        },
        {
          name: "Total Price",
          field: "transaction_total_price",
          sort: "",
        },
        {
          name: "Stock Quantity",
          field: "stock_quantity",
          sort: "",
        },
        {
          name: "Creation Time",
          field: "creation_time",
          sort: "",
        },
        {
          name: "Create User",
          field: "creation_user",
          sort: "",
        },
        {
          name: "Update Time",
          field: "update_time",
          sort: "",
        },
        {
          name: "Update User",
          field: "update_user",
          sort: "",
        },
      ],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "$",
        precision: 2,
        masked: false,
      },
      croppa: {},
      unitList: [],
    }
  },
  components: {
    Dataset,
    DatasetItem,
    DatasetInfo,
    DatasetPager,
    // DatasetShow,
    VendorAutoComplete,
  },
  directives: {
    mask
  },
  watch: {
    getPage() {
      store.dispatch("getTransactionList",{
        stockHash: this.stocks.vStockHash,
        storeHash: this.stores.current.storeHash,
        brandHash: this.stores.current.brandHash,
        page: this.getPage,
      });
    },
  },
  beforeMount() {
    this.fistUnitList();
  },
  created() {
    this.setUnitMulitiplier();
  },
  beforeDestroy() {
    this.clearForm();
  },
  computed: {
    ...mapState({
      stocks: "stocks",
      common: "common",
      stores: "stores",
      stateTableFields: state => state.stocks.vVendorList,
      stateUnitFields: state => state.stocks.vUnitList,
      statecommonFields: state => state.common.vUnitList,
    }),
    ...mapGetters(["getPage", "getFormType"]),
    sortBy() {
      return this.cols.reduce((acc, o) => {
        if (o.sort) {
          o.sort === "asc" ? acc.push(o.field) : acc.push("-" + o.field);
        }
        return acc;
      }, []);
    },
    formValidation() {
      return store.state.stocks.formValidation;
    }
  },
  methods: {
    numberParse(val) {
      return parseFloat(val);
    },
    dateParse(val) {
      return new Date(val).getTime();
    },
    clickSort(event, i) {
      let toset;
      const sortEl = this.cols[i];
      if (!event.shiftKey) {
      this.cols.map(o => {
        if (o.field !== sortEl.field) {
        o.sort = "";
        }
      });
      }
      if (!sortEl.sort) {
        toset = "asc";
      }
      if (sortEl.sort === "desc") {
        toset = event.shiftKey ? "" : "asc";
      }
      if (sortEl.sort === "asc") {
        toset = "desc";
      }
      sortEl.sort = toset;
    },
    clearForm() {
      store.commit("RESET_STOCK_FORM");
    },
    // vendor assignments
    assignVendor(value) {            
      this.stateTableFields[this.stocks.selectedRow].vendor_hash = value.hash;
      this.stateTableFields[this.stocks.selectedRow].vendor_name = value.name;
      this.dismissError('vVendorList');
    },
    addRow() {
      this.dismissError('vVendorList');
      this.stateTableFields.push({
        vendor_hash: '',
        vendor_name: '',
      });
    },
    deleteRow(field) {
      // delete selected row
      let idx = this.stateTableFields.indexOf(field);
      if (idx > -1) {
        this.stateTableFields.splice(idx, 1);
      }
    },
    selectedRowIndex(idx) {
      this.stocks.selectedRow = idx;
    },
    // for set up unit multiplier
    setUnitMulitiplier() {
      // if create new stock (getFormType: 0), set default unit list
      // if update stock (getFormType: 1), set unit list
      if(this. getFormType === 0) {
        store.commit("SET_UNIT_LIST", this.statecommonFields);
      } else if (this.getFormType === 1){
        store.commit("SET_UNIT_LIST_UPDATE");
      }
    },
    // for update unit multiplier
    updateUnitMultiplier(hash, newValue) {
      this.stateUnitFields.map((unit) => {
        if (hash === unit.unit_hash) {
          unit.unit_multiplier = Number(newValue);
        }
      });
    },
    // for reset unit multiplier
    fistUnitList() {
      this.stateUnitFields.map((unit) => {
        this.unitList.push({
          unit_hash: unit.unit_hash,
          unit_name: unit.unit_name,
          unit_multiplier: unit.unit_multiplier,
        });
      });
    },
    restoreUnit() {
      this.stateUnitFields.map((unitField) => {
        this.unitList.map((unit) => {
          if (unit.unit_hash === unitField.unit_hash) {
            unitField.unit_multiplier = unit.unit_multiplier;
          }
        });
      });
    },
    /***   Validation Methods Start */
    dismissError (field) {
      this.formValidation[field].isValid = false;
      this.formValidation[field].errorMessage = "";
    },
    isEmpty(val) {
      return val === "" || val === " " || val === null ? true : false;
    },
    checkForm() {
      let checkingStockSKU = this.isValidStockSKU();
      let checkingStockBarcode = this.isValidStockBarcode();
      let checkingStockDefinition = this.isValidStockDefinition();
      let checkingStockUnitDefinition = this.isValidStockUnitDefinition();
      let checkingStockUnitPrice = this.isValidStockUnitPrice();
      let checkingStockGST = this.isValidStockGST();
      let checkingStockPST = this.isValidStockPST();
      let checkingVendorList = this.isValidVendorList();
      return checkingStockSKU &&
        checkingStockBarcode &&
        checkingStockDefinition &&
        checkingStockUnitDefinition &&
        checkingStockUnitPrice &&
        checkingStockGST &&
        checkingStockPST && 
        checkingVendorList
        ? true
        : false;
    },
    isValidStockSKU() {
      if (this.isEmpty(this.stocks.vStockSKU)) {
        this.formValidation.vStockSKU.isValid = false;
        this.formValidation.vStockSKU.errorMessage =
        "Stock SKU cannot be empty!";
        return false;
      }
      this.formValidation.vStockSKU.isValid = true;
      this.formValidation.vStockSKU.errorMessage = "";
      return true;
    },
    isValidStockBarcode() {
      if (this.isEmpty(this.stocks.vStockBarcode)) {
        this.formValidation.vStockBarcode.isValid = false;
        this.formValidation.vStockBarcode.errorMessage = "Barcode cannot be empty!";
        return false;
      }
      this.formValidation.vStockBarcode.isValid = true;
      this.formValidation.vStockBarcode.errorMessage = "";
      return true;
    },
    isValidStockDefinition() {
      if (this.isEmpty(this.stocks.vStockName)) {
        this.formValidation.vStockName.isValid = false;
        this.formValidation.vStockName.errorMessage =
        "Stock name cannot be empty!";
        return false;
      }
      this.formValidation.vStockName.isValid = true;
      this.formValidation.vStockName.errorMessage = "";
      return true;
    },
    isValidStockUnitDefinition() {
      if (this.isEmpty(this.stocks.vStockUnitHash)) {
        this.formValidation.vStockUnitHash.isValid = false;
        this.formValidation.vStockUnitHash.errorMessage =
        "Stock unit cannot be empty!";
        return false;
      }
      this.formValidation.vStockUnitHash.isValid = true;
      this.formValidation.vStockUnitHash.errorMessage = "";
      return true;
    },
    isValidStockUnitPrice() {
      if (this.isEmpty(this.stocks.vStockUnitPrice)) {
        this.formValidation.vStockUnitPrice.isValid = false;
        this.formValidation.vStockUnitPrice.errorMessage =
        "Stock unit price cannot be empty!";
        return false;
      } else if(this.stocks.vStockUnitPrice <= 0) {
        this.formValidation.vStockUnitPrice.isValid = false;
        this.formValidation.vStockUnitPrice.errorMessage =
        "Stock unit price cannot be zero or negative!";
        return false;
      }
      this.formValidation.vStockUnitPrice.isValid = true;
      this.formValidation.vStockUnitPrice.errorMessage = "";
      return true;
    },
    isValidStockGST() {
      if (this.isEmpty(this.stocks.vStockGST)) {
        this.formValidation.vStockGST.isValid = false;
        this.formValidation.vStockGST.errorMessage =
        "Goods and Services Tax cannot be empty!";
        return false;
      }
      this.formValidation.vStockGST.isValid = true;
      this.formValidation.vStockGST.errorMessage = "";
      return true;
    },
    isValidStockPST() {
      if (this.isEmpty(this.stocks.vStockPST)) {
        this.formValidation.vStockPST.isValid = false;
        this.formValidation.vStockPST.errorMessage =
        "Provincial Sales Tax cannot be empty!";
        return false;
      }
      this.formValidation.vStockPST.isValid = true;
      this.formValidation.vStockPST.errorMessage = "";
      return true;
    },
    isValidVendorList() {
      if (this.stocks.vVendorList.length === 0) {
        this.formValidation.vVendorList.isValid = false;
        this.formValidation.vVendorList.errorMessage =
        "Vendor list cannot be empty!";
        return false;
      } else if (this.stocks.vVendorList.length > 0) {
        // check if vendor list has vendor_hash=""
        let isVendorHash = this.stocks.vVendorList.some(vendor => vendor.vendor_hash === "");
        // check if vendor is duplicated
        let isDuplicated = this.stocks.vVendorList.some((vendor, index) => {
          return this.stocks.vVendorList.findIndex((v) => v.vendor_hash === vendor.vendor_hash) !== index;
        });
        if(isVendorHash) {
          this.formValidation.vVendorList.isValid = false;
          this.formValidation.vVendorList.errorMessage =
          "Vendor is not added!";
          return false;
        } else if (isDuplicated) {
          this.formValidation.vVendorList.isValid = false;
          this.formValidation.vVendorList.errorMessage =
          "Vendor list cannot be duplicated!";
          return false;
        }
      }
      this.formValidation.vVendorList.isValid = true;
      this.formValidation.vVendorList.errorMessage = "";
      return true;
    },
  }
}
</script>

<style scoped>
.stock-form {
  display: flex;
  align-items: baseline;
  gap: 30px;
}
textarea {
  min-width: 30rem;
  min-height: 5rem;
  max-height: 10rem;
}
.form-col {
  width: 100%;
}
.flex {
  display: flex;
  justify-content: flex-start;
  gap: 30px;
}
.stock-transaction {
  margin-top: 1.5rem;
}
.transaction-header {
  margin: 1.5em 0;
}
.flex-grow {
  flex-grow: 1; 
}
.table-search-wrapper  input {
  width: 50px;
  min-width: unset;
  margin-left: 1rem; 
  text-align: center;
}
.table-list table {
  margin-top: 0;
}
.actions-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; 
}
.form-row .input-group label {
  margin: 0.5rem 0;
}
</style>