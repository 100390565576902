<template>
    <div class="form-button-actions">
        <button class="btn-secondary" @click="cancel()">Cancel</button>
        <button class="btn-primary" @click="updateCustomerScreen()" v-if="getFormType == 1">Update</button>
        <button class="btn-primary" @click="saveCustomerScreen()" v-else>Save</button>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import store from '@/store';

export default {
    name: 'CustomerScreenFormButton',
    computed: {
        ...mapState({
            stateCustomerScreen: "customerscreens"
        }),
        ...mapGetters(["getFormType"])
    },
    methods: {
        cancel() {
            store.dispatch("closeCustomerScreenModalBox");
        },
        updateCustomerScreen() {
            const screenHash = this.stateCustomerScreen.vCustomerScreenHash;
            if (this.$parent.$parent.$refs.customerScreenForm.checkForm()) {
                store.dispatch("updateCustomerScreen", screenHash);
            }
        },
        saveCustomerScreen() {
            if (this.$parent.$parent.$refs.customerScreenForm.checkForm()) {
                store.dispatch("saveCustomerScreen");
            }
        }
    }
}
</script>