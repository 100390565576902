<template>
  <div id="customer-screen">
    <div class="customer-screen-header">
      <h2 class="flex-grow">Customer Queue Screens</h2>
      <div>
        <button class="btn btn-primary" @click.prevent="showCustomerScreenModal(0, 'newCustomerScreen')">Create Screen</button>
      </div>
    </div>
    <div class="customer-screen">
      <div class="table-list-data">
        <dataset v-slot="{ ds }" :ds-data="queue" :ds-filter-fields="{}" :ds-sortby="sortBy" :ds-sort-as="{}"
          :ds-search-in="[]" :ds-search-as="{}">
            <div :data-page-count="ds.dsPagecount" class="table-filter-wrapper">
              <div class="actions-menu">
                  <div class="table-search-wrapper">
                    <dataset-search ds-search-placeholder="Search..." />
                  </div>
              </div>
              <div class="table-show-wrapper">
                  <dataset-show />
              </div>
            </div>
            <div class="table-list">
              <table>
                <thead>
                  <th v-for="(th, index) in cols" :key="th.field" :class="['sort', th.sort]"
                    @click="clickSort($event, index)">
                    {{ th.name }}
                    <i class="gg-select"></i>
                  </th>
                  <th></th>
                </thead>
                <dataset-item tag="tbody">
                  <template v-slot="{ row, rowIndex }">
                    <tr :key="rowIndex">
                      <td width="30%">{{ row.name }}</td>
                      <td width="50%">{{ row.image }}</td>
                      <td width="20%">
                        <div class="item-actions">
                          <button @click="showCustomerScreenModal(1, 'editCustomerScreen', row.image)">
                            <i class="flaticon-edit"></i>
                          </button>
                          <button @click="deleteCustomerScreen(row.image)">
                            <i class="flaticon-delete"></i>
                          </button>
                          <button @click.prevent="syncPos(row.image)">
                            <i class="flaticon-sync"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-slot:noDataFound>
                    <tr>
                      <td colspan="8">No results found</td>
                    </tr>
                  </template>
                </dataset-item>
              </table>
            </div>
            <div class="table-pagination">
              <div class="table-pagination-pager-wrapper">
                  <dataset-pager />
              </div>
              <div class="table-pagination-info-wrapper">
                  <dataset-info />
              </div>
            </div>
        </dataset>
      </div>
    </div>
    <ModalBox :alias="'newCustomerScreen'">
      <template #modalTitle>New Image</template>
      <template #modalBody>
        <CustomerScreenForm ref="customerScreenForm" />
      </template>
      <template #modalFooter>
        <CustomerScreenFormButton />
      </template>
    </ModalBox>
    <ModalBox :alias="'editCustomerScreen'">
      <template #modalTitle>Edit Image</template>
      <template #modalBody>
        <CustomerScreenForm ref="customerScreenForm" />
      </template>
      <template #modalFooter>
        <CustomerScreenFormButton />
      </template>
    </ModalBox>
  </div>
</template>

<script>
import store from "@/store";
import ModalBox from '@/widgets/ModalBox';
import CustomerScreenForm from "./CustomerScreenForm.vue";
import CustomerScreenFormButton from "./CustomerScreenFormButton.vue";
import {
  Dataset,
  DatasetItem,
  DatasetInfo,
  DatasetPager,
  DatasetSearch,
  DatasetShow,
} from "vue-dataset";

export default {
  name: "CustomerScreen",
  data() {
    return {
      queue: [
        {
          id: 1,
          name: 'Queue 1',
          image: 'https://www.megabitepizza.com/images/screen/granville-dayscreen3-v3.jpg',
        },
        {
          id: 2,
          name: 'Queue 2',
          image: 'https://www.megabitepizza.com/images/screen/granville-dayscreen3-v3.jpg',
        },
        {
          id: 3,
          name: 'Queue 3',
          image: 'https://www.megabitepizza.com/images/screen/granville-dayscreen3-v3.jpg',
        }
      ],
      cols: [
        {
          name: 'Name',
          field: 'name',
          sort: 'name',
        },
        {
          name: 'Image',
          field: 'image',
          sort: 'image',
        },
      ],
    }
  },
  components: {
    ModalBox,
    CustomerScreenForm,
    CustomerScreenFormButton,
    Dataset,
    DatasetItem,
    DatasetInfo,
    DatasetPager,
    DatasetSearch,
    DatasetShow,
  },
  computed: {
    sortBy() {
      return this.cols.reduce((acc, o) => {
        if (o.sort) {
          o.sort === "asc" ? acc.push(o.field) : acc.push("-" + o.field);
        }
        return acc;
      }, []);
    },
  },
  methods: {
    numberParse(val) {
      return parseFloat(val);
    },
    clickSort(event, i) {
      let toset;
      const sortEl = this.cols[i];
      if (!event.shiftKey) {
        this.cols.map(o => {
          if (o.field !== sortEl.field) {
            o.sort = "";
          }
        });
      }
      if (!sortEl.sort) {
        toset = "asc";
      }
      if (sortEl.sort === "desc") {
        toset = event.shiftKey ? "" : "asc";
      }
      if (sortEl.sort === "asc") {
        toset = "desc";
      }
      sortEl.sort = toset;
    },
    syncPos() {
      console.log("syncPos");
    },
    showCustomerScreenModal(type, alias, adHash) {
      let modalOpts = {
        isActiveStatus: true,
        type,
        alias,
      };
      if (adHash) {
        store.dispatch("getCustomerScreen", adHash);
        modalOpts["hash"] = adHash;
      }
      store.dispatch(
        "showModalBox",
        Object.assign(store.state.common.modalBox, modalOpts)
      );
    },
    deleteCustomerScreen(adHash) {
      console.log("deleteCustomerScreen", adHash);
    },
  }
};
</script>

<style lang="css" scoped>
.customer-screen-header {
  display: flex;
  margin-bottom: 1rem;
  margin-right: 1rem;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 1px;
  border-color: #F3F4F6;
}
.table-show-wrapper {
  margin-right: 1rem;
}
.flex-grow {
  flex-grow: 1;
}

.item-actions {
  justify-content: center;
}

.item-actions button {
  font-size: 1.6em;
  line-height: 1.5rem;
  padding: 0 10px;
  background: none;
  border: none;
  outline: none;
  font-weight: 600;
  border-radius: var(--rounded-lg);
  margin: 0 auto;
}
</style>