import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "./vue-axios";

// css
import "@/assets/styles/styles.css";

// $http assign for store
// Vue.prototype.$http = axios;
window.$http = axios;

import store from "./store";

// import HeaderWrapper from "@/components/Dashboard/HeaderWrapper";
// Vue.component("HeaderWrapper", HeaderWrapper);

import Loader from "@/widgets/Loader";
Vue.component("Loader", Loader);

import Notification from "@/widgets/Notification";
Vue.component("Notification", Notification);

import ModalBox from "@/widgets/ModalBox";
Vue.component("ModalBox", ModalBox);

import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
Vue.component("VueCtkDateTimePicker", VueCtkDateTimePicker);
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

// Vue.config.productionTip = false;

import "@/assets/styles/flaticon.css";
import "@/assets/styles/base.css";
import "@/assets/styles/_forms.css";

/* *** Croppa *** */
import "vue-croppa/dist/vue-croppa.css";
import Croppa from "vue-croppa";
Vue.use(Croppa);
/* *** *** *** *** */

// Vue Mask
import VueMask from 'v-mask'
Vue.use(VueMask);

// Disable the development mode warning
Vue.config.productionTip = false

new Vue({
  router,
  store,
  axios,
  // services,
  render: (h) => h(App),
}).$mount("#app");
