<template>
  <div class="coupon-form">
    <div class="row">
      <div class="column">
        <!-- <h3 class="text-base">Coupon</h3> -->
        <div class="form-row">
          <div class="input-group">
            <label for="discountType">Discount Type</label>
            <div class="input-group-item">
              <select
                id="discountType"
                name="discountType"
                v-model="stateCoupons.current.c_type"
              >
                <option value="0">Percentage (%)</option>
                <option value="1">Money ($)</option>
                <option value="2">Discount for Same Second Product (%)</option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-row" v-if=" stateCoupons.current.c_type !== 3 && stateCoupons.current.c_type !== 4">
          <div class="input-group">
            <label for="productType">Product Type</label>
            <div class="input-group-item">
              <select
                id="productType"
                name="productType"
                v-model="stateCoupons.current.p_type"
              >
                <option value="0">All Products</option>
                <option value="1">Special Products</option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="checkbox-group-wrapper product-wrapper" v-if="stateCoupons.current.p_type == 1">
            <div class="checkbox-group" v-for="(item, idx) in getAllProducts" :key="idx">
              <input
                type="checkbox"
                v-model="stateCoupons.current.c_phash"
                :id="item.phash"
                :value="item.phash"
              />
              <label :for="item.phash">
                {{ item.name }}
                <div class="font-semibold">{{ item.mname }}</div>
              </label>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="input-group">
            <label for="serviceType">Service Type</label>
            <div class="input-group-item">
              <select
                id="serviceType"
                name="serviceType"
                v-model="stateCoupons.current.s_type"
              >
                <option value="0">All Services</option>
                <option value="1">Only Delivery</option>
                <option value="2">Only Pickup</option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="input-group">
            <label for="txtCouponTitle">Coupon Title</label>
            <input
              id="txtCouponTitle"
              name="txtCouponTitle"
              type="text"
              v-model="stateCoupons.current.c_name"
              @focus="dismissError('vCouponName')"
            />
          </div>
          <div class="validation-error" v-if="formValidation.vCouponName.errorMessage">
            {{ formValidation.vCouponName.errorMessage }}
          </div>
        </div>
        <div class="form-row">
          <div class="input-group">
            <label for="txtDiscount">Discount</label>
            <input
              id="txtDiscount"
              name="txtDiscount"
              type="number"
              min="0"
              v-model="stateCoupons.current.c_discount"
              @focus="dismissError('vCouponDiscount')"
            />
          </div>
          <div class="validation-error" v-if="formValidation.vCouponDiscount.errorMessage">
            {{ formValidation.vCouponDiscount.errorMessage }}
          </div>
        </div>
        <div class="form-row">
          <div class="input-group">
            <label for="txtCouponDescription">Coupon Description</label>
            <input
              id="txtCouponDescription"
              name="txtCouponDescription"
              type="text"
              v-model="stateCoupons.current.c_desc"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="input-group">
            <label for="txtMinimumPrice">Minimum Price</label>
            <input
              type="text"
              name="txtMinimumPrice"
              id="txtMinimumPrice"
              v-model="stateCoupons.current.c_min_price"
            />
          </div>
        </div>
        <div class="form-row flex">
          <div class="mr-10 input-group">
            <label class="mb-2">Web</label>
            <ToggleCheckbox
              @clickToggle="changeStatus($event, 'WEB')"
              :onOff="stateCoupons.current.l_type_online == 1"
            ></ToggleCheckbox>
          </div>
          <div class="mr-10 input-group">
            <label class="mb-2">POS</label>
            <ToggleCheckbox
              @clickToggle="changeStatus($event, 'POS')"
              :onOff="stateCoupons.current.l_type_pos == 1"
            ></ToggleCheckbox>
          </div>
          <div class="mr-10 input-group">
            <label class="mb-2">Kiosk</label>
            <ToggleCheckbox
              @clickToggle="changeStatus($event, 'KIOSK')"
              :onOff="stateCoupons.current.l_type_kiosk == 1"
            ></ToggleCheckbox>
          </div>
          <div class="mr-10 input-group">
            <label class="mb-2">Mobile</label>
            <ToggleCheckbox
              @clickToggle="changeStatus($event, 'MOBILE')"
              :onOff="stateCoupons.current.l_type_mobil == 1"
            ></ToggleCheckbox>
          </div>
          <div class="validation-error" v-if="formValidation.vCouponPlatform.errorMessage">
            {{ formValidation.vCouponPlatform.errorMessage }}
          </div>
        </div>
        <div class="form-row">
          <div class="input-group">
            <label for="txtAdminNotes">Admin Notes</label>
            <textarea
              id="txtAdminNotes"
              name="txtAdminNotes"
              v-model="stateCoupons.current.c_adminnotes"
              cols="30"
              rows="10"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="form-row">
          <div class="flex-column">
            <!-- <h3 class="text-base">Schedule</h3> -->
            <div class="input-group">
              <label for="txtStartingDate">Starting Date</label>
              <div class="flex flex-col mr-2 input-group">
                <VueCtkDateTimePicker
                  v-model="stateCoupons.current.c_date_start"
                  :format="'YYYY-MM-DD HH:mm'"
                  :formatted="'YYYY-MM-DD HH:mm'"
                  :no-label="true"
                  :overlay="true"
                  @input="dismissError('vCouponDate')"
                ></VueCtkDateTimePicker>
              </div>
            </div>
            <div class="input-group">
              <label for="txtExpiredDate">Expired Date</label>
              <div class="flex flex-col mr-2 input-group">
                <VueCtkDateTimePicker
                  v-model="stateCoupons.current.c_date_end"
                  :format="'YYYY-MM-DD HH:mm'"
                  :formatted="'YYYY-MM-DD HH:mm'"
                  :no-label="true"
                  :overlay="true"
                  :right="true"
                  @input="dismissError('vCouponDate')"
                ></VueCtkDateTimePicker>
              </div>
            </div>
            <button class="btn-primary" @click="addDate()">
              <i class="flaticon-plus"></i>
              <span>Add Date</span>
            </button>
          </div>
          <div class="mt-2" v-if="stateCoupons.current.c_dates.length > 0">
            <div class="table-list">
              <table>
                <thead>
                  <tr>
                    <th class="w-5/12">Start Date</th>
                    <th class="w-5/12">End Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(dateItem, idx) in stateCoupons.current.c_dates" :key="idx">
                    <td>
                      {{ formatDate(dateItem.date_start) }}
                      <div class="text-sm font-semibold">{{ formatTime(dateItem.date_start) }}</div>
                    </td>
                    <td>
                      {{ formatDate(dateItem.date_end) }}
                      <div class="text-sm font-semibold">{{ formatTime(dateItem.date_end) }}</div>
                    </td>
                    <td>
                      <button class="btn-primary" @click.prevent="deleteDate(idx)">Delete</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="validation-error" v-if="formValidation.vCouponDate.errorMessage">
              {{ formValidation.vCouponDate.errorMessage }}
            </div>
        </div>
        <div class="form-row">
          <div class="group-input">
            <div class="input-group">
              <label for="txtStore">Store</label>
              <div class="flex-col mr-2 input-group">
                <div class="checkbox-group-wrapper flex">
                  <div class="checkbox-group" v-for="(storeItem, idx) in stateStores.stores" :key="idx">
                    <label class="mb-2" :for="storeItem.shash">{{ storeItem.s_name }}</label>
                    <input
                      type="checkbox"
                      :id="storeItem.shash"
                      :value="storeItem.shash"
                      v-model="stateCoupons.current.c_stores"
                      @change="dismissError('vCouponStore')"
                    />
                    <!-- <label class="mb-2" :for="storeItem.shash">{{ storeItem.s_name }}</label> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="validation-error" v-if="formValidation.vCouponStore.errorMessage">
            {{ formValidation.vCouponStore.errorMessage }}
          </div>
        </div>
        <div class="form-row">
          <div class="flex-column">
            <div class="input-group">
              <label for="useType">Use Type</label>
              <div class="flex radio-group-wrapper">
                <div class="radio-group">
                  <label for="useType0">Single</label>
                  <input
                    id="useType0"
                    name="useType"
                    type="radio"
                    v-model="stateCoupons.current.c_use_type"
                    :value="0"
                  />
                </div>
                <div class="radio-group">
                  <label for="useType1">Multiple</label>
                  <input
                    id="useType1"
                    name="useType"
                    type="radio"
                    v-model="stateCoupons.current.c_use_type"
                    :value="1"
                  />
                </div>
              </div>
            </div>
            <div class="input-group">
              <label for="txtCouponCode">Coupon Code</label>
              <div class="flex flex-col mr-2 input-group">
                <input
                  id="txtCouponCode"
                  name="txtCouponCode"
                  type="text"
                  v-model="stateCoupons.current.c_code"
                  @focus="dismissError('vCouponCode')"
                />
              </div>
            </div>
            <button class="btn-primary" @click="addCouponCode()">
              <i class="flaticon-plus"></i>
              <span>Add Code</span>
            </button>
          </div>
          <div class="validation-error" v-if="formValidation.vCouponCode.errorMessage">
            {{ formValidation.vCouponCode.errorMessage }}
          </div>
          <div class="mt-2" v-if="stateCoupons.current.c_codes.length > 0">
            <div class="table-list">
              <table>
                <thead>
                  <tr>
                    <th class="w-5/12">Coupon Code</th>
                    <th class="w-5/12">Usage Type</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(codeItem, idx) in stateCoupons.current.c_codes" :key="idx">
                    <td>
                      <div
                        class="px-4 py-2 text-2xl font-semibold text-center text-yellow-500 bg-yellow-100 border-2 border-yellow-500 border-dashed rounded-md"
                      >{{ codeItem.code }}</div>
                    </td>
                    <td>
                      <div v-if="codeItem.i_type === 0">SINGLE</div>
                      <div v-if="codeItem.i_type === 1">MULTIPLE</div>
                    </td>
                    <td>
                      <button class="btn-primary" @click.prevent="deleteCouponCode(idx)">Delete</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import ToggleCheckbox from "@/widgets/ToggleCheckbox";

export default {
  name: "CouponsForm",
  data() {
    return {
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "$",
        // suffix: " #",
        precision: 2,
        masked: false
      },
      stores: [],
    };
  },
  components: {
    ToggleCheckbox,
    // Money,
  },
  created() {
    this.setStores();
  },
  computed: {
    ...mapState({
      stateStores: "stores",
      stateCommon: "common",
      stateMenus: "menus",
      stateCoupons: "coupons"
    }),
    ...mapGetters(["getFormType"]),
    getAllProducts() {
      const allProductsList = [];
      const { menus } = this.stateMenus;
      const combinedMenus = [...menus.b, ...menus.s];
      combinedMenus.forEach(menu => {
        menu.products.forEach(product => {
          allProductsList.push({
            phash: product.phash,
            name: product.p_name,
            mname: menu.m_name,
            select_stat: 0
          });
        });
      });
      return allProductsList;
    },
    currentCoupon() {
      return this.stateCoupons.current;
    },
    formValidation() {
      return this.stateCoupons.formValidation;
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format("MMMM Do, YYYY");
    },
    formatTime(date) {
      return moment(date).format("h:mm a");
    },
    addDate() {
      const { c_date_start, c_date_end, c_dates } = this.currentCoupon;
      if (c_date_start && c_date_end) {
        const dateExists = c_dates.some(dateItem => dateItem.date_start === c_date_start && dateItem.date_end === c_date_end);
        if (!dateExists) {
          c_dates.push({ date_start: c_date_start, date_end: c_date_end });
          this.currentCoupon.c_date_start = "";
          this.currentCoupon.c_date_end = "";
          this.dismissError("vCouponDate");
        } else {
          this.formValidation.vCouponDate.isValid = false;
          this.formValidation.vCouponDate.errorMessage = "This date range already exists!";
        }
      } else {
        this.formValidation.vCouponDate.isValid = false;
        this.formValidation.vCouponDate.errorMessage = "Please fill date start and date end areas!";
      }
    },
    deleteDate(itemIdx) {
      this.currentCoupon.c_dates.splice(itemIdx, 1);
    },
    addCouponCode() {
      this.formValidation.vCouponCode.isValid = true;
      if (!this.currentCoupon.c_code) {
        this.formValidation.vCouponCode.isValid = false;
        this.formValidation.vCouponCode.errorMessage = "Coupon code cannot be empty!";
      } else if (this.currentCoupon.c_code.length < 6) {
        this.formValidation.vCouponCode.isValid = false;
        this.formValidation.vCouponCode.errorMessage = "Coupon code must be at least 6 characters!";
      } else if (this.currentCoupon.c_codes.some(codeItem => codeItem.code === this.currentCoupon.c_code)) {
        this.formValidation.vCouponCode.isValid = false;
        this.formValidation.vCouponCode.errorMessage = "This code already exists!";
      } else {
        this.currentCoupon.c_codes.push({
          code: this.currentCoupon.c_code,
          i_type: this.currentCoupon.c_use_type
        });
        this.currentCoupon.c_code = "";
        this.dismissError("vCouponCode");
      }
    },
    deleteCouponCode(itemIdx) {
      this.currentCoupon.c_codes.splice(itemIdx, 1);
    },
    changeStatus(value, stateToggle) {
      let toggleNewValue = value ? 1 : 0;
      switch (stateToggle) {
        case "WEB":
          this.currentCoupon.l_type_online = toggleNewValue;
          this.dismissError("vCouponPlatform");
          break;
        case "POS":
          this.currentCoupon.l_type_pos = toggleNewValue;
          this.dismissError("vCouponPlatform");
          break;
        case "KIOSK":
          this.currentCoupon.l_type_kiosk = toggleNewValue;
          this.dismissError("vCouponPlatform");
          break;
        case "MOBILE":
          this.currentCoupon.l_type_mobil = toggleNewValue;
          break;
        default:
          break;
      }
      this.dismissError("vCouponPlatform");
    },
    // Validation Functions
    isEmpty(val) {
      return val === "" || val === " " || val === null ? true : false;
    },
    dismissError(field) {
      this.formValidation[field].isValid = false;
      this.formValidation[field].errorMessage = "";
    },
    checkForm() {
      let checkingCouponName = this.isValidCouponName();
      let checkingCouponDiscount = this.isValidCouponDiscount();
      let checkingCouponPlatform = this.isValidCouponPlatform();
      let checkingCouponDate = this.isValidCouponDate();
      let checkingCouponStore = this.isValidCouponStore();
      let checkingCouponCode = this.isValidCouponCode();
      return checkingCouponName 
      && checkingCouponDiscount
      && checkingCouponPlatform
      && checkingCouponDate
      && checkingCouponStore
      && checkingCouponCode ? true : false;
    },
    isValidCouponName() {
      if (this.isEmpty(this.currentCoupon.c_name)) {
        this.formValidation.vCouponName.isValid = false;
        this.formValidation.vCouponName.errorMessage =
          "Coupon name cannot be empty!";
        return false;
      }
      this.formValidation.vCouponName.isValid = true;
      this.formValidation.vCouponName.errorMessage = "";
      return true;
    },
    isValidCouponDiscount() {
      if (this.isEmpty(this.currentCoupon.c_discount) || this.currentCoupon.c_discount == 0) {
        this.formValidation.vCouponDiscount.isValid = false;
        this.formValidation.vCouponDiscount.errorMessage =
          "Coupon discount cannot be empty or zero!";
        return false;
      } else if (this.currentCoupon.c_discount < 0) {
        this.formValidation.vCouponDiscount.isValid = false;
        this.formValidation.vCouponDiscount.errorMessage =
          "Coupon discount cannot be negative!";
        return false;
      }
      this.formValidation.vCouponDiscount.isValid = true;
      this.formValidation.vCouponDiscount.errorMessage = "";
      return true;
    },
    isValidCouponPlatform() {
      if (
        this.currentCoupon.l_type_online == 0 &&
        this.currentCoupon.l_type_pos == 0 &&
        this.currentCoupon.l_type_kiosk == 0 &&
        this.currentCoupon.l_type_mobil == 0
      ) {
        this.formValidation.vCouponPlatform.isValid = false;
        this.formValidation.vCouponPlatform.errorMessage =
          "Please select at least one platform!";
        return false;
      }
      this.formValidation.vCouponPlatform.isValid = true;
      this.formValidation.vCouponPlatform.errorMessage = "";
      return true;
    },
    isValidCouponDate() {
      if (this.currentCoupon.c_dates.length == 0) {
        this.formValidation.vCouponDate.isValid = false;
        this.formValidation.vCouponDate.errorMessage =
          "Please add at least one date!";
        return false;
      }
      this.formValidation.vCouponDate.isValid = true;
      this.formValidation.vCouponDate.errorMessage = "";
      return true;
    },
    isValidCouponStore() {
      if (this.currentCoupon.c_stores.length == 0) {
        this.formValidation.vCouponStore.isValid = false;
        this.formValidation.vCouponStore.errorMessage =
          "Please select at least one store!";
        return false;
      }
      this.formValidation.vCouponStore.isValid = true;
      this.formValidation.vCouponStore.errorMessage = "";
      return true;
    },
    isValidCouponCode() {
      if (this.currentCoupon.c_codes.length == 0) {
        this.formValidation.vCouponCode.isValid = false;
        this.formValidation.vCouponCode.errorMessage =
          "Please add at least one code!";
        return false;
      }
      this.formValidation.vCouponCode.isValid = true;
      this.formValidation.vCouponCode.errorMessage = "";
      return true;
    },
    setStores() {
      if(this.getFormType == 1) {
        this.stores = this.stateCoupons.current.c_stores;
      }
    }
  }
};
</script>
<style lang="css" scoped>
.row {
  display: flex;
  align-items: baseline;
  gap: 2em;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 2em;
}
.product-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
}
.product-wrapper .checkbox-group {
  width: 25%;
}
.form-row .input-group label {
  width: auto;
}
button {
  margin-top: 1em;
}
.mb-2 {
  margin-bottom: 0.5em;
}
</style>
