<template>
  <div>
    <div class="form-button-actions">
      <button class="btn-secondary" @click="cancel()">Cancel</button>
      <button class="btn-primary" @click="updateStock()" v-if="getFormType == 1">Update</button>
      <button class="btn-primary" @click="saveStock()" v-else>Save</button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import store from "@/store";
export default {
  name: "StockFormButton",
  data() {
    return {
      brandHash: "",
      storeHash: ""
    }
  },
  computed: {
    ...mapState(["stocks", "common", "stores", "invoices" ]),
    ...mapGetters(["getFormType"])
  },
  created() {
    this.brandHash = this.stores.current.brandHash;
    this.storeHash = this.stores.current.storeHash;
  },
  methods: {
    cancel() {
      this.$parent.$parent.$refs.stockForm.restoreUnit();
      store.dispatch("closeStockModalBox");
      if(this.common.location == "invoices") {
        this.$parent.$parent.$refs.invoiceForm.$forceUpdate();
      }
    },
    updateStock() {
      const stockHash = store.state.stocks.vStockHash;
      if(this.$parent.$parent.$refs.stockForm.checkForm()) {
        store.dispatch("updateStock", {
          stockHash,
          brandHash: this.brandHash,
          storeHash: this.storeHash
        });
      }
    },
    saveStock() {
      if(this.common.location == "invoices") {
        const vendorMatch = this.stocks.vVendorList.some(vendor => vendor.vendor_hash === this.invoices.vCompanyHash);
        if (!vendorMatch) {
          store.commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: "Vendor is not as same as invoice vendor!" });
          store.commit("LOADING", { isActiveStatus: false});
          return;
        }
      }
      if(this.$parent.$parent.$refs.stockForm.checkForm()) {
        store.dispatch("saveStock", {
          brandHash: this.brandHash,
          storeHash: this.storeHash
        });
        // need to fix: force update for stock price
        if(this.common.location == "invoices") {
          setTimeout(() => {
            this.$parent.$parent.$refs.invoiceForm.$forceUpdate();
          }, 2000);
        }
      }
    }
  }
}
</script>