<template>
  <div>
    <div class="form-button-actions">
      <button class="btn-primary" v-if="!stateIngredient" @click="toggleIngredient()">{{ ingredient.name }}</button>
      <button class="btn-secondary" v-if="stateIngredient" @click="toggleIngredient()">Close {{ ingredient.name }}</button>
    </div>
    <div>
      <div class="ingredient" v-if="stateIngredient">
        <p>Ingredient Name: {{ ingredient.name }}</p>
        <p>Ingredient Hash: {{ ingredient.ingredient_hash }}</p>
        <p>Ingredient Unit: {{ ingredient.unit }}</p>
        <p>Ingredient Amount: {{ ingredient.amount }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IngredientInfo',
  data() {
    return {
      stateIngredient: false,
    }
  },
  props: {
    ingredient: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    }
  },
  methods: {
    toggleIngredient() {
      this.stateIngredient = !this.stateIngredient;
    }
  },
}
</script>

<style css scoped>
.form-button-actions {
  padding: 10px 0;
}
.ingredient p {
  font-size: 1em;
  line-height: 1em;
  padding-left: 30px;
  padding-bottom: 10px;
}
</style>