const getInvoicesDefaultState = () => {
    return {
        isReadOnly: false,
        isWrite: true,
        startDate: new Date().toISOString().substr(0, 10), 
        endDate: new Date().toISOString().substr(0, 10),
        vInvoiceList: [],
        // Invoice
        vInvoiceHash: '',
        vInvoiceNumber: '',
        vInvoiceType: '',
        vCompanyHash: '',
        vCompany: '',
        vEmail: '',
        vMessageInvoice: '',
        vBillingAddress: '',
        vInvoiceDate: new Date().toISOString().substr(0, 10),
        vGST: '',
        vPST: '',
        // Calculated
        vTaxtotal: '',
        vSubtotal: '',
        vTotal: '',
        // Table
        selectedRow: 0,
        // Croppa
        myCroppa: {},
        pictureControl: -1,
        // Second Modal by 'Add New Company/ Product'
        vendorModalBox: false,
        stockModalBox: false,
        // table fields
        tableFields: [
          {
            vRowId: '-1',
            vProduct: '',
            vStockHash: '',
            vProductPrice: '',
            vDescription: '',
            vAmount: 1,
            vGST: '',
            vPST: '',
            vProductTax: '',
            vProductTotal: '',
            vUnitHash: '',
            vUnitName: '',
          }
        ],
        // Validation
        formValidation: {
          vInvoiceType: { isValid: false, errorMessage: '' },
          vCompanyHash: { isValid: false, errorMessage: '' },
          vCompany: { isValid: false, errorMessage: '' },
          vEmail: { isValid: false, errorMessage: '' },
          vMessageInvoice: { isValid: false, errorMessage: '' },
          vBillingAddress: { isValid: false, errorMessage: '' },
          vInvoiceDate: { isValid: false, errorMessage: '' },
          vInvoiceNumber: { isValid: false, errorMessage: '' },
          vGST: { isValid: false, errorMessage: '' },
          vPST: { isValid: false, errorMessage: '' },
          vProduct: { isValid: false, errorMessage: '' },
          vPicture: { isValid: false, errorMessage: '' },
        },
      };
}

const invoices = {
    state: getInvoicesDefaultState(),
    mutations: {
        RESET_INVOICE_FORM(state) {
          state.firstSave = false,
          state.vInvoiceType = '',
          state.vInvoiceHash = '',
          state.vInvoiceNumber = '',
          state.vCompanyHash = '',
          state.vCompany = '',
          state.vEmail = '',
          state.vMessageInvoice = '',
          state.vBillingAddress = '',
          state.vInvoiceDate = new Date().toISOString().substr(0, 10),
          state.vGST = '',
          state.vPST = '',
          state.vTaxtotal = '',
          state.vSubtotal = '',
          state.vTotal = '',
          state.selectedRow = 0,
          state.myCroppa = {},
          state.pictureControl = -1,
          state.tableFields = [
            {
              vRowId: '-1',
              vProduct: '',
              vStockHash: '',
              vDescription: '',
              vAmount: 1,
              vGST: '',
              vPST: '',
              vProductTotal: '',
            },
          ],
          state.formValidation.vInvoiceType = { isValid: false, errorMessage: '',},
          state.formValidation.vCompanyHash = { isValid: false, errorMessage: '',},
          state.formValidation.vCompany = { isValid: false, errorMessage: '' },
          state.formValidation.vEmail = { isValid: false, errorMessage: '' },
          state.formValidation.vMessageInvoice = { isValid: false, errorMessage: '',},
          state.formValidation.vBillingAddress = { isValid: false, errorMessage: '',},
          state.formValidation.vInvoiceDate = { isValid: false, errorMessage: '',};
          state.formValidation.vInvoiceNumber = { isValid: false, errorMessage: '',};
          state.formValidation.vGST = { isValid: false, errorMessage: '',};
          state.formValidation.vPST = { isValid: false, errorMessage: '',};
          state.formValidation.vProduct = { isValid: false, errorMessage: '',};
        },
        GET_INVOICE(state, value) {
          state.tableFields.pop(); // Deleting empty first row in table
          state.vInvoiceDate = value.invoice_date;
          state.vInvoiceHash = value.invoice_hash;
          state.vInvoiceNumber = value.invoice_number;
          state.vCompanyHash = value.invoice_firm_hash;
          state.vCompany = value.invoice_firm_name;
          state.vEmail = value.invoice_firm_email;
          state.vBillingAddress = value.invoice_firm_address;
          state.vMessageInvoice = value.invoice_note;
          state.vGST = value.invoice_tax1;
          state.vPST = value.invoice_tax2;
          state.vSubtotal = value.invoice_sub_price;
          state.vTotal = value.invoice_total_price;
          state.myCroppa = value.invoice_files;
          state.tableFields = [];
          let tableItems = value.invoice_items;
          tableItems.map((item, index) => {
            let convertItem = {
              vRowId: index,
              vProduct: item.item_name,
              vDescription: item.item_description,
              vProductPrice: parseFloat(item.item_price).toFixed(2),
              vStockHash: item.item_stock_hash,
              vProductTotal: parseFloat(item.item_total_price).toFixed(2),
              vAmount: parseFloat(item.item_quantity),
              vGST: item.item_tax1_rate,
              vPST: item.item_tax2_rate,
              vGSTPrice: item.item_tax1_amount,
              vPSTPrice: item.item_tax2_amount,
              vProductTax: item.item_tax1_amount + item.item_tax2_amount,
              vUnitHash: item.item_unit_hash,
              vUnitName: item.item_unit_name,
            };
            state.tableFields.push(convertItem);
          });
        },
        ADD_INVOICE(state, value) {
          state.vInvoiceList.push(value);
        },
        UPDATE_INVOICE(state, payload) {
          let iIndex = state.vInvoiceList.findIndex(
            (item) => item.invoice_hash == payload.invoice_hash
          );
          Object.assign(state.vInvoiceList[iIndex], payload);
        },
        DELETE_INVOICE(state, value) {
          let iIndex=state.vInvoiceList.findIndex((item) => item.invoice_hash === value);
          state.vInvoiceList.splice(iIndex, 1);
        },
        UPDATE_VENDOR_IN_INVOICE(state, value) {
          state.vCompanyHash = value.hash;
          state.vCompany = value.name;
          state.vEmail = value.email;
          state.vBillingAddress = value.address;
          state.vendorModalBox = false;
        },
        UPDATE_STOCK_IN_INVOICE(state, value) {
          let newTableItem = {
            vRowId: null,
            vDescription: "",
            vAmount: 0,
            vGST: value.stock_tax1,
            vGSTPrice: 0,
            vPST: value.stock_tax2,
            vPSTPrice: 0,
            vProduct: value.stock_name,
            vProductTax: 0,
            vProductPrice: value.stock_unit_price,
            vProductTotal: 0,
            vBarcode: value.stock_barcode,
            vStockHash: value.stock_hash,
            vUnit: value.unit_name,
          };
          state.tableFields[state.selectedRow] = newTableItem;
          state.stockModalBox = false;
        },
        SET_TABLE_FIELDS(state, value) {
          state.tableFields = value;
        }
    },
    actions: {
        closeInvoiceModalBox({ commit }) {
          commit("RESET_INVOICE_FORM");
          commit("RESET_MODALBOX");
        },
        async getInvoiceList({ state, commit }, { 
          startDate, 
          endDate, 
          invoiceNumber,
          brandHash,
          storeHash,
        }) {
          state.vInvoiceList = [];
          commit("LOADING", { isActiveStatus: true});
          try {
              let { data } = await window.$http.axios.get(
                `inventory/api/invoices?start=${startDate}&end=${endDate}&invoice_number=${invoiceNumber}&brand_hash=${brandHash}&store_hash=${storeHash}`
              );
              if(data.status) {
                state.vInvoiceList = data.invoices;
              } else {
                state.vInvoiceList = [];
                // commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: data.message });
              }
          } catch (error) {
              state.vInvoiceList = [];
              commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: "Invoice list has not been loaded successfully" });
          }
          commit("LOADING", { isActiveStatus: false});
        },
        async getInvoice({ commit }, {
          invoiceHash,
          brandHash,
          storeHash,
        }) {
          commit("LOADING", { isActiveStatus: true});
          try {
            let { data } = await window.$http.axios.get(
              `inventory/api/invoices/${invoiceHash}?brand_hash=${brandHash}&store_hash=${storeHash}`
            );
            commit('GET_INVOICE', data.invoice)
          } catch(error) {
            console.error(error);
          }
          commit("LOADING", { isActiveStatus: false});
        },
        async saveInvoice({ state, commit, dispatch }, {
          brandHash,
          storeHash,
        }) {
          const FormData = require('form-data');
          const formData = new FormData();
          let invoice = {
            invoice_number: state.vInvoiceNumber,
            invoice_date: state.vInvoiceDate,
            invoice_note: state.vMessageInvoice,
            invoice_sub_price: Number(state.vSubtotal),
            invoice_total_price: Number(state.vTotal),
            invoice_tax1: Number(state.vGST),
            invoice_tax2: Number(state.vPST),
            invoice_firm_name: state.vCompany,
            invoice_firm_hash: state.vCompanyHash,
            invoice_firm_email: state.vEmail,
            invoice_firm_address: state.vBillingAddress,
            invoice_type: 1,
            invoice_items: [],
            // invoice_files: state.myCroppa.generateDataUrl("image/jpeg", 0.8),
          };
          // invoice items
          state.tableFields.map((item) => {
            if(item.vProduct == "") return;
            let invoice_item = {
              item_name: item.vProduct,
              item_description: item.vDescription,
              item_stock_hash: item.vStockHash,
              item_tax1_rate: parseFloat(item.vGST),
              item_tax2_rate: parseFloat(item.vPST),
              item_price: Number(item.vProductPrice),
              item_quantity: Number(item.vAmount),
            };
            invoice.invoice_items.push(invoice_item);
          });
          formData.append('invoice', JSON.stringify(invoice));
          const config = {
            method: 'post',
            url: 'inventory/api/invoices?brand_hash=' + brandHash + '&store_hash=' + storeHash,
            data: formData,
          }
          commit("LOADING", { isActiveStatus: true});
          try {
            let { data } = await window.$http.axios(config);
            if(data.status) {
              commit('ADD_INVOICE', data.invoice)
              commit("NOTIFICATION_MESSAGE", { status: 1, title: "Saved", message: "Invoice has been saved successfully" });
              dispatch('closeInvoiceModalBox');
              
            } else {
              commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: data.message });
            }
          } catch (error) {
            console.log(error.message);
            commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: "Invoice has not been saved successfully" });
          }
          commit("LOADING", { isActiveStatus: false});
        },
        async updateInvoice({ state, commit, dispatch }, {
          invoiceHash,
          brandHash,
          storeHash,
        }) {
          const FormData = require('form-data');
          const formData = new FormData();
          let invoice = {
            invoice_number: String(state.vInvoiceNumber),
            invoice_date: state.vInvoiceDate,
            invoice_note: state.vMessageInvoice,
            invoice_sub_price: Number(state.vSubtotal),
            invoice_total_price: Number(state.vTotal),
            invoice_tax1: Number(state.vGST),
            invoice_tax2: Number(state.vPST),
            invoice_firm_name: state.vCompany,
            invoice_firm_hash: state.vCompanyHash,
            invoice_firm_email: state.vEmail,
            invoice_firm_address: state.vBillingAddress,
            invoice_type: 1,
            invoice_items: [],
            // invoice_files: state.myCroppa.generateDataUrl("image/jpeg", 0.8),
          };
          // invoice items
          state.tableFields.map((item) => {
            let invoice_item = {
              item_name: item.vProduct,
              item_description: item.vDescription,
              item_stock_hash: item.vStockHash,
              item_tax1_rate: parseFloat(item.vGST),
              item_tax2_rate: parseFloat(item.vPST),
              item_price: Number(item.vProductPrice),
              item_quantity: Number(item.vAmount),
            };
            invoice.invoice_items.push(invoice_item);
          });
          formData.append('invoice', JSON.stringify(invoice));
          const config = {
            method: 'put',
            url: `inventory/api/invoices/${invoiceHash}?brand_hash=${brandHash}&store_hash=${storeHash}`,
            data: formData,
          }
          commit("LOADING", { isActiveStatus: true});
          try {
            let { data } = await window.$http.axios(config);
            if(data.invoice) {
              commit('UPDATE_INVOICE', data.invoice);
              commit("NOTIFICATION_MESSAGE", { status: 1, title: "Updated", message: "Invoice has been updated successfully" });
              dispatch('closeInvoiceModalBox');
            } else {
              commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: data.message });
            }
          } catch (error) {
            commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: "Invoice has not been updated successfully" });
          }
          commit("LOADING", { isActiveStatus: false});
        },
        async deleteInvoice({ commit }, {
          invoiceHash,
          brandHash,
          storeHash,
        }) {
          commit("LOADING", { isActiveStatus: true});
          try {
            let { data } = await window.$http.axios.delete(`inventory/api/invoices/${invoiceHash}?brand_hash=${brandHash}&store_hash=${storeHash}`);
            if(data.status) {
              commit('DELETE_INVOICE', invoiceHash);
              commit("NOTIFICATION_MESSAGE", { status: 1, title: "Deleted", message: "Invoice has been deleted successfully" });
            } else {
              commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error", message: data.message });
            }
          } catch (error) {
            console.log(error.message);
            commit("NOTIFICATION_MESSAGE", { status: -1, title: "Error",message: "Invoice has not been deleted successfully" });
          }
          commit("LOADING", { isActiveStatus: false});
        },
    },
    getters: {
        getStartDate: (state) => state.startDate,
        getEndDate: (state) => state.endDate,
        getInvoiceNumber: (state) => state.vInvoiceNumber,
        stateTableFields: (state) => state.invoices.tableFields,
    }
}

export default invoices;