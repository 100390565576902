<template>
    <div id="invoice">
      <div class="invoice-header">
        <h2 class="flex-grow">Invoices</h2>
        <div>
          <button class="btn-primary" @click="showInvoiceModal(0, 'newInvoice')">
            <span>Create Invoice</span>
          </button>
        </div>
      </div>
      <div class="table-list-data">
        <dataset v-slot="{ ds }" :ds-data="stateInvoice.vInvoiceList" :ds-filter-fields="{}" :ds-sortby="sortBy"
          :ds-sort-as="{ invoice_number: numberParse, invoice_date: dateParse, invoice_tax1: numberParse, invoice_tax2: numberParse, invoice_sub_price: numberParse, invoice_total_price: numberParse }"
          :ds-search-in="[
            // 'invoice_number',
            'invoice_date',
            'invoice_firm_name',
          ]" :ds-search-as="{}">
          <div :data-page-count="ds.dsPagecount">
            <div class="actions-menu">
              <div class="table-show-wrapper">
                <dataset-show />
              </div>
              <div class="table-search-wrapper">
                <div class="search-box">
                  <!-- <label>Invoice Number : </label> -->
                  <input type="text" v-model="invoice_number" placeholder="Search..." />
                </div>
                <div class="search-box">
                  <label>Start Date </label>
                  <input 
                    type="date" 
                    v-model="stateInvoice.startDate"
                    :max="stateInvoice.endDate"
                  />
                </div>
                <div class="search-box">
                  <label>End Date </label>
                  <input 
                    type="date" 
                    v-model="stateInvoice.endDate"
                    :min="stateInvoice.startDate"
                  />
                </div>
              </div>
            </div>
            <div class="table-list">
              <table>
                <thead>
                  <tr>
                    <th v-for="(th, index) in cols" :key="th.field" :class="['sort', th.sort]"
                      @click="clickSort($event, index)">
                      {{ th.name }}
                      <i class="gg-select"></i>
                    </th>
                    <th class="table-actions-col"></th>
                  </tr>
                </thead>
                <dataset-item tag="tbody">
                  <template v-slot="{ row, rowIndex }">
                    <tr :key="rowIndex">
                      <td width="20%">{{ row.invoice_number }}</td>
                      <td width="25%">{{ row.invoice_date }}</td>
                      <td width="20%">{{ row.invoice_firm_name }}</td>
                      <!-- <td width="15%">{{ parseFloat(row.invoice_tax1).toFixed(2) }}</td>
                      <td width="15%">{{ parseFloat(row.invoice_tax2).toFixed(2) }}</td> -->
                      <td width="10%">${{ numberParse(row.invoice_sub_price) }}</td>
                      <td width="10%">${{ numberParse(row.invoice_total_price) }}</td>
                      <td width="15%">
                        <div class="item-actions">
                          <button @click="showInvoiceModal(1, 'editInvoice', row.invoice_hash)">
                            <i class="flaticon-edit"></i>
                          </button>
                          <button @click="deleteInvoice(row.invoice_hash)">
                            <i class="flaticon-delete"></i>
                          </button>
                        </div>
                      </td>
                      <div class="confirm" v-if="isDeletePanelActive && deleteInvoiceHash==row.invoice_hash">
                        <p>Are you sure you want to delete this invoice?</p>
                        <div>
                          <button @click="deleteYes(row.invoice_hash)">Yes</button>
                          <button @click="deleteNo()">No</button>
                        </div>
                      </div>
                    </tr>
                  </template>
                  <template v-slot:noDataFound>
                    <tr>
                      <td colspan="8">No results found</td>
                    </tr>
                  </template>
                </dataset-item>
              </table>
            </div>
            <div class="table-pagination">
              <div class="table-pagination-info-wrapper">
                <dataset-info />
              </div>
              <div class="table-pagination-pager-wrapper">
                <dataset-pager />
              </div>
            </div>
          </div>
        </dataset>
      </div>
      <SecondModalBox v-if="stateInvoice.vendorModalBox" :alias="'newVendor'">
        <template #modalTitle>New Vendor</template>
        <template #modalBody>
          <VendorForm ref="vendorForm" />
        </template>
        <template #modalFooter>
          <VendorFormButton />
        </template>
      </SecondModalBox>
      <SecondModalBox v-if="stateInvoice.stockModalBox" :alias="'newStock'">
        <template #modalTitle>New Stock</template>
        <template #modalBody>
          <StockForm ref="stockForm" />
        </template>
        <template #modalFooter>
          <StockFormButton />
        </template>
      </SecondModalBox>
      <ModalBox :alias="'newInvoice'">
        <template #modalTitle>New Invoice</template>
        <template #modalBody>
          <InvoiceForm ref="invoiceForm" />
        </template>
        <template #modalFooter>
          <InvoiceFormButton />
        </template>
      </ModalBox>
      <ModalBox :alias="'editInvoice'">
        <template #modalTitle>Edit Invoice</template>
        <template #modalBody>
          <InvoiceForm ref="invoiceForm" />
        </template>
        <template #modalFooter>
          <InvoiceFormButton />
        </template>
      </ModalBox>
    </div>
  </template>
  
  <script>
import { mapState, mapGetters } from 'vuex';
import store from '@/store';
import ModalBox from '@/widgets/ModalBox';
import SecondModalBox from '@/widgets/ModalBox/SecondModalBox.vue';
import InvoiceForm from './InvoiceForm';
import InvoiceFormButton from './InvoiceFormButtons';
import VendorForm from '../Vendors/VendorForm';
import VendorFormButton from '../Vendors/VendorFormButtons';
import StockForm from '../Stocks/StockForm';
import StockFormButton from '../Stocks/StockFormButtons';

import {
    Dataset,
    DatasetItem,
    DatasetInfo,
    DatasetPager,
    // DatasetSearch,
    DatasetShow,
} from "vue-dataset";

export default {
  name: "Invoices",
  data() {
    return {
      vInvoiceList: [],
      cols: [
        {
          name: "Invoice Number",
          field: "invoice_number",
          sort: "invoice_number"
        },
        {
          name: "Date",
          field: "invoice_date",
          sort: "invoice_date"
        },
        {
          name: "Company",
          field: "invoice_firm_name",
          sort: "invoice_firm_name"
        },
        // {
        //   name: "GST",
        //   field: "invoice_tax1",
        //   sort: "invoice_tax1"
        // },
        // {
        //   name: "PST",
        //   field: "invoice_tax2",
        //   sort: "invoice_tax2"
        // },
        {
          name: "Subtotal",
          field: "invoice_sub_price",
          sort: "invoice_sub_price"
        },
        {
          name: "Total",
          field: "invoice_total_price",
          sort: "invoice_total_price"
        }
      ],
      startDate: '',
      endDate: '',
      invoice_number: '',
      brandHash: '',
      storeHash: '',
      isDeletePanelActive: false,
      deleteInvoiceHash: '',
    };
  },
  components: {
    ModalBox,
    SecondModalBox,
    InvoiceForm,
    InvoiceFormButton,
    VendorForm,
    VendorFormButton,
    StockForm,
    StockFormButton,
    Dataset,
    DatasetItem,
    DatasetInfo,
    DatasetPager,
    // DatasetSearch,
    DatasetShow,
  },
  watch: {
    getStartDate() {
      this.getInvoiceList();
    },
    getEndDate() {
      this.getInvoiceList();
    },
    invoice_number() {
      this.getInvoiceList();
    }
  },
  computed: {
    ...mapState({ 
      stateInvoice: "invoices", 
      stateStore: "stores" 
    }),
    ...mapGetters(["getStartDate", "getEndDate", "getInvoiceNumber"]),
    sortBy() {
      return this.cols.reduce((acc, o) => {
        if (o.sort) {
          o.sort === "asc" ? acc.push(o.field) : acc.push("-" + o.field);
        }
        return acc;
      }, []);
    },
  },
  created() {
    this.brandHash = this.stateStore.current.brandHash;
    this.storeHash = this.stateStore.current.storeHash;
    this.getInvoiceList();
  },
  methods: {
    numberParse(val) {
      return parseFloat(val);
    },
    dateParse(val) {
      return new Date(val).getTime();
    },
    clickSort(event, i) {
      let toset;
      const sortEl = this.cols[i];
      if (!event.shiftKey) {
        this.cols.map(o => {
          if (o.field !== sortEl.field) {
            o.sort = "";
          }
        });
      }
      if (!sortEl.sort) {
        toset = "asc";
      }
      if (sortEl.sort === "desc") {
        toset = event.shiftKey ? "" : "asc";
      }
      if (sortEl.sort === "asc") {
        toset = "desc";
      }
      sortEl.sort = toset;
    },
    getInvoiceList() {
      store.dispatch("getInvoiceList", {
        startDate: this.stateInvoice.startDate,
        endDate: this.stateInvoice.endDate,
        invoiceNumber: this.invoice_number,
        brandHash: this.brandHash,
        storeHash: this.storeHash,
      })
    },
    showInvoiceModal(type, alias, invoiceHash) {
      let modalOpts = {
        isActiveStatus: true,
        type: type,
        alias: alias,
      };
      if (invoiceHash) {
        store.dispatch("getInvoice", {
          invoiceHash,
          brandHash: this.brandHash,
          storeHash: this.storeHash,
        });
        modalOpts["hash"] = invoiceHash;
      }
      store.dispatch(
        "showModalBox",
        Object.assign(store.state.common.modalBox, modalOpts)
      );
    },
    deleteInvoice(invoiceHash) {
      this.deleteInvoiceHash = invoiceHash;
      this.isDeletePanelActive = true;
    },
    deleteYes(invoiceHash) {
      store.dispatch("deleteInvoice", {
        invoiceHash,
        brandHash: this.brandHash,
        storeHash: this.storeHash,
      });
      this.deleteNo();
    },
    deleteNo() {
      this.deleteInvoiceHash = "";
      this.isDeletePanelActive = false;
    },
  }
};
</script>

<style scoped>
.invoice-header {
  display: flex; 
  padding-bottom: 1rem; 
  margin-bottom: 1rem; 
  justify-content: space-between; 
  align-items: center; 
  border-bottom-width: 1px; 
  border-color: #F3F4F6; 
}
.flex-grow {
  flex-grow: 1; 
}
.actions-menu {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center; 
}
.item-actions {
  justify-content: center;
}
.item-actions button {
  font-size: 1.6em;
  line-height: 1.5rem;
  padding: 0 10px;
  background: none;
  border: none;
  outline: none;
  font-weight: 600;
  border-radius: var(--rounded-lg);
  margin: 0 auto;
}
.search-box input {
  width: unset;
}
</style>