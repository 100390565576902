<template>
  <div
    class="product-group-element"
    :class="!gtype ? ['yellow'] : ['green']"
  >
    <div class="mb-2">
      <div class="pel-title">
        <span
          class="pel-name"
          :class="!gtype ? ['y-text'] : ['g-text']"
        >{{gelement.name}}</span>
        <button @click.prevent="$emit('click-child', gelement.ohash)">
          <i class="flaticon-close" :class="!gtype ? ['y-text'] : ['g-text']"></i>
        </button>
      </div>
      <div v-if="gelement.desc" class="pel-desc">({{gelement.desc}})</div>
    </div>
    <div v-if="gtype===0">
      <div class="mini-checkbox-wrapper">
        <label :for="`default-${checkboxID}`">
          <span>Default</span>
          <ToggleCheckbox
          @clickToggle="setDefault()" 
          :onOff="gelement.selected == 1"></ToggleCheckbox>
        </label>
      </div>
    </div>
    <div class="config" v-if="gtype===1">
      <div class="mini-checkbox-wrapper">
        <label :for="`checked-${checkboxID}`">
          <span>Checked</span>
          <ToggleCheckbox
          @clickToggle="setDefault()" 
          :onOff="gelement.selected == 1"></ToggleCheckbox>
        </label>
      </div>
      <div class="mini-checkbox-wrapper">
        <label :for="`unremovable-${checkboxID}`">
          <span>Unremovable</span>
          <ToggleCheckbox
          @clickToggle="setUnremovable()" 
          :onOff="gelement.removable == 1"></ToggleCheckbox>
        </label>
      </div>
    </div>
    <div class="mini-checkbox-wrapper">
      <div v-if="isSizeOpen" class="grid-cols-2 grid">
        <div class="size-row" v-for="(size, sIdx) in stateProducts.product.sizes" :key="sIdx">
          <label :for="`default-${checkboxID}`">{{size.name}}</label>
          <div class="flex">
            <div class="size-type">
              <label>Light</label>
              <money
                v-model="grecipe[sIdx].light_amount"
              ></money>
            </div>
            <div class="size-type">
              <label>Regular</label>
              <money
                v-model="grecipe[sIdx].regular_amount"
              ></money>
            </div>
            <div class="size-type">
              <label>Extra</label>
              <money
                v-model="grecipe[sIdx].extra_amount"
              ></money>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ToggleCheckbox from "@/widgets/ToggleCheckbox";
import { Money } from 'v-money';

export default {
  name: "ProductGroupElement",
  props: ["gelement", "grecipe", "gtype", "checkboxID", "isSizeOpen"],
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        suffix: ' #',
        precision: 2,
        masked: false
      }
    };
  },
  components: {
    ToggleCheckbox,
    Money,
  },
  computed: {
    ...mapState({
      stateProducts: "products",
      stateSizes: state => state.products.product.sizes,
      currentSize: state => state.products.currentSize,
    })
  },
  watch: {
    stateSizes: {
      handler: function() {
        this.updateRecipeBySize();
      }
    }
  },
  created () {
    this.addNewRecipe();
  },
  methods: {
    addNewRecipe() {
      // if grecipe doesn't have any amount 
      // add new recipe
      if(this.grecipe.length == 0) {
        this.stateSizes.map((size) => {
          let newRecipe = {
            regular_amount: 0,
            light_amount: 0,
            extra_amount: 0,
            size_id: size.code,
          };
          this.grecipe.push(newRecipe);
        });
      }
    },
    updateRecipeBySize() {
      if(this.currentSize.status) {
        // create new recipe
        let newRecipe = {
          regular_amount: 0,
          light_amount: 0,
          extra_amount: 0,
          size_id: this.currentSize.code,
        };
        this.grecipe.push(newRecipe);
        return;
      } else {
        // remove recipe
        this.grecipe.map((item, idx) => {
          if(item.size_id == this.currentSize.code) {
            this.grecipe.splice(idx, 1);
            return;
          }
        });
      }
    },
    changeStatus(value, stateToggle) {
      let toggleNewValue = value ? 1 : 0;
      switch(stateToggle) {
        case "SIZE":
          this.gelement.status = toggleNewValue;
          break;
      }
    },
    setDefault() {
      this.gelement.selected = this.gelement.selected === 1 ? 0 : 1;
    },
    setUnremovable() {
      this.gelement.removable = this.gelement.removable === 1 ? 0 : 1;
    }
  }
};
</script>

<style lang="css" scoped>
.product-group-element {
  display: inline-block; 
  padding: 0.5rem; 
  margin-right: 0.5rem; 
  margin-bottom: 0.5rem; 
  font-size: 0.875rem;
  line-height: 1.25rem; 
  font-weight: 300; 
  border-radius: 0.25rem; 
  border-width: 1px; 
  border-style: solid; 
}
.pel-title {
  display: flex; 
  flex-direction: row; 
  justify-content: space-between; 
}
.pel-title button {
  cursor: pointer;
  font-size: 1.6em;
  line-height: 1.5rem;
  padding: 0 10px;
  background: none;
  border: none;
  outline: none;
  font-weight: 600;
  border-radius: var(--rounded-lg);
}
.pel-name {
  font-weight: 600; 
  width: 150px;
}
.pel-desc {
  font-size: 0.75rem;
  line-height: 1rem; 
}
.mini-checkbox-wrapper {
  margin-right: 0.5rem;
}
.mini-checkbox-wrapper label {
  font-size: 0.75rem;
  line-height: 1rem; 
  line-height: 1; 
  display: flex;
  align-items: center;
  gap: 1rem; 
}
.size-row {
  display: grid;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  margin: 10px 0 5px;
}
.size-row .flex {
  align-items: center;
  gap: 5px;
}
.size-type label {
  font-size: 0.7em;
}
.size-type input {
  width: 3rem;
}
/* custom */
.yellow {
  background-color: #FFFBEB; 
  border-color: #FEF3C7; 
}
.y-text {
  color: #F59E0B; 
}
.green {
  background-color: #ECFDF5; 
  border-color: #D1FAE5; 
}
.g-text {
  color: #10B981; 
}
.flex {
  display: flex; 
}
.mb-2 {
  margin-bottom: 0.5rem; 
}
.items-center {
  align-items: center; 
}
.config {
  display: flex; 
  flex-direction: column;
}
.config .mini-checkbox-wrapper {
  margin-bottom: 0.5rem; 
  justify-content: space-between;
}
.config .mini-checkbox-wrapper span {
  width: 100px;
}
</style>  