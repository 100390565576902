<template>
  <div
    class="sidebar"
  >
    <div class="sidebar-header">
      <router-link to="/">
        <svg
          data-name="logo"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1920 384"
        >
          <path
            d="m1178.71 234.43-59.07-136.12h-39.63l78.89 181.88h39.55l78.9-181.88h-39.49l-59.15 136.12zM778.42 207.37q60.63 0 60.63-54.57t-60.63-54.56H657.17v182h36.37v-72.9h17.68l85.32 72.75h55.89l-85.25-72.75ZM693.54 171v-36.39h78.82q30.24 0 30.31 18.19T772.36 171ZM79.8 98.31.91 280.19h39.63L99.61 144l59.15 136.19h39.56L119.36 98.31H79.8zM1299.82 243.82h181.88v36.38h-181.88zM366.23 225.04 257.84 98.31h-37.12v181.88h36.38V153.47l108.54 126.72h36.97V98.31h-36.38v126.73zM560.24 98.24H439v182h121.24q60.69 0 60.62-60.63V159q0-60.75-60.62-60.76Zm24.17 116.52q0 28.77-29.13 28.76h-80V134.61h79.93q28.9 0 29 28.62ZM1801.55 98.31l-78.96 181.88h39.7L1821.3 144l59.22 136.19H1920l-78.89-181.88h-39.56zM1518.08 158.94v60.63q0 60.71 60.63 60.63H1700v-97h-36.09v60.63h-80.07q-29.57 0-29.57-29.21v-50.8c0-19.37 9.76-29.13 29.2-29.13h116.3V98.31h-121.28c-40.3 0-60.41 18.19-60.41 60.63ZM1299.82 171.07h181.88v36.38h-181.88zM1299.82 98.31h181.88v36.38h-181.88zM908.92 276.05l-46.65 107.51h39.63l44.8-103.51h50q60.71 0 60.63-60.63v-60.63q0-44.88-33.42-56.49L1068.25-.47h-39.63l-42.81 98.71h-50q-60.69 0-60.62 60.63v60.62c.24 30.02 11.48 48.8 33.73 56.56Zm2.88-110.9q0-30.32 24.26-30.31h54.56q30.32 0 30.31 30.31v48.5q0 30.32-30.31 30.32h-48.5q-30.32 0-30.32-30.32Z"
          />
        </svg>
      </router-link>
    </div>
    <DashboardMenu ref="menu"></DashboardMenu>
  </div>
</template>

<script>
import DashboardMenu from "@/components/Dashboard/Sidebar/Menu/Menu";

export default {
  name: "Sidebar",
  components: {
    DashboardMenu
  },
};
</script>

<style lang="css" scoped>
.sidebar {
  background: #e5e5e5;
  position: fixed; 
  left: 0px;
  right: 0px;
  top: 0px;
  z-index: 10;
  width: 15rem;
  height: 100vh; 
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  border-right-width: 1px;
  border-color: #F3F4F6; 
  /* border-right: #999 1px solid;; */
  z-index: 20;
}
.sidebar-header {
  margin-left: auto;
  margin-right: auto; 
  margin-bottom: 1.25rem; 
  width: 8rem; 
}
</style>