<template>
  <!-- <transition enter-active-class="slide-enter-active" leave-active-class="slide-leave-active"> -->
  <div class="notification" :class="`${notificationClass}`">
    <div class="notification-icon">
      <i :class="`flaticon-${notificationClass}`"></i>
    </div>
    <div class="notification-body">
      <div class="notification-title" v-if="title">{{ title }}</div>
      <div class="notification-message">{{ message }}</div>
    </div>
  </div>
  <!-- </transition> -->
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Notification",
  props: {
    status: {
      // status => -1: error, 0: info, 1:success, 2: warning
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: "Notification Title"
    },
    message: {
      type: String,
      default: "Notification Message"
    }
  },
  computed: {
    ...mapState({
      stateCommon: "common"
    }),
    notificationClass() {
      if (this.status === -1) {
        return "error";
      } else if (this.status === 1) {
        return "success";
      } else if (this.status === 2) {
        return "warning";
      }
      return "info";
    }
  },
  mounted() {
    let notify = setInterval(() => {
      this.stateCommon.notification.notificationList.splice(0, 1);
      clearInterval(notify);
    }, 2000);
    // this.stateCommon.notification.notificationList.splice(0, 1);
  }
};
</script>

<style lang="css" scoped>
 /* .notification-wrapper {
   position: fixed;
   top: 25px;
   right: 25px;
 } */

.notification {
	 display: flex;
	 overflow: hidden;
	 margin-bottom: 0.25rem;
	 background-color: #fff;
	 flex-direction: row;
	 align-items: center;
	 width: 20rem;
	 border-radius: 0.5rem;
	 border-width: 2px;
	 border-left-width: 8px;
	 border-color: #f9fafb;
	 border-style: solid;
	 box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
   z-index: 9999!important;
}
 .notification.error {
	 border-color: #b91c1c;
}
 .notification.error i, .notification.error .notification-title {
	 color: #b91c1c;
}
 .notification.info {
	 border-color: #4338ca;
}
 .notification.info i, .notification.info .notification-title {
	 color: #4338ca;
}
 .notification.success {
	 border-color: #059669;
}
 .notification.success i, .notification.success .notification-title {
	 color: #059669;
}
 .notification.warning {
	 border-color: #f59e0b;
}
 .notification.warning i, .notification.warning .notification-title {
	 color: #f59e0b;
}
 .notification-icon {
	 margin: 0.5rem;
	 font-size: 1.875rem;
	 line-height: 2.25rem;
	 text-align: center;
	 width: 16.666667%;
}
 .notification-body {
	 padding: 0.5rem;
}
 .notification-title {
	 font-size: 1rem;
	 line-height: 1.5rem;
	 font-weight: 600;
	 text-transform: uppercase;
}
 .notification-message {
	 color: #1f2937;
	 font-size: 0.875rem;
	 line-height: 1.25rem;
	 font-weight: 400;
}
 
/* .notification-wrapper.success .notification-content {
  border-color: var(--green-600);
}
.notification-wrapper.error .notification-content {
  border-color: var(--red-700);
}

.notification-wrapper.success .notification-icon {
  color: var(--green-600);
}
.notification-wrapper.error .notification-icon {
  color: var(--red-700);
}

.notification-wrapper.success .notification-title {
  color: var(--green-600);
}
.notification-wrapper.error .notification-title {
  color: var(--red-700);
} */

</style>