<template>
  <div class="page">
    <div class="right-bar-wrapper" v-if="stateCommon.notification.notificationList.length > 0">
      <Notification
        class="slide-enter-active"
        v-for="(notify, idx) in stateCommon.notification.notificationList"
        :key="idx"
        :message="notify.message"
        :status="notify.status"
        :title="notify.title"
      ></Notification>
    </div>
    <div class="flex">
      <Sidebar ref="sidebar"></Sidebar>
      <div class="grow content">
        <component :is="stateCommon.location"></component>
      </div>
    </div>
    <FooterWrapper></FooterWrapper>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import store from "@/store";

import Sidebar from "@/components/Dashboard/Sidebar/Sidebar";
import FooterWrapper from "@/components/Dashboard/FooterWrapper";
import Menus from "@/views/Menus/";
import Products from "@/views/Products";
import OptionItems from "@/views/OptionItems";
import Stocks from "@/views/Stocks";
import Vendors from "@/views/Vendors";
import Invoices from "@/views/Invoices";
import StockAudits from "@/views/Stocks/StockAudits";
import StockReductedReports from "@/views/Stocks/StockReductedReports";
import StockAddedReports from "@/views/Stocks/StockAddedReports";
import Coupons from "@/views/Coupons";
import PosUsers from "@/views/BranchSettings/PosUsers";
import BranchSettings from "@/views/BranchSettings";
import WorkingHours from "@/views/BranchSettings/WorkingHours";
import DeliveryMap from "@/views/BranchSettings/DeliveryMap";
import CustomerScreen from "@/views/BranchSettings/CustomerScreen";
import Menuboard from "@/views/BranchSettings/Menuboard";
import Loader from "@/widgets/Loader";

export default {
  name: "Dashboard",
  computed: {
    ...mapState({
      stateStores: "stores",
      stateMenus: "menus",
      stateCommon: "common",
      stateAuth: "auth",
    }),
    ...mapGetters(["getFirstStoreHash"])
  },
  components: {
    Sidebar,
    FooterWrapper,
    Menus,
    Products,
    OptionItems,
    Stocks,
    Vendors,
    Invoices,
    StockAudits,
    StockReductedReports,
    StockAddedReports,
    Coupons,
    PosUsers,
    BranchSettings,
    WorkingHours,
    DeliveryMap,
    CustomerScreen,
    Menuboard,
    Loader,
  },
  created() {
    this.firstConfig();
    this.getStores();
    store.commit("LOADING", { isActiveStatus: true });
  },
  methods: {
    parseJwt (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    },
    firstConfig() {
      let getLocalToken = localStorage.getItem("token");
      // decode token
      let expTime = this.parseJwt(getLocalToken).exp;
      let now = new Date().getTime();
      if (now > expTime * 1000) {
        console.log('expired')
        store.dispatch("logoutService");
        this.stateAuth.isAuthenticated = false;
        return;
      }
      console.log('not expired')
      this.$http.defaults.headers.common["Authorization"] = `Bearer ${getLocalToken}`;
    },
    getStores() {
      let $self = this;
      this.axios.get("get/stores")
        .then((response) => {
          $self.$store.commit("SET_STORE_LIST", response.data);
          if ($self.stateStores.current.storeHash === "") {
            $self.$store.commit("SET_CURRENT_STORE", {
              brandHash: $self.stateStores.stores[0].bhash,
              brandName: $self.stateStores.stores[0].b_name,
              storeHash: $self.stateStores.stores[0].shash,
              storeName: $self.stateStores.stores[0].s_name
            });
            $self.stateMenus.sizes = $self.stateStores.stores[0].sizes;
          }
        })
        .then(() => {
          store.dispatch("getMenus", this.stateStores.current.storeHash)
          .then(() => {
            store.dispatch("getTabsMenu");
          });
          store.dispatch("getOptions", this.stateStores.current.storeHash)
          .then(() => {
            store.dispatch("getTabsOptionItems");
          })
          .then(() => {
            store.dispatch("getUnitList");
          })
          .then(() => {
            store.commit("LOADING", { isActiveStatus: false });
          });
        }).catch((error) => {
          store.commit("LOADING", { isActiveStatus: false });
          if(error) {
            localStorage.removeItem('token');
            this.stateAuth.isAuthenticated = false;
          } else {
            console.log('error', error);
          };
        });
    },
  }
};
</script>

<style lang="css" scoped>
.right-bar-wrapper {
  z-index: 9999!important;
}
.flex {
  display: flex;
  width: 100%;
  overflow-x: hidden;
}
.content {
  padding: 2rem 1rem 4em;  
  padding-left: 16rem; 
  width: calc(100vw - 18rem); 
}
</style>
