<template>
    <form class="customer-screen-form" action="#">
        <div class="left">
            <div class="form-row">
                <div class="form-col">
                    <div class="input-group">
                        <label for="txtCustomerScreenTitle">CustomerScreen Title</label>
                        <input 
                            id="txtCustomerScreenTitle"
                            name="txtCustomerScreenTitle"
                            type="text"
                            v-model="stateCustomerScreen.a_name"
                            @focus="dismissError('a_name')"
                        >
                        <div class="validation-error" v-if="formValidation.a_name.errorMessage">
                            {{ formValidation.a_name.errorMessage }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="input-group">
                    <label for="txtCustomerScreenDescription">Description</label>
                    <textarea 
                        id="txtCustomerScreenDescription" 
                        name="txtCustomerScreenDescription" 
                        cols="30" 
                        rows="10"
                        v-model="stateCustomerScreen.a_desc"
                        @focus="dismissError('a_desc')"
                    ></textarea>
                </div>
            </div>
        </div>
        <div class="right">
            <div class="imagearea" v-bind:class="{picture_error:stateCustomerScreen.pictureControl==0}">
                <croppa
                @image-remove="onRemoveImage"
                @new-image-drawn="onLoadImage"
                v-model="stateCustomerScreen.myCroppa"
                :height="200"
                :input-attrs="{class:'croppainput'}"
                :placeholder-font-size="16"
                :prevent-white-space="true"
                :width="200"
                :show-loading="true"
                canvas-color="#edf4f4"
                remove-button-color="black"
                ></croppa>
                <span class="pe-message" v-if="stateCustomerScreen.pictureControl==0">Image is required.</span>
            </div>
        </div>
    </form>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'CustomerScreenForm',
    computed: {
        ...mapState({
            stateCustomerScreen: "customerscreens",
            formValidation: state => state.customerscreens.formValidation
        })
    },
    methods: {
        onLoadImage() {
            this.stateItem.pictureControl = 1;
        },
        onRemoveImage() {
            this.stateItem.pictureControl = 0;
        },
        dismissError(field) {
            this.stateCustomerScreen.formValidation[field].isValid = false;
            this.stateCustomerScreen.formValidation[field].errorMessage = "";
        },
        checkForm() {
            if(this.isEmpty(this.stateCustomerScreen.a_name)) {
                this.formValidation.a_name.isValid = false;
                this.formValidation.a_name.errorMessage = "CustomerScreen name cannnot be empty!.";
                return false;
            }
            this.formValidation.a_name.isValid = true;
            this.formValidation.a_name.errorMessage = "";
            return true;
        }
    }
}
</script>

<style>
.customer-screen-form {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    align-items: center;
}
</style>