<template>
  <div id="delivery-map">
    <div class="delivery-map-header">
      <h2 class="flex-grow">Delivery Map</h2>
      <!-- <button class="btn-primary" @click="addNewZone()">Add Zone</button> -->
      <button class="btn-primary" v-if='new_area_type==0 && area_edit_status==0' @click='addNewArea()'>Create Area</button>
    </div>
    <div class="zone-wrapper">
			<div class="area-info">
				<div class="item"
          v-for="(item, index) in stateMenus.areas" 
          :key="item.a_hash"
          @mouseover="areaMouseOver(index)"
          @mouseleave="areaMouseLeave(index)"
          :class="{
            editing: area_edit_status == 1, 
            active: item.remove_stat==1 || item.editable==1,
            ek: item.a_type == 1,
          }"
        >
          <!-- delete area buttons -->
          <div class='item-remove-area confirm' v-if='item.remove_stat'>
            <p class="message">Are you sure you want to delete this map?</p>
            <div class='ba'>
              <div class='btn' @click='areaDeleteYes(item)'>YES</div>
              <div class='btn' @click='areaDeleteNo(item)'>NO</div>
            </div>
          </div>
          <!-- main or additional area -->
          <div class='a'>
            <div v-if='item.editable==1'>
              <input class='w100y' v-model='edit_name' type='text'>
            </div>
            <div v-if='item.editable==0'>
              {{item.area_name}} 
              <span v-if='item.a_type==0'>(MAIN AREA)</span>
              <span v-if='item.a_type==1'>(ADDITIONAL AREA)</span>
            </div>
          </div>
					<div class='p'>
            <div v-if='item.editable==1'>
              <div class='min'>
                <span>Delivery Price:</span>
                <money size=7 v-model='edit_price' v-bind="money" ></money>
              </div>
              <div class='min'>
                <span>Min.Amount:</span>
                <money size=7 v-model='edit_min_price' v-bind="money" ></money>
              </div>
              <div class='atype'>
                <span :class='{aktif:edit_atype==0}' @click='edit_atype=0'>MAIN</span>
                <span :class='{aktif:edit_atype==1}'  @click='edit_atype=1'>ADDITIONAL</span>
              </div>
            </div>
            <div v-if='item.editable==0'>
              <div class='dp'><span>Delivery Price:</span>${{parseFloat(item.a_pricing).toFixed(2)}}</div>
              <div class='dp'><span>Min.Amount:</span>${{parseFloat(item.a_min_pricing).toFixed(2)}}</div>
            </div>
          </div>
          <div class='b'>
            <div v-if='item.editable==1' class="action-items">
              <button class="mr-2 btn btn-primary" @click='areaEditSave(item, index)'>SAVE</button>
              <button class="btn btn-secondary"  @click='areaEditCancel(item, index)'>Cancel</button>
            </div>
            <div v-if='item.editable==0' class="action-items">
              <button class="mr-2 btn btn-primary" @click='areaEdit(item, index)'>Edit</button>
              <button class="btn btn-secondary"  @click='areaDelete(item)'>Remove</button>
            </div>
          </div>
				</div>
        <div class='newarea'>
					<div class='item' v-if='new_area_type==1'>
						<div class='a'><input class='w100y' v-model='edit_name' type='text'></div>
						<div class='p'>
							<div class='min'><span>Delivery Price:</span><money size=7 v-model='edit_price' v-bind="money" ></money></div>
							<div class='min'><span>Min.Amount:</span><money size=7 v-model='edit_min_price' v-bind="money" ></money></div>
							<div class='atype'>
                <span :class='{aktif:edit_atype==0}' @click='edit_atype=0'>MAIN</span>
                <span :class='{aktif:edit_atype==1}'  @click='edit_atype=1'>ADDITIONAL</span></div>
						</div>
						<div class='b action-items'>
							<button class="btn btn-primary" @click='addNewAreaSave'>Save</button>
              <button class="btn btn-secondary"  @click='addNewAreaCancel'>Cancel</button>
						</div>
					</div>
				</div>
			</div>
			<div class="zone-maps">
				<div id="map"></div>
			</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Money } from "v-money";
import { Loader } from '@googlemaps/js-api-loader';
import store from '@/store';
import qs from 'qs';

export default {
  name: "DeliveryMap",
  data() {
    return {
      map: null,
      drawingManager : null,
			newAreaPolygon: [],
      // otherAreaPolygon: [],
			polygonArray : [],
      area_edit_status : 0,
			edit_name: '',
			edit_price: 0.00,
			edit_min_price: 0.00,
			edit_atype: 0,
			new_area_type: 0,
			money: {
				decimal: '.',
				thousands: ',',
				prefix: '$',
				precision: 2,
				masked: false
			},

      storeHash: "",
    };
  },
  components: {
    Money,
  },
  created() {
    this.storeHash = this.stateStore.current.storeHash;
  },
  computed: {
    ...mapState({
      stateMenus: "menus",
      stateStore: "stores",
    }),
  },
	mounted() {
		this.initMap();
	},
  methods: {
		initMap() {
      this.polygonArray = [];
      const loader = new Loader({
        // apiKey: 'AIzaSyAYXRVh1iHnMfouQvbd6ElzP4uOT1zhFj8',
        apiKey: 'AIzaSyConUEl-n-mTUqIP1AXxT002DXbJ29SiL0',
        libraries: ['visualization', 'drawing'],
      });
      loader.load()
        .then((google) => {
          const mapElement = document.getElementById('map');
          const lat = Number(this.stateMenus.areas[0].latlngs[0].lat);
          const lng = Number(this.stateMenus.areas[0].latlngs[0].lng);
          const defaultLocation = { lat: lat, lng: lng } 

          this.map = new google.maps.Map(mapElement, {
            center: defaultLocation,
            zoom: 12,
            mapTypeId: google.maps.MapTypeId.ROADMAP
          });

          this.drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: google.maps.drawing.OverlayType.POLYGON,
            drawingControl: true,
            drawingControlOptions: {
              position: google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [google.maps.drawing.OverlayType.POLYGON]
            },
            polygonOptions: {
              fillColor: 'gray',
              fillOpacity: 0.05,
              strokeWeight: 2,
              strokeColor: 'red',
              clickable: false,
              editable: false,
              zIndex: 1,
            }
          });

          // For existing areas (createPolygon)
          this.stateMenus.areas.map((area) => {
            this.newAreaPolygon = [];
            area.latlngs.map((latlng) => {
              this.newAreaPolygon.push(new google.maps.LatLng(latlng.lat, latlng.lng));
            });
            // for (var z = 0; z < area.latlngs.length; z++) {
            //   this.newAreaPolygon.push(new google.maps.LatLng(parseFloat(area.latlngs[z].lat), parseFloat(area.latlngs[z].lng)));
            // }
            // change latlngs to polygon format
            const a_polygon = JSON.stringify(this.newAreaPolygon);
            const boundary = new google.maps.Polygon({
              paths: JSON.parse(a_polygon),
              strokeColor: 'blue',
              strokeWeight: 2,
              fillColor: 'gray',
              fillOpacity: 0.1,
              zIndex: 2,
              content: area.area_name,
              cursor: 'grab'
            });
            boundary.setMap(this.map);
            this.polygonArray.push(boundary);
          });

          // Add event listener for polygon complete event
          google.maps.event.addListener(this.drawingManager, 'polygoncomplete', function (polygon) {
            // polygon.getPath().map((latlng) => {
            //   this.newAreaPolygon.push({ lat: latlng.lat(), lng: latlng.lng() });
            // });
            for (var i = 0; i < polygon.getPath().getLength(); i++) {
              this.newAreaPolygon.push({lat: polygon.getPath().getAt(i).lat(), lng: polygon.getPath().getAt(i).lng()});
            }
          });

          // For other areas
          this.stateMenus.otherstoreareas.map((area) => {
          this.otherAreaPolygon = [];
          area.latlngs.map((latlng) => {
            this.otherAreaPolygon.push(new google.maps.LatLng(latlng.lat, latlng.lng));
          });
          // change latlngs to polygon format
          const a_polygon = JSON.stringify(this.otherAreaPolygon);
          var boundary = new google.maps.Polygon({
            paths: JSON.parse(a_polygon),
            strokeColor: 'red',
            strokeWeight: 1,
            fillColor: '#999999',
            fillOpacity: 0.1,
            zIndex: 1,
            content: area.area_name,
            cursor: 'grab'
          });
          boundary.setMap(this.map);
          this.otherAreaPolygon.push(boundary);
        });
      }).catch((error) => console.log(error));
		},
    areaMouseOver(index) {
      var Options = {strokeWeight: 4};
      this.polygonArray[index].setOptions(Options);
    },
    areaMouseLeave(index) {
      var Options = {strokeWeight: 1};
      this.polygonArray[index].setOptions(Options);
    },
    // Edit
    areaEdit(aitem, index)  {
      this.polygonArray[index].setEditable(true);
      this.polygonArray[index].setDraggable(true);
      this.polygonArray[index].setVisible(true);
      console.log(this.polygonArray);
      if(this.area_edit_status == 0) {
        aitem.editable = 1;
        this.area_edit_status = 1;
				this.edit_name = JSON.parse(JSON.stringify(aitem.area_name));
				this.edit_price = JSON.parse(JSON.stringify(aitem.a_pricing));
				this.edit_min_price = JSON.parse(JSON.stringify(aitem.a_min_pricing));
				this.edit_atype = JSON.parse(JSON.stringify(aitem.a_type));
      }
    },
    async areaEditSave(aitem, index) {
      store.commit("LOADING", { isActiveStatus: true}); 
      let editedAreaLatLng = [];
			for (var i = 0; i < this.polygonArray[index].getPath().getLength(); i++) {
				editedAreaLatLng.push({
          lat: this.polygonArray[index].getPath().getAt(i).lat(),
          lng: this.polygonArray[index].getPath().getAt(i).lng()
        });
			}
      const postdata = {
        'latlngs': JSON.stringify(editedAreaLatLng),
        'area_name': this.edit_name,
        'a_pricing': this.edit_price,
        'a_min_pricing': this.edit_min_price,
        'a_type': this.edit_atype,
      }
      let formData = qs.stringify(postdata)
      let { data } = await window.$http.axios.post(
        `store/updatearea?s=${this.storeHash}&ahash=${aitem.a_hash}`,
        formData,
      );
      try {
        if(data.status) {
          aitem.latlngs = JSON.parse(JSON.stringify(editedAreaLatLng));
          aitem.area_name = JSON.parse(JSON.stringify(this.edit_name));
          aitem.a_pricing = JSON.parse(JSON.stringify(this.edit_price));
          aitem.a_min_pricing = JSON.parse(JSON.stringify(this.edit_min_price));
          aitem.a_type = JSON.parse(JSON.stringify(this.edit_atype));
          aitem.editable = 0;
          this.area_edit_status = 0;
          // store.commit('updateArea', aitem); // update area to store
          this.initMap();
        } else {
          alert('Something went wrong!');
        }
      } catch (e) {
        console.log(e);
      }
      store.commit("LOADING", { isActiveStatus: false});
    },
    areaEditCancel(aitem, index) {
      this.polygonArray[index].setEditable(false);
      const sample = [];
      for (var z = 0; z < aitem.latlngs.length; z++) {
				sample.push(new google.maps.LatLng(parseFloat(aitem.latlngs[z].lat), parseFloat(aitem.latlngs[z].lng)));
			}
			this.polygonArray[index].setPaths(sample);
      aitem.editable = 0;
      this.area_edit_status = 0;
    },
    // Delete
    areaDelete(aitem) {
      this.stateMenus.areas.map((area) => {
        // remove the area
        if(area.a_hash == aitem.a_hash) {
          area.remove_stat = 1;
        } else {
          area.remove_stat = 0;
        }
      });
    },
    async areaDeleteYes(aitem) {
      store.commit("LOADING", { isActiveStatus: true});
      let { data } = await window.$http.axios.post(
        `store/deletearea?s=${this.storeHash}&ahash=${aitem.a_hash}`,
      );
      try {
        if(data.status) {
          aitem.remove_stat = 0;
          this.area_edit_status = 0;
          // delete area from store
          this.stateMenus.areas = this.stateMenus.areas.filter((area) => area.a_hash !== aitem.a_hash);
          this.initMap();
        } else {
          alert('Something went wrong!');
        }
      } catch (e) {
        console.log(e);
      }
      store.commit("LOADING", { isActiveStatus: false});
    },
    areaDeleteNo(aitem) {
      aitem.remove_stat = 0;
      this.area_edit_status = 0;
    },
    // Add New
    addNewArea() {
      if(this.area_edit_status == 0) {
        this.edit_name = '';
				this.edit_price = 0.00;
				this.edit_min_price = 0.00;
				this.edit_atype = 1;
				// this.newAreaPolygon = [];

				this.area_edit_status = 1;
				this.new_area_type = 1;
				this.drawingManager.setMap(this.map);
      }
      // console.log(this.drawingManager)
    },
    async addNewAreaSave() {
      store.commit("LOADING", { isActiveStatus: true});
      const postdata = {
        'latlngs': JSON.stringify(this.newAreaPolygon),
        'area_name': this.edit_name,
        'a_pricing': this.edit_price,
        'a_min_pricing': this.edit_min_price,
        'a_type': this.edit_atype,
      }
      // formData.append('data', JSON.stringify(postdata));
      let formData = qs.stringify(postdata);
      const latlngsObjectArray = JSON.stringify(this.newAreaPolygon);
      let { data } = await window.$http.axios.post(
        `store/addarea?s=${this.storeHash}`,
        formData,
      );
      try {
        if(data.status) {
          this.area_edit_status = 0;
          this.new_area_type = 0;
          // add new area to store
          this.stateMenus.areas.push({
            a_hash: data.a_hash,
            area_name: this.edit_name,
            a_pricing: this.edit_price,
            a_min_pricing: this.edit_min_price,
            a_type: String(this.edit_atype),
            latlngs: JSON.parse(latlngsObjectArray),
            editable: 0,
            remove_stat: 0,
          });
          this.initMap();
          this.area_edit_status = 0;
          this.new_area_type = 0;
        } else {
          console.log(data.message);
        };
      } catch (e) {
        console.log(e);
      }
      store.commit("LOADING", { isActiveStatus: false});
    },
    addNewAreaCancel() {
      this.area_edit_status = 0;
      this.new_area_type = 0;
      this.initMap();
    },
  },
};
</script>

<style lang="css" scoped>
.area-info .item .confirm {
  align-items: center;
  flex-direction: column;
}
.area-info .item .confirm .message {
  text-align: center;
  margin-bottom: 1rem;
}
.delivery-map-header {
  display: flex; 
  margin-bottom: 1rem; 
  margin-right: 1rem;
  flex-direction: row; 
  justify-content: space-between; 
  align-items: center; 
}
.zone-wrapper {
  width: 100%; 
	display: flex;
	flex-direction: row-reverse;
}
.zone-wrapper .area-info {
	width: 33.3333333%;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}
.item {
  padding: 0.5rem;
  margin-bottom: 1.25rem;
  background-color: #f9fafb;
  border-radius: 0.5rem;
  border-width: 1px;
  border-color: #f3f4f6;
  border-style: solid;
  flex-direction: column;
  gap: 0.5rem;
}
.item:last-child {
  margin-bottom: 0;
}
.item h3 {
  margin: 0;
  margin-bottom: 1.25rem;
  color: #4338ca;
  font-size: 1.25rem;
  line-height: 1.75rem;
  text-transform: uppercase;
}
.item strong {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
}
.item em {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-style: normal;
}
.item-row {
  display: flex;
  margin-bottom: 0.75rem;
  flex-direction: column;
}
.zone-maps {
  width: 66.666667%;
}
.zone-wrapper #map {
	height: 50vh;
	width: 100%;
}



.zone-maps{
	display: inline-block;
	width: 70%;
	vertical-align: top;
}
.area-info {
	display: inline-block;
	width: calc(30% - 20px);
	vertical-align: top;
	margin-left: 20px;
}
.area-info .item {
	border: 2px solid #7ba0c4;
	margin-bottom: 10px;
	border-radius: 2px;
	padding: 5px;
	position: relative;
}
.area-info .item .item-remove-area {
	position: absolute;
	width: 100%;
	height: 100%;
	left:0;
	top:0;
	background: #26230dcc;
	z-index: 1;
	/*display: table;*/
	display: flex;
	justify-content: center;
	align-items: center;
}
.area-info .item .item-remove-area .ta {
	/*display: table-cell;
	vertical-align: middle;*/
	padding: 20px;
	text-align: center;
	color: #ffffff;
}
.area-info .item .item-remove-area .ba {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
}
.area-info .item .item-remove-area .ba .btn {
	font-size: 12px;
	line-height: 30px;
}

.area-info .item .item-remove-area .ba .btn {
	background: #17b3e6;
	font-size: 16px;
	line-height: 50px;
	display: inline-block;
	padding: 0 40px;
	border-radius: 2px;
	color: #fff;
	cursor: pointer;
    transition: 0.2s;
    -webit-transition: 0.1s;
    -moz-transition: 0.1s;
    -o-transition: 0.1s;
    margin: 20px;
}
.area-info .item .item-remove-area .ba .btn:hover {
	background: #069bcd;
	box-shadow: 0 0 2px #0003;
}
.area-info .item .item-remove-area .ba .btn:active {
	box-shadow: none;
	transform: scale(.98)
}

.area-info .item .item-remove-area .ba .btn {
	margin: 0 10px;
	line-height: 30px;
	font-size: 12px;
}


.area-info .item.editing.active {
	opacity: 1;
}
.area-info .item.editing {
	opacity: .4;
}

.area-info .item.ek {
	color: #737373;
	border: 2px solid #afb0b1;
}



.area-info .bas {
	color: #7ba1c5;
	font-weight: 700;
	position: relative;
}

.area-info .item .a {
	font-weight: 700;
	line-height: 24px;
	display: block;
	margin-bottom: 5px;
}

.area-info .item input {
	font-size: 14px;
	display: inline-block;
	padding: 5px;
	background: #d9e0e6;
  color: #000;
	border: none;
  /* font-family: 'Metropolis', serif; */
  border-radius: 2px;
	vertical-align: middle;
}



.area-info .item .a span {
	font-size: 12px;
}
.area-info .item .p {
	display: inline-block;
	width: calc(100% - 100px);
	font-size: 22px;
	vertical-align: middle;
}
.area-info .item .dp, .area-info .item .min {
	display: inline-block;
	margin: 5px 0;
}
.area-info .item .dp span, .area-info .item .min span {
	display: inline-block;
	margin-right: 10px;
	font-size: 12px;
}

.area-info .item .p .atype {
	display: inline-block;
	vertical-align: middle;
}
.area-info .item .p .atype span {
	display: inline-block;
	font-size: 9px;
	padding: 5px;
	cursor: pointer;
}
.area-info .item .p .atype span.aktif {
	border: 2px dashed #7ba0c4;
	border-radius: 2px;
	cursor: default;
}
.area-info .item .action-items {
  display: flex;
  gap: 0.5rem;
}
.area-info .item.editing .b .btn {
	cursor: default;
}
.area-info .item.editing.aktif .b .btn {
	cursor: pointer;
}
</style>