<template>
  <div
    class="bag-group-element"
  >
    <div class="mb-2">
      <div class="bel-title">
        <span class="bel-name">{{pelement.p_name}}</span>
        <button @click.prevent="$emit('click-child', pelement.phash)">
          <i class="flaticon-close"></i>
        </button>
      </div>
      <div v-if="pelement.p_desc" class="bel-desc">({{pelement.p_desc}})</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "BagGroupElement",
  props: ["pelement"],
  data() {
    return {
      remove_stat: false
    };
  },
  computed: {
    ...mapState({
      stateProducts: "products"
    })
  },
  mounted() {}
};
</script>

<style lang="css" scoped>
.bag-group-element {
  /* display: inline-block;  */
  display: inline-flex;
  padding: 0.5rem; 
  margin-right: 0.5rem; 
  margin-bottom: 0.5rem; 
  background-color: #FEF2F2; 
  font-size: 0.875rem;
  line-height: 1.25rem; 
  font-weight: 300; 
  border-radius: 0.25rem; 
  border-width: 1px; 
  border-color: #FEE2E2; 
  border-style: solid; 
}
.bel-title {
  display: flex; 
  flex-direction: row; 
  justify-content: space-between; 
}
.bel-title .bel-name {
  color: #EF4444; 
  font-weight: 600; 
}
.get-title button i {
  color: #EF4444; 
}
.bel-desc {
  font-size: 0.75rem;
  line-height: 1rem; 
}
.mini-checkbox-wrapper {
  margin-right: 0.5rem;
}
.mini-checkbox-wrapper label {
  font-size: 0.75rem;
  line-height: 1rem; 
  line-height: 1; 
}
/* custom */
.mb-2 {
  margin-bottom: 0.5rem; 
}
</style>